import { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";

import Spinner from "../misc/Spinner";
import ErrorMessage from "../misc/ErrorMessage";
import {
  Tooltip,
  WarningTooltip,
  ImportantTooltip,
} from "../../components/Tooltip";
import { TYPES, STANDARD_TO_TITLE } from "../ecommerce/LocationMetadata";

const LOCATIONS = gql`
  query LocationsApi {
    locationsApi {
      party_id
      id
      name
      address
      city
      post_code
      soft_blocked
      coordinates {
        lat
        lng
      }
      country
      evses {
        connected
        id
        name
        ocpp
        charge_point_model
        soft_blocked
        vendor
        charging_type
        tariff {
          country_code
          party_id
          id
          currency
          type
          tariff_alt_text {
            language
            text
          }
          tariff_alt_url
          min_price {
            excl_vat
            incl_vat
          }
          max_price {
            excl_vat
            incl_vat
          }
          elements {
            price_components {
              type
              price
              vat
              step_size
            }
          }
          start_date_time
          end_date_time
          energy_mix {
            is_green_energy
            energy_sources {
              source
              percentage
            }
            environ_impact {
              category
              amount
            }
            supplier_name
            energy_product_name
          }
          last_updated
        }
        capabilities
        connectors {
          status
          id
          standard
          format
          power_type
          voltage
          max_amperage
          current_amperage
          session_id
          physical_reference
        }
        last_heartbeat
        last_updated
      }
      time_zone
      images {
        url
        thumbnail
        category
        type
        width
        height
      }
      facilities
      parking_type
      created
      last_updated
      dlb_enabled
    }
  }
`;

const CREATE_DLB_GROUP = gql`
  mutation CreateDLBGroup($group: DLBGroupInput!) {
    createDLBGroup(group: $group)
  }
`;

export const GroupForm = ({ onSubmit, onClose }) => {
  const { data, loading, error } = useQuery(LOCATIONS, {
    fetchPolicy: "no-cache",
  });

  const [groupName, updateGroupName] = useState(undefined);
  const [maxAmperage, updateMaxAmperage] = useState(undefined);
  const [selectedLocation, updateSelectedLocation] = useState(undefined);
  const [bufferPercent, updateBufferPercent] = useState(5);
  const [selectedDevices, updateSelectedDevices] = useState([]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  // This is where we handle filtering of tenancy priveleges
  const locations = data?.locationsApi.filter((x) => x.dlb_enabled);
  var location_selection = (
    <select
      className="bg-black border rounded source-sans-pro text-lg border-gray-500 p-1.5 text-white w-1/3"
      defaultValue="default"
      onChange={(e) => {
        const loc = locations.find((x) => x.id === e.target.value);
        updateSelectedLocation(loc);
        updateSelectedDevices([]);
      }}
    >
      <option value="default" label="Select Location" />
      {locations.map((x) => (
        <option value={x.id} key={x.id} label={x.name} />
      ))}
    </select>
  );
  if (locations.length === 0) {
    location_selection = (
      <div className=" bg-black border rounded source-sans-pro text-lg border-gray-500 p-1.5 brandPrimaryColor w-auto">
        Contact EVUp to purchase DLB for one of your locations.
      </div>
    );
  }

  return (
    <div className="border-2 border-black bg-primary p-4 rounded-lg flex flex-col gap-3">
      <div className="flex flex-row items-center">
        <h1 className="brandPrimaryColor w-1/6">Location:</h1>
        {location_selection}
        <button
          className="py-1 px-4 border border-black rounded bg-red-500 ml-auto"
          onClick={() => onClose()}
        >
          Cancel
        </button>
      </div>

      <div className="flex flex-row items-center">
        <h1 className="brandPrimaryColor w-1/6">Group Name:</h1>
        <input
          className="bg-black border source-sans-pro pl-2 rounded border-gray-500 w-1/3 py-1 text-white"
          placeholder={
            locations.length !== 0
              ? `e.g ${locations[0].name || locations[0].address} (Group 1)`
              : "e.g Flinders Street (Group 1)"
          }
          onChange={(e) => updateGroupName(e.target.value)}
        />
      </div>

      <div className="flex flex-row items-center">
        <h1 className="brandPrimaryColor w-1/6">Amperage Limit:</h1>
        <input
          type="number"
          className="bg-black border rounded source-sans-pro pl-2 text-lg border-gray-500 p-1 text-white w-16"
          onChange={(e) => updateMaxAmperage(Number.parseFloat(e.target.value))}
        />
        <ImportantTooltip id={"amps"} className="ml-2 text-white comfortaa">
          Important: It is critical you ensure this value leaves enough amperage
          for the rest of your property.
        </ImportantTooltip>
      </div>

      <div className="flex flex-row items-center">
        <h1 className="brandPrimaryColor w-1/6">Buffer Percent:</h1>
        <select
          className="bg-black border rounded border-gray-500 p-1.5 text-white w-16 source-sans-pro"
          value={`${bufferPercent}`}
          onChange={(e) => updateBufferPercent(Number.parseInt(e.target.value))}
        >
          <option value="0" label="0%" key="0" />
          <option value="5" label="5%" key="5" />
          <option value="10" label="10%" key="10" />
          <option value="15" label="15%" key="15" />
          <option value="20" label="20%" key="20" />
        </select>
        <Tooltip id={"buffer"} className="ml-2 text-white comfortaa">
          This will shave x% off the optimal charger amperage allocations, for
          additional safety.
        </Tooltip>
      </div>

      <div className="flex flex-row">
        <h1 className="brandPrimaryColor w-1/5">Devices:</h1>
        <div className="flex flex-col w-full gap-4 items-center">
          <DevicesList
            locEvses={selectedLocation?.evses.filter((x) => x.ocpp)}
            selectedEvses={selectedDevices}
            onChange={(evses) => {
              updateSelectedDevices(evses);
            }}
          />
        </div>
      </div>
      <div className="flex flex-row">
        <button
          className="ml-auto font-bold justify-center flex flex-row items-center bg-black p-3 brandPrimaryColor border-color-secondary border rounded-lg px-4"
          onClick={async () => {
            if (locations.length === 0) return;

            const groupInput = {
              group_name: groupName,
              location_id: selectedLocation.id,
              buffer: 1 + bufferPercent / 100,
              dlb_policy: "BALANCE_SPLIT",
              max_amperage: maxAmperage,
              devices: selectedDevices,
            };
            await onSubmit(groupInput);
          }}
        >
          Create +
        </button>
      </div>
    </div>
  );
};

function addEvse(locEvses, selectedEvses, evse_id) {
  const evse = locEvses.find((x) => evse_id === x.id);
  if (!selectedEvses.find((x) => evse_id === x.id) && evse) {
    const connectors = evse.connectors?.map((x) => {
      const phase_mapping = x.power_type === "AC_1_PHASE" ? [1] : [1, 2, 3];
      return {
        connector_id: x.id,
        phase_mapping,
      };
    });
    selectedEvses.push({
      evse_id,
      connectors,
    });
  }
  return selectedEvses;
}

function removeEvse(evses, evse_id) {
  return evses.filter((x) => x.evse_id !== evse_id);
}

function updatePhaseMapping(selectedEvses, evse_id, connector_id, mapping) {
  var map_array = [];
  switch (mapping) {
    case "L1": {
      map_array = [1];
      break;
    }
    case "L2": {
      map_array = [2];
      break;
    }
    case "L3": {
      map_array = [3];
      break;
    }
  }
  var selectedEvse = selectedEvses.find((x) => x.evse_id === evse_id);
  selectedEvse.connectors[connector_id - 1].phase_mapping = map_array;
  return selectedEvses;
}

export const DevicesList = ({ locEvses, selectedEvses = [], onChange }) => {
  return (
    <div className="bg-black border rounded border-gray-500 p-4 flex flex-col gap-4 w-full">
      <div className="flex flex-row items-center">
        <select
          className="bg-primary text-white border source-sans-pro border-gray-500 rounded p-1.5 w-48"
          onChange={(e) =>
            onChange(addEvse(locEvses, [...selectedEvses], e.target.value))
          }
        >
          <option value="default" label="Select Device" />
          {locEvses?.map((x) => (
            <option label={x.name} value={x.id} key={x.id} />
          ))}
        </select>
        <Tooltip id="devices" className="text-white ml-2 comfortaa">
          Select a device to add it to the group.
        </Tooltip>
      </div>
      {selectedEvses?.map((x) => (
        <Device
          selectedEvses={selectedEvses}
          evse={locEvses.find((y) => x.evse_id === y.id)}
          onPhaseMappingUpdate={(evse, connector_id, mapping) =>
            onChange(
              updatePhaseMapping(
                [...selectedEvses],
                evse,
                connector_id,
                mapping
              )
            )
          }
          onRemove={(x) => onChange(removeEvse([...selectedEvses], x))}
          key={x.id}
        />
      ))}
    </div>
  );
};

export const Device = ({ evse, onRemove, onPhaseMappingUpdate }) => {
  var connected = null;
  if (!evse?.connected) {
    connected = (
      <WarningTooltip className="ml-2 mb-1">
        Warning: This device is currently disconnected.
        <br />
        You can still add a disconnected device, or connect it after group
        creation,
        <br /> but if it is disconnected performance will be greatly impacted.
      </WarningTooltip>
    );
  }

  return (
    <div className="flex flex-col">
      <hr className="border-0.5 rounded mb-2" />
      <div className="flex flex-row items-center mb-2">
        <h1 className="text-white mt-1">{evse?.name}</h1>
        {connected}
        <button
          className="ml-auto border-gray-500 bg-red-600 text-white px-2 py-1 rounded"
          onClick={() => onRemove(evse.id)}
        >
          Remove
        </button>
      </div>
      <div className="flex grid-cols-2 gap-2">
        {evse?.connectors?.map((x, i) => (
          <DLBConnector
            evse_id={evse.id}
            connector={x}
            onPhaseMappingUpdate={onPhaseMappingUpdate}
            key={x.id}
          />
        ))}
      </div>
    </div>
  );
};

export const DLBConnector = ({ evse_id, connector, onPhaseMappingUpdate }) => {
  return (
    <div className="bg-primary p-3 flex flex-col w-full rounded gap-1">
      <h1 className="brandPrimaryColor">Connector {connector.id}</h1>
      <p className="text-gray-400">
        {TYPES[STANDARD_TO_TITLE[connector.standard]]?.title} -{" "}
        {connector?.power_type.replaceAll("_", " ")}
      </p>
      {connector.power_type === "AC_1_PHASE" && (
        <div className="flex flex-row items-center">
          <h1 className="text-white mr-4">Phase: </h1>
          <select
            className="rounded bg-black brandPrimaryColor p-1.5 text-xs"
            onChange={(e) =>
              onPhaseMappingUpdate(evse_id, connector.id, e.target.value)
            }
          >
            <option value="L1" label="L1" />
            <option value="L2" label="L2" />
            <option value="L3" label="L3" />
          </select>
          <ImportantTooltip id={"phase"} className="ml-2 text-white comfortaa">
            Important: It is critical that the grid phase that
            <br />a single phase connector is wired to is specified correctly.
          </ImportantTooltip>
        </div>
      )}
    </div>
  );
};
