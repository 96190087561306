import React from "react";
import CommandButton from "./CommandButton";

function Commands(props) {
  const { evse_id } = props;
  return (
    <div className="flex-shrink">
      <div className="text-xs px-2 text-center">
        <div className="my-1 p-1">
          <CommandButton
            evse_id={evse_id}
            messageType="Reset"
            payload={{}}
            title="Soft Reset"
          />
          <CommandButton
            evse_id={evse_id}
            messageType="Reset"
            payload={{}}
            title="Hard Reset"
          />
          <p>
            Gracefully stop all charging sessions and restart the machine. A
            Hard reset will force an immediate shutdown.{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Commands;
