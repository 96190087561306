import { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { Tooltip } from "../../components/Tooltip";
import Joi from "joi";
import Spinner from "../misc/Spinner";
import { useAuth } from "../../hooks/useAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CREATE_LOCATION = gql`
  mutation createLocation(
    $name: String!
    $publish: Boolean!
    $ocpi: Boolean!
    $address: Address!
    $parking_type: String!
    $party_id: String
    $type: String!
  ) {
    createLocation(
      name: $name
      publish: $publish
      ocpi: $ocpi
      address: $address
      party_id: $party_id
      parking_type: $parking_type
      type: $type
    )
  }
`;

const EDIT_LOCATION = gql`
  mutation EditLocation(
    $editLocationId: String!
    $name: String!
    $publish: Boolean!
    $ocpi: Boolean!
    $parkingType: String!
    $partyId: String!
    $type: String!
  ) {
    editLocation(
      id: $editLocationId
      name: $name
      publish: $publish
      ocpi: $ocpi
      parking_type: $parkingType
      party_id: $partyId
      type: $type
    )
  }
`;

const DELETE_LOCATION = gql`
  mutation ($id: String!) {
    deleteLocation(id: $id)
  }
`;

const TIME_ZONES = [
  "Australia/Adelaide",
  "Australia/Canberra",
  "Australia/Darwin",
  "Australia/Tasmania",
  "Australia/Melbourne",
  "Australia/Sydney",
  "Australia/Queensland",
];

const LOCATION_TYPES = ["ONLINE", "OFFLINE"];

const CREATE_MESSAGE = `The location represents a physical location where charging stations are
situated. The address you enter will be encoded into geo coordinates, so
please ensure it is accurate.`;

const EDIT_MESSAGE = `Changing location address is not allowed.
Instead, a new location must be created and devices transferred.`;

export const NewLocation = ({ role }) => {
  const [locationInfo, setLocationInfo] = useState({});

  const [createLocation, { loading, error, data }] =
    useMutation(CREATE_LOCATION);

  var user = useAuth();

  if (loading) return <Spinner />;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <ToastContainer // for managing the browser messages like error message
        toastStyle={{ borderRadius: 200 }}
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <LocationEditForm
        type={"CREATE"}
        role={role}
        onChange={(info) => setLocationInfo(info)}
      />
      <button
        id="creation-button"
        className="mt-2 btn-green"
        onClick={async () => {
          var valid =
            validateLocation(locationInfo, user) &&
            validateAddress(locationInfo.address);

          if (!valid) {
            toast.error(
              "Please ensure all fields are filled in correctly to proceed!"
            );
            return;
          }
          var result = await createLocation({
            variables: {
              name: locationInfo.name,
              publish: locationInfo.publish,
              ocpi: locationInfo.ocpi,
              address: locationInfo.address,
              parking_type: locationInfo.parking_type
                ? locationInfo.parking_type
                : "ON_STREET",
              party_id: locationInfo.party_id,
              type: locationInfo.type ? locationInfo.type : "ONLINE",
            },
          });

          var locationId = result?.data?.createLocation;

          if (result) {
            toast("Operation success, location created");
            window.location.assign(`/locations/${locationId}`);
          }
        }}
      >
        Submit
      </button>
    </div>
  );
};

function transformLocation(location) {
  return {
    id: location.id,
    name: location.name,
    publish: location.publish,
    ocpi: true,
    address: {
      street_address: location.address,
      city: location.city,
      post_code: location.postal_code,
      country: location.country,
    },
    parking_type: location.parking_type,
    party_id: location.party_id,
    time_zone: location.time_zone,
    coordinates: location.coordinates,
    type: location.type,
  };
}

export const EditLocation = ({ role, location }) => {
  const [locationInfo, updateLocationInfo] = useState(
    transformLocation(location)
  );
  const [editLocation, editStatus] = useMutation(EDIT_LOCATION);
  const [deleteLocation, deleteStatus] = useMutation(DELETE_LOCATION);

  if (editStatus?.loading || deleteStatus?.loading) return <Spinner />;
  if (editStatus?.error || deleteStatus?.error)
    return <p>An error occurred! :C</p>;

  console.log("pub", location.publish);

  return (
    <div>
      <ToastContainer
        toastStyle={{ borderRadius: 200 }}
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <LocationEditForm
        type={"UPDATE"}
        role={role}
        onChange={(info) => updateLocationInfo(info)}
        defaultValue={locationInfo}
      />
      <div className="mt-2 flex flex-row">
        <button
          id="location-update"
          className="btn-green"
          onClick={async () => {
            //no need to validate the address on update,
            //we dont allow it to be changed after the fact.
            const vars = {
              editLocationId: location.id,
              name: locationInfo.name,
              publish: locationInfo.publish,
              ocpi: true,
              parkingType: locationInfo.parking_type,
              partyId: locationInfo.party_id,
              type: locationInfo.type || LOCATION_TYPES[0],
            };

            console.log("vars", vars);

            var result = await editLocation({
              variables: vars,
            });

            if (result) {
              toast("Operation success!");
              window.location.reload(false);
            }
          }}
        >
          Update
        </button>
        <button
          id="location-delete"
          className="ml-2 btn-red"
          onClick={async () => {
            if (
              !window.confirm("Are you sure you want to delete this location?")
            ) {
              return;
            }

            var result = await deleteLocation({
              variables: {
                id: location.id,
              },
            });

            if (result) {
              toast("Location successfully deleted!");
              window.location.assign(`/locations`);
            }
          }}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

const PARKING_TYPES = [
  "ALONG_MOTORWAY",
  "PARKING_GARAGE",
  "PARKING_LOT",
  "ON_DRIVEWAY",
  "ON_STREET",
  "UNDERGROUND_GARAGE",
];

const LocationEditForm = ({ role, onChange, defaultValue, type }) => {
  /*
   *  In the case of update location, we
   *  want to specify the data we already have.
   */
  const user = useAuth();
  const [locationInfo, setLocationInfo] = useState(
    defaultValue
      ? defaultValue
      : {
          time_zone: TIME_ZONES[0],
          parking_type: PARKING_TYPES[0],
          publish: true,
          ocpi: true,
          party_id: user?.party_id || "EUP",
          type: LOCATION_TYPES[0],
        }
  );
  const [address, setAddress] = useState(
    defaultValue ? defaultValue.address : {}
  );

  useEffect(() => {
    onChange({
      ...locationInfo,
      address,
    });
  }, [locationInfo, address]);

  return (
    <div className="container p-2">
      <p key="create">{type === "CREATE" && CREATE_MESSAGE}</p>
      <p key="update">{type === "UPDATE" && EDIT_MESSAGE}</p>
      <div key="name" className="mt-2 flex flex-row">
        <h1 key="label" className="w-1/2 p-2">
          Name
        </h1>
        <input
          id="location-name"
          key="input"
          className="p-2 border rounded"
          type="text"
          value={locationInfo.name}
          onChange={(e) => {
            setLocationInfo({
              ...locationInfo,
              name: e.target.value,
            });
          }}
        />
      </div>
      <div key="publish" className="flex flex-row mt-2">
        <h1 key="label" className="w-1/2 p-2">
          Publicly Visible
        </h1>
        <input
          id="location-publish"
          key="input"
          className="p-2 mt-2"
          checked={locationInfo.publish}
          type="checkbox"
          onChange={(e) => {
            setLocationInfo({
              ...locationInfo,
              publish: !locationInfo.publish,
            });
          }}
        />
        <Tooltip id="publicTooltip" className="mt-4 ml-4">
          Allow this location to be published to the public <br />
          via EVUp's customer facing charging site.
        </Tooltip>
      </div>
      <div key="ocpi" className="flex flex-row mt-2">
        <h1 key="label" className="w-1/2 p-2">
          OCPI Sharing
        </h1>
        <input
          id="location-ocpi"
          key="input"
          className="p-2 mt-2"
          type="checkbox"
          checked={locationInfo.ocpi}
          onChange={(e) => {
            setLocationInfo({
              ...locationInfo,
              ocpi: !locationInfo.ocpi,
            });
          }}
        />
        <Tooltip id="ocpiTooltip" className="mt-4 ml-4">
          Share this location with third party <br />
          services via the OCPI network.
        </Tooltip>
      </div>
      {type !== "UPDATE" && (
        <div>
          <div key="street-address" className="flex flex-row mt-2">
            <h1 className="w-1/2 p-2">Street Address</h1>
            <input
              id="location-address"
              className="p-2 border rounded"
              type="text"
              value={address.street_address}
              onChange={(e) => {
                setAddress({
                  ...address,
                  street_address: e.target.value,
                });
              }}
            />
          </div>
          <div key="city" className="flex flex-row mt-2">
            <h1 className="w-1/2 p-2">City</h1>
            <input
              id="location-city"
              className="p-2 border rounded"
              type="text"
              value={address.city}
              onChange={(e) => {
                setAddress({
                  ...address,
                  city: e.target.value,
                });
              }}
            />
          </div>
          <div key="post-code" className="flex flex-row mt-2">
            <h1 className="w-1/2 p-2">Post Code</h1>
            <input
              id="location-postcode"
              className="p-2 border rounded"
              type="text"
              value={address.post_code}
              onChange={(e) => {
                setAddress({
                  ...address,
                  post_code: e.target.value,
                });
              }}
            />
          </div>
          <div key="country" className="flex flex-row mt-2">
            <h1 className="w_43 p-2">Country</h1>
            <Tooltip id="country" className="mt-3 mr-4">
              Country should be represented as a 3 letter code (e.g. Australia =
              AUS)
            </Tooltip>
            <input
              id="location-country"
              className="p-2 border rounded"
              type="text"
              maxLength="3"
              value={address.country}
              onChange={(e) => {
                setAddress({
                  ...address,
                  country: e.target.value.toUpperCase(),
                });
              }}
            />
          </div>
        </div>
      )}
      <div key="parking-type" className="flex flex-row mt-2">
        <h1 className="w-1/2 p-2">Parking Type</h1>
        <select
          id="location-parkingType"
          className="p-2 border rounded"
          value={locationInfo.parking_type}
          onChange={(e) => {
            setLocationInfo({
              ...locationInfo,
              parking_type: e.target.value,
            });
          }}
        >
          {PARKING_TYPES.map((x) => (
            <option key={x} value={x} label={x} />
          ))}
        </select>
      </div>
      {role === "sysadmin" && (
        <div key="party-id" className="flex flex-row mt-2">
          <h1 className="w-1/2 p-2">Party ID</h1>
          <input
            id="location-partyId"
            className="p-2 border rounded"
            type="text"
            value={locationInfo.party_id}
            onChange={(e) => {
              setLocationInfo({
                ...locationInfo,
                party_id: e.target.value,
              });
            }}
          />
        </div>
      )}
      <div key="location-type" className="flex flex-row mt-2">
        <h1 className="w-1/2 p-2">Location Type</h1>
        <select
          id="location-type"
          value={locationInfo.type}
          label="Select a time zone" // <---------- ?
          className="p-2 border rounded"
          onChange={(e) => {
            setLocationInfo({
              ...locationInfo,
              type: e.target.value,
            });
          }}
        >
          {LOCATION_TYPES.map((x) => (
            <option key={x} value={x} label={x} />
          ))}
        </select>
      </div>
    </div>
  );
};

const validateLocation = (location, user) => {
  const partyValidate =
    user?.permissions?.role === "sysadmin"
      ? Joi.string().min(3).max(3).required()
      : Joi.string().valid(user?.party_id).required();

  const schema = Joi.object().keys({
    name: Joi.string().required(),
    publish: Joi.boolean().required(), //The info icon and checkbox next to this should be styled to be inline with 'Publish
    ocpi: Joi.boolean().required(), //The info icon and checkbox next to this should be styled to be inline with 'OCPI Sharing'
    address: {
      street_address: Joi.string()
        .required()
        .regex(new RegExp("[A-Za-z0-9.-]+")),
      post_code: Joi.string().regex(new RegExp("^[0-9]{4}$")).required(),
      city: Joi.string().regex(new RegExp("[ A-Za-z-]+")).required(),
      country: Joi.string()
        .min(3)
        .max(3)
        .required()
        .regex(new RegExp("[ A-Za-z-]+")),
    },
    parking_type: Joi.string()
      .required()
      .allow(...PARKING_TYPES),
    party_id: partyValidate,
    type: Joi.string()
      .required()
      .allow(...LOCATION_TYPES),
    time_zone: Joi.string()
      .required()
      .allow(...TIME_ZONES),
  });

  // console.log(schema);
  console.log(location);

  var result = schema.validate(location);
  if (result.error) {
    console.error(result.error);
  }
  console.log(result);
  return !result.error;
};

const validateAddress = (address) =>
  address.street_address !== undefined &&
  typeof address.street_address === "string" &&
  address.city !== undefined &&
  typeof address.city === "string" &&
  address.post_code !== undefined &&
  typeof address.post_code === "string" &&
  address.country !== undefined &&
  typeof address.country === "string";
