// a generic error page to show whenever something goes wrong in other views

import React from "react";
import { Link } from "react-router-dom";

const Error = ({ error }) => {
  console.debug("error ", error);
  return (
    <div>
      <p className="mt-2">{`Sorry, that page is not available.`}</p>
      <p className="mt-2">{error}</p>
      <Link to="/" className="btn-green mt-2">
        Go to the homepage
      </Link>
    </div>
  );
};

export default Error;
