/*
 *  Defines consts for uam roles, will be stored
 *  as key value pair in user UAM field as a map.
 *  for example:
 *  uam: {
 *      "permissions.create.location": true,
 *      "permissions.edit.location": true,
 *      "permissions.delete.location": false,
 *      "permissions.delete.evse": false
 *  }
 *
 *  All keys must be specified.
 */
export const CREATE_LOCATION = "permissions.create.location";
export const EDIT_LOCATION = "permissions.edit.location";
export const DELETE_LOCATION = "permissions.delete.location";
export const DELETE_EVSE = "permissions.delete.evse";

/*
 *  @param {any} userProfile - The currently logged in user
 *  we expect them to have UAM permissions in an array called uam.
 *
 *  @returns {boolean} - Does the user have the uam permission or not?
 */
export function hasUAM(userProfile, key) {
  var uam = userProfile?.uam;
  if (!uam) {
    return false;
  }
  var item = uam.find((x) => x.key === key);

  if (!item) {
    return false;
  } else {
    return item.value;
  }
}
