import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useQuery, gql } from "@apollo/client";
import { EVSEListItem } from "../admin/EVSEList";
import { OCPPEnrolment } from "./enrolment/EvseEnrolmentModal";
import { ActionButton } from "../layout/rightModal";
import _ from "lodash";
import Spinner from "../misc/Spinner";
import { useAuth } from "../../hooks/useAuth";

import Error from "../misc/Error";

const DEVICES_QUERY = gql`
  query GetDevices {
    getUnenrolledDevices {
      connected
      id
      charge_point_model
      vendor
      charging_type
      capabilities
      connectors {
        status
        id
        standard
        format
        power_type
        voltage
        max_amperage
        current_amperage
        session_id
        physical_reference
      }
      last_heartbeat
      last_updated
    }
    locationsApi {
      evses {
        connected
        id
        name
        ocpp
        charge_point_model
        vendor
        soft_blocked
        charging_type
        capabilities
        connectors {
          status
          id
          standard
          format
          power_type
          voltage
          max_amperage
          current_amperage
          session_id
          physical_reference
        }
        last_heartbeat
        last_updated
      }
      party_id
      id
      name
    }
  }
`;

export const MachinesPage = ({ userProfile }) => {
  const { tenancyState } = useAuth();
  const { loading, error, data, refetch } = useQuery(DEVICES_QUERY, {
    fetchPolicy: "no-cache",
  });
  const [showModal, setShowModal] = useState(false);
  const [showUnenrolled, updateShowUnenrolled] = useState(false);

  if (loading) return <Spinner />;
  if (error) return <Error error={error?.message} />;

  const unenrolledDevices = data.getUnenrolledDevices;

  let sortedUnenrolledDevices = _.orderBy(
    unenrolledDevices,
    ["connected", "last_updated"],
    ["desc", "desc"]
  );

  const locations = data.locationsApi;

  var addDeviceMessage;

  if (unenrolledDevices.length === 0 && locations.length === 0) {
    addDeviceMessage = <div></div>;
  }

  return (
    <div>
      <OCPPEnrolment
        showModal={showModal}
        onClose={() => setShowModal(false)}
      />
      <div className="flex flex-row ml-1 mr-1 mb-2">
        <h1 className="text-3xl font-bold mt-2">OCPP Machines</h1>
        <div
          className={`ml-auto ${
            tenancyState && !tenancyState.active
              ? " opacity-50 pointer-events-none"
              : ""
          }`}
        >
          <ActionButton
            toggleOpen={setShowModal}
            isOpen={showModal}
            startOpen={false}
            title={"Enrol New Device"}
            showIcon={true}
            className="mr-0"
          />
        </div>
      </div>
      <hr className="border-gray-400 border my-2" />
      <MissingLocation
        evses={sortedUnenrolledDevices}
        onReloadMachines={() => refetch()}
        showDevices={showUnenrolled}
        setShowDevices={updateShowUnenrolled}
      />
      {addDeviceMessage}
      <div className="bg-primary rounded-lg py-1">
        <h1 className="text-2xl bg-primary w-auto pt-3 pb-3 px-4 brandPrimaryColor rounded-md">
          Enrolled Devices
        </h1>
        <hr className="mx-3 text-gray-500" />
        <div className="mt-3">
          {locations.map((x) => (
            <LocationCard
              key={x.id}
              location={x}
              onReloadMachines={() => refetch()}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const LocationCard = ({ location, onReloadMachines }) => {
  const [showDevices, setShowDevices] = useState(false);
  const ocpp_evses = location?.evses.filter((x) => x.ocpp);
  const no_devices =
    location?.evses.length === 0 || !location?.evses || !ocpp_evses.length;

  return (
    <div
      key={location.id}
      className="flex flex-col bg-black px-2 py-1 mx-2 my-2 rounded-lg border border-gray-500 hoverShadow"
    >
      <div
        className="flex flex-row my-3"
        onClick={() => {
          setShowDevices(!showDevices);
        }}
      >
        <h1 className="uppercase text-lg ml-2 brandPrimaryColor">
          {location.name} - {location.party_id}
        </h1>
        <h1 className="text-lg ml-auto text-gray-500 source-sans-pro">
          ID: {location.id}
        </h1>
        <button className="ml-4 mr-4 p-1 text-white">
          {showDevices ? <FaChevronUp /> : <FaChevronDown />}
        </button>
      </div>
      <div>
        {showDevices &&
          ocpp_evses.length !== 0 &&
          ocpp_evses.map((evse) => (
            <EVSEListItem
              key={evse.id}
              evse={evse}
              locationId={location.id}
              onReloadMachines={onReloadMachines}
            />
          ))}
      </div>
      {showDevices && no_devices && (
        <div className="ml-1 bg-primary p-2 rounded mb-4">
          <h1 className="ml-1 text-white source-sans-pro">
            There are no OCPP devices enrolled in this location yet!
          </h1>
        </div>
      )}
    </div>
  );
};

const MissingLocation = ({
  evses,
  onReloadMachines,
  showDevices,
  setShowDevices,
}) => {
  if (evses.length) {
    return (
      <div>
        <div
          onClick={() => {
            setShowDevices(!showDevices);
          }}
          className="bg-primary hoverShadow flex flex-row mb-2 w-auto border-2 rounded-md border-black"
        >
          <h1 className="text-2xl brandPrimaryColor px-4 py-3">
            Unenrolled Devices
          </h1>
          <div className="ml-auto mr-8 my-auto">
            {showDevices ? (
              <FaChevronUp className="text-white" />
            ) : (
              <FaChevronDown className="text-white" />
            )}
          </div>
        </div>
        {showDevices && (
          <MachineList
            evses={evses}
            enrolled={false}
            onReloadMachines={onReloadMachines}
          />
        )}
      </div>
    );
  } else {
    return (
      <h1 className="mb-4 ml-1 text-xl source-sans-pro">
        Direct some OCPP 1.6 J compatible devices to wss://ocpp.evup.com.au to
        get started!
      </h1>
    );
  }
};

const MachineList = ({ evses, onReloadMachines }) => {
  if (!evses || !evses.length) {
    return null;
  }
  return evses.map((evse) => (
    <EVSEListItem
      key={evse.id}
      evse={evse}
      onReloadMachines={onReloadMachines}
    />
  ));
};
