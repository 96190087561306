import { TenancyGroups } from "./GroupDisplay";
import { GroupForm } from "./AddDlbGroup";
import { useState, useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import ErrorMessage from "../misc/ErrorMessage";
import Spinner from "../misc/Spinner";
import { useAuth } from "../../hooks/useAuth";

const TENANCY_GROUPS = gql`
  query GetTenancyGroups {
    getTenancyGroups {
      location_id
      group_id
      tenancy_id
      group_name
      dlb_policy
      buffer
      max_amperage
      devices {
        evse_id
        max_amperage
        connectors {
          connector_id
          connector_status
          max_amperage
          power_type
          phase_mapping {
            One
            Two
            Three
          }
          allocated_amperage
          charging_start_time
        }
        disconnected
      }
    }
  }
`;

const CREATE_GROUP = gql`
  mutation Mutation($group: DLBGroupInput!) {
    createDLBGroup(group: $group)
  }
`;

export const GroupManager = () => {
  const { tenancyState } = useAuth();
  const [addingGroup, updateAddingGroup] = useState(false);
  const [createDLBGroup, mutationResult] = useMutation(CREATE_GROUP);
  const { error, loading, data, stopPolling, startPolling, refetch } =
    useQuery(TENANCY_GROUPS);
  useEffect(() => {
    startPolling(10000);
    return () => stopPolling();
  });

  if (loading || mutationResult.loading) {
    return <Spinner />;
  }
  if (error) {
    return <ErrorMessage>{`${error}`}</ErrorMessage>;
  }
  if (mutationResult.error) {
    return <ErrorMessage>{`${mutationResult.error}`}</ErrorMessage>;
  }

  if (!addingGroup) {
    var creationDisplay = (
      <div className="flex flex-row mt-4 justify-center">
        <button
          className={`items-center bg-primary p-3 brandPrimaryColor border-black border-2 rounded-lg px-4${
            tenancyState && !tenancyState.active
              ? " opacity-50 pointer-events-none"
              : ""
          }`}
          onClick={() => {
            updateAddingGroup(true);
          }}
        >
          Create DLB Group +
        </button>
      </div>
    );
  } else {
    var creationDisplay = (
      <div className="mt-6">
        <h1 className="text-2xl font-semibold mb-4">New DLB Group:</h1>
        <GroupForm
          onSubmit={async (input) => {
            await createDLBGroup({
              variables: {
                group: input,
              },
            });
            updateAddingGroup(false);
            await refetch();
          }}
          onClose={() => {
            updateAddingGroup(false);
          }}
        />
      </div>
    );
  }

  console.log("GROUPS:", data.getTenancyGroups);

  return (
    <div className="p-2">
      <h1 className="text-3xl font-bold">DLB Group Manager</h1>
      <hr className="border border-gray-400 my-2" />
      <div className="p-6 bg-primary mb-6 text-lg rounded-lg border-2 border-black source-sans-pro text-white">
        <p className="mb-4 text-xl brandPrimaryColor">
          Dynamic Load Balancing offers safe and reliable support for your OCPP
          chargers.
        </p>
        <hr className="mb-3 border-gray-500" />
        <p>
          Each property is allocated some fixed amperage from the grid. If your
          property draws a greater amperage from the grid than it is allocated,
          the property's circuit will be tripped leading to a power outage.
          Electric vehicle chargers draw a significant amount of power from the
          grid, meaning when multiple are connected, they may trip the capacity
          of your building resulting in undefined behaviour and potential safety
          hazards.
        </p>
        <br />
        <p>
          The EVUp Charge dynamic load balancer offers a solution to this
          problem. A group of devices from a selected location can be enrolled
          as a 'DLB Group', where they will dynamically adapt their charging
          rates according to which connectors are in use or dormant. These set
          rates are also distributed across all active chargers in the fairest
          and most equalised manner achievable. This ensures a safe and
          optimally distributed electric load, which guarantees to not exceed a
          set capacity.
        </p>
      </div>
      <TenancyGroups data={data} />
      {creationDisplay}
    </div>
  );
};
