import { useState, useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import Joi from "joi";
import Spinner from "../misc/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "../../components/Tooltip";
import { useAuth } from "../../hooks/useAuth";

const EDIT_BANKINFO = gql`
  mutation UpdateDisbursementDetails(
    $bsb: String!
    $acc: String!
    $email: String!
  ) {
    updateDisbursementDetails(bsb: $bsb, acc: $acc, email: $email)
  }
`;

const GET_BANKINFO = gql`
  query Query {
    getDisbursementDetails {
      bsb
      account_number
      email
    }
  }
`;

export const UpdateBankDetails = () => {
  const user = useAuth();

  const [updateDisbursementDetails, editResult] = useMutation(EDIT_BANKINFO);
  const { loading, error, data } = useQuery(GET_BANKINFO);

  const [bankInfo, setBankInfo] = useState(false);

  if (editResult.loading || loading) return <Spinner />;
  if (editResult.error || error) return <p>Error: {error.message}</p>;
  else if (!bankInfo) {
    setBankInfo({
      bsb: data?.getDisbursementDetails?.bsb || "",
      acc: data?.getDisbursementDetails?.account_number || "",
      email: data?.getDisbursementDetails?.email || "",
    });
  }

  return (
    <div>
      <ToastContainer // for managing the browser messages like error message
        toastStyle={{ borderRadius: 200 }}
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <BankUpdateForm
        setBankInfo={(info) => setBankInfo(info)}
        bankInfo={bankInfo}
      />
      <button
        id="creation-button"
        className="mt-2 btn-green ml-1"
        onClick={async () => {
          if (
            data.getDisbursementDetails?.email &&
            user.email !== data.getDisbursementDetails.email
          ) {
            alert(
              `Only the account associated with the tenancy financial email (${data.getDisbursementDetails.email}) has permission to change these details.`
            );
            return;
          }

          var valid = validateBankDetails(bankInfo);

          if (!valid) {
            toast.error(
              "Please ensure all fields are filled in correctly to proceed!"
            );
            return;
          }

          var result = await updateDisbursementDetails({
            variables: {
              bsb: bankInfo.bsb,
              acc: bankInfo.acc,
              email: bankInfo.email,
            },
          });

          if (result) {
            alert("Disbursement details updated successfully.");
          }
        }}
      >
        Submit
      </button>
    </div>
  );
};

const BankUpdateForm = ({ setBankInfo, bankInfo }) => {
  return (
    <div className="container p-2 ">
      <div key="bsb" className="mt-2 flex flex-row">
        <h1 key="label" className="w-1/2 py-2">
          BSB
        </h1>
        <input
          id="BSB"
          key="input"
          className="p-2 border rounded bg-primary brandPrimaryColor"
          type="text"
          value={bankInfo.bsb}
          onChange={(e) => {
            setBankInfo({
              ...bankInfo,
              bsb: e.target.value,
            });
          }}
        />
      </div>
      <hr className="mt-1" />
      <div key="acc" className="mt-1 flex flex-row">
        <h1 key="label" className="w-1/2 py-2">
          Account Number
        </h1>
        <input
          id="ACC"
          key="input"
          className="p-2 border rounded bg-primary brandPrimaryColor"
          type="text"
          value={bankInfo.acc}
          onChange={(e) => {
            setBankInfo({
              ...bankInfo,
              acc: e.target.value,
            });
          }}
        />
      </div>
      <hr className="mt-1" />
      <div key="email" className="mt-1 flex flex-row">
        <h1 key="label" className="w-1/2 py-2 flex flex-row">
          <p>Financial Email</p>
          <Tooltip className="mt-0.5 ml-2">
            Email to be associated with tenancy finances/statements - usually
            that of your company financial officer
          </Tooltip>
        </h1>

        <input
          id="Email"
          key="input"
          className="p-2 border rounded bg-primary brandPrimaryColor"
          type="text"
          value={bankInfo.email}
          onChange={(e) => {
            setBankInfo({
              ...bankInfo,
              email: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};

const validateBankDetails = (bankInfo) => {
  const schema = Joi.object().keys({
    bsb: Joi.string().required(),
    acc: Joi.string().required(),
    email: Joi.string().required(),
  });

  var result = schema.validate(bankInfo);
  if (result.error) {
    console.error(result.error);
  }
  console.log(result);
  return !result.error;
};
