import ReactTooltip from "react-tooltip";
import { FaInfoCircle } from "react-icons/fa";
import { AiFillWarning, AiFillExclamationCircle } from "react-icons/ai";

/*
 *   A pure and simple tooltip icon, when users
 *   hover over it, the children will be shown in as
 *   a floating tooltip.
 */
export const Tooltip = ({ id, className, children, color = "gray" }) => {
  return (
    <div className={className} data-tip data-for={id}>
      <FaInfoCircle color={color} />
      <ReactTooltip id={id}>{children}</ReactTooltip>
    </div>
  );
};

export const WarningTooltip = ({ id, className, children }) => {
  return (
    <div className={className} data-tip data-for={id}>
      <AiFillWarning size={30} color="orange" />
      <ReactTooltip id={id}>{children}</ReactTooltip>
    </div>
  );
};

export const ImportantTooltip = ({ id, className, children }) => {
  return (
    <div className={className} data-tip data-for={id}>
      <AiFillExclamationCircle size={20} color="#E91E63" />
      <ReactTooltip id={id}>{children}</ReactTooltip>
    </div>
  );
};
