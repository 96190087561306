export const LOCATION_ID = "LOCATION_ID";
export const PRESET_ID = "PRESET_ID";
export const DISPLAY_NAME = "DISPLAY_NAME";
export const PARTY_ID = "PARTY_ID";
export const PHYSICAL_REF = "PHYSICAL_REF";

export const PREFILL_VALUES = "PREFILL_VALUES";

const INITIAL_STATE = {};

export function updateMetadataFormReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PREFILL_VALUES: {
      var reducedState = {
        ...state,
        location_id: action.location_id,
        preset_id: action.preset_id,
        display_name: action.display_name,
        party_id: action.party_id
      };
      return reducedState;
    }
    case LOCATION_ID: {
      return {
        ...state,
        location_id: action.location_id,
      };
    }
    case PRESET_ID: {
      return {
        ...state,
        preset_id: action.preset_id,
      };
    }
    case PARTY_ID: {
      return {
        ...state,
        party_id: action.party_id,
      };
    }
    case DISPLAY_NAME: {
      return {
        ...state,
        display_name: action.display_name,
      };
    }
  }
  return state;
}
