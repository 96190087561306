import { useState } from "react";
import { BiSupport } from "react-icons/bi";

export const RefreshTrello = () => {
  const [loading, updateLoading] = useState(false);
  return (
    <div className="mx-1">
      <button
        className="flex flex-row border border-black bg-primary py-2 px-4 rounded brandPrimaryColor align-center"
        onClick={async () => {
          updateLoading(true);
          var res = await postRefreshTrello();
          alert(res);
          updateLoading(false);
        }}
      >
        {loading ? (
          <p className="mr-1 text-white inline my-auto">...</p>
        ) : (
          <BiSupport className="text-white inline mr-1 my-auto" />
        )}
        <p className="mt-0.5">Refresh Support Trello</p>
      </button>
    </div>
  );
};

async function postRefreshTrello() {
  const requestOptions = {
    method: "GET",
  };
  await fetch(
    `${process.env.REACT_APP_GRAPHQL_URL}/updateSupportTrello`,
    requestOptions
  );
}
