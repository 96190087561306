import { gql, useQuery, useMutation } from "@apollo/client";
import { useState } from "react";
import { findRoleDistance } from "./UserActions";
import Spinner from "../misc/Spinner";

const USERS_QUERY = gql`
  query GetUsers {
    users {
      uid
      name
      canEdit
      permissions {
        role
      }
      uam {
        key
        value
      }
    }
  }
`;

const UAM_UPDATE = gql`
  mutation ($uid: String!, $uam: [UAMEntryInput]!) {
    updateUAM(uid: $uid, uam: $uam)
  }
`;

export const UAMEditor = ({ role }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const { loading, error, data } = useQuery(USERS_QUERY);
  const [updateUAM, UAMUpdate] = useMutation(UAM_UPDATE);

  if (loading || UAMUpdate.loading) return <Spinner />;
  if (error || UAMUpdate.error) return <p>Error :(</p>;

  const { users } = data;

  var canEdit = findRoleDistance(role, selectedUser?.permissions?.role) > 0;

  return (
    <div>
      <div className="mb-2 flex flex-row">
        <h1 className="mr-4 mt-2">User Name:</h1>
        <select
          label="Select a user"
          className="p-2 border rounded"
          onChange={(e) => {
            //Find the user with id that is = to the selected id.
            setSelectedUser(users.find((x) => x.uid === e.target.value));
          }}
        >
          {users.map((x) => (
            <option key={x.uid} value={x.uid} label={x.name} />
          ))}
        </select>
      </div>
      {selectedUser && <p>Role: {selectedUser.permissions.role}</p>}
      <div className="my-2 border rounded p-2">
        {selectedUser?.uam?.map((x) => (
          <UAMEntry
            canEdit={canEdit}
            key={x.key}
            uamKey={x.key}
            value={x.value}
            onChange={(y) => {
              //A bit gross!
              var newUAM = [...selectedUser.uam];
              var entryIndex = newUAM.map((x) => x.key).indexOf(x.key);
              newUAM[entryIndex] = {
                key: x.key,
                value: y,
              };
              setSelectedUser({
                ...selectedUser,
                uam: newUAM,
              });
            }}
          />
        ))}
      </div>
      <div>
        {canEdit && (
          <button
            className="btn-blue"
            onClick={async () => {
              var result = await updateUAM({
                variables: {
                  uid: selectedUser.uid,
                  uam: selectedUser.uam.map((x) => {
                    return {
                      key: x.key,
                      value: x.value,
                    };
                  }),
                },
              });

              if (result?.data?.updateUAM === true) {
                alert(`Operation Success!`);
              } else {
                alert("Error updating UAM");
              }
            }}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

const UAMEntry = ({ uamKey, value, onChange, canEdit }) => {
  return (
    <div key={uamKey} className="flex flex-row my-1">
      <h1 key={uamKey} className="w-1/2">
        {uamKey}
      </h1>
      <input
        disabled={canEdit === false}
        id={`${uamKey}_checkbox`}
        type="checkbox"
        className="mt-2 ml-8"
        checked={value === true}
        onChange={() => onChange(!value)}
      />
    </div>
  );
};
