import { useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import ErrorMessage from "../misc/ErrorMessage";

const TENANCY_DETAILS_QUERY = gql`
  query GetTenancyDetails($partyId: String!) {
    getTenancyDetails(party_id: $partyId) {
      accounts
      users
      locations
      evses
      tariffs
      prepaidContracts
      dlbGroups
      ocppLogs
      paymentDetails
      postpaidContracts
      sessions
    }
  }
`;

const DELETE_ACCOUNT = gql`
  mutation DeleteTenancy($partyId: String!) {
    deleteTenancy(party_id: $partyId)
  }
`;

const DeleteAccount = ({ name, partyId, onClose }) => {
  const [counter, setCounter] = useState(null);
  const tenancyDetails = useQuery(TENANCY_DETAILS_QUERY, {
    variables: { partyId },
    fetchPolicy: "no-cache",
  });
  const [deleteTenancy, { loading, error }] = useMutation(DELETE_ACCOUNT);

  const tenancyDetailsData = tenancyDetails.data?.getTenancyDetails;

  useEffect(() => {
    if (counter !== null) {
      const timeoutId = setTimeout(() => {
        setCounter(counter === 0 ? null : counter - 1);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [counter]);

  useEffect(() => {
    if (tenancyDetailsData) {
      setCounter(5);
    }
  }, [tenancyDetailsData]);

  function getValue(key) {
    return tenancyDetailsData ? tenancyDetailsData[key] : "-";
  }

  return (
    <div>
      <div className="text-gray-500">
        <p>
          The deletion of a tenancy is permanent and cannot be restored. The
          tenancy {name} will be deleted, and the following linked data will
          also be removed:
        </p>
        <ul className="list-disc ml-8 mt-1">
          <li>Users: {getValue("users")}</li>
          <li>Locations: {getValue("locations")}</li>
          <li>Devices: {getValue("evses")}</li>
          <li>Tariffs: {getValue("tariffs")}</li>
          <li>Prepaid Contracts: {getValue("prepaidContracts")}</li>
          <li>DLB Groups: {getValue("dlbGroups")}</li>
          <li>OCPP Logs: {getValue("ocppLogs")}</li>
          <li>Payment Details: {getValue("paymentDetails")}</li>
          <li>Postpaid Contracts: {getValue("postpaidContracts")}</li>
          <li>Sessions: {getValue("sessions")}</li>
        </ul>
        <p className="mt-3">
          Are you sure you want to delete the tenancy account {name}?
        </p>
      </div>
      {error && (
        <div className="mt-3">
          <ErrorMessage message={error.message} />
        </div>
      )}
      <div className="mt-4 flex flex-row">
        <div>
          <button
            className={`bg-green-600 text-white text-sm font-bold py-2 px-4 rounded ml-auto${
              loading ? " opacity-70" : ""
            }`}
            type="button"
            onClick={() => onClose()}
            disabled={loading}
          >
            Cancel
          </button>
        </div>
        <div className="ml-7">
          <button
            className={`bg-red-600 text-white text-sm font-bold py-2 px-4 rounded ml-auto${
              !tenancyDetailsData || loading || counter !== null
                ? " opacity-70"
                : ""
            }`}
            type="button"
            onClick={async () => {
              try {
                await deleteTenancy({ variables: { partyId } });
                onClose(true);
              } catch (err) {}
            }}
            disabled={!tenancyDetailsData || loading || counter !== null}
          >
            {!tenancyDetailsData
              ? "Loading..."
              : loading
              ? "Deleting..."
              : `Delete${counter !== null ? ` (${counter})` : ""}`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
