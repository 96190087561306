import React, { useState } from "react";
import Moment from "react-moment";

import DataGrid from "react-data-grid";
import "react-data-grid/lib/styles.css";
import { SearchBar } from "./SearchBar";
import { LegacyCheckbox } from "./SessionsPage";
import { gql, useQuery } from "@apollo/client";
import Spinner from "../misc/Spinner";
import { ExportButton } from "./SessionsPage";
import { useAuth } from "../../hooks/useAuth";

const LEGACY_SESSIONS = gql`
  query LegacySessions {
    legacy_sessions {
      party_id
      id
      start_date_time
      end_date_time
      kwh
      location_id
      evse_uid
      connector_id
      status
      last_updated
    }
  }
`;

const EXPORTED_FIELDS = [
  "start_date_time",
  "end_date_time",
  "party_id",
  "id",
  "kwh",
  "connector_id",
  "status",
  "evse_uid",
];

const columns = [
  {
    key: "start_date_time",
    name: "Start",
    headerCellClass: "brandPrimaryColor",
    formatter: ({ row }) => (
      <Moment format="DD/MM hh:mm a">{row.start_date_time}</Moment>
    ),
  },
  {
    key: "end_date_time",
    name: "End",
    headerCellClass: "brandPrimaryColor",
    formatter: ({ row }) => {
      if (row.end_date_time) {
        return <Moment format="DD/MM hh:mm a">{row.end_date_time}</Moment>;
      } else {
        return <h1>In progress</h1>;
      }
    },
  },
  { key: "party_id", headerCellClass: "brandPrimaryColor", name: "Party ID" },
  { key: "id", headerCellClass: "brandPrimaryColor", name: "ID" },
  { key: "kwh", headerCellClass: "brandPrimaryColor", name: "kWh" },
  { key: "connector_id", headerCellClass: "brandPrimaryColor", name: "Port #" },
  { key: "status", headerCellClass: "brandPrimaryColor", name: "Status" },
  { key: "evse_uid", headerCellClass: "brandPrimaryColor", name: "EVSE ID" },
];

export const LegacySessionsPage = ({ match }) => {
  const [filters, updateFilters] = useState({});
  const { loading, error, data } = useQuery(LEGACY_SESSIONS, {
    variables: {
      filters,
    },
    fetchPolicy: "no-cache",
  });
  const user = useAuth();

  if (loading) return <Spinner />;
  if (error) return <h1>Error! {`${error}`}</h1>;

  if (match?.params?.evse_id && !filters.evseID) {
    updateFilters({
      ...filters,
      evseID: match?.params?.evse_id,
    });
  }

  var title = match.params.evse_id ? (
    <p className=" text-gray-500 ml-3 mt-3">
      # EVSE ID: {match.params.evse_id}
    </p>
  ) : (
    <p className=" text-gray-500 ml-3 mt-3"># Tenancy ID: {user.party_id}</p>
  );

  const legacySessions = data.legacy_sessions;
  console.log("Retrieved", legacySessions.length, "legacy sessions.");

  return (
    <div>
      <div className="flex flex-row">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <h1 className="mt-1 ml-1 text-3xl font-bold">Legacy Sessions</h1>
            {title}
          </div>
          <p className="ml-2 text-gray-500">
            For sessions prior to 23rd January 2022
          </p>
        </div>
        {/*<SearchBar
        permissions={user?.permissions}
        filtersUpdated={updateFilters}
  />*/}
        <div className="ml-auto flex flex-row">
          <LegacyCheckbox evse_id={match?.params?.evse_id} checked={true} />
          <ExportButton
            data={legacySessions}
            fields={EXPORTED_FIELDS}
            title="legacy-session"
          />
        </div>
      </div>
      <hr className="my-3 border border-gray-400" />
      <div>
        <style>
          {
            ".rdg { height: 100vh !important; max-height: calc(100vh - 100px); z-index: 10 }"
          }
        </style>
        <div className="w-full">
          <DataGrid
            columns={columns}
            rows={legacySessions
              ?.filter((x) => filterSession(x, filters))
              ?.map((x) => mapSessionToRow(x))}
            className="text-xs rdg-dark"
          />
        </div>
      </div>
    </div>
  );
};

function filterSession(session, filters) {
  var start_date_time = session.start_date_time;
  var end_date_time = session.end_date_time;

  if (start_date_time && typeof start_date_time !== "string") {
    start_date_time = start_date_time.toDate().toISOString();
  }

  if (end_date_time && typeof end_date_time !== "string") {
    end_date_time = end_date_time.toDate().toISOString();
  }

  if (!end_date_time) {
    end_date_time = new Date();
  } else {
    end_date_time = new Date(end_date_time);
  }

  if (!start_date_time) {
    start_date_time = new Date();
  } else {
    start_date_time = new Date(start_date_time);
  }

  var duration = (end_date_time - start_date_time) / (1000 * 60 * 60);

  if (filters?.evseID && filters?.evseID !== session.evse_uid) {
    return false;
  }

  if (
    filters?.minDuration &&
    duration < Number.parseFloat(filters?.minDuration)
  ) {
    //check session duration falls within min and max
    //if the duration is below the min, invalidate
    return false;
  }

  //if the duration is above the max, invalidate
  if (
    filters?.maxDuration &&
    duration > Number.parseFloat(filters?.maxDuration)
  ) {
    return false;
  }

  //if the session statuses don't match, invalidate
  if (filters?.sessionStatus && session?.status !== filters?.sessionStatus) {
    return false;
  }

  //if the session KWH is below the min, invalidate
  if (filters.minKWH && session?.kwh < Number.parseFloat(filters?.minKWH)) {
    return false;
  }

  //if the session KWH is above the max, invalidate
  if (filters.maxKWH && session?.kwh > Number.parseFloat(filters?.maxKWH)) {
    return false;
  }

  //Type coercion here to ensure the date comparison isnt make on strings or something wierd.
  //if the session starttime is before the filter starting date, invalidate
  if (
    filters?.startingDate &&
    +start_date_time < +new Date(filters?.startingDate)
  ) {
    return false;
  }

  //if the session endtime is after the filter finishing, invalidate
  if (
    filters?.finishingDate &&
    +end_date_time > +new Date(filters?.finishingDate)
  ) {
    return false;
  }

  return true;
}

const mapSessionToRow = (session) => {
  var start_date_time = session.start_date_time;
  var end_date_time = session.end_date_time;
  var kwh = session.kwh;

  if (kwh > 500) {
    kwh = kwh / 1000;
  }

  return {
    start_date_time: start_date_time,
    end_date_time: end_date_time,
    id: session.id,
    party_id: session.party_id,
    kwh: kwh.toFixed(2),
    connector_id: session.connector_id,
    status: session.status,
    evse_uid: session.evse_uid,
  };
};
