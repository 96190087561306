import Spinner from "../misc/Spinner";

import { Modal } from "../layout/Modal";
import { useState } from "react";
import { TariffTile } from "../tariffs/TariffManager";
import { gql, useQuery } from "@apollo/client";

const TARIFFS = gql`
  query Ocpi_tariffs {
    ocpi_tariffs {
      country_code
      party_id
      currency
      id
      type
      elements {
        price_components {
          type
          price
          vat
          step_size
        }
      }
      tariff_alt_text {
        language
        text
      }
      end_date_time
      start_date_time
      last_updated
    }
  }
`;

export const TariffSelector = ({ tariff, updateTariff, show = true }) => {
  const [modalOpen, updateModalOpen] = useState(false);

  if (!show) {
    return null;
  }

  var selectedTariff = "";

  if (tariff) {
    selectedTariff = tariff.desc || tariff.id;
  }

  return (
    <div>
      <div className="flex flex-row items-center mt-2 h-8">
        <h1 className="truncate my-auto text-gray-200 w-1/2">Tariff</h1>
        <div className="w-1/2">
          <button
            onClick={() => updateModalOpen(true)}
            className="bg-secondary truncate px-1 py-1.5 w-full whitespace-nowrap rounded my-2 border border-black hover:border-white"
          >
            {selectedTariff ? selectedTariff : "Select Tariff"}
          </button>
        </div>
        <Modal
          title="Select Tariff"
          isOpen={modalOpen}
          onClose={() => updateModalOpen(false)}
        >
          <TariffSelectionListWrapper
            onSelected={(tariff) => {
              console.log("Selected tariff", tariff);
              updateModalOpen(false);
              updateTariff(tariff);
            }}
          />
        </Modal>
      </div>
    </div>
  );
};

const TariffSelectionListWrapper = ({ onSelected }) => {
  const { error, loading, data } = useQuery(TARIFFS);
  if (error) {
    return <div>{error?.message}</div>;
  }
  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      {data.ocpi_tariffs.length !== 0 ? (
        data.ocpi_tariffs.map((x) => (
          <TariffSelection tariff={x} onSelected={onSelected} />
        ))
      ) : (
        <div>No tariffs found. Go to the tariffs menu to create one!</div>
      )}
    </div>
  );
};

const TariffSelection = ({ tariff, onSelected }) => {
  return (
    <div
      className="hover:shadow"
      key={tariff.id}
      onClick={() =>
        onSelected({ id: tariff.id, desc: tariff.tariff_alt_text[0]?.text })
      }
    >
      <TariffTile tariff={tariff} />
    </div>
  );
};
