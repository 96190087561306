import Firebase from "firebase/app";

import { RiAccountCircleLine, RiAccountCircleFill } from "react-icons/ri";
import {
  AiOutlineLogout,
  AiOutlineMail,
  AiFillCreditCard,
} from "react-icons/ai";
import { FaCar } from "react-icons/fa";
import { Link } from "react-router-dom";
import { logout } from "../../../middleware/loginMiddleware";
import { useDispatch } from "react-redux";

import { gql, useMutation } from "@apollo/client";
import Spinner from "../../misc/Spinner";

const DELETE_ME = gql`
  mutation Mutation {
    deleteMe
  }
`;

export const LoginModal = ({ showLoginModal, updateLoginModal }) => {
  const dispatch = useDispatch();

  const [deleteMe, deleteMeResult] = useMutation(DELETE_ME);

  /* Add favourite chargers system */
  if (!showLoginModal) {
    return null;
  }
  var currentUser = Firebase?.auth()?.currentUser;
  /* SHOULD BE CHANGED TO WHETHER USER IS LOGGED IN */
  var account;

  if (!currentUser) {
    account = (
      <div className="flex flex-row h-20 bg-primary">
        <RiAccountCircleLine
          size={40}
          className="my-auto ml-3 brandPrimaryColor"
        />
        <Link
          to="/login"
          className="text-xl ml-2 my-auto brandPrimaryColor hover:underline"
        >
          Login
        </Link>
        <span className="text-white ml-2 text-xl my-auto">/</span>
        <Link
          to="/createDriver"
          className="text-xl ml-2 my-auto brandPrimaryColor hover:underline"
        >
          Register
        </Link>
      </div>
    );
  } else {
    account = (
      <div className="flex flex-row h-16 bg-primary">
        <RiAccountCircleFill
          size={35}
          className="my-auto ml-2 brandPrimaryColor"
        />
        <span className="text-lg ml-2 my-auto brandPrimaryColor">
          <span className="hover:underline">{currentUser.displayName}</span>
        </span>
        <button
          id="logout-button"
          className="ml-auto mr-6 my-auto"
          onClick={() => dispatch(logout())}
        >
          <AiOutlineLogout
            color="white"
            size={20}
            className="text-center my-0 relative top-1 mx-auto"
          />
          <span className="text-xs text-white text-center my-0">Logout</span>
        </button>
      </div>
    );
  }

  if (deleteMeResult.loading) {
    return <Spinner />;
  }

  if (deleteMeResult?.error) {
    return <h1>{deleteMeResult?.error?.message}</h1>;
  }

  if (deleteMeResult.data?.deleteMe) {
    alert("Account has been deleted successfully!");
  }

  return (
    <div
      className="absolute z-40 inset-0 h-full w-full overflow-auto bg-gray-500 bg-opacity-50"
      onClick={() => {
        updateLoginModal(false);
      }}
    >
      <div className="loginModal no-scrollbar">
        <div className="bg-white border border-solid border-black w-full shadow z-100 min-h-full">
          {account}
          <div className="flex flex-col brandSecondaryColor">
            <div className="flex flex-row my-3.5 ml-6">
              <AiOutlineMail size={35} />
              <a
                href="https://www.evup.com.au/contact-us"
                className="my-auto ml-4 text-lg"
              >
                Contact Us
              </a>
            </div>
            <div className=" h-96"></div>
            <button
              id="delete-user"
              className=" bg-red-600 text-white text-lg font-bold py-2 px-4 rounded  m-4 mt-16 "
              onClick={async () => {
                if (
                  !window.confirm(
                    "Are you sure you want to delete this account?"
                  )
                ) {
                  return;
                }

                var result = await deleteMe();

                console.debug("Deleted Account: ", result);

                window.location.reload(true);
              }}
            >
              Delete Account
            </button>
          </div>
          <div className="absolute bottom-0 right-0 left-0">
            <hr className="width_95 mx-auto" />
            <div className="mx-4 flex flex-row opacity-50 justify-between">
              <a
                href="https://www.evup.com.au/assets/evup-eula.pdf"
                target="_blank"
                className="my-1"
              >
                Privacy Policy
              </a>
              <a
                href="https://www.evup.com.au/ev-charging"
                target="_blank"
                className="my-1"
              >
                About Us
              </a>
              <div className="my-1">{process.env.REACT_APP_VERSION}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
