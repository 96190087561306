import { Link } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { connect } from "react-redux";

import Spinner from "../../misc/Spinner";
import logo from "../../../assets/logo.png";

import {
  FORGOT_PASSWORD,
  LOGIN_READY,
  MFA_REQUIRED,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  FACEBOOK_LOGIN,
  GOOGLE_LOGIN,
} from "../../../reducers/userState";

import { LoginUserContainer } from "./LoginForm";
import { ForgotPassword } from "./ForgotPassword";
import { MFAContainer } from "./MFA";
import { loginUser } from "../../../middleware/loginMiddleware";
import { useDispatch } from "react-redux";

const LoginForm = ({ action, loading }) => {
  const dispatch = useDispatch();

  if (loading) {
    return <Spinner />;
  }

  var content = null;

  switch (action) {
    case LOGIN_SUCCESS:
    case LOGIN_ERROR:
    case LOGIN_READY: {
      content = <LoginUserContainer />;
      break;
    }
    case FORGOT_PASSWORD: {
      content = <ForgotPassword />;
      break;
    }
    case MFA_REQUIRED: {
      content = <MFAContainer />;
      break;
    }
  }

  return (
    <>
      <div className="bg-primary flex-none w-full h-screen overflow-y-scroll">
        <div className="flex-none max-w-sm text-white font-bold pt-1 pb-2 px-4 m-auto">
          <img src={logo} alt="Logo" className="m-auto block mt-16" />
          <h1 className="heading m-auto block text-center py-6">CHARGE</h1>
          <div className="relative">{content}</div>
          <hr className="w-full mb-2 mt-3" />
          <div className="flex flex-row justify-center my-1">Login With</div>
          <div className="flex flex-row justify-center gap-x-2 mb-1">
            <button
              className="flex flex-col py-2 facebook-background px-3 border-1 rounded jusitfy-right"
              onClick={() => dispatch(loginUser({ provider: FACEBOOK_LOGIN }))}
            >
              <div className="inline my-auto">
                <FaFacebook className="inline mr-2" />
                <span className="relative top-0.5">Facebook</span>
              </div>
            </button>
            <button
              className="flex flex-col bg-white py-2 px-3 border-1 rounded  jusitfy-right"
              onClick={() => dispatch(loginUser({ provider: GOOGLE_LOGIN }))}
            >
              <div className="inline my-auto">
                <FcGoogle className="inline mr-2" />
                <span className="relative text-black top-0.5">Google</span>
              </div>
            </button>
          </div>
          <hr className="w-full mb-1 mt-3" />
          <div className="flex flex-row justify-center">
            <Link
              to="/create-driver"
              className="flex text-center bg-green-600 text-white mt-2 py-2 px-6 rounded-md border-1 text-md font-bold"
            >
              Register
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToLoginFormProps = (state) => {
  const userState = state.userState;
  return {
    loading: userState.loading,
    action: userState.action,
    message: userState.message,
  };
};

const LoginFormContainer = connect(mapStateToLoginFormProps)(LoginForm);

export default LoginFormContainer;
