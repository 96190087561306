import { Formik, Form, Field } from "formik";
import { updateAccount } from "../../actions/accounts";
import Joi from "joi";
import { useState, useEffect } from "react";
import moment from "moment";
import { ButtonlessModal } from "../layout/rightModal";
import DeleteAccount from "./DeleteAccount";

import { toast } from "react-toastify";

import { gql, useQuery, useMutation } from "@apollo/client";
import Spinner from "../misc/Spinner";

const CREATE_ACCOUNT = gql`
  mutation CreateTenancy(
    $companyName: String!
    $abn: String!
    $address: String!
    $postcode: String!
    $contactNumber: String!
    $state: String!
    $partyId: String!
    $email: String!
    $password: String!
    $name: String!
    $bsb: String
    $accountNumber: String
    $startDate: String
    $endDate: String
  ) {
    createTenancy(
      company_name: $companyName
      ABN: $abn
      address: $address
      postcode: $postcode
      contact_number: $contactNumber
      state: $state
      party_id: $partyId
      email: $email
      password: $password
      name: $name
      bsb: $bsb
      account_number: $accountNumber
      start_date: $startDate
      end_date: $endDate
    ) {
      subscriptionId
      signinToken
      errors
      clientSecret
    }
  }
`;

const RESET_TENANCY_EVSES = gql`
  mutation ResetTenancyEvses($partyId: String!) {
    resetTenancyEvses(party_id: $partyId)
  }
`;

export const AccountEditor = ({
  accountData,
  type,
  refetch,
  planTypes,
  onDelete,
}) => {
  const [dlb, updateDlb] = useState(false); // This is very lazy
  const [tenancy_tier, updateTier] = useState(false); // This is very lazy
  const [closed, setClosed] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [createTenancy, { loading, error, data }] = useMutation(CREATE_ACCOUNT);
  const [resetTenancyEvses] = useMutation(RESET_TENANCY_EVSES);

  useEffect(() => {
    if (type === "update") {
      updateDlb(accountData?.dlb_enabled === "Yes");
      setClosed(accountData?.closed === "Yes");
      updateTier(accountData?.tenancy_tier || "UNLIMITED");
    } else {
      updateTier("UNLIMITED");
    }
  }, []);

  if (loading) return <Spinner />;
  if (error) return <p>Error: {error.message}</p>;

  var submitAction;

  if (type === "update") {
    submitAction = "Update";
  } else if (type === "create") {
    submitAction = "Create";
  }

  return (
    <div>
      <Formik
        initialValues={{
          accountName: accountData?.name || "",
          abn: accountData?.abn || "",
          streetAddress: accountData?.street || "",
          postcode: accountData?.postcode || "",
          contactNumber: accountData?.contact || "",
          state: accountData?.state || "",
          party_id: accountData?.party_id || "",
          email: accountData?.email || "",
          password: accountData?.password || "",
          username: accountData?.username || "",
          bsb: accountData?.bsb || "",
          acc: accountData?.acc || "",
          startDate:
            accountData?.start_date ||
            accountData?.subscription?.start_date ||
            "",
          endDate:
            accountData?.end_date || accountData?.subscription?.end_date || "",
          // locations: accountData?.locations || "",
        }}
        onSubmit={async (values) => {
          console.log(values);

          var result;

          console.log("tenancy tier is", tenancy_tier);
          console.log("dlb is", dlb);

          var valResult = validateAccounts(values, type, tenancy_tier);

          if (!valResult.passed) {
            toast.error(`${valResult.error}`);
            return;
          }

          if (type === "update") {
            if (
              tenancy_tier === "UNLIMITED" &&
              accountData.tenancy_tier !== "UNLIMITED" &&
              accountData.subscription?.status === "active"
            ) {
              toast.error(
                "Can't upgrade to full tenancy while subscription is in progress!"
              );
              return;
            }

            if (closed) {
              if (
                !window.confirm(
                  `When a tenancy is closed, the following actions take place:\n- All active sessions on devices within the location will be terminated.\n- E-commerce settings will be updated to enable free charging mode, and all authorization controls will be unchecked.\n- Users associated with the tenancy will be logged out and blocked from logging in.\n\nAre you sure you want to close the tenancy account ${values.accountName}?`
                )
              ) {
                return;
              }

              var result = await resetTenancyEvses({
                variables: {
                  partyId: values.party_id,
                },
              });
              if (!result.data.resetTenancyEvses) {
                alert("Failed to reset the tenancy's evses. Please try again.");
                return;
              }
            }

            // Check if custom dates are entered for a lite tenancy
            var liteStartDateSame = false;
            var liteEndDateSame = false;
            if (
              tenancy_tier !== "UNLIMITED" &&
              !accountData.start_date &&
              !accountData.end_date &&
              accountData.subscription
            ) {
              if (values.startDate === accountData.subscription.start_date) {
                liteStartDateSame = true;
              }
              if (values.endDate === accountData.subscription.end_date) {
                liteEndDateSame = true;
              }
            }

            console.log("Updating...");
            result = await updateAccount(
              accountData.id,
              values.accountName,
              values.abn,
              values.contactNumber,
              values.streetAddress,
              values.postcode,
              values.state,
              tenancy_tier,
              dlb,
              closed,
              values.bsb,
              values.acc,
              liteStartDateSame && liteEndDateSame ? null : values.startDate,
              liteStartDateSame && liteEndDateSame ? null : values.endDate
            );
            if (result) alert("Successfully updated tenancy");
            else alert("Failed updating tenancy. Contact developers");
            await refetch();
          } else if (type === "create") {
            const variables = {
              companyName: values.accountName,
              abn: values.abn,
              address: values.streetAddress,
              postcode: values.postcode,
              contactNumber: values.contactNumber,
              state: values.state,
              partyId: values.party_id,
              email: values.email,
              password: values.password,
              name: values.username,
              bsb: values.bsb,
              accountNumber: values.acc,
              startDate: values.startDate,
              endDate: values.endDate,
            };

            result = await createTenancy({
              variables,
            });

            console.log("result:" + result);

            if (result) {
              alert("Operation success, account created");
              refetch();
            }
          }
        }}
      >
        {({ values, resetForm }) => {
          const tenancyDuration = (startDate, endDate, expiry) => {
            if (startDate && endDate) {
              const start = moment(new Date(startDate));
              const end = expiry
                ? moment(new Date(endDate))
                : moment(new Date(endDate)).add("1", "days");
              const duration = moment.duration(end.diff(start));
              const durationData = duration._data;
              const years = durationData.years;
              const months = durationData.months;
              const days = durationData.days;
              const totalDays = duration.asDays();

              if (expiry) {
                return `Expires in ${totalDays}d`;
              }

              let data = "";
              if (years > 0) {
                data = `${years}yr`;
              }
              if (months > 0) {
                data = data + ` ${months}m`;
              }
              if ((years > 0 || months > 0) && days > 0) {
                data = data + ` ${days}d`;
              }

              return `(Duration: ${data ? data + " or " : ""}${totalDays}d)`;
            }
          };
          return (
            <Form>
              <div className="mb-2 ml-2">
                <p>Business Account Name</p>
                <Field
                  className={`w-full border rounded p-2 bg-primary brandPrimaryColor${
                    closed ? " opacity-70" : ""
                  }`}
                  type="text"
                  name="accountName"
                  disabled={closed}
                />
              </div>
              <div className="mb-2 ml-2">
                <p>Start Date</p>
                <Field
                  className={`w-full border rounded p-2 bg-primary brandPrimaryColor${
                    closed ? " opacity-70" : ""
                  }`}
                  type="date"
                  name="startDate"
                  disabled={closed}
                />
              </div>
              <div className="mb-2 ml-2">
                <p>
                  <span>
                    End Date {tenancyDuration(values.startDate, values.endDate)}
                  </span>
                  <span style={{ float: "right" }}>
                    {tenancyDuration(
                      new Date().toISOString().split("T")[0],
                      values.endDate,
                      true
                    )}
                  </span>
                </p>
                <Field
                  className={`w-full border rounded p-2 bg-primary brandPrimaryColor${
                    closed ? " opacity-70" : ""
                  }`}
                  type="date"
                  name="endDate"
                  disabled={closed}
                />
              </div>
              <div className="mb-2 ml-2">
                <p>ABN</p>
                <Field
                  as={CustomABNInputComponent}
                  className={`w-full border rounded p-2 bg-primary brandPrimaryColor${
                    closed ? " opacity-70" : ""
                  }`}
                  type="text"
                  name="abn"
                  disabled={closed}
                />
              </div>
              <div className="mb-2 ml-2">
                <p>Contact Number</p>
                <Field
                  className={`w-full border rounded p-2 bg-primary brandPrimaryColor${
                    closed ? " opacity-70" : ""
                  }`}
                  type="text"
                  name="contactNumber"
                  disabled={closed}
                />
              </div>
              <div className="mb-2 ml-2">
                <p>Street Address</p>
                <Field
                  className={`w-full border rounded p-2 bg-primary brandPrimaryColor${
                    closed ? " opacity-70" : ""
                  }`}
                  type="text"
                  name="streetAddress"
                  disabled={closed}
                />
              </div>
              <div className="mb-2 ml-2">
                <p>Postcode</p>
                <Field
                  className={`w-full border rounded p-2 bg-primary brandPrimaryColor${
                    closed ? " opacity-70" : ""
                  }`}
                  type="text"
                  name="postcode"
                  disabled={closed}
                />
              </div>
              <div className="mb-2 ml-2">
                <p>State</p>
                <Field
                  className={`w-full border rounded p-2 bg-primary brandPrimaryColor${
                    closed ? " opacity-70" : ""
                  }`}
                  type="text"
                  name="state"
                  disabled={closed}
                />
              </div>
              {type === "create" && (
                <div className="mb-2 ml-2">
                  <p>Admin User Name</p>
                  <Field
                    className="w-full border rounded p-2 bg-primary brandPrimaryColor"
                    type="text"
                    name="username"
                  />
                </div>
              )}
              <div className="mb-2 ml-2">
                <p>Admin User Email</p>
                <Field
                  className={`w-full border rounded p-2 bg-primary brandPrimaryColor${
                    closed || type === "update" ? " opacity-70" : ""
                  }`}
                  type="email"
                  name="email"
                  autoComplete="off"
                  disabled={closed || type === "update"}
                />
              </div>
              {type === "create" && (
                <div className="mb-2 ml-2">
                  <p>Admin User Password</p>
                  <Field
                    className="w-full border rounded p-2 bg-primary brandPrimaryColor"
                    type="password"
                    name="password"
                    autoComplete="off"
                  />
                </div>
              )}
              {type === "create" && (
                <div className="mb-2 ml-2">
                  <p>Party ID</p>
                  <Field
                    className="w-full border rounded p-2 bg-primary brandPrimaryColor"
                    type="text"
                    name="party_id"
                    autoComplete="off"
                  />
                </div>
              )}
              <div className="mb-2 ml-2">
                <p>BSB (optional)</p>
                <Field
                  className={`w-full border rounded p-2 bg-primary brandPrimaryColor${
                    closed ? " opacity-70" : ""
                  }`}
                  type="text"
                  name="bsb"
                  disabled={closed}
                />
              </div>
              <div className="mb-2 ml-2">
                <p>Account Number (optional)</p>
                <Field
                  className={`w-full border rounded p-2 bg-primary brandPrimaryColor${
                    closed ? " opacity-70" : ""
                  }`}
                  type="text"
                  name="acc"
                  disabled={closed}
                />
              </div>
              {/* <div className="mb-2 ml-2">
            <p>Locations Purchased</p>
            <Field
              className="w-full border rounded p-2"
              type="text"
              name="locations"
            />
          </div> */}
              {type === "update" && (
                <div className="mb-4 ml-2">
                  <p>Plan Type</p>
                  <select
                    className="w-full border rounded p-2 bg-primary brandPrimaryColor"
                    value={tenancy_tier}
                    onChange={(e) => updateTier(e.target.value)}
                    disabled={
                      type === "update" &&
                      (closed || accountData?.tenancy_tier === "UNLIMITED")
                    }
                  >
                    {planTypes.map((x) => (
                      <option
                        value={x.value}
                        label={x.label}
                        key={x.value}
                        disabled={x.disabled}
                      />
                    ))}
                  </select>
                </div>
              )}
              {type === "update" && (
                <>
                  <div className="mb-2 ml-2 flex flex-row">
                    <div
                      className={`flex items-center rounded${
                        closed ? " opacity-70" : ""
                      }`}
                    >
                      <input
                        id="dlb-checkbox"
                        type="checkbox"
                        checked={dlb}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        onChange={(e) => updateDlb(e.target.checked)}
                        disabled={closed}
                      />
                      <label
                        style={{ marginTop: 2 }}
                        htmlFor="dlb-checkbox"
                        className="ml-2 ms-2"
                      >
                        DLB Enabled
                      </label>
                    </div>
                  </div>
                  <div className="mb-2 ml-2 flex flex-row">
                    <div className="flex items-center rounded">
                      <input
                        id="closed-checkbox"
                        type="checkbox"
                        checked={closed}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        onChange={(e) => {
                          const isClosed = e.target.checked;
                          if (accountData?.closed === "Yes" && isClosed) {
                            resetForm();
                          }
                          setClosed(isClosed);
                        }}
                      />
                      <label
                        style={{ marginTop: 2 }}
                        htmlFor="closed-checkbox"
                        className="ml-2 ms-2"
                      >
                        Closed
                      </label>
                    </div>
                  </div>
                </>
              )}
              <div className="my-3 ml-2 flex flex-row">
                <button
                  className="bg-green-600 text-white text-sm font-bold py-2 px-4 rounded"
                  type="submit"
                >
                  {submitAction}
                </button>
                <ButtonlessModal
                  show={showDeleteModal}
                  onClose={() => setShowDeleteModal(false)}
                  withoutHeader
                >
                  <DeleteAccount
                    name={accountData?.name}
                    partyId={accountData?.party_id}
                    onClose={(deleted) => {
                      setShowDeleteModal(false);
                      if (deleted) {
                        onDelete();
                      }
                    }}
                  />
                </ButtonlessModal>
                {type === "update" && (
                  <button
                    className="bg-red-600 text-white text-sm font-bold py-2 px-4 rounded ml-auto"
                    type="button"
                    onClick={() => setShowDeleteModal(true)}
                  >
                    Delete
                  </button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

// Validation of AccountEditor Form

const validateAccounts = (values, type, tenancy_tier) => {
  const schema = Joi.object().keys({
    accountName: Joi.string().required(),
    startDate: Joi.date().required(),
    endDate: Joi.date().greater(Joi.ref("startDate")).required(),
    abn: Joi.string()
      .length(11)
      .pattern(/^[0-9]+$/)
      .required()
      .allow(""),
    contactNumber: Joi.string().required(),
    streetAddress: Joi.string().required().regex(new RegExp("[A-Za-z0-9.-]+")),
    postcode: Joi.string().regex(new RegExp("^[0-9]{4}$")).required(),
    state: Joi.string()
      .min(2)
      .max(3)
      .required()
      .regex(new RegExp("[ A-Za-z-]+")),

    party_id:
      type === "create"
        ? Joi.string().required().regex(new RegExp("[ A-Za-z]+"))
        : Joi.any(),
    // locations: Joi.number().required(),
    username: type === "create" ? Joi.string().required() : Joi.any(),
    email: type === "create" ? Joi.string().required() : Joi.any(),
    password: type === "create" ? Joi.string().min(8).required() : Joi.any(),
    bsb: Joi.optional(),
    acc: Joi.optional(),
    // .min(8)
    // .max(11)
    // .pattern(/^[0-9]+$/),
    // tenancy_tier: Joi.required(),
  });

  var result = schema.validate(values);
  if (result.error) {
    console.error(result.error);
  }
  return { passed: !result.error, error: result.error };
};

const CustomABNInputComponent = ({ value, onChange, ...rest }) => {
  const parsedValue = value.split("");
  if (parsedValue[2]) parsedValue.splice(2, 0, " ");
  if (parsedValue[6]) parsedValue.splice(6, 0, " ");
  if (parsedValue[10]) parsedValue.splice(10, 0, " ");

  return (
    <input
      type="text"
      value={parsedValue.join("")}
      onChange={(e) => {
        e.target.value = e.target.value.replace(/\s+/g, "");
        onChange(e);
      }}
      {...rest}
    />
  );
};
