import { useState } from "react";

import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import {
  DIRECTIONS,
  IMAGE,
  ADD_FACILITY,
  REMOVE_FACILITY,
} from "../../reducers/locationOCPIInfo";

import Spinner from "../misc/Spinner";

import { gql, useMutation } from "@apollo/client";
import { connect } from "react-redux";

const EDIT_OCPI_LOCATION_MUTATION = gql`
  mutation Ocpi_update_location(
    $ocpiUpdateLocationId: String!
    $facilities: [String]
    $directions: String
    $images: [Upload]
  ) {
    ocpi_update_location(
      id: $ocpiUpdateLocationId
      facilities: $facilities
      directions: $directions
      images: $images
    )
  }
`;

const LOCATION_FACILITIES = [
  "HOTEL",
  "RESTAURANT",
  "CAFE",
  "MALL",
  "SUPERMARKET",
  "SPORT",
  "RECREATION_AREA",
  "NATURE",
  "MUSEUM",
  "BIKE_SHARING",
  "BUS_STOP",
  "TAXI_STAND",
  "TRAM_STOP",
  "METRO_STATION",
  "TRAIN_STATION",
  "AIRPORT",
  "PARKING_LOT",
  "CARPOOL_PARKING",
  "FUEL_STATION",
  "WIFI",
];

const mapStateEditOCPILocationProps = (state) => {
  const locationOCPIInfoForm = state.locationOCPIInfoForm;
  return {
    ocpiInfo: locationOCPIInfoForm,
  };
};

const EditOCPILocation = ({ locationId, role, ocpiInfo }) => {
  const [updateOCPILocation, { loading, error, data }] = useMutation(
    EDIT_OCPI_LOCATION_MUTATION
  );

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <h1>Error!</h1>;
  }

  if (
    role !== "sysadmin" &&
    role !== "tenancyadmin" &&
    role !== "locationadmin"
  ) {
    return null;
  }

  return (
    <div>
      <p>
        Adding additional information about your location (such as facilities,
        images and directions) helps attract users!
      </p>
      <div className="mt-2">
        <h1 className="formSectionTitle">Facilities</h1>
        <LocationFacilitySelectorContainer />
      </div>
      <div className="mt-2">
        <h1 className="formSectionTitle">Images</h1>
        <LocationImageSelectorContainer />
      </div>
      <div className="mt-2">
        <h1 className="formSectionTitle">Directions</h1>
        <p className="mt-2">
          Please note: Any text here will be turned into a list of steps,
          deliniated by a semicolor <b className="font-bold">;</b> If you wish
          to only provide a single step i.e "EVSES are located next to the
          entrace of the car park", don't include any semicolons.
        </p>
        <LocationDirectionsSectionContainer />
      </div>
      <button
        className="mt-4 border rounded bg-green-600 font-bold text-white px-4 py-2"
        onClick={async () => {
          await updateOCPILocation({
            variables: {
              ocpiUpdateLocationId: locationId,
              facilities: ocpiInfo.facilities,
              directions: ocpiInfo.directions,
              images: ocpiInfo.images,
            },
          });

          if (data?.updateOfflineDevices?.length >= 1) {
            alert(data.updateOfflineDevices.join("\n"));
          } else {
            alert("OCPI Location Metadata Updated Successfully");
          }
        }}
      >
        Save
      </button>
    </div>
  );
};

export const EditOCPILocationContainer = connect(mapStateEditOCPILocationProps)(
  EditOCPILocation
);

const handleAddFacilities = (facility) => ({
  type: ADD_FACILITY,
  facility,
});

const handleRemoveFacilities = (facility) => ({
  type: REMOVE_FACILITY,
  facility,
});

const LocationFacilitySelector = ({
  facilities = [],
  handleAddFacilities,
  handleRemoveFacilities,
}) => {
  /* A local state is used to know which capability is selected from dropdown */
  const [selectedFacility, updateSelectedFacility] = useState(undefined);

  var capabilityList = null;

  if (facilities?.length) {
    capabilityList = facilities
      .sort()
      .map((x) => (
        <LocationFacilityTile
          key={x}
          name={x}
          onDeleted={() => handleRemoveFacilities(x)}
        />
      ));
  }

  return (
    <div className="mt-2" key="device-capability-selector">
      {capabilityList}
      <h1 key="title">Add Facility:</h1>
      <div className="flex flex-row">
        <select
          className="border rounded mb-1"
          name="location"
          onChange={(e) => updateSelectedFacility(e.target.value)}
        >
          <option value="none" label="Select a capability" key="none" />
          {LOCATION_FACILITIES.map((x) => (
            <option value={x} label={x} key={x} />
          ))}
        </select>
        <div className="ml-2 mt-0.5">
          <button
            type="button"
            onClick={() => handleAddFacilities(selectedFacility)}
          >
            <FaPlusCircle size={28} />
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToLocationFacilitySelector = (state) => {
  const locationOCPIInfoForm = state.locationOCPIInfoForm;
  return {
    facilities: locationOCPIInfoForm.facilities,
  };
};

const LocationFacilitySelectorContainer = connect(
  mapStateToLocationFacilitySelector,
  {
    handleAddFacilities,
    handleRemoveFacilities,
  }
)(LocationFacilitySelector);

const mapStateToLocationImageSelector = (state) => {
  const locationOCPIInfoForm = state.locationOCPIInfoForm;
  return {
    image: locationOCPIInfoForm.image,
  };
};

const handleUpdateImage = (image) => ({
  type: IMAGE,
  image,
});

const LocationImageSelector = ({ handleUpdateImage }) => {
  var imageList = null;

  return (
    <div className="mt-2" key="device-capability-selector">
      {imageList}
      <h1 key="title">Add image:</h1>
      <div className="flex flex-row">
        <input
          type="file"
          id="img"
          name="img"
          accept="image/*"
          onChange={(e) => handleUpdateImage(e.target.files[0])}
        />
      </div>
    </div>
  );
};

const LocationImageSelectorContainer = connect(
  mapStateToLocationImageSelector,
  {
    handleUpdateImage,
  }
)(LocationImageSelector);

const mapStateToLocationDirectionsSection = (state) => {
  const locationOCPIInfoForm = state.locationOCPIInfoForm;
  return {
    directions: locationOCPIInfoForm.directions,
  };
};

const handleUpdateDirections = (directions) => ({
  type: DIRECTIONS,
  directions,
});

const LocationDirectionsSection = ({ handleUpdateDirections, directions }) => {
  return (
    <div className="flex flex-row mt-2">
      <h1 className="mr-2 w-1/2">Directions:</h1>
      <textarea
        className="border rounded p-2"
        name="directions"
        rows="3"
        cols="50"
        value={directions}
        onChange={(e) => handleUpdateDirections(e.target.value)}
      />
    </div>
  );
};

const LocationDirectionsSectionContainer = connect(
  mapStateToLocationDirectionsSection,
  {
    handleUpdateDirections,
  }
)(LocationDirectionsSection);

/*
 *  This same code is used multiple times throughout
 *  the codebase, it should really be abstracted.
 */
const LocationFacilityTile = ({ name, onDeleted }) => {
  return (
    <div className="m-2 border rounded flex flex-row">
      <p className="p-2" key={name}>
        {name}
      </p>
      <button
        key="button"
        type="button"
        className="ml-auto mr-2"
        onClick={onDeleted}
      >
        <FaMinusCircle />
      </button>
    </div>
  );
};
