import { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { gql, useQuery, useMutation } from "@apollo/client";
import Spinner from "../misc/Spinner";
import { Tooltip } from "../../components/Tooltip";

const CREATE_TARIFF = gql`
  mutation Mutation($tariff: TariffCreationInput!) {
    ocpi_create_tariff(tariff: $tariff)
  }
`;

const THEME = gql`
  query Account_theme {
    account_theme {
      primary_color
      secondary_color
      logo_url
    }
  }
`;

//The following are not yet supported.
//"FLAT", "PARKING_TIME"
const TARIFF_TYPES = ["ENERGY", "TIME", "FLAT"];

export const TariffForm = ({ onRefetch }) => {
  const [ocpi_create_tariff, { loading, error }] = useMutation(CREATE_TARIFF);

  const { themeLoading, themeError, themeData } = useQuery(THEME);

  const [tariffElements, updateTariffElements] = useState({
    price: null,
    step_size: null,
    type: null,
    parking_price: 0,
  });
  const [tariffDescription, updateTariffDescription] = useState("");

  if (loading || themeLoading) {
    return <Spinner />;
  }
  if (error || themeError) {
    return <div>Error</div>;
  }

  var background = "333";
  var color = "2fdce1";
  if (themeData?.account_theme?.primary_color) {
    background = themeData.account_theme.primary_color;
  }
  if (themeData?.account_theme?.secondary_color) {
    color = themeData.account_theme.secondary_color;
  }
  var styles = {
    backgroundColor: `#${background}`,
    color: `#${color}`,
  };
  return (
    <div>
      <h1 className="w-full p-1 text-center rounded" style={styles}>
        Add Tariff Element
      </h1>
      <TariffElementEditor
        updateTariffElements={updateTariffElements}
        tariffElements={tariffElements}
        styles={styles}
      />
      {/* Add parking fee section here, mention it is the price applied per hour of idling */}

      <h1 style={styles} className="mt-2 w-full p-1 text-center rounded">
        Description
      </h1>
      <TariffDescriptionEditor
        onChange={updateTariffDescription}
        styles={styles}
      />
      <button
        className="bg-green-600 text-white text-sm font-bold py-2 px-4 rounded"
        onClick={async () => {
          if (
            !tariffDescription ||
            !tariffElements ||
            !tariffDescription.length
          ) {
            alert("Please fill out all fields!");
            return;
          }

          var tariff = buildTariff(tariffDescription, tariffElements);
          console.log("Tariff", tariff);
          if (!validateTariff(tariff)) {
            alert("Tariff incomplete!");
            return;
          }

          const result = await ocpi_create_tariff({
            variables: {
              tariff: tariff,
            },
          });

          alert("Tariff successfully added.");
          // window.location.reload(false);
          onRefetch();
        }}
      >
        Save Tariff
      </button>
    </div>
  );
};

function validateTariff(tariff) {
  if (
    !(
      tariff.country_code &&
      tariff.currency &&
      tariff.price &&
      tariff.dimension_type &&
      tariff.step_size &&
      tariff.description
    )
  ) {
    return false;
  } else {
    return true;
  }
}

function buildTariff(description, elements) {
  return {
    country_code: "AU",
    currency: "AUD",
    dimension_type: elements["type"],
    price: elements["price"],
    step_size: elements["type"] === "ENERGY" ? 1 : 60,
    parking_price: elements.parking_price,
    description: description,
  };
}

const TariffDescriptionEditor = ({ onChange, styles }) => {
  return (
    <div>
      <label style={styles} name="description"></label>
      <textarea
        className="mt-2 border rounded w-full pl-1 pt-1"
        name="description"
        placeholder="Enter tariff description here."
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

const TariffElementEditor = ({ tariffElements, updateTariffElements }) => {
  console.log(tariffElements);
  return (
    <div className="border rounded mt-2 p-2" key="form">
      <Formik>
        <div className="mt-2">
          <div className="flex flex-row" key="type">
            <h1 className="w-1/3">Tariff Type: </h1>
            <Field
              as="select"
              onChange={(e) => {
                tariffElements.type = e.target.value;
                if (e.target.value === "TIME") tariffElements.step_size = 60;
                else tariffElements.step_size = 1;
                updateTariffElements(tariffElements);
              }}
              className="border rounded ml-1 w-1/2"
            >
              <option value="none" label="Select tariff type" />
              {TARIFF_TYPES.map((x) => (
                <option key={x} value={x} label={x} />
              ))}
            </Field>
          </div>
          <div className="flex flex-row mt-2" key="price">
            <h1 className="w-1/3">Tariff Price ($): </h1>
            <input
              className="border rounded ml-1 w-1/2 pl-1"
              type="number"
              placeholder={"Price per minute/kWh"}
              onChange={(e) => {
                tariffElements.price = parseFloat(e.target.value);
                updateTariffElements(tariffElements);
              }}
            />
            <Tooltip id="flat" className="mt-1 ml-2">
              If FLAT type was selected, this is just the flat rate applied.
            </Tooltip>
          </div>
          <div className="flex flex-row mt-2" key="parking_price">
            <h1 className="w-1/3">Idle Fee ($)</h1>
            <input
              className="border rounded ml-1 w-1/2 pl-1"
              type="number"
              placeholder={"Price per hour"}
              onChange={(e) => {
                tariffElements.parking_price = parseFloat(e.target.value);
                updateTariffElements(tariffElements);
              }}
            />
            <Tooltip id="parking" className="mt-1 ml-2">
              This is the fee applied to drivers who are finished charging, but
              occupying the charger.
            </Tooltip>
          </div>
        </div>
      </Formik>
    </div>
  );
};
