import logo from "../../../assets/logo.png";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { EcommerceHeader } from "../../../components/EcommerceHeader";

export const VehiclesPage = () => {
  const [newVehicleCard, showNewVehicleCard] = useState(false);
  /* TEMPORARY RETURN NULL */
  if (true) {
    var body = <h1 className="mt-32 text-center w-full">Coming soon;</h1>;
  } else {
    var body = (
      <div>
        <VehicleList />
        <div className="mt-8 w-full flex flex-row justify-center">
          <div className="mx-auto rounded inline-block items-center text-center border border-solid p-1 border-black w-auto">
            <AiOutlinePlus className="mr-0.5 mb-1 inline" /> Add Vehicle
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="w-full sm:flex overflow-y-auto">
      <div className="bg-white w-full h-full overflow-auto">
        <EcommerceHeader>MY VEHICLES</EcommerceHeader>
        {body}
      </div>
    </div>
  );
};

/* List of the users vehicles. Vehicle object should have fields:
    Name, Photo, Battery Capacity, Port Type */
const VehicleList = ({}) => {
  const dummy_vehicles = [
    {
      name: "Tesla Model S",
      photo: logo,
      capacity: 90,
      portType: "Type 2",
    },
    {
      name: "Tesla Model X",
      photo: logo,
      capacity: 110,
      portType: "Type 2 DC",
    },
  ];
  /* Query vehicles here */
  return (
    <div className="flex flex-row flex-wrap justify-center px-2 pt-5 mt-20">
      <VehicleCard vehicle={dummy_vehicles[0]} />
      <VehicleCard vehicle={dummy_vehicles[1]} />
    </div>
  );
};

const VehicleCard = ({ vehicle }) => {
  return (
    <div className="inline-block rounded mb-2 w-full mx-4 max-w-sm bg-primary ">
      <div className="flex flex-row">
        <div className="flex flex-col justify-center">
          <img className="h-32 w-48 p-4" src={vehicle.photo} />
        </div>
        <div className="flex flex-col py-1 justify-center">
          <div className="flex flex-row brandPrimaryColor mb-3">
            {vehicle.name}
          </div>
          <div className="text-sm text-white">
            Capacity: {vehicle.capacity} kWh
          </div>
          <div className="text-sm text-white">
            Port Type: {vehicle.portType}
          </div>
        </div>
      </div>
    </div>
  );
};
