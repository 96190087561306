import React, { useEffect, useState } from "react";
import Firebase from "firebase/app";

import { startCommand } from "../../actions/machines";
import Spinner from "../misc/Spinner";

import moment from "moment";

const Commands = ({ chargePointId }) => {
  const [isOpen, toggleOpen] = useState(true);
  const [pendingCommands, updatePendingCommands] = useState(undefined);
  const [resultedCommands, updateResultedCommands] = useState(undefined);

  useEffect(() => {
    Firebase.firestore()
      .collection(`ocpp_logs`)
      .doc(chargePointId)
      .collection("pending_commands")
      .orderBy("timestamp", "desc")
      .limit(20)
      .onSnapshot((x) => {
        updatePendingCommands(x.docs.map((x) => x.data()));
      });

    Firebase.firestore()
      .collection(`ocpp_logs`)
      .doc(chargePointId)
      .collection("resulted_commands")
      .orderBy("timestamp", "desc")
      .limit(20)
      .onSnapshot((x) => {
        updateResultedCommands(x.docs.map((x) => x.data()));
      });
  }, []);

  return (
    <div className="w-full">
      <div className="mb-4 text-xs">
        <div className="flex justify-right mb-2">
          <button
            className="border p-1 ml-2"
            onClick={() => startCommand(chargePointId, "ClearCache", {})}
          >
            Clear Cache
          </button>
        </div>
        <div className="flex justify-right mb-2">
          <button
            className="border p-1 ml-2"
            onClick={() => startCommand(chargePointId, "GetConfiguration", {})}
          >
            Get Config
          </button>
        </div>
        <p>Request Message from Machine:</p>
        <div className="flex flex-wrap justify-right mb-2">
          <button
            className="border p-1 ml-2"
            onClick={() =>
              startCommand(chargePointId, "TriggerMessage", {
                requestedMessage: "BootNotification",
              })
            }
          >
            BootNotification
          </button>
          <button
            className="border p-1 ml-2"
            onClick={() =>
              startCommand(chargePointId, "TriggerMessage", {
                requestedMessage: "StatusNotification",
              })
            }
          >
            StatusNotification
          </button>
          <button
            className="border p-1 ml-2"
            onClick={() =>
              startCommand(chargePointId, "TriggerMessage", {
                requestedMessage: "MeterValues",
              })
            }
          >
            MeterValues 0{" "}
          </button>
          <button
            className="border p-1 ml-2"
            onClick={() =>
              startCommand(chargePointId, "TriggerMessage", {
                requestedMessage: "MeterValues",
                connectorId: 1,
              })
            }
          >
            MeterValues 1{" "}
          </button>
          <button
            className="border p-1 ml-2"
            onClick={() =>
              startCommand(chargePointId, "TriggerMessage", {
                requestedMessage: "MeterValues",
                connectorId: 2,
              })
            }
          >
            MeterValues 2{" "}
          </button>
          <button
            className="border p-1 ml-2"
            onClick={() =>
              startCommand(chargePointId, "TriggerMessage", {
                requestedMessage: "DiagnosticsStatusNotification",
              })
            }
          >
            DiagnosticsStatusNotification
          </button>
          <button
            className="border p-1 ml-2"
            onClick={() =>
              startCommand(chargePointId, "TriggerMessage", {
                requestedMessage: "FirmwareStatusNotification",
              })
            }
          >
            FirmwareStatusNotification
          </button>
          <button
            className="border p-1 ml-2"
            onClick={() =>
              startCommand(chargePointId, "TriggerMessage", {
                requestedMessage: "Heartbeat",
              })
            }
          >
            Heartbeat
          </button>
        </div>
        <p>Remote Reset:</p>
        <div className="flex justify-right mb-2">
          <button
            className="border p-1 ml-2"
            onClick={() =>
              startCommand(chargePointId, "Reset", { type: "Soft" })
            }
          >
            Soft Reset
          </button>
          <button
            className="border p-1 ml-2"
            onClick={() =>
              startCommand(chargePointId, "Reset", { type: "Hard" })
            }
          >
            Hard Reset
          </button>
        </div>
        <p>Change Availability:</p>
        <div className="flex justify-right mb-2">
          <button
            className="border p-1 ml-2"
            onClick={() =>
              startCommand(chargePointId, "ChangeAvailability", {
                type: "Operative",
                connectorId: 0,
              })
            }
          >
            Operative
          </button>
          <button
            className="border p-1 ml-2"
            onClick={() =>
              startCommand(chargePointId, "ChangeAvailability", {
                type: "Inoperative",
                connectorId: 0,
              })
            }
          >
            Inoperative
          </button>
        </div>
        <p>Remote Start Transaction:</p>
        <div className="flex justify-right mb-2">
          <button
            className="border p-1 ml-2"
            onClick={() =>
              startCommand(chargePointId, "RemoteStartTransaction", {
                idTag: "manualTransaction",
                connectorId: 1,
              })
            }
          >
            #1
          </button>
          <button
            className="border p-1 ml-2"
            onClick={() =>
              startCommand(chargePointId, "RemoteStartTransaction", {
                idTag: "manualTransaction",
                connectorId: 2,
              })
            }
          >
            #2
          </button>
        </div>
        <p>Unlocks Conectors:</p>
        <div className="flex justify-right mb-2">
          <button
            className="border p-1 ml-2"
            onClick={() =>
              startCommand(chargePointId, "UnlockConnector", { connectorId: 1 })
            }
          >
            #1
          </button>
          <button
            className="border p-1 ml-2"
            onClick={() =>
              startCommand(chargePointId, "UnlockConnector", { connectorId: 2 })
            }
          >
            #2
          </button>
        </div>
      </div>
      <hr />
      <CommandInterface
        pendingCommands={pendingCommands}
        resultedCommands={resultedCommands}
      />
    </div>
  );
};

const CommandInterface = ({ pendingCommands, resultedCommands }) => {
  if (!pendingCommands || !resultedCommands) {
    return <Spinner />;
  }

  return (
    <div className="mt-4">
      <p className="underline mb-2">Pending Commands</p>
      {pendingCommands.map((x) => (
        <PendingCommand data={x} />
      ))}
      <p className="underline mb-2">Resulted Commands</p>
      {resultedCommands.map((x) => (
        <ResultedCommand data={x} />
      ))}
    </div>
  );
};

const PendingCommand = ({ data }) => {
  return (
    <div className="w-full border mb-1 relative" key={data.id}>
      <div className="flex text-xs">
        <p className="mx-2">{data?.command}</p>
      </div>
      <div className="flex text-xs">
        {data.error && <span className="">ERROR:{data.error}</span>}
      </div>
    </div>
  );
};

const ResultedCommand = ({ data }) => {
  var sentDateText = "unknown";

  if (data?.timestamp && typeof data?.timestamp === "string") {
    sentDateText = new Date(data.timestamp).toDateString();
  }

  if (
    data?.timestamp &&
    typeof data?.timestamp !== "string" &&
    new Date(data?.timestamp?.toDate())?.toDateString()
  ) {
    sentDateText = moment(new Date(data?.timestamp?.toDate())).format(
      "MMMM Do YYYY, h:mm:ss a"
    );
  }

  return (
    <div className="w-full border mb-1 relative" key={data.id}>
      <div className="flex text-xs">
        <p className="">{data?.command?.command}</p>
        <span className="ml-auto mr-2 mt-1">Sent: {sentDateText}</span>
      </div>
      <div className="">
        <pre style={{ fontSize: "10px" }}>
          {JSON.stringify(data.response, null, 2)}
        </pre>
      </div>
      <div className="flex text-xs">
        {data.error && <span className="">ERROR:{data.error}</span>}
      </div>
    </div>
  );
};

const RemoteStopTransaction = ({ chargePointId }) => {
  return (
    <div className="flex flex-col">
      <div className="flex justify-right mb-2">
        <button
          className="border p-1 ml-2"
          onClick={() =>
            startCommand(chargePointId, "RemoteStopTransaction", {
              idTag: "manualTransaction",
              connectorId: 0,
            })
          }
        >
          #0
        </button>
        <button
          className="border p-1 ml-2"
          onClick={() =>
            startCommand(chargePointId, "RemoteStopTransaction", {
              idTag: "manualTransaction",
              connectorId: 1,
            })
          }
        >
          #1
        </button>
        <button
          className="border p-1 ml-2"
          onClick={() =>
            startCommand(chargePointId, "RemoteStopTransaction", {
              idTag: "manualTransaction",
              connectorId: 2,
            })
          }
        >
          #2
        </button>
      </div>
    </div>
  );
};

export default Commands;
