import { Tooltip } from "../../components/Tooltip";
import { useState } from "react";
import { checkPartyID } from "../../actions/accounts";

export const CompanyInfo = ({ onNextPage }) => {
  const [companyInfo, setCompanyInfo] = useState({});

  return (
    <div className="">
      <form>
        <div className="flex flex-row mt-2">
          <label className="w-1/4 mt-1">Tenancy Name</label>
          <input
            value={companyInfo.companyname}
            className="border rounded p-1 bg-primary brandPrimaryColor"
            type="text"
            id="companyname"
            placeholder="Name"
            onChange={(e) => {
              setCompanyInfo({
                ...companyInfo,
                companyname: e.target.value,
              });
            }}
          />
        </div>
        <div className="flex flex-row mt-1">
          <label className="w-1/4 mt-1">Street Address</label>
          <input
            className="border rounded p-1 bg-primary brandPrimaryColor"
            type="address"
            id="address"
            placeholder="Address"
            onChange={(e) => {
              setCompanyInfo({
                ...companyInfo,
                address: e.target.value,
              });
            }}
          />
        </div>
        <div className="flex flex-row mt-1">
          <label className="w-1/4 mt-1">Postcode</label>
          <input
            className="border rounded p-1 bg-primary brandPrimaryColor"
            type="text"
            id="postcode"
            placeholder="Postcode"
            onChange={(e) => {
              setCompanyInfo({
                ...companyInfo,
                postcode: e.target.value,
              });
            }}
          />
        </div>
        <div className="flex flex-row mt-1">
          <label className="w-1/4 mt-1">State</label>
          <input
            className="border rounded p-1 bg-primary brandPrimaryColor"
            type="text"
            id="state"
            placeholder="State"
            onChange={(e) => {
              setCompanyInfo({
                ...companyInfo,
                state: e.target.value,
              });
            }}
          />
        </div>
        <div className="flex flex-row mt-1">
          <label className="w-1/4 mt-1">Party ID</label>
          <input
            className="border rounded p-1 bg-primary brandPrimaryColor"
            type="text"
            id="party_id"
            placeholder="Party ID"
            maxLength="3"
            onChange={(e) => {
              setCompanyInfo({
                ...companyInfo,
                party_id: e.target.value,
              });
            }}
          />
          <Tooltip id="partyid" className="mt-2.5 ml-2">
            Party ID will identify you as a tenancy in our system. <br />
            Choose a 3 character code which will be used for your tenancy.{" "}
            <br />
            For example: EVUp = EUP.
          </Tooltip>
        </div>
        <p className="text-center mt-2">Optional</p>
        <hr className="mb-2" />

        <div className="flex flex-row mt-1">
          <label className="w-1/4 mt-1">Contact Number</label>
          <input
            className="border rounded p-1 bg-primary brandPrimaryColor"
            type="text"
            id="phonenumber"
            placeholder="Contact #"
            onChange={(e) => {
              setCompanyInfo({
                ...companyInfo,
                phonenumber: e.target.value,
              });
            }}
          />
        </div>
        <div className="flex flex-row mt-1">
          <label className="w-1/4 mt-1">ABN</label>
          <input
            className="border rounded p-1 bg-primary brandPrimaryColor"
            type="number"
            id="abn"
            placeholder="Enter ABN"
            onChange={(e) => {
              setCompanyInfo({
                ...companyInfo,
                abn: Number.parseInt(e.target.value),
              });
            }}
          />
        </div>
        <div className="flex flex-row mt-1">
          <label className="w-1/4 mt-1">BSB</label>
          <input
            className="border rounded p-1 bg-primary brandPrimaryColor"
            type="number"
            id="bsb"
            placeholder="BSB"
            onChange={(e) => {
              setCompanyInfo({
                ...companyInfo,
                bsb: Number.parseInt(e.target.value),
              });
            }}
          />
          <Tooltip id="bankdetails" className="mt-2.5 ml-2">
            We use your bank details to disburse your charger earnings! If you
            wish to have a paid charger, please provide this
          </Tooltip>
        </div>
        <div className="flex flex-row mt-1">
          <label className="w-1/4 mt-1">Account Number</label>
          <input
            className="border rounded p-1 bg-primary brandPrimaryColor"
            type="number"
            id="account"
            placeholder="Account #"
            onChange={(e) => {
              setCompanyInfo({
                ...companyInfo,
                account_number: Number.parseInt(e.target.value),
              });
            }}
          />
        </div>
        <button
          type="submit"
          className="bg-blue-400 text-white py-2 px-4 rounded mt-2"
          onClick={async (e) => {
            e.preventDefault();

            var result = await validateCompanyInfo(companyInfo);

            if (result !== true) {
              alert(
                `The following information must be filled out to proceed: ${result}`
              );
              return;
            }

            onNextPage(companyInfo);
          }}
        >
          Next
        </button>
      </form>
    </div>
  );
};

//create a function which validates all fields in company info are valid
const validateCompanyInfo = async (companyInfo) => {
  if (!companyInfo.companyname || companyInfo.companyname.length === 0) {
    return "Company name";
  }
  if (!companyInfo.abn) {
    return "ABN";
  }
  if (!companyInfo.address) {
    return "Address";
  }
  if (!companyInfo.postcode) {
    return "Postcode";
  }
  if (!companyInfo.phonenumber) {
    return "Contact Number";
  }
  if (!companyInfo.state) {
    return "State";
  }
  if (!companyInfo.party_id || companyInfo.party_id.length !== 3) {
    return "Party ID, must be only 3 characters. Can be numbers.";
  }

  var validPartyID = await checkPartyID(companyInfo.party_id);

  if (validPartyID === false) {
    return "Party ID already exists";
  }

  return true;
};
