import { gql, useQuery } from "@apollo/client";
import { useState } from "react";
import Spinner from "../../misc/Spinner";
import { PresetPreview } from "./PresetPreview";

const GET_PRESETS = gql`
  query Device_presets {
    device_presets {
      capabilities
      connectors {
        id
        standard
        format
        power_type
        max_voltage
        max_amperage
        max_electric_power
        tariff_ids
        terms_and_conditions
        last_updated
      }
      vendor
      model_name
      preset_name
      id
      chargePointModels
    }
  }
`;

export const PresetSelect = ({
  selectedPreset,
  updateSelectedPreset,
  onNext,
}) => {
  const { error, loading, data } = useQuery(GET_PRESETS);
  if (error) return <div>Error fetching presets :(</div>;

  if (loading) return <Spinner />;

  return (
    <div className="mt-2 flex flex-col">
      <div className="p-2 w-full rounded bg-primary text-sm text-white items-center m-auto mb-2">
        <p>
          Select from the presets below which type of device you wish to
          connect!
        </p>
      </div>
      <hr />
      <div className="flex flex-col gap-y-2 overflow-y-scroll h-96 bg-black p-2 rounded border border-black">
        {data.device_presets.map((x) => (
          <PresetPreview
            preset={x}
            updateSelected={updateSelectedPreset}
            selected={selectedPreset}
          />
        ))}
      </div>
      <div className="flex flex-row">
        <button
          className={`text-white border rounded p-2 ml-auto mt-4
            ${selectedPreset ? "bg-green-600" : "bg-green-300"}`}
          onClick={() => {
            if (selectedPreset) onNext();
          }}
        >
          Continue {">"}
        </button>
      </div>
    </div>
  );
};
