import React, { useState } from "react";
import ReactDOM from "react-dom";
import { FaCog, FaWindowClose } from "react-icons/fa";
import disableScroll from "disable-scroll";

export const RightModal = ({
  title = "",
  startOpen,
  showIcon = true,
  children,
  scrollOnOpen = true,
  showTitle = true,
  className = "",
}) => {
  const [isOpen, toggleOpen] = useState(startOpen);
  const portalContainer = document.getElementById("rightModalTarget");

  if (isOpen && !scrollOnOpen) {
    console.debug("Going to top");
    window.scrollTo(0, 0);
    disableScroll.on();
  } else {
    disableScroll.off();
  }

  return (
    <>
      <ActionButton
        toggleOpen={toggleOpen}
        isOpen={isOpen}
        title={title}
        showIcon={showIcon}
        className={className}
      />
      {isOpen &&
        ReactDOM.createPortal(
          <>
            <div className="absolute z-20 inset-0 h-full w-full overflow-auto bg-gray-500 bg-opacity-50">
              <div className="absolute z-50 right-0 top-0 bottom-0 h-full w-full max-w-lg">
                <div className="bg-white w-full shadow p-4 z-100 min-h-full">
                  <div className="flex flex-row">
                    <h1 className="text-2xl mt-1 ml-2">{title}</h1>
                    <button
                      className="btn-red flex mb-4 ml-auto p-2"
                      onClick={() => {
                        toggleOpen(false);
                      }}
                    >
                      <FaWindowClose className="inline mr-3 mt-0.5 text-lg" />
                      <span className="inline">Close</span>
                    </button>
                  </div>
                  {React.Children.map(children, (child) => {
                    return React.cloneElement(child, {
                      toggleOpen: () => toggleOpen(!isOpen),
                    });
                  })}
                </div>
              </div>
            </div>
          </>,
          portalContainer
        )}
    </>
  );
};

export const ButtonlessModal = ({
  children,
  onClose,
  show,
  title,
  withoutHeader,
}) => {
  if (!show) {
    return null;
  }

  const portalContainer = document.getElementById("rightModalTarget");

  return ReactDOM.createPortal(
    <>
      <div className="absolute z-20 inset-0 h-full w-full overflow-auto bg-gray-500 bg-opacity-50">
        <div className="absolute z-50 right-0 top-0 bottom-0 h-full w-full max-w-lg">
          <div
            className={`bg-white w-full shadow p-4 z-100 ${
              withoutHeader ? "absolute bottom-0" : "min-h-full"
            }`}
          >
            {!withoutHeader && (
              <div className="flex flex-row">
                <h1 className="m-3 text-2xl">{title}</h1>
                <button
                  className="btn-red flex mb-4 ml-auto p-2"
                  onClick={() => {
                    onClose(false);
                  }}
                >
                  <FaWindowClose className="inline mr-3 mt-0.5 text-lg" />
                  <span className="inline">Close</span>
                </button>
              </div>
            )}
            <div className="m-3">
              {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                  toggleOpen: () => onClose(false),
                });
              })}
            </div>
          </div>
        </div>
      </div>
    </>,
    portalContainer
  );
};

export const ActionButton = ({
  toggleOpen,
  isOpen,
  startOpen,
  title,
  showIcon,
  className = "",
}) => {
  if (!isOpen && !startOpen) {
    if (title) {
      return (
        <button
          id={title}
          className={`btn bg-primary flex mx-1 border border-black rounded p-2 ${className}`}
          onClick={() => toggleOpen(!isOpen)}
        >
          {showIcon && <FaCog className="inline text-white mr-2 mt-1" />}
          {title && <span className="inline brandPrimaryColor">{title}</span>}
        </button>
      );
    } else {
      return (
        <button
          className="btn bg-white flex mx-1 border rounded p-2"
          onClick={() => toggleOpen(!isOpen)}
        >
          <FaCog className="text-green-600" />
        </button>
      );
    }
  } else {
    return null;
  }
};
