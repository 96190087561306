import React, { useState, useEffect } from "react";
import { useMutation, gql } from "@apollo/client";
import { FirestoreDocument } from "react-firestore";
import {
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { FaCreditCard } from "react-icons/fa";

import { PricingCalculator } from "./PricingCalculator";
import { PaymentForm } from "./PaymentForm";
import { useAuth } from "../../hooks/useAuth";

import { Stepper } from "react-form-stepper";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_SECRET_KEY}`);

const CREATE_INTENT = gql`
  mutation CreateCardPaymentIntent($amount: String!, $currency: String!) {
    createCardPaymentIntent(amount: $amount, currency: $currency)
  }
`;

const STARTCHARGING_MUTATION = gql`
  mutation InitiatePrepaidCharging($prop: ContractProposition) {
    initiatePrepaidCharging(prop: $prop)
  }
`;

export const TariffWrapper = ({ evse, connector_id }) => {
  var tariff = evse.tariff;

  if (!tariff) {
    return <h1>This device is misconfigured. Please contact site admin.</h1>;
  }

  return (
    <PaymentFlow connector_id={connector_id} tariff={tariff} evse={evse} />
  );
};

const PaymentFlow = ({ evse, tariff, connector_id }) => {
  const [paymentStep, updatePaymentStep] = useState(0);
  const [contractProposition, updateContractProposition] = useState(undefined);
  const [createIntent, createIntentStatus] = useMutation(CREATE_INTENT);
  const [clientSecret, setClientSecret] = useState(undefined);

  async function createPaymentIntent(amount) {
    const result = await createIntent({
      variables: {
        amount: Math.round(amount).toString(),
        currency: tariff.currency,
      },
    });

    if (result.error) {
      throw new Error(`${result.error}`);
    }

    return result;
  }

  var currentStep = (
    <h1 className="text-white text-center mt-2">
      Error initiating session. Please try another device.
    </h1>
  );

  if (paymentStep === 0) {
    /*
     * The first step of payment, the user selects
     * how much charging they would like to purchase.
     * on completion of this, a contract proposition
     * is returned, this describes the proposed contract
     * the charging device enters with the user.
     */
    currentStep = (
      <PricingCalculator
        tariff={tariff}
        evse={evse}
        onNext={async (x) => {
          updateContractProposition(x);
          const result = await createPaymentIntent(x.total_price * 100);
          setClientSecret(result.data.createCardPaymentIntent);
          updatePaymentStep(paymentStep + 1);
        }}
      />
    );
  } else if (paymentStep === 1) {
    /*
     * Next, the user will enter payment details
     */
    currentStep = (
      <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
        <PaymentCardForm
          contractProposition={contractProposition}
          connector_number={connector_id}
          evse_id={evse.id}
          onNext={() => {
            updatePaymentStep(paymentStep + 1);
          }}
          onBack={() => {
            updateContractProposition(undefined);
            updatePaymentStep(0);
          }}
        />
      </Elements>
    );
  }

  return (
    <div>
      <div className="border rounded bg-white p-2 mb-2">
        <h1 className="text-center text-2xl font-bold mt-2">
          Pre-pay for charging
        </h1>
        <Stepper
          steps={[
            { label: "Select terms" },
            { label: "Enter payment information" },
            { label: "Begin session" },
          ]}
          activeStep={paymentStep}
          styleConfig={{
            activeBgColor: "#63b3ed",
            completedBgColor: "#059669",
          }}
        />
      </div>
      {currentStep}
    </div>
  );
};

const PaymentCardForm = ({
  contractProposition,
  evse_id,
  connector_number,
  onNext,
  onBack,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [startCharging, startChargingStatus] = useMutation(
    STARTCHARGING_MUTATION
  );

  const user = useAuth();

  function displayPaymentResults(status) {
    const statusContainer = document.getElementById("payment-status-container");
    if (!statusContainer) return;
    if (status === "SUCCESS") {
      statusContainer.classList.remove("is-failure");
      statusContainer.classList.add("is-success");
    } else {
      statusContainer.classList.remove("is-success");
      statusContainer.classList.add("is-failure");
    }

    statusContainer.style.visibility = "visible";
  }

  const handleSubmit = async (event) => {
    setPaymentProcessing(true);
    console.debug(`Payment button clicked`);
    event.preventDefault();

    if (!stripe || !elements) {
      console.error("Stripe or Elements not set.");
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      displayPaymentResults("FAILURE");
    } else {
      // Payment successful, start charging
      const prop = {
        total_price: contractProposition.total_price,
        type: contractProposition.type,
        units: contractProposition.units,
        evse_id: evse_id,
        connector_number: Number.parseInt(connector_number),
        tariff_id: contractProposition.tariff.id,
        user_uid: user?.uid || null,
        payment_intent: result.paymentIntent.id,
      };

      await startCharging({
        variables: {
          prop: prop,
        },
      });
      displayPaymentResults("SUCCESS");
      if (!startChargingStatus.error) onNext();
    }
    setPaymentProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button
        id="card-button"
        type="submit"
        disabled={!stripe || paymentProcessing}
        style={{ marginTop: "20px" }}
      >
        Pay ${contractProposition.total_price}
      </button>
      {!paymentProcessing && (
        <button className="btn-red mt-2" onClick={() => onBack()}>
          Back
        </button>
      )}
      <div id="payment-status-container"></div>
    </form>
  );
};
