import React from 'react'
import { Formik, Form, Field } from 'formik';

const timeZones = [
    "Australia/Adelaide",
    "Australia/Canberra",
    "Australia/Darwin",
    "Australia/Tasmania",
    "Australia/Melbourne",
    "Australia/Sydney",
    "Australia/Queensland"
]


export const CreateLocation = ({ initialData, onSubmit }) => {

    return (
        <Formik
            initialValues={initialData}
            onSubmit={onSubmit}
        >
            <Form className="">
                <div className="mb-2 p-1 w-full">
                    <label className="">Location Name: </label>
                    <Field className="" type="text" name="metadata.displayName" />
                </div>
                <div className="mb-2 p-1 w-full border rounded p-2">
                    <label className="">Mode</label>
                    <Field as="select" name="mode">
                        <option value="free" label="Free Use" />
                        <option value="rfid" label="Valid RFID Required" />
                        <option value="paid" label="Requires Payment" />
                    </Field>
                    <p>Set the mode of the location</p>
                </div>
                <div className="mb-2 p-1 w-full flex justify-between items-center border rounded">
                    <label className="">Publish: visible publicly in app</label>
                    <Field className="" type="checkbox" name="publish" />
                </div>
                <div className="mb-2 p-1 w-full flex justify-between items-center border rounded">
                    <label className="">OCPI: integrate with 3rd party apps?</label>
                    <Field className="" type="checkbox" name="ocpi" />
                </div>
                <Field className="" type="hidden" name="source" required />
                <Field className="" type="hidden" name="country_code" required />
                <Field className="" type="hidden" name="party_id" required />
                <div className="mb-2 p-1 w-full">
                    <label className="">Street Address</label>
                    <Field className="" type="text" name="address" />
                </div>
                <div className="mb-2 p-1 w-full md:w-1/2">
                    <label className="">City</label>
                    <Field className="" type="text" name="city" />
                </div>
                <div className="mb-2 p-1 w-full md:w-1/2">
                    <label className="">Post Code</label>
                    <Field className="" type="text" name="postal_code" required/>
                </div>
                <div className="mb-2 p-1 w-full">
                    <label className="">Country</label>
                    <Field className="" type="text" name="country" required />
                </div>
                <div className="mb-2 p-1 w-full md:w-1/2">
                    <label className="">Latitude</label>
                    <Field className="" type="text" name="coordinates.latitude" required/>
                </div>
                <div className="mb-2 p-1 w-full md:w-1/2">
                    <label className="">Longitude</label>
                    <Field className="" type="text" name="coordinates.longitude" required/>
                </div>
                <div className="mb-2 p-1 w-full">
                    <label className="">Parking Type</label>
                    <Field className="" type="text" name="parking_type" />
                </div>
                <div className="mb-2 p-1 w-full">
                    <label className="">Time Zone</label>
                    <Field as="select" name="time_zone">
                        {timeZones.map(x => {<option value={x} label={x}/>})}
                    </Field>
			    </div>
			<hr/>
			<div>
                <button className="bg-green-600 text-white text-sm font-bold py-2 px-4 rounded" type="submit">Create Location</button>
			</div>
        </Form>
    </Formik>
    );
}

/*

*/