// this Layout component wraps every page with the app header on top
// check out App.js to see how it's used

import React from "react";
import { useAuth } from "../../hooks/useAuth";

export const subscriptionErrorMessage = (expired) => {
  return `Your subscription has ${
    expired ? "Expired" : "been Closed"
  }. Please contact EVUp to reactivate
            your subscription to gain full access to your account, either by
            phone at 1300 70 11 99 or by email at
            admin@evolutionaustralia.com.au.`;
};

const Layout = (props) => {
  const { tenancyState } = useAuth();
  return (
    <div>
      {tenancyState && !tenancyState.active && (
        <div
          className="subscription-banner bg-red-100 border border-red-400 text-red-700 px-4 py-3 relative text-center"
          role="alert"
        >
          <strong className="font-bold">
            {subscriptionErrorMessage(true)}
          </strong>
        </div>
      )}
      <div className="min-h-screen h-full flex max-h-screen w-full bg-gray-100">
        {props.children}
      </div>
      <div id="rightModalTarget" className="z-10" />
    </div>
  );
};

export default Layout;
