import { ButtonlessModal } from "../layout/rightModal";
import { EcommerceSettingsRightModal } from "./EcommerceSettings";
import { UpdateMetadataFormContainer } from "../enrollment/EVSEMetadata";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";

import {
  FaBolt,
  FaCogs,
  FaExclamationCircle,
  FaCheckCircle,
} from "react-icons/fa";

import { TYPES } from "../device_presets/DevicePreset";
import Moment from "react-moment";
import { useAuth } from "../../hooks/useAuth";

const RESET_EVSE_DEVICE = gql`
  mutation Mutation($evse_id: String!) {
    resetEVSEDevice(evse_id: $evse_id)
  }
`;

const EVSEList = ({ evses, deleteEVSE, location_id, onReloadMachines }) => {
  return (
    <div className="flex-grow mb-24">
      {evses.map((evse) => (
        <EVSEListItem
          deleteEVSE={deleteEVSE}
          key={evse.id}
          evse={evse}
          locationId={location_id}
          onReloadMachines={onReloadMachines}
        />
      ))}
    </div>
  );
};

export const EVSEListItem = ({
  evse,
  locationId,
  deleteEVSE,
  onReloadMachines,
}) => {
  var soft_blocked = evse.soft_blocked;

  var className =
    "bg-primary text-white rounded-lg p-2 text-sm mb-2 border-2 border-black";

  if (soft_blocked) {
    className += " opacity-25";
  }

  return (
    <SoftBlockLayer key={evse.id} id={evse.id} soft_blocked={soft_blocked}>
      <div className={className}>
        <div className="flex flex-row w-full">
          <div className="w-full flex ">
            <EVSEItemGenerateDisplayName evse={evse} />
          </div>
        </div>
        <div className="flex flex-row ml-1 mb-4 bg-primary">
          {evse.connectors.length !== 0 ? (
            evse.connectors.map((x, i) => (
              <EVSEConnector key={i} connector={x} />
            ))
          ) : (
            <p className="text-gray-400 ml-2">
              Connectors will appear upon first use or status update.
            </p>
          )}
          <EVSEActions
            evse={evse}
            enabled={!soft_blocked}
            location_id={locationId}
            delete_evse={deleteEVSE}
            onReloadMachines={onReloadMachines}
          />
        </div>
      </div>
    </SoftBlockLayer>
  );
};

const SoftBlockLayer = ({ soft_blocked, children, id }) => {
  if (soft_blocked) {
    return (
      <>
        <h1 className="mx-auto my-auto text-black" key={id}>
          This device is unavailable until more capacity is allocated.
        </h1>
        {children}
      </>
    );
  } else {
    return children;
  }
};

const EVSEActions = ({
  evse,
  location_id,
  delete_evse,
  enabled = true,
  onReloadMachines,
}) => {
  const [showEcommerceSettings, toggleShowEcommerce] = useState(false);
  const [showEditmeta, toggleShowEditMeta] = useState(false);
  const [resettingDevice, setResettingDevice] = useState(false);
  const [resetDevice] = useMutation(RESET_EVSE_DEVICE);

  const user = useAuth();

  const last_heartbeat = evse.last_heartbeat;
  const last_heartbeat_content = last_heartbeat ? (
    <Moment format="DD/MM/YY">{last_heartbeat}</Moment>
  ) : (
    "Unknown"
  );

  if (!enabled) {
    return null;
  }

  return (
    <div className="ml-auto flex flex-col mt-3">
      <button
        className="text-sm text-center border-color-secondary  border bg-primary rounded px-4 py-2 mx-2 mb-2"
        onClick={() => toggleShowEcommerce(!showEcommerceSettings)}
      >
        Ecommerce Settings
      </button>
      <EcommerceSettingsRightModal
        evse_id={evse.id}
        locationId={location_id}
        show={showEcommerceSettings}
        onClose={() => toggleShowEcommerce(false)}
      />
      <button
        className="text-sm text-center border-color-secondary  border bg-primary rounded px-4 py-2 mx-2 mb-2"
        onClick={() => toggleShowEditMeta(!showEditmeta)}
      >
        Edit EVSE Metadata
      </button>
      <EditEvseRightModal
        canDelete={delete_evse}
        evse={evse}
        show={showEditmeta}
        onClose={() => {
          toggleShowEditMeta(false);
          onReloadMachines();
        }}
      />
      {user?.permissions?.role === "sysadmin" && (
        <>
          <Link
            to={`/machines/${evse.id}`}
            className="text-sm text-center border-color-secondary  border bg-primary rounded px-4 py-2 mx-2 mb-2"
          >
            OCPP Settings <FaCogs className="inline text-green-600 ml-2" />
          </Link>
          <button
            disabled={resettingDevice}
            className="text-sm text-center border-color-secondary  border bg-primary rounded px-4 py-2 mx-2 mb-2"
            onClick={async (e) => {
              e.preventDefault();
              if (
                window.confirm(
                  `This reset operation will perform force-stop charging on the device and terminate all active sessions for all of its connectors.\n- If it's a free-charging session, it will be marked as fulfilled and terminated immediately.\n- If it's a whitelisted charging session, it will be terminated immediately.\n- If it's a prepaid charging session, it will be marked as not fulfilled and terminated immediately, even if the user has not consumed what they have prepaid for.\n- If it's a postpaid charging session, payment will be processed immediately based on the consumption, and if the payment is successful, then the session will be marked as fulfilled and terminated immediately. If the payment is unsuccessful, then the session will be marked as not fulfilled and terminated immediately.\n[Note]: If it's a time-based postpaid charging session, we cannot calculate the last phase against the current time, as session termination time is not the actual session ending time, and we might overcharge the customers. Hence, we calculate the last phase against the current time with a maximum cap of 6 hours.\n\nAre you sure you want to reset the EVSE device ${evse.name}?`
                ) === true
              ) {
                setResettingDevice(true);
                try {
                  await resetDevice({
                    variables: {
                      evse_id: evse.id,
                    },
                  });
                  onReloadMachines();
                  alert(`Operation Success!`);
                } catch (err) {
                  alert(`Operation Failed! \n ${err?.message}`);
                }
                setResettingDevice(false);
              }
            }}
          >
            {resettingDevice ? "Resetting EVSE..." : "Reset EVSE"}
          </button>
        </>
      )}
      <Link
        className="text-sm text-center border-color-secondary  border bg-primary rounded px-4 py-2 mx-2 mb-2"
        to={`/sessions/evse/${evse.id}`}
      >
        View Sessions <FaBolt className="inline ml-2 text-green-600" />
      </Link>
      <h1 className="mt-auto mr-2 ">
        Last Heartbeat: {last_heartbeat_content}
      </h1>
    </div>
  );
};

const EVSEConnector = ({ connector }) => {
  var standard = connector?.standard || "UNKNOWN";

  var maxRate = "Unknown";

  if (connector.voltage && connector.max_amperage) {
    maxRate = connector.voltage * connector.max_amperage;

    if (connector.power_type === "AC_3_PHASE") {
      maxRate *= 3;
    }

    maxRate = (maxRate / 1000).toFixed(2);
  }

  if (!(standard in TYPES)) {
    standard = "UNKNOWN";
  }

  const title = TYPES[standard].title;
  const img = TYPES[standard].logo;

  return (
    <div className="p-4 ml-2 text-md border border-color-secondary rounded-lg bg-primary mr-2">
      <h1 className="text-center text-md">
        {title} - {maxRate} kW
      </h1>
      <img src={img} width="150px" className="mx-auto" />
      <h1 className="text-center">{connector.status ?? "Unknown"}</h1>
    </div>
  );
};

export const EditEvseRightModal = ({ evse, show, onClose, canDelete }) => {
  return (
    <ButtonlessModal title="Edit EVSE Metadata" show={show} onClose={onClose}>
      <UpdateMetadataFormContainer
        chargePointId={evse.id}
        canDelete={canDelete}
        onClose={onClose}
      />
    </ButtonlessModal>
  );
};

function EVSEItemGenerateDisplayName({ evse }) {
  const model = evse.charge_point_model || "Unknown";
  const vendor = evse.vendor || "Unknown";

  return (
    <div className=" text-white w-full">
      <div className="ml-2 flex flex-row mb-2">
        <div className="mt-1 ml-2  flex flex-col">
          <span className="mr-1 text-xl">{evse.name}</span>

          <span className="text-sm text-gray-300 mr-1">ID: {evse.id}</span>
        </div>
        <div className="ml-auto mr-2 mt-4">
          <ConnectionStatus evse={evse} />
        </div>
      </div>
    </div>
  );
}

const ConnectionStatus = ({ evse }) => {
  const icon = evse.connected ? (
    <FaCheckCircle color="green" size={28} />
  ) : (
    <FaExclamationCircle color="red" size={28} />
  );

  const message = evse.connected ? "Connected" : "Disconnected";

  return (
    <div className="flex flex-row">
      <h1 className="mr-2 text-xl mt-0.5">{message}</h1>
      {icon}
    </div>
  );
};

export default EVSEList;
