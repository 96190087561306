import { gql, useQuery } from "@apollo/client";
import { useState } from "react";

import Spinner from "../../misc/Spinner";

const PARTY_LOCATIONS = gql`
  query LocationsApi {
    locationsApi {
      name
      address
      id
    }
  }
`;

export const LinkLocation = ({ onNext }) => {
  const [selectedLocation, updateSelectedLocation] = useState("NONE");
  const { loading, error, data } = useQuery(PARTY_LOCATIONS);

  if (loading) return <Spinner />;
  if (error) return <p>Error: couldn't query party locations</p>;

  const locations = data?.locationsApi;

  return (
    <div className="flex flex-row mt-4">
      <p className="p-2 mr-2">Finally, select the location: </p>
      <select
        className="border border-black rounded ml-auto p-2"
        onChange={(e) => updateSelectedLocation(e.target.value)}
        value={selectedLocation}
      >
        <option value="none" label="Select a location" />
        {locations?.map((x) => (
          <option value={x.id} label={x?.name || x?.address} key={x.id} />
        ))}
      </select>
      {selectedLocation !== "NONE" && (
        <button
          className="absolute right-3 bottom-3 text-white bg-green-600 p-2 rounded"
          onClick={() => onNext(selectedLocation)}
        >
          Finish
        </button>
      )}
    </div>
  );
};
