import Spinner from "../misc/Spinner";
import { StatementDownSpinner } from "./Statement";
import { ExportButton } from "../sessions/SessionsPage";

import { FaCog } from "react-icons/fa";
import { Modal } from "../layout/Modal";

import {
  Line,
  LineChart,
  XAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

//Date range css.
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const SESSIONS_SUMMARY = gql`
  query Sessions_summary(
    $timeframe: SummaryTimeframe
    $locationId: String
    $evseId: String
  ) {
    sessions_summary(
      timeframe: $timeframe
      location_id: $locationId
      evse_id: $evseId
    ) {
      total_revenue
      total_sessions
      locations
      users
      kwh_delivered
      free_sessions
      prepaid_sessions
      postpaid_sessions
      hours_spent
      daily_summary {
        index
        price
        kwh
        hours
        label
      }
    }
    account {
      id
      abn
      address
      name
      number
      party_id
      postcode
      state
      account_number
      bsb
    }
  }
`;

const EXPORTED_FIELDS = [
  "total_revenue",
  "total_sessions",
  "locations",
  "users",
  "kwh_delivered",
  "free_sessions",
  "prepaid_sessions",
  "postpaid_sessions",
  "hours_spent",
  "daily_summary",
];

export const Payments = ({ match }) => {
  const [timeframe, updateTimeframe] = useState("MONTH");

  const { loading, error, data, refetch } = useQuery(SESSIONS_SUMMARY, {
    context: {
      headers: {
        "x-timeout": 5 * 60 * 1000, // 5 minutes
      },
    },
    variables: {
      timeframe,
      evseId: match?.params?.evse_id || null,
      locationId: match?.params?.location_id || null,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    refetch({
      timeframe,
      evseId: match?.params?.evse_id || null,
      locationId: match?.params?.location_id || null,
    });
  }, [timeframe, match.params, refetch]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <h1>Error!</h1>;
  }

  return (
    <div className="overflow-y-scroll">
      <div className="flex flex-row">
        <h1 className="text-3xl font-bold my-1 ml-1">Analytics Dashboard</h1>

        <div className="flex-row flex ml-auto h-12">
          <ExportButton
            data={data.sessions_summary}
            fields={EXPORTED_FIELDS}
            title="summary"
          />
          {/* <MerchantControls account={data.account} /> */}
        </div>
      </div>
      <hr className="border border-gray-400 my-2" />
      <SummaryGraphics
        payments_summary={data.sessions_summary}
        timeframe={timeframe}
        updateTimeframe={updateTimeframe}
      />
    </div>
  );
};

const MerchantControls = ({ account }) => {
  const [showTransactionStatementModal, updateShowTransactionStatementModal] =
    useState(false);

  return (
    <div>
      <Modal
        title="Select Time Range"
        isOpen={showTransactionStatementModal}
        onClose={() => updateShowTransactionStatementModal(false)}
      >
        <StatementDownSpinner />
      </Modal>
      <div>
        <div className="mx-1">
          <div className="ml-auto align-top h-full">
            <button
              className="ml-auto btn bg-primary border brandPrimaryColor border-black rounded"
              onClick={() => updateShowTransactionStatementModal(true)}
            >
              <FaCog className="inline  mr-2 mb-1" />
              <span className="inline ">Download Transaction Statement</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SummaryItem = ({
  mainCourse,
  description,
  className,
  selected,
  onClick,
}) => {
  return (
    <div
      className={`brandPrimaryColor px-1  ${className}`}
      onClick={() => onClick && onClick()}
    >
      <h1 className="text-4xl header font-bold">{mainCourse}</h1>
      <h4>{description}</h4>
    </div>
  );
};

const SummaryGraphics = ({ payments_summary, timeframe, updateTimeframe }) => {
  const [selectedMetric, updateMetric] = useState(
    payments_summary.total_revenue === 0 ? "kwh" : "price"
  );
  var daily_summary = payments_summary.daily_summary.map((x) => {
    return { index: x.index, total: x[selectedMetric], label: x.label };
  });

  return (
    <div className="mt-4 border rounded-xl bg-primary h-full">
      <div className="flex flex-row p-4 mt-1">
        <SummaryItem
          mainCourse={`$${payments_summary.total_revenue}`}
          description={"Total Revenue"}
          className={`hover:bg-gray-600 pr-6 pl-3 py-1 ${
            selectedMetric === "price" && "bg-gray-700"
          }`}
          selected={selectedMetric === "price"}
          onClick={() => updateMetric("price")}
        />
        <SummaryItem
          mainCourse={`${payments_summary.kwh_delivered}`}
          description={"kWh Delivered"}
          className={`border-l border-white hover:bg-gray-600 pr-6 pl-3 py-1 ${
            selectedMetric === "kwh" && "bg-gray-700"
          }`}
          selected={selectedMetric === "kwh"}
          onClick={() => updateMetric("kwh")}
        />
        <SummaryItem
          mainCourse={`${payments_summary.hours_spent}`}
          description={"Hours Charging"}
          className={`border-l border-white hover:bg-gray-600 pr-6 pl-3 py-1 ${
            selectedMetric === "hours" && "bg-gray-700"
          }`}
          selected={selectedMetric === "hours"}
          onClick={() => updateMetric("hours")}
        />
        <SummaryItem
          mainCourse={`${payments_summary.locations}`}
          description={"Locations"}
          className="border-l border-white pl-7 pr-6 py-1"
        />
        <SummaryItem
          mainCourse={`${payments_summary.users}`}
          description={"Non-anonymous Users"}
          className="border-l border-white pl-7 pr-6 py-1"
        />
        <div className="ml-auto bg-dark-primary p-3  gap-x-2 h-3/4 align-middle flex flex-row my-auto rounded-lg border border-black">
          <label for="range" className="inline my-auto brandPrimaryColor">
            Time Range:
          </label>
          <select
            name="range"
            id="range"
            defaultValue={timeframe}
            className="bg-secondary text-black my-auto p-2 rounded"
            onChange={(e) => updateTimeframe(e.target.value)}
          >
            <option value="MONTH">Last Month</option>
            <option value="YEAR">Last Year</option>
            <option value="ALL_TIME">All Time</option>
          </select>
        </div>
      </div>
      <div className="pl-4 pr-3 pb-4 flex flex-row">
        <ResponsiveContainer width="90%" height={400}>
          <LineChart
            data={daily_summary}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          >
            <XAxis dataKey="label" reversed />
            <Tooltip />
            <CartesianGrid stroke="#FFFFF" />
            <Line
              type="monotone"
              dataKey="total"
              stroke="#2fdce1"
              yAxisId={1}
            />
          </LineChart>
        </ResponsiveContainer>
        <div className="flex flex-col gap-y-6 mt-4 ml-3">
          <SummaryItem
            mainCourse={`${payments_summary.total_sessions}`}
            description={"Sessions"}
            className="border-b border-white pb-2"
          />
          <SummaryItem
            mainCourse={`${payments_summary.free_sessions}`}
            description={"Free"}
          />
          <SummaryItem
            mainCourse={`${payments_summary.prepaid_sessions}`}
            description={"Prepaid"}
          />
          <SummaryItem
            mainCourse={`${payments_summary.postpaid_sessions}`}
            description={"Postpaid"}
          />
        </div>
      </div>
    </div>
  );
};

export const LegacyPaymentsCheckbox = ({ checked, className }) => {
  var reassignmentURL = !checked ? "/legacyAnalytics" : "/Analytics";

  return (
    <div className={`${className} mx-1`}>
      <button
        className="flex-row flex bg-primary py-2 px-4 rounded brandPrimaryColor"
        onClick={() => window.location.assign(reassignmentURL)}
      >
        <h1>See Legacy Analytics</h1>
        <input
          type="checkbox"
          className="border-black border my-auto ml-2"
          defaultChecked={checked}
        />
      </button>
    </div>
  );
};
