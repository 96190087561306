import {
  AiFillCar,
  AiOutlineThunderbolt,
  AiFillThunderbolt,
  AiTwotoneThunderbolt,
} from "react-icons/ai";

import Spinner from "../misc/Spinner";

import { gql, useQuery } from "@apollo/client";

const USER_PROFILE = gql`
  query Current_user {
    current_user {
      party_id
    }
  }
`;

// Features will be hard coded and not modifiable via website
const DEFAULT_FEATURES = [
  "3 free locations",
  "$$ per location",
  "$$ per device",
  "Up to 5 tariffs",
];
const GOLD_FEATURES = [
  "10 free locations",
  "10 free ports per location",
  "$$ per location",
  "$$ per device",
  "Up to 10 tariffs",
];
const DIAMOND_FEATURES = [
  "50 free locations",
  "20 free ports per location",
  "$$ per location",
  "$$ per device",
  "Unlimited tariffs",
];
const PLATINUM_FEATURES = [
  "Unlimited free locations",
  "Unlimited free ports",
  "Unlimited tariffs",
  "Custom features",
];

/*
class Features {
    Features();
    var locationLimit;

}*/

const SUBSCRIPTION_TYPES = [
  {
    logo: <AiFillCar className="text-white mr-2" size={25} />,
    name: "Default",
    features: DEFAULT_FEATURES,
    pricing: "Free",
  },
  {
    logo: (
      <AiOutlineThunderbolt className="text-yellow-400 mt-0.5 mr-2" size={25} />
    ),
    name: "Gold",
    features: GOLD_FEATURES,
    pricing: "$$ per month",
  },
  {
    logo: (
      <AiFillThunderbolt className="brandPrimaryColor mt-0.5 mr-2" size={25} />
    ),
    name: "Diamond",
    features: DIAMOND_FEATURES,
    pricing: "$$ per month",
  },
  {
    logo: (
      <AiTwotoneThunderbolt className="text-red-600 mt-0.5 mr-2" size={25} />
    ),
    name: "Platinum",
    features: PLATINUM_FEATURES,
    pricing: "Contact EVUp",
  },
];

export const TenancyPlanPage = () => {
  return (
    <div>
      <div className="ml-6 text-xxl">Tenancy Plans</div>
      <div className="flex flex-row gap-x-4 my-4">
        {SUBSCRIPTION_TYPES.map((x) => (
          <Feature sub_type={x} />
        ))}
      </div>
    </div>
  );
};

const Feature = ({ sub_type }) => {
  return (
    <div className="flex flex-col SubscriptionSection">
      <FeatureTitle sub_type={sub_type} />
      <SubscriptionFeatures features={sub_type.features} />
      <SubscriptionPricing sub_type={sub_type} />
    </div>
  );
};

const FeatureTitle = ({ sub_type }) => {
  return (
    <div className="flex flex-row justify-center mt-4">
      {sub_type.logo}
      <div className="text-center text-white text-xl">{sub_type.name}</div>
    </div>
  );
};

// Pass in an array of feature strings to display nicely
const SubscriptionFeatures = ({ features }) => {
  return (
    <div>
      <div className="border mt-4 mx-4 p-2 border-color-secondary text-white">
        <span className="ml-1 text-lg brandPrimaryColor">Features:</span>
        <ul className="list-disc text-lg ml-6 gap-1 mt-1 source-sans-pro">
          {features.map((x) => (
            <li key={features.indexOf(x)}>{x}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const SubscriptionPricing = ({ sub_type }) => {
  // call get user to get party id and determine tenancy plan
  const { loading, error, data } = useQuery(USER_PROFILE);
  var buttonMessage = sub_type.pricing;
  if (loading) {
    buttonMessage = <Spinner />;
  } else if (error) {
    buttonMessage = "Error";
  } else {
    // use data.current_user.party_id to query tenancy plan, check if plan is name of sub_type
  }

  return (
    <button className="mt-auto mb-8 w-4/5 py-2 px-4 rounded mx-auto bg-secondary border-2 border-black">
      {buttonMessage}
    </button>
  );
};
