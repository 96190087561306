import { useState } from "react";

/*
 *   Create a user information form,
 *   which will be used to create a new admin user,
 *   they should input, name, email, password,
 *   and select a role.
 */
export const UserInfo = ({ onNextPage, onBack }) => {
  const [userInfo, updateUserInfo] = useState({});

  return (
    <div className="">
      <p className="text-center text-xl mb-4">Create/Login an Admin account</p>
      <form className="mt-2">
        <div className="flex flex-row mt-1">
          <label className="w-1/4">Name</label>
          <input
            className="border rounded p-1 bg-primary brandPrimaryColor"
            type="text"
            id="companyname"
            placeholder="Enter name"
            onChange={(e) =>
              updateUserInfo({
                ...userInfo,
                name: e.target.value,
              })
            }
          />
        </div>
        <div className="flex flex-row mt-1">
          <label className="w-1/4">Email</label>
          <input
            className="border rounded p-1 bg-primary brandPrimaryColor"
            type="text"
            id="email"
            placeholder="Enter email"
            onChange={(e) =>
              updateUserInfo({
                ...userInfo,
                email: e.target.value,
              })
            }
          />
        </div>
        <div className="flex flex-row mt-1">
          <label className="w-1/4">Password</label>
          <input
            className="border rounded p-1 bg-primary brandPrimaryColor"
            type="text"
            id="password"
            placeholder="Enter password"
            onChange={(e) =>
              updateUserInfo({
                ...userInfo,
                password: e.target.value,
              })
            }
          />
        </div>
        <button
          type="submit"
          className="bg-blue-400 text-white py-2 px-4 rounded mt-2"
          onClick={(e) => {
            e.preventDefault();

            var result = validateUserInfo(userInfo);

            if (result !== true) {
              alert(
                `The following information must be filled out to proceed: ${result}`
              );
              return;
            }

            onNextPage(userInfo);
          }}
        >
          Next
        </button>
        <button
          className="ml-2 bg-red-600 text-white py-2 px-4 rounded mt-2"
          onClick={(e) => {
            e.preventDefault();
            onBack();
          }}
        >
          Back
        </button>
      </form>
    </div>
  );
};

//create a function which validates user info
const validateUserInfo = (userInfo) => {
  if (!userInfo.name || userInfo.name.length === 0) {
    return "Name";
  }

  if (!userInfo.email) {
    return "Email";
  }

  if (/^\S+@\S+\.\S+$/.test(userInfo.email) !== true) {
    return "Email is not valid";
  }

  if (!userInfo.password) {
    return "Password";
  }

  if (userInfo.password.length < 6) {
    return "Password must be at least 6 characters";
  }
  return true;
};
