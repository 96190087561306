import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import Spinner from "../misc/Spinner";

import { Link } from "react-router-dom";
import { FaQrcode } from "react-icons/fa";
import { gql, useMutation, useQuery } from "@apollo/client";

import { ButtonlessModal, RightModal } from "../layout/rightModal";
import { TariffSelector } from "./TariffSelector";
import { useAuth } from "../../hooks/useAuth";
import { Tooltip } from "../../components/Tooltip";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaChevronDown } from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";

/*
This exists to monetize chargers,
the tariff must be selected and set on chargepointID
*/
const MONETIZATION = ["FREE", "PAID"];

const ECOMMERCE_SETTINGS = gql`
  query Ecommerce_settings($evseId: String!) {
    ecommerce_settings(evse_id: $evseId) {
      charging_mode
      start_on_preparing
      tariff_id
      tariff_name
      authLevel
      whitelist
    }
  }
`;

const UPDATE_ECOMMERCE_SETTINGS = gql`
  mutation UpdateEcommerceSettings(
    $evseId: String!
    $chargingMode: ChargingMode!
    $tariffId: String
    $startOnPreparing: Boolean
    $authLevel: Int
    $whitelist: [String]
  ) {
    updateEcommerceSettings(
      evse_id: $evseId
      charging_mode: $chargingMode
      tariff_id: $tariffId
      start_on_preparing: $startOnPreparing
      authLevel: $authLevel
      whitelist: $whitelist
    )
  }
`;

export const EcommerceSettingsRightModal = ({
  evse_id,
  locationId,
  show,
  onClose,
}) => {
  const [showQRCode, toggleQR] = useState(false);
  const { loading, error, data, refetch } = useQuery(ECOMMERCE_SETTINGS, {
    variables: {
      evseId: evse_id,
    },
    fetchPolicy: "no-cache",
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <h1>{error?.message}</h1>;
  }

  var qrCode = null;

  if (showQRCode) {
    qrCode = (
      <div className="font-sans">
        <p className="my-2">
          Right Click → Save Image As... → Print → Stick on a charger
        </p>
        <QRCode
          value={`${window.location.host}/ecommerce/${locationId}`}
          size="400"
        />
      </div>
    );
  }

  return (
    <ButtonlessModal title="Ecommerce Settings" show={show} onClose={onClose}>
      <div>
        <Link
          className="font-bold text-xs py-1 px-2 rounded border border-black p-1"
          to={`/ecommerce/${locationId}`}
        >
          Go to Ecommerce Page
        </Link>
        <button
          className="bg-blue-400 text-white text-sm font-bold py-1 px-2 rounded ml-2"
          onClick={() => toggleQR(!showQRCode)}
        >
          <FaQrcode className="inline mr-2" />
          Show QR Code
        </button>
        {qrCode}
        <EcommerceControls
          ecommerce_settings={data?.ecommerce_settings}
          evse_id={evse_id}
          refetch={refetch}
        />
      </div>
    </ButtonlessModal>
  );
};

export const EcommerceControls = ({ ecommerce_settings, evse_id, refetch }) => {
  const [updateEcommerceSettings, { loading, error, data }] = useMutation(
    UPDATE_ECOMMERCE_SETTINGS
  );

  const [chargingMode, updateChargingMode] = useState(
    ecommerce_settings?.charging_mode || "FREE"
  );
  const [startOnPreparing, setStartOnPreparing] = useState(
    ecommerce_settings?.start_on_preparing || false
  );
  const [tariff, updateTariff] = useState({
    id: ecommerce_settings?.tariff_id,
    desc: ecommerce_settings?.tariff_name,
  });
  const [authLevel, updateAuthLevel] = useState(
    ecommerce_settings?.authLevel || 0
  );
  const [whitelist, updateWhitelist] = useState(
    ecommerce_settings?.whitelist || []
  );

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <h1>{error?.message}</h1>;
  }

  return (
    <div className="bg-primary p-3 rounded mt-2">
      <h1 className="text-lg brandPrimaryColor font-bold">
        Ecommerce Controls
      </h1>
      <div className="flex flex-row mt-2 h-8">
        <h1 className="w-1/2 text-gray-300 my-auto">Charging Mode</h1>
        <select
          className="border border-black rounded bg-secondary p-1"
          value={chargingMode}
          label="Select a charger"
          onChange={(e) => {
            updateChargingMode(e.target.value);
            setStartOnPreparing(false);
          }}
        >
          {MONETIZATION.map((x) => (
            <option key={x} value={x} label={x} />
          ))}
        </select>
      </div>
      {chargingMode === "PAID" && (
        <div>
          <TariffSelector
            updateTariff={(tariff) => updateTariff(tariff)}
            tariff={tariff}
          />
        </div>
      )}
      <hr className="mt-3 border border-gray-500" />
      <h1 className="text-lg brandPrimaryColor font-bold mt-2">
        Authorisation Controls
      </h1>
      {chargingMode === "FREE" && authLevel === 0 && (
        <div className="flex flex-row mt-2 h-8">
          <h1 className="w-1/2 text-gray-300 my-auto">Start on plugin </h1>
          <input
            className="ml-2"
            type="checkbox"
            defaultChecked={startOnPreparing}
            onChange={(e) => setStartOnPreparing(!startOnPreparing)}
          />
        </div>
      )}
      {!startOnPreparing && (
        <div className="flex flex-row h-8 mt-2">
          <h1 className="w-1/2 text-gray-300 my-auto">Require login</h1>
          <input
            className="ml-2"
            type="checkbox"
            defaultChecked={authLevel}
            onChange={(e) => updateAuthLevel((authLevel + 1) % 2)}
          />
        </div>
      )}

      {chargingMode === "PAID" && authLevel >= 1 && (
        <WhitelistSection
          whitelist={whitelist}
          updateWhitelist={updateWhitelist}
        />
      )}
      <hr className="mt-3 border border-gray-500" />
      <button
        className="mt-3 bg-green-600 text-white text-sm font-bold py-2 px-4 rounded"
        onClick={async () => {
          console.log("Submitting whitelist", whitelist);
          await updateEcommerceSettings({
            variables: {
              evseId: evse_id,
              chargingMode: chargingMode,
              startOnPreparing: startOnPreparing,
              tariffId: tariff.id,
              authLevel,
              whitelist,
            },
          });
          if (!error) {
            alert("Operation Success!");
            refetch();
          } else {
            alert(`Operation Failure! ${error?.message}`);
          }
        }}
      >
        Save
      </button>
    </div>
  );
};

const WhitelistSection = ({ whitelist, updateWhitelist }) => {
  const [expanded, updateExpanded] = useState(false);
  const [entry, updateEntry] = useState("");

  const addEntry = () => {
    if (entry) {
      updateWhitelist([entry, ...whitelist]);
      updateExpanded(true);
      updateEntry("");
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row mt-2 h-8">
        <h1 className="w-1/2 text-gray-200 my-auto flex flex-row">
          Whitelist{" "}
          <Tooltip className="ml-1.5 mt-0.5" color="white">
            Users included in the whitelist charge for free. Enter emails or
            party IDs explicitly.
          </Tooltip>
        </h1>
        <input
          className="ml-2 bg-black brandPrimaryColor px-2 w-full rounded outline-none"
          type="text"
          value={entry}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") addEntry();
          }}
          onChange={(e) => updateEntry(e.target.value)}
        />
        <button
          className="bg-secondary p-1 h-full w-12 rounded"
          onClick={() => addEntry()}
        >
          <AiOutlinePlus color="black" className="mx-auto" />
        </button>
      </div>
      <div className="flex flex-col w-3/5 gap-1 ml-auto mt-1">
        {expanded
          ? whitelist.length !== 0 &&
            whitelist.map((x) => (
              <WhitelistTile
                key={x}
                entry={x}
                onClose={(tilename) => {
                  whitelist = whitelist.filter((x) => x !== tilename);
                  updateWhitelist(whitelist);
                }}
              />
            ))
          : whitelist.length > 0 && (
              <button
                className="w-3/5 flex flex-row bg-secondary rounded py-1 pl-1.5 ml-auto"
                onClick={() => updateExpanded(true)}
              >
                Expand List
                <FaChevronDown className="ml-auto mr-1 my-auto hover:border hover:border-white" />
              </button>
            )}
      </div>
    </div>
  );
};

const WhitelistTile = ({ entry, onClose }) => {
  return (
    <div className="flex flex-row bg-secondary p-1 rounded">
      <h1 className="truncate w-5/6">{entry}</h1>
      <button className="ml-auto mr-1 my-auto" onClick={() => onClose(entry)}>
        <AiFillCloseCircle />
      </button>
    </div>
  );
};
