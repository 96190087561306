import Firebase from "firebase/app";

export async function updateAccount(
  id,
  name,
  abn,
  number,
  address,
  postcode,
  state,
  tenancy_tier,
  dlb_enabled,
  closed,
  bsb,
  account_number,
  start_date,
  end_date
) {
  try {
    var accounts = Firebase.firestore().collection("accounts");

    console.log("updating tenancy tier to", tenancy_tier);
    console.log("updating dlb to", dlb_enabled);

    var result = await accounts.doc(id).update({
      id: id,
      name: name,
      number: number,
      address: address,
      postcode: postcode,
      state: state,
      abn: abn,
      tenancy_tier: tenancy_tier,
      dlb_enabled: dlb_enabled,
      closed: closed,
      bsb: bsb,
      account_number: account_number,
      start_date: start_date,
      end_date: end_date,
    });
    console.log("Update successful", result);
  } catch (err) {
    console.log("Error updating", err?.message);
    return false;
  }
  return true;
}

export async function checkPartyID(party_id) {
  try {
    var accounts = Firebase.firestore().collection("accounts");

    var query = await accounts.where("party_id", "==", party_id).get();

    if (query.empty) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
}
