import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Stepper } from "react-form-stepper";
import firebase from "firebase";
import { useMutation, gql } from "@apollo/client";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

/*
 *  For 2FA, the following must happen.
 *  The user should first reauthenticate with their username and password.
 *  The user should enter the phone number they wish to enroll in 2FA.
 *  The user should enter the verification code sent to their phone to complete the enrollment.
 */
const MFA_REAUTH = 1;
const PHONE_NUMBER_ENTRY = 2;
const TWO_FACTOR_CODE = 3;
const VERIFIED = 4;

const MESSAGES = [
  "Please confirm your login details.",
  "Please enter your mobile number.",
  "Please confirm the code sent to your mobile.",
];

const UPDATE_MFA = gql`
  mutation Mutation($action: MFAUpdateEvent!) {
    mfa_update(action: $action)
  }
`;

export const EnrollMFAPage = ({}) => {
  const [step, setStep] = useState(1);
  const [mfaToken, updateMfaToken] = useState(undefined);
  const [currentUser, updateCurrentUser] = useState(undefined);

  var content = null;

  switch (step) {
    case MFA_REAUTH: {
      content = (
        <MFAReauth
          nextStep={(user) => {
            updateCurrentUser(user);
            setStep(step + 1);
          }}
        />
      );
      break;
    }
    case PHONE_NUMBER_ENTRY: {
      content = (
        <EnterPhoneNumber
          nextStep={(mfaToken) => {
            updateMfaToken(mfaToken);
            setStep(step + 1);
          }}
        />
      );
      break;
    }
    case TWO_FACTOR_CODE: {
      content = (
        <Enter2FACode
          mfaToken={mfaToken}
          user={currentUser}
          nextStep={() => setStep(step + 1)}
        />
      );
      break;
    }
    case VERIFIED: {
      content = <Verified />;
      break;
    }
  }

  return (
    <div>
      <Stepper
        steps={[
          { label: "Confirm your login information" },
          { label: "Enter your mobile number" },
          { label: "Confirm the code sent to your number" },
        ]}
        activeStep={step}
        styleConfig={{
          activeBgColor: "#63b3ed",
          completedBgColor: "#059669",
        }}
      />
      <p className="text-center p-4">{MESSAGES[step - 1]}</p>
      {content}
    </div>
  );
};

const MFAReauth = ({ nextStep }) => {
  const [loginDetails, updateLoginDetails] = useState({
    email: "",
    password: "",
  });

  return (
    <div className="flex flex-row w-full justify-center text-white">
      <div className="h-full border rounded content-center p-2 bg-primary">
        <div className="flex flex-row">
          <label className="w-28 p-2">Email</label>
          <input
            onChange={(e) =>
              updateLoginDetails({ ...loginDetails, email: e.target.value })
            }
            className="border rounded p-2 text-black"
          />
        </div>
        <div className="flex flex-row mt-2">
          <label className="w-28 p-2">Password</label>
          <input
            type="password"
            className="border rounded p-2 text-black"
            onChange={(e) =>
              updateLoginDetails({ ...loginDetails, password: e.target.value })
            }
          />
        </div>
        <div className="flex flex-row justify-center mt-4 mb-2">
          <button
            className="rounded p-2 bg-green-600"
            onClick={async () => {
              var { email, password } = loginDetails;

              await firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then((x) => nextStep(x.user))
                .catch((err) => {
                  console.debug("signin error: ", err);
                });
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const EnterPhoneNumber = ({ nextStep }) => {
  const [phoneNumber, updatePhoneNumber] = useState(undefined);
  const [recaptchaVerifier, updateRecaptchaVerifier] = useState(undefined);

  useEffect(() => {
    const recaptcha = new firebase.auth.RecaptchaVerifier("recaptchaVerifier", {
      size: "invisible",
    });
    recaptcha.render();
    updateRecaptchaVerifier(recaptcha);
  }, []);

  return (
    <div className="flex flex-row w-full justify-center text-white">
      <div className="h-full border rounded content-center p-2 bg-primary">
        <div id="recaptchaVerifier" />
        <div className="p-2">
          <label className="p-2">Phone Number</label>
          <PhoneInput
            className="text-black border rounded p-2 mt-2"
            value={phoneNumber}
            onChange={updatePhoneNumber}
            placeholder="Enter phone number"
          />
        </div>
        <div className="flex flex-row justify-center mt-4 mb-2">
          <button
            className="rounded p-2 bg-green-600"
            onClick={async () => {
              var user = firebase.auth().currentUser;

              try {
                var mfaSession = await user.multiFactor.getSession();

                const phoneInfoOptions = {
                  phoneNumber: phoneNumber,
                  session: mfaSession,
                };

                const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

                // Send SMS verification code.
                const verificationId =
                  await phoneAuthProvider.verifyPhoneNumber(
                    phoneInfoOptions,
                    recaptchaVerifier
                  );
                nextStep(verificationId);
              } catch (err) {
                console.debug(`Error enrolling MFA: `, err);
              }
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const Enter2FACode = ({ nextStep, mfaToken, user }) => {
  const [updateMFAState, { loading, error, data }] = useMutation(UPDATE_MFA);
  const [authCode, updateAuthCode] = useState();

  return (
    <div className="flex flex-row w-full justify-center text-white">
      <div className="h-full border rounded content-center p-2 bg-primary">
        <div className="flex flex-row">
          <label className="p-2">2FA Code</label>
          <input
            type="number"
            className="border rounded p-2 text-black"
            onChange={(e) => updateAuthCode(e.target.value)}
          />
        </div>
        <div className="flex flex-row justify-center mt-4 mb-2">
          <button
            className="rounded p-2 bg-green-600"
            onClick={async () => {
              var credential = firebase.auth.PhoneAuthProvider.credential(
                mfaToken,
                authCode
              );



              try {
                var mfaAssertion =
                  firebase.auth.PhoneMultiFactorGenerator.assertion(credential);

                var mfaResult = await user.multiFactor.enroll(
                  mfaAssertion,
                  "Second factor."
                );
                await updateMFAState({
                  variables: {
                    action: "ENROLLED",
                  },
                });
                console.debug(`MFA assertion enrolled.`, mfaResult);
                nextStep();
              } catch (err) {
                console.error(
                  `Error signing user in with generated credential: `,
                  err
                );
              }
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const Verified = ({}) => {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      history.push("/account");
    }, 5 * 1000);
  }, []);

  return (
    <div className="flex flex-row w-full justify-center text-white">
      <div className="h-full border rounded content-center p-2 bg-primary">
        <h1>2FA enrollment success!</h1>
        <p>
          Your account is now 2FA enrolled, future logins will require
          confirmation from your phone.
        </p>
        <p>Going back to profile in 5s.</p>
      </div>
    </div>
  );
};
