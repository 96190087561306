import { useState, useEffect } from "react";
import { FaDollarSign } from "react-icons/fa";
import moment from "moment";

const DisbursementsManager = () => {
  const [latestDisbursement, setLatestDisbursement] = useState("");
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_PREPAYMENTS_URL}/disbursements/latest`)
      .then((res) => res.ok && res.text())
      .then((res) => res && setLatestDisbursement(res))
      .catch((err) => err);
  }, []);

  // These functions call microservice endpoints to perform large operations
  // This is an alternative to these services being automated
  async function postRunDisbursements() {
    const requestOptions = {
      method: "GET",
    };
    var response = await fetch(
      `${process.env.REACT_APP_PREPAYMENTS_URL}/disbursements/run`,
      requestOptions
    );
    return await response.text();
  }

  async function downloadDisbursements() {
    if (!downloading) {
      setDownloading(true);
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/zip",
        },
      };
      let filename = "statements.zip";

      await fetch(
        `${process.env.REACT_APP_PREPAYMENTS_URL}/disbursements/download`,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            return response.text().then((errorMessage) => {
              throw new Error(errorMessage || response.statusText);
            });
          }
          const header = response.headers.get("Content-Disposition");
          const parts = header.split(";");
          filename = parts[1].split("=")[1].replace(/['"]+/g, "");
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setDownloading(false);
        })
        .catch((error) => {
          alert(`Something went wrong: ${error.message}`);
          setDownloading(false);
        });
    }
  }

  return (
    <div>
      <div className="ml-2">
        <p className="text-gray-500">
          Last disbursement:{" "}
          {latestDisbursement
            ? moment(latestDisbursement, "MM-YYYY").format("MMMM YYYY")
            : "Loading..."}
        </p>
      </div>
      <div className="ml-2 mt-5">
        <button
          className={`flex flex-row border border-black bg-primary py-2 px-4 rounded brandPrimaryColor align-center${
            !latestDisbursement ? " opacity-50 pointer-events-none" : ""
          }`}
          onClick={async () => {
            if (!loading) {
              setLoading(true);
              try {
                var res = await postRunDisbursements();
                alert(res);
              } catch (err) {
                alert(err?.message);
              }
              setLoading(false);
            }
          }}
        >
          {loading ? (
            <p className="mr-1 text-white inline my-auto">...</p>
          ) : (
            <FaDollarSign className="text-white inline mr-1 my-auto" />
          )}
          <p className="mt-0.5">Run Disbursements</p>
        </button>
      </div>
      <div className="ml-2 mt-5">
        <button
          className={`flex flex-row border border-black bg-primary py-2 px-4 rounded brandPrimaryColor align-center${
            !latestDisbursement ? " opacity-50 pointer-events-none" : ""
          }`}
          onClick={downloadDisbursements}
        >
          {downloading ? (
            <p className="mr-1 text-white inline my-auto">...</p>
          ) : (
            <FaDollarSign className="text-white inline mr-1 my-auto" />
          )}
          <p className="mt-0.5">Download Disbursements</p>
        </button>
      </div>
    </div>
  );
};

export default DisbursementsManager;
