import React from "react";
import Spinner from "../misc/Spinner";

import { gql, useQuery, useMutation } from "@apollo/client";
import { connect, useDispatch } from "react-redux";
import { Tooltip } from "../../components/Tooltip";
import { forgotPassword, logout } from "../../middleware/loginMiddleware";
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  TENANT_LOGO,
} from "../../reducers/tenancyStyling";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

const ACCOUNT_NAME_QUERY = gql`
  query Account {
    account {
      name
    }
  }
`;

const CLEAR_TENANCY = gql`
  mutation CustomizeTenancy {
    clearTenancyTheme
  }
`;

const CUSTOMISE_COLOR = gql`
  mutation CustomizeTenancy($primaryColor: Int!, $secondaryColor: Int!) {
    customizeTenancy(
      primary_color: $primaryColor
      secondary_color: $secondaryColor
    )
  }
`;

const UPLOAD_LOGO = gql`
  mutation CustomizeTenancy($file: Upload!) {
    uploadTenancyImages(file: $file)
  }
`;

const Profile = ({ history }) => {
  const dispatch = useDispatch();
  const { permissions, email, phoneNumber, company, mfaEnrolled } = useAuth();

  var themeFeature = null;
  if (false) {
    // Deprecated
    themeFeature = (
      <div>
        <div className="flex flex-row">
          <TenantColorContainer />
          <TenantLogoContainer />
          <ClearTheme />
        </div>

        <hr className="my-2" />
      </div>
    );
  }
  return (
    <div>
      <div className="flex flex-row">
        <p className="text-semibold text-xl">Email:</p>
        <p className="text-lg ml-2">{email}</p>
        <Tooltip className="my-auto ml-2">
          To change your registered email address, contact your administrator.
        </Tooltip>
      </div>
      <hr className="my-2" />
      <div>
        <p className="text-base text-semibold inline">Phone:</p>
        <p className="text-sm inline ml-2">{phoneNumber || "N/A"}</p>
      </div>
      <div>
        <p className="text-base text-semibold inline">Company:</p>
        <CompanyName uid={company} />
      </div>
      <PermissionList />
      <hr className="my-2" />
      {themeFeature}

      <button
        className="font-bold text-xs py-1 px-2 rounded border border-black mt-1"
        onClick={() => dispatch(forgotPassword(email))}
      >
        Reset Password
      </button>
      <p className="text-xs mt-1">
        Send a reset password link to your registered email address. Check your
        spam folder.
      </p>
      <hr className="my-2" />
      <div>
        {!mfaEnrolled && (
          <Link
            to="/enrol-mfa"
            className="ml-2 bg-blue-400 text-white text-sm font-bold py-2.5 px-4 rounded"
          >
            Enroll MFA
          </Link>
        )}

        <button
          className="bg-orange-400 text-white text-sm font-bold py-2 px-4 rounded m-2"
          onClick={async () => {
            await dispatch(logout());
            history.push("/");
          }}
        >
          Log Out
        </button>

        <button
          className="bg-red-600 text-white text-sm font-bold py-2 px-4 rounded "
          onClick={async () => {
            await dispatch(logout());
            history.push("/");
          }}
        >
          Delete Account
        </button>
      </div>
    </div>
  );
};

const mapStateToThemeProps = (state) => {
  const tenancyStyling = state.tenancyStyling;
  return {
    primaryColor: tenancyStyling.primaryColor,
    secondaryColor: tenancyStyling.secondaryColor,
    logo: tenancyStyling.logo,
  };
};

const TenantColors = ({
  primaryColor,
  secondaryColor,
  handleUpdatePrimary,
  handleUpdateSecondary,
}) => {
  const [customizeTenancy, { loading, error, data }] =
    useMutation(CUSTOMISE_COLOR);
  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <div>Error :(</div>;
  }

  return (
    <div>
      <p className="text-base ml-2">Customise tenancy colors</p>
      <div className="flex flex-row">
        <div className="flex flex-col text-center text-xs m-2">
          <label name="colorPrimary" className="flex flex-row mb-1">
            <h1 className="inline font-bold">Primary color</h1>
            <Tooltip id="primary" className="ml-1 pt-0.5 inline">
              Suggestion: Make this the darker color
            </Tooltip>
          </label>
          <input
            className="pl-1 p-2 border border-black border-solid rounded"
            type="text"
            name="colorPrimary"
            onChange={(e) => {
              handleUpdatePrimary(e.target.value);
            }}
          />
        </div>
        <div className="flex flex-col text-center text-xs m-2">
          <label name="colorSecondary" className="flex flex-row mb-1">
            <h1 className="inline font-bold">Secondary color</h1>
            <Tooltip id="secondary" className="ml-1 pt-0.5 inline">
              Suggestion: Make this the lighter color
            </Tooltip>
          </label>
          <input
            name="colorSecondary"
            className="pl-1 p-2 border border-black border-solid rounded"
            label={"Secondary color:"}
            type="text"
            onChange={(e) => {
              handleUpdateSecondary(e.target.value);
            }}
          />
        </div>
        <button
          className="text-sm bg-green-600 mt-auto mb-1.5 ml-2 px-4 py-2 border border-solid text-white rounded"
          onClick={async () => {
            await customizeTenancy({
              variables: {
                primaryColor: parseInt(primaryColor.replace("#", ""), 16),
                secondaryColor: parseInt(secondaryColor.replace("#", ""), 16),
              },
            });
            window.location.reload(false);
            if (data?.customizeTenancy?.length >= 1) {
              alert(data.customizeTenancy.join("\n"));
            } else {
              alert("Tenancy successfully customised.");
            }
          }}
        >
          Submit
        </button>
      </div>
      <p className="text-xs ml-2">
        Please enter colors in hexadecimal (example #2fdce1)
      </p>
    </div>
  );
};

const TenantLogo = ({ logo, handleUpdateLogo }) => {
  const [uploadTenancyImages, { loading, error, data }] =
    useMutation(UPLOAD_LOGO);

  if (loading) return <Spinner />;
  if (error) return <p>Error: {error.message}</p>;
  return (
    <div className="ml-4 border-l border-r px-4">
      <p className="text-base">Customise tenancy logo</p>
      <input
        type="file"
        id="img"
        name="img"
        accept="image/*"
        className="bg-gray-400 w-64 rounded"
        onChange={(e) => handleUpdateLogo(e.target.files[0])}
      />
      <button
        className="text-sm bg-green-600 mt-6 ml-4 px-4 py-2 border border-solid text-white rounded"
        onClick={async () => {
          await uploadTenancyImages({
            variables: {
              file: logo,
            },
          });
          window.location.reload(false);
          if (data?.customizeTenancy?.length >= 1) {
            alert(data.customizeTenancy.join("\n"));
          } else {
            alert("Tenancy successfully customised.");
          }
        }}
      >
        Submit
      </button>
    </div>
  );
};

const ClearTheme = ({}) => {
  const [clearTenancyTheme, { loading, error, data }] =
    useMutation(CLEAR_TENANCY);
  if (loading) return <Spinner />;
  if (error) return <p>Error: {error.message}</p>;
  return (
    <div className="ml-2 pl-4">
      <p className="text-base">Reset Logo and Colours</p>
      <button
        className="bg-red-600 text-white text-sm font-bold py-2 px-4 mt-6 rounded"
        onClick={async () => {
          await clearTenancyTheme();
          window.location.reload(false);
          if (data?.customizeTenancy?.length >= 1) {
            alert(data.customizeTenancy.join("\n"));
          } else {
            alert("Tenancy theme successfully cleared.");
          }
        }}
      >
        Clear Tenancy Styling
      </button>
    </div>
  );
};

const handleUpdatePrimary = (primaryColor) => ({
  type: PRIMARY_COLOR,
  primaryColor,
});

const handleUpdateSecondary = (secondaryColor) => ({
  type: SECONDARY_COLOR,
  secondaryColor,
});

const handleUpdateLogo = (logo) => ({
  type: TENANT_LOGO,
  logo,
});

const TenantColorContainer = connect(mapStateToThemeProps, {
  handleUpdatePrimary,
  handleUpdateSecondary,
})(TenantColors);

const TenantLogoContainer = connect(mapStateToThemeProps, {
  handleUpdateLogo,
})(TenantLogo);

const CompanyName = () => {
  const { loading, error, data } = useQuery(ACCOUNT_NAME_QUERY);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <h1>Error!</h1>;
  }

  return <p className="inline ml-2 text-sm">{data.account.name}</p>;
};

const PermissionList = () => {
  const { uam, permissions } = useAuth();

  if (!uam) {
    uam = {};
  }

  var uamView = uam.map((x) => (
    <p key={x.key} className="source-sans-pro text-lg">
      - {x.key.slice(12).replace(".", " ")} : {x.value ? "Yes" : "No"}
    </p>
  ));
  if (!uamView.length) {
    uamView = (
      <p className="text-xs">
        No Permissions, if you wish to create, modify or delete locations,
        please contact your tenancy administrator.
      </p>
    );
  }

  return (
    <div>
      <h1 className="text-semibold">
        Role: <span className="text-sm ">{permissions?.role}</span>
      </h1>
      <hr className="my-2" />
      <h1>Permissions:</h1>
      {uamView}
    </div>
  );
};

export default Profile;
