import Spinner from "../misc/Spinner";
import { FaMinusCircle, FaPlusCircle, FaTrash } from "react-icons/fa";

import { OfflinePresetsContainer } from "./OfflineEVSEPresets";

import { gql, useMutation, useQuery } from "@apollo/client";

import { Tooltip } from "../../components/Tooltip";

import { connect } from "react-redux";
import { useState } from "react";

import {
  DEVICE_CAPABILITIES,
  CONNECTOR_FORMAT,
  POWER_TYPE,
} from "../device_presets/DevicePresetForm";

import {
  ALLOWED_TYPES,
  TYPES,
  STANDARD_TO_TITLE,
} from "../ecommerce/LocationMetadata";

import {
  REFERENCE_NUMBER,
  EVSE_ID,
  ADD_CAPABILITY,
  IMAGE,
  DIRECTIONS,
  REMOVE_CAPABILITY,
  FLOOR,
  CONNECTORS,
} from "../../reducers/offlineEvseForm";

const EDIT_EVSE = gql`
  mutation ($location_id: String!, $evse: OCPIEVSEInput!) {
    updateOfflineDevices(location_id: $location_id, evse: $evse)
  }
`;

/* Ideally should have query for single preset with uid or preset_name variable */
const DEVICE_PRESETS_QUERY = gql`
  query DevicePresetQuery {
    device_presets {
      id
      capabilities
      connectors {
        id
        standard
        format
        max_voltage
        power_type
        max_amperage
        last_updated
      }
      vendor
      model_name
      preset_name
    }
  }
`;

const ADD_PRESET_DEVICE = gql`
  mutation AddOfflineDeviceAsPreset(
    $locationId: String!
    $presetId: String!
    $number: Int!
  ) {
    addOfflineDeviceAsPreset(
      location_id: $locationId
      preset_id: $presetId
      number: $number
    )
  }
`;

export const AddOfflineEVSE = ({ locationId }) => {
  return (
    <div className="w-full h-full">
      <AddOfflineDeviceContainer location_id={locationId} />
    </div>
  );
};

const mapStateToEvseProps = (state) => {
  const offlineEvseForm = state.offlineEvseForm;
  return {
    evse: offlineEvseForm,
  };
};

const AddOfflineDeviceForm = ({ evse, location_id }) => {
  const [updateOfflineDevices, edit_evse] = useMutation(EDIT_EVSE);
  const [addOfflineDeviceAsPreset, add_preset] = useMutation(ADD_PRESET_DEVICE);
  const preset_query = useQuery(DEVICE_PRESETS_QUERY);

  if (edit_evse.loading || add_preset.loading || preset_query.loading)
    return <Spinner />;
  if (edit_evse.error || add_preset.error || preset_query.error)
    return <p>Error :(</p>;

  var fieldsSection = (
    <div>
      <div className="mt-2">
        <h1 className="formSectionTitle flex flex-row w-full justify-center">
          Identification
        </h1>
        <EVSEIdentificationContainer />
      </div>
      <div className="mt-2">
        <h1 className="formSectionTitle">Device Capabilities</h1>
        <DeviceCapabilitySelectorContainer />
      </div>
      <div className="mt-2">
        <h1 className="formSectionTitle">Device Connectors</h1>
        <DeviceConnectorContainer />
      </div>
      <div className="mt-2">
        <h1 className="formSectionTitle">Image (optional)</h1>
        <DeviceImageContainer />
      </div>
      <div className="mt-2">
        <h1 className="formSectionTitle">Position (optional)</h1>
        <DevicePositionContainer />
      </div>
    </div>
  );

  /* No fields should be shown besides identification if preset is selected */
  var notice = null;
  if (evse.preset_name) {
    fieldsSection = null;
    notice = (
      <Tooltip className="mt-5 ml-2">
        NOTE: Adding more than one preset device at a time <br />
        means you cannot set identification (as it would apply to all). <br />{" "}
        If you wish to modify the identification of several added presets,{" "}
        <br />
        do so individually via the MODIFY EVSE button after adding.
      </Tooltip>
    );
  }

  return (
    <div>
      <div className="mt-2">
        <h1 className="formSectionTitle">Presets</h1>
        <OfflinePresetsContainer />
      </div>
      {fieldsSection}
      <div className="flex flex-row">
        <button
          className="mt-2 btn-green"
          onClick={async () => {
            /* Add the device as a number of presets if this field is non null */
            if (evse.preset_name) {
              var preset_id = preset_query.data?.device_presets?.filter(
                (x) => x.preset_name === evse.preset_name
              )[0]?.id;
              console.debug("PRESET QTY: ", evse.preset_qty);

              await addOfflineDeviceAsPreset({
                variables: {
                  locationId: location_id,
                  presetId: preset_id,
                  number: parseInt(evse.preset_qty),
                },
              });

              /* Otherwise add the customised offline device */
            } else {
              await updateOfflineDevices({
                variables: {
                  location_id,
                  evse,
                },
              });
            }

            if (edit_evse.data?.updateOfflineDevices?.length >= 1) {
              alert(edit_evse.data.updateOfflineDevices.join("\n"));
            } else if (add_preset.data?.updateOfflineDevices?.length >= 1) {
              alert(add_preset.data.updateOfflineDevices.join("\n"));
            } else {
              window.location.reload(false);
              alert("EVSE successfully added.");
            }
          }}
        >
          Submit
        </button>
        {notice}
      </div>
    </div>
  );
};

const AddOfflineDeviceContainer =
  connect(mapStateToEvseProps)(AddOfflineDeviceForm);

/*        EVSE ID SECTION           */

const mapStateToEvseIdProps = (state) => ({
  physical_reference: state.physical_reference,
  evse_id: state.evse_id,
});

const handleUpdateRefNo = (physical_reference) => ({
  type: REFERENCE_NUMBER,
  physical_reference,
});

const handleUpdateEvseId = (evse_id) => ({
  type: EVSE_ID,
  evse_id,
});

const EVSEIdentification = ({
  physical_reference,
  evse_id,
  handleUpdateRefNo,
  handleUpdateEvseId,
}) => {
  return (
    <div className="mt-2 border rounded p-2">
      <div className="flex flex-row">
        <h1 className="mr-2 w-1/2 p-1">Physical Reference No.</h1>
        <input
          className="border rounded pl-1"
          type="text"
          value={physical_reference}
          onChange={(e) => handleUpdateRefNo(e.target.value)}
        />
      </div>
      <div className="flex flex-row mt-2">
        <h1 className="mr-2 w-1/2 p-1">EVSE ID:</h1>
        <input
          className="border rounded pl-1"
          type="text"
          value={evse_id}
          onChange={(e) => handleUpdateEvseId(e.target.value)}
        />
      </div>
    </div>
  );
};

const EVSEIdentificationContainer = connect(mapStateToEvseIdProps, {
  handleUpdateRefNo,
  handleUpdateEvseId,
})(EVSEIdentification);

/*        CAPABILITIES SECTION           */

export const DeviceCapabilitySelector = ({
  capabilities,
  handleAddCapabilities,
  handleRemoveCapabilities,
}) => {
  /* A local state is used to know which capability is selected from dropdown */
  const [selectedCapability, updateSelectedCapability] = useState(undefined);

  var capabilityList = null;

  if (capabilities?.length) {
    capabilityList = capabilities.sort().map((x) => (
      <DeviceCapabilityTile
        key={x}
        name={x}
        onDeleted={() => {
          handleRemoveCapabilities(x);
        }}
      />
    ));
  }

  return (
    <div className="mt-2" key="device-capability-selector">
      {capabilityList}
      <h1 key="title">Add capability:</h1>
      <div className="flex flex-row">
        <select
          className="border rounded mb-1"
          name="location"
          onChange={(e) => updateSelectedCapability(e.target.value)}
        >
          <option value="none" label="Select a capability" key="none" />
          {DEVICE_CAPABILITIES.map((x) => (
            <option value={x} label={x} key={x} />
          ))}
        </select>
        <div className="ml-2 mt-0.5">
          <button
            type="button"
            onClick={() => {
              handleAddCapabilities(selectedCapability);
            }}
          >
            <FaPlusCircle size={28} />
          </button>
        </div>
      </div>
    </div>
  );
};

const DeviceCapabilityTile = ({ name, onDeleted }) => {
  return (
    <div className="m-2 border rounded flex flex-row">
      <p className="p-2" key={name}>
        {name}
      </p>
      <button
        key="button"
        type="button"
        className="ml-auto mr-2"
        onClick={onDeleted}
      >
        <FaMinusCircle />
      </button>
    </div>
  );
};

const mapStateToCapabilityProps = (state) => {
  const offlineEvseForm = state.offlineEvseForm;
  return {
    capabilities: offlineEvseForm.capabilities,
  };
};

/* Add a single capability to capability state */
const handleAddCapabilities = (capabilities) => ({
  type: ADD_CAPABILITY,
  capabilities,
});

/* Remove a capability from capability state */
const handleRemoveCapabilities = (capabilities) => ({
  type: REMOVE_CAPABILITY,
  capabilities,
});

const DeviceCapabilitySelectorContainer = connect(mapStateToCapabilityProps, {
  handleAddCapabilities,
  handleRemoveCapabilities,
})(DeviceCapabilitySelector);

/*        DEVICE CONNECTOR SECTION           */

const DeviceConnectorEditor = ({ connectors, handleUpdateConnectors }) => {
  return (
    <div>
      {Object.keys(connectors).map((x) => (
        <DeviceConnectorTile
          key={x}
          connector={connectors[x]}
          index={x}
          //Remove ith connector
          deleteConnector={() => {
            var connectorsClone = {
              ...connectors,
            };
            //remove connector from object.
            delete connectorsClone[x];
            handleUpdateConnectors(connectorsClone);
          }}
        />
      ))}
      <DeviceConnectorForm
        createDeviceConnector={(conn) => {
          var connectorId = conn.connectorId;
          //dont mutate state directly!
          var connectorsClone = [...connectors];

          if (!connectorId) {
            connectorId = connectorsClone.length + 1;
          }

          connectorsClone.push({
            id: String(connectorId),
            standard: conn.connectorStandard,
            format: conn.connectorFormat,
            power_type: conn.connectorPowerType,
            max_voltage: conn.maxVoltage,
            max_amperage: conn.maxAmperage,
          });

          handleUpdateConnectors(connectorsClone);
        }}
      />
    </div>
  );
};

const DeviceConnectorTile = ({ connector, index, deleteConnector }) => {
  return (
    <div key={index} className="p-2 border rounded mt-2">
      <div className="flex flex-row">
        <h1> Connector #{index}</h1>
        <button
          className="ml-auto mr-2"
          type="button"
          onClick={deleteConnector}
        >
          <FaTrash />
        </button>
      </div>
      <h1> Standard: {TYPES[STANDARD_TO_TITLE[connector.standard]].title}</h1>
      <h1> Format: {connector.format}</h1>
      <h1> Power Type: {connector.power_type.replace(/_/g, " ")}</h1>
      <h1> Max Voltage: {connector.max_voltage}V</h1>
      <h1> Max Amperage: {connector.max_amperage}A</h1>
    </div>
  );
};

const DeviceConnectorForm = ({ createDeviceConnector }) => {
  const [connectorStandard, updateConnectorStandard] = useState(undefined);
  const [connectorFormat, updateConnectorFormat] = useState(undefined);
  const [connectorPowerType, updateConnectorPowerType] = useState(undefined);
  const [maxVoltage, updateConnectorVoltage] = useState(undefined);
  const [maxAmperage, updateConnectorAmperage] = useState(undefined);
  const [description, updateDescription] = useState("");

  return (
    <div className="border rounded mt-2">
      <div className="p-2">
        <h1 className="font-bold">Add Connector</h1>
        <h1>Connector Standard</h1>
        <select
          className="border rounded mb-1 mt-1"
          name="standard"
          onChange={(e) => updateConnectorStandard(e.target.value)}
        >
          <option value="none" key="none" label="Select a standard" />
          {ALLOWED_TYPES.map((x) => (
            <option
              value={x}
              label={TYPES[STANDARD_TO_TITLE[x]].title}
              key={x}
            />
          ))}
        </select>
        <h1>Connector Format</h1>
        <select
          className="border rounded mb-1 mt-1"
          name="format"
          onChange={(e) => updateConnectorFormat(e.target.value)}
        >
          <option value="none" key="none" label="Select a format" />
          {CONNECTOR_FORMAT.map((x) => (
            <option value={x} label={x} key={x} />
          ))}
        </select>
        <h1>Connector Power Type</h1>
        <select
          className="border rounded mb-1 mt-1"
          name="powerType"
          onChange={(e) => updateConnectorPowerType(e.target.value)}
        >
          <option value="none" key="none" label="Select a power type" />
          {POWER_TYPE.map((x) => (
            <option value={x} label={x.replace(/_/g, " ")} key={x} />
          ))}
        </select>
        <h1>Max Voltage</h1>
        <input
          className="border rounded mb-1 mt-1"
          type="number"
          onChange={(e) => updateConnectorVoltage(e.target.value)}
        />

        <h1>Max Amperage</h1>
        <input
          className="border rounded mb-1 mt-1"
          type="number"
          onChange={(e) => updateConnectorAmperage(e.target.value)}
        />

        <br />
        <button
          className="bg-blue-400 text-white text-sm font-bold py-1 px-2 rounded mt-2"
          type="button"
          onClick={() => {
            if (
              !connectorStandard ||
              !connectorFormat ||
              !connectorPowerType ||
              !maxVoltage ||
              !maxAmperage
            ) {
              alert("Ensure all fields are filled out!");
              return;
            }

            var deviceConnectorObject = {
              connectorStandard,
              connectorFormat,
              connectorPowerType,
              maxVoltage: parseFloat(maxVoltage),
              maxAmperage: parseFloat(maxAmperage),
              description: description,
            };

            createDeviceConnector(deviceConnectorObject);
          }}
        >
          Add Connector
        </button>
      </div>
    </div>
  );
};

const mapStateToConnectorProps = (state) => {
  const offlineEvseForm = state.offlineEvseForm;
  return {
    connectors: offlineEvseForm.connectors,
  };
};

const handleUpdateConnectors = (connectors) => ({
  type: CONNECTORS,
  connectors,
});

const DeviceConnectorContainer = connect(mapStateToConnectorProps, {
  handleUpdateConnectors,
})(DeviceConnectorEditor);

/*        DEVICE POSITION SECTION           */

const DevicePositionSection = ({
  handleUpdateDirections,
  directions,
  handleUpdateFloor,
  floor_level,
}) => {
  return (
    <div className="mt-2 border rounded p-2">
      <div className="flex flex-row">
        <h1 className="mr-2 w-1/2">Floor Level:</h1>
        <input
          className="border rounded pl-1"
          name="floorLevel"
          type="text"
          value={floor_level}
          onChange={(e) => handleUpdateFloor(e.target.value)}
        />
      </div>
      <div className="flex flex-row mt-2">
        <h1 className="mr-2 w-1/2">Directions:</h1>
        <textarea
          className="border rounded p-2"
          name="directions"
          rows="3"
          cols="50"
          value={directions}
          onChange={(e) => handleUpdateDirections(e.target.value)}
        />
      </div>
    </div>
  );
};

const mapStateToPositionProps = (state) => {
  const offlineEvseForm = state.offlineEvseForm;
  return {
    position: offlineEvseForm.position,
  };
};

const handleUpdateDirections = (directions) => ({
  type: DIRECTIONS,
  directions,
});

const handleUpdateFloor = (floor_level) => ({
  type: FLOOR,
  floor_level,
});

const DevicePositionContainer = connect(mapStateToPositionProps, {
  handleUpdateDirections,
  handleUpdateFloor,
})(DevicePositionSection);

/* IMAGE SECTION */

const mapStateToImageProps = (state) => {
  const offlineEvseForm = state.offlineEvseForm;
  return {
    image: offlineEvseForm.image,
  };
};

const DeviceImageSelector = ({ image, handleUpdateImage }) => {
  var imageList = null;

  return (
    <div className="mt-2" key="device-capability-selector">
      {imageList}
      <h1 key="title">Add image:</h1>
      <div className="flex flex-row">
        <input
          type="file"
          id="img"
          name="img"
          accept="image/*"
          onChange={(e) => handleUpdateImage(e.target.files[0])}
        />
      </div>
    </div>
  );
};

const handleUpdateImage = (image) => ({
  type: IMAGE,
  image,
});

const DeviceImageContainer = connect(mapStateToImageProps, {
  handleUpdateImage,
})(DeviceImageSelector);
