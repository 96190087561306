import { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { FaPlusCircle } from "react-icons/fa";
import Spinner from "../misc/Spinner";

const ROLES = ["sysadmin", "tenancyadmin"];

export const EditUserForm = ({
  role,
  defaultValue,
  onChange,
  canEdit,
  type,
}) => {
  const [userInfo, setUserInfo] = useState(defaultValue ? defaultValue : {});

  useEffect(() => {
    onChange(userInfo);
  }, [userInfo]);

  console.log("Role is", role);
  var allowedRoles = filterRoles(role);
  console.log("Allowed roles", allowedRoles);

  return (
    <div key="edituserform">
      {!canEdit && <p>You do not have permission to edit this user.</p>}
      <div key="userform-wrapper">
        <div className="flex flex-row mt-2" key="name">
          <h1 className="p-2 w-1/2">Name</h1>
          <input
            disabled={!canEdit}
            defaultValue={defaultValue?.name ? defaultValue?.name : ""}
            className="border rounded ml-2 p-2"
            onChange={(e) =>
              setUserInfo({
                ...userInfo,
                name: e.target.value,
              })
            }
          />
        </div>
        <div className="flex flex-row mt-2" key="email">
          <h1 className="p-2 w-1/2">Email</h1>
          <input
            disabled={!canEdit}
            defaultValue={defaultValue?.email ? defaultValue?.email : ""}
            className="border rounded ml-2 p-2"
            onChange={(e) =>
              setUserInfo({
                ...userInfo,
                email: e.target.value,
              })
            }
          />
        </div>
        {type === "CREATE" && (
          <div className="flex flex-row mt-2" key="password">
            <h1 className="p-2 w-1/2">Password</h1>
            <input
              disabled={!canEdit}
              type="password"
              className="border rounded ml-2 p-2"
              onChange={(e) =>
                setUserInfo({
                  ...userInfo,
                  password: e.target.value,
                })
              }
            />
          </div>
        )}
        <div className="flex flex-row mt-2" key="phone-number">
          <h1 className="p-2 w-1/2">Phone number</h1>
          <input
            disabled={!canEdit}
            className="border rounded ml-2 p-2"
            defaultValue={defaultValue?.phone_number}
            onChange={(e) =>
              setUserInfo({
                ...userInfo,
                phone_number: e.target.value,
              })
            }
          />
        </div>
        <div className="flex flex-row mt-2" key="role">
          <h1 className="p-2 w-1/2">Role</h1>
          <select
            disabled={!canEdit}
            defaultValue={defaultValue?.permissions?.role}
            className="border rounded ml-2 w-48 p-2"
            onChange={(e) =>
              setUserInfo({
                ...userInfo,
                permissions: {
                  role: e.target.value,
                },
              })
            }
          >
            {allowedRoles?.map((x) => (
              <option key={x}>{x}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

/*
 {type === "EDIT" && false && (
  <div className="flex flex-row mt-2">
    <h1 className="p-2 w-1/2">UAM</h1>
    <UAMForm canEdit={canEdit} />
  </div>
)}
*/

//Return only roles lower or equal in heirachy
function filterRoles(role) {
  if (role === "sysadmin") {
    return ROLES;
  }

  var index = ROLES.indexOf(role);
  if (index === -1) return [];

  return ROLES.slice(index);
}

/*
 *  A form to edit the UAM for a user.
 */
const UAM_PERMISSIONS = [
  "permissions.create.location",
  "permissions.edit.location",
  "permissions.delete.location",
];

export const UAMForm = ({ role, defaultValue, onChange, canEdit }) => {
  const [UAM, setUAM] = useState([]);
  const [selectedUAM, setSelectedUAM] = useState("");

  return (
    <div key="uam-wrapper">
      <div className="flex flex-row">
        <select
          onChange={(e) => {
            setSelectedUAM(e.target.value);
          }}
          disabled={!canEdit}
          className="border rounded ml-2 p-2"
        >
          {UAM_PERMISSIONS.map((x) => (
            <option value={x} label={x} />
          ))}
        </select>
        <button
          className="ml-2"
          onClick={() => {
            if (selectedUAM === "") {
              return;
            }

            var newUAM = [...UAM];

            if (newUAM.indexOf(selectedUAM) === -1) {
              newUAM.push(selectedUAM);
            }

            setUAM(newUAM);
          }}
        >
          <FaPlusCircle />
        </button>
      </div>
      <div key="uam">
        {UAM.map((x) => {
          <h1>{x}</h1>;
        })}
      </div>
    </div>
  );
};
