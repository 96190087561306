import React, { useMemo, useState, useRef } from "react";
import DataGrid from "react-data-grid";
import { gql, useQuery } from "@apollo/client";
import _ from "lodash";
import moment from "moment";
import { IoIosSearch } from "react-icons/io";
import { ButtonlessModal, RightModal } from "../layout/rightModal";
import { AccountEditor } from "./AccountEditor";
import { RefreshTrello } from "./SoftwareOperation";
import Spinner from "../misc/Spinner";
import DisbursementsManager from "./DisbursementsManager";
import { ExportButton } from "../sessions/SessionsPage";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ACCOUNT_QUERY = gql`
  query GetAccounts {
    accounts {
      id
      abn
      address
      name
      number
      party_id
      postcode
      email
      state
      account_number
      bsb
      dlb_enabled
      tenancy_tier
      subscription {
        status
        start_date
        end_date
        auto_renewal
        current_plan {
          billing_period
          creation_date
        }
      }
      start_date
      end_date
      closed
      lite
    }
  }
`;

const tenancyDuration = (startDate, endDate, expiry) => {
  if (startDate && endDate) {
    const start = moment(new Date(startDate));
    const end = expiry
      ? moment(new Date(endDate))
      : moment(new Date(endDate)).add("1", "days");
    const duration = moment.duration(end.diff(start));
    const totalDays = duration.asDays();

    return totalDays;
  }
};

const tenancyWithinDuration = (startDate, endDate, tenancyEndDate) => {
  if (tenancyEndDate) {
    const start = moment(new Date(startDate));
    const end = moment(new Date(endDate));
    const tenancyEnd = moment(new Date(tenancyEndDate));

    return tenancyEnd.isBetween(start, end, undefined, "[]");
  }
};

export const AccountsManager = () => {
  const [selectedAccount, updateSelectedAccount] = useState(undefined);
  const [searchQuery, setSearchQuery] = useState("");
  const [planType, setPlanType] = useState("");
  const [planStatus, setPlanStatus] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const searchInputRef = useRef();

  const { loading, error, data, refetch } = useQuery(ACCOUNT_QUERY);

  const planTypesMemoized = useMemo(
    () =>
      _.sortBy(
        _.uniqBy(data?.accounts, "tenancy_tier").map((x) => {
          const value = x.tenancy_tier;
          switch (value) {
            case "FLEX":
              return {
                label: "Lite",
                value,
                disabled: true,
              };
            case "UNLIMITED":
              return {
                label: "Full",
                value,
              };
            default:
              return {
                label: _.startCase(_.lowerCase(value)),
                value,
                disabled: true,
              };
          }
        }),
        ["label"]
      ),
    [data?.accounts]
  );

  const subscriptionStatusMemoized = useMemo(
    () =>
      _.sortBy(
        _.uniqBy(data?.accounts, "subscription.status")
          .filter((x) => x.subscription?.status)
          .map((x) => ({
            label: _.startCase(_.lowerCase(x.subscription.status)),
            value: x.subscription.status,
          })),
        ["label"]
      ),
    [data?.accounts]
  );

  if (loading) return <Spinner />;

  if (error) return <p>Error</p>;

  // This is a temporary fix for duplication issue that occurs after creating a new tenancy from Admin page.
  // This could be graphql caching issue. From DB, data is not duplicated.
  const accounts = _.uniqBy(data.accounts, "id").map((x) => ({
    ...x,
    expiry_in_days: tenancyDuration(
      new Date().toISOString().split("T")[0],
      x.end_date || x.subscription?.end_date,
      true
    ),
  }));

  var columns = [
    { key: "party_id", headerCellClass: "brandPrimaryColor", name: "Party ID" },
    {
      key: "name",
      headerCellClass: "brandPrimaryColor",
      name: "Business Account Name",
    },
    {
      key: "plan_type",
      headerCellClass: "brandPrimaryColor",
      name: "Plan",
    },
    {
      key: "closed",
      headerCellClass: "brandPrimaryColor",
      name: "Closed",
    },
    {
      key: "expired",
      headerCellClass: "brandPrimaryColor",
      name: "Expired",
    },
    {
      key: "start_date_formatted",
      headerCellClass: "brandPrimaryColor",
      name: "Start Date",
      resizable: true,
    },
    {
      key: "end_date_formatted",
      headerCellClass: "brandPrimaryColor",
      name: "End Date",
      resizable: true,
    },
    {
      key: "duration_in_days_formatted",
      headerCellClass: "brandPrimaryColor",
      name: "Duration",
      resizable: true,
    },
    {
      key: "expiry_in_days_formatted",
      headerCellClass: "brandPrimaryColor",
      name: "Expires In",
      resizable: true,
    },
    {
      key: "subscription_status",
      headerCellClass: "brandPrimaryColor",
      name: "Subscription",
    },
    {
      key: "street",
      headerCellClass: "brandPrimaryColor",
      name: "Street Address",
    },
    { key: "postcode", headerCellClass: "brandPrimaryColor", name: "Postcode" },
    {
      key: "contact",
      headerCellClass: "brandPrimaryColor",
      name: "Contact Number",
    },
    { key: "state", headerCellClass: "brandPrimaryColor", name: "State" },

    {
      key: "dlb_enabled",
      headerCellClass: "brandPrimaryColor",
      name: "DLB Access",
    },
  ];

  // All data filters
  const parsedSearchQuery = searchQuery.toLowerCase().trim();
  const searchResults = accounts.filter(
    (x) =>
      (x.party_id || "").toLowerCase().includes(parsedSearchQuery) ||
      (x.name || "").toLowerCase().includes(parsedSearchQuery) ||
      (x.email || "").toLowerCase().includes(parsedSearchQuery)
  );
  const filterResults = searchResults.filter((x) => {
    const showTenanciesWithoutEndDate =
      planStatus === "UNKNOWN" && !x.end_date && !x.subscription?.end_date;
    return (
      (!planType || x.tenancy_tier === planType) &&
      (!subscriptionStatus || x.subscription?.status === subscriptionStatus) &&
      (!planStatus ||
        (planStatus === "ACTIVE"
          ? x.expiry_in_days >= 0
          : planStatus === "EXPIRED"
          ? x.expiry_in_days < 0
          : planStatus === "CLOSED"
          ? x.closed
          : showTenanciesWithoutEndDate)) &&
      (!fromDate ||
        !toDate ||
        showTenanciesWithoutEndDate ||
        tenancyWithinDuration(
          fromDate,
          toDate,
          x.end_date || x.subscription?.end_date
        ))
    );
  });

  const gridData = filterResults.map((x) =>
    mapRow(x, planTypesMemoized, subscriptionStatusMemoized)
  );
  const exportData = gridData.map((t) => ({
    party_id: t.party_id,
    business_account_name: t.name,
    plan_type: t.plan_type,
    closed: t.closed,
    expired: t.expired,
    start_date: t.start_date_formatted,
    end_date: t.end_date_formatted,
    duration: t.duration_in_days_formatted,
    [`expires_from_${moment(new Date()).format("DD-MMM-YY")}_in`]:
      t.expiry_in_days_formatted,
    subscription_status: t.subscription_status,
    street_address: t.street,
    postcode: `'${t.postcode}`,
    contact_number: `'${t.contact}`,
    state: t.state,
    dlb_access: t.dlb_enabled,
  }));

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="light"
      />
      <div className="flex flex-row">
        <div className="flex flex-col  ml-1">
          <h1 className="heading text-3xl font-bold mt-1">
            Tenancy Manager ({filterResults.length})
          </h1>
          <p className="text-gray-500 ml-1">
            This page is exclusive to EVUp Charge Adminstrators
          </p>
        </div>
        <ButtonlessModal
          show={!!selectedAccount}
          title="Modify Tenancy"
          onClose={() => updateSelectedAccount(undefined)}
        >
          <AccountEditor
            accountData={selectedAccount}
            type="update"
            refetch={refetch}
            planTypes={planTypesMemoized}
            onDelete={() => {
              refetch();
              updateSelectedAccount(undefined);
              toast.success(
                `${selectedAccount.name} (${selectedAccount.party_id}) was deleted successfully!`
              );
            }}
          />
        </ButtonlessModal>

        <div className="flex flex-row ml-auto max-h-11">
          <RightModal title="Disbursements">
            <DisbursementsManager />
          </RightModal>
          <RefreshTrello />
          <RightModal title="Create Tenancy">
            <AccountEditor
              type="create"
              refetch={refetch}
              planTypes={planTypesMemoized}
            />
          </RightModal>
          <ExportButton
            btnStyle={{ minHeight: 44 }}
            data={exportData}
            fields={Object.keys(exportData[0] || {})}
            title={`tenancy-accounts-(${exportData.length})`}
          />
        </div>
      </div>
      <hr className="my-2 border border-gray-400" />
      <div className="flex">
        <div className="flex-column">
          <div style={{ width: "20.1rem" }}>
            <div className="relative flex w-full flex-wrap items-stretch">
              <input
                ref={searchInputRef}
                type="search"
                className="relative m-0 block flex-auto rounded border border-solid border-neutral-300 bg-transparent bg-clip-padding pl-3 pr-1 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_#2fdce1] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
                placeholder="Search by party id/name/email"
                aria-label="Search"
                autoFocus
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <IoIosSearch className="brandPrimaryColor mt-1" size={30} />
            </div>
          </div>
        </div>
        <div className="flex-column">
          <div
            style={{ height: 36 }}
            className="ml-4 flex flex-row bg-primary brandPrimaryColor p-2 rounded"
          >
            <label className="mr-2 my-auto">Plan:</label>
            <select
              className="border rounded"
              name="plan-type"
              value={planType}
              onChange={(e) => setPlanType(e.target.value)}
            >
              <option label="All" />
              {planTypesMemoized.map((x) => (
                <option value={x.value} label={x.label} key={x.value} />
              ))}
            </select>
          </div>
        </div>
        <div className="flex-column">
          <div
            style={{ height: 36 }}
            className="ml-5 flex flex-row bg-primary brandPrimaryColor p-2 rounded"
          >
            <label className="mr-2 my-auto">Status:</label>
            <select
              className="border rounded"
              name="plan-status"
              value={planStatus}
              onChange={(e) => setPlanStatus(e.target.value)}
            >
              <option label="All" />
              <option value="ACTIVE" label="Active" />
              <option value="CLOSED" label="Closed" />
              <option value="EXPIRED" label="Expired" />
              <option value="UNKNOWN" label="Unknown" />
            </select>
          </div>
        </div>
        <div className="flex-column">
          <div
            style={{ height: 36 }}
            className="ml-5 flex flex-row bg-primary brandPrimaryColor p-2 rounded"
          >
            <label className="mr-2 my-auto">From:</label>
            <input
              type="date"
              style={{ width: 140 }}
              className="border rounded p-1"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
        </div>
        <div className="flex-column">
          <div
            style={{ height: 36 }}
            className="ml-5 flex flex-row bg-primary brandPrimaryColor p-2 rounded"
          >
            <label className="mr-2 my-auto">To:</label>
            <input
              type="date"
              style={{ width: 140 }}
              className="border rounded p-1"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>
        </div>
        <div className="flex-column">
          <div
            style={{ height: 36 }}
            className="ml-5 flex flex-row bg-primary brandPrimaryColor p-2 rounded"
          >
            <label className="mr-2 my-auto">Subscription:</label>
            <select
              className="border rounded"
              name="subscription-status"
              value={subscriptionStatus}
              onChange={(e) => setSubscriptionStatus(e.target.value)}
            >
              <option label="Any" />
              {subscriptionStatusMemoized.map((x) => (
                <option value={x.value} label={x.label} key={x.value} />
              ))}
            </select>
          </div>
        </div>
        <div className="flex-column">
          <button
            style={{ height: 36 }}
            className="ml-5 flex flex-row border border-black bg-primary py-2 px-4 rounded brandPrimaryColor align-center"
            onClick={() => {
              setSearchQuery("");
              setPlanType("");
              setPlanStatus("");
              setFromDate("");
              setToDate("");
              setSubscriptionStatus("");
              if (searchInputRef.current) searchInputRef.current.focus();
            }}
          >
            <p style={{ marginTop: -2 }} className="mt-0.5">
              Reset
            </p>
          </button>
        </div>
        {/* <div className="flex flex-column">
          <div className="flex items-center ml-5 bg-primary brandPrimaryColor pl-2 pr-2 rounded">
            <input
              id="expired-checkbox"
              type="checkbox"
              value={expiredTenancy}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              onChange={(e) => setExpiredTenancy(e.target.checked)}
            />
            <label
              style={{ marginTop: 2 }}
              htmlFor="expired-checkbox"
              className="ml-1 ms-2 font-medium"
            >
              Expired
            </label>
          </div>
        </div> */}
      </div>
      <div className="mt-2">
        <style>
          {
            ".rdg { height: 100vh !important; max-height: calc( 100vh - 158px ) }"
          }
        </style>
        <DataGrid
          columns={columns}
          rows={gridData}
          onRowClick={(row) => {
            updateSelectedAccount(row);
          }}
          className="rdg-dark"
        />
      </div>
    </div>
  );
};

function mapRow(account, planTypesMemoized, subscriptionStatusMemoized) {
  const start_date = account.start_date || account.subscription?.start_date;
  const end_date = account.end_date || account.subscription?.end_date;
  const duration = tenancyDuration(start_date, end_date);

  return {
    id: account.id,
    name: account.name,
    abn: account.abn,
    street: account.address,
    postcode: account.postcode,
    contact: account.number,
    state: account.state,
    party_id: account.party_id,
    dlb_enabled: account.dlb_enabled ? "Yes" : "No",
    closed: account.closed ? "Yes" : "No",
    email: account.email,
    bsb: account.bsb,
    acc: account.account_number,
    tenancy_tier: account.tenancy_tier,
    plan_type:
      planTypesMemoized.find((x) => x.value === account.tenancy_tier)?.label ||
      "",
    subscription: account.subscription,
    subscription_status:
      subscriptionStatusMemoized.find(
        (x) => x.value === account.subscription?.status
      )?.label || "",
    start_date: account.start_date,
    start_date_formatted: start_date
      ? moment(start_date).format("DD-MMM-YY")
      : "",
    end_date: account.end_date,
    end_date_formatted: end_date ? moment(end_date).format("DD-MMM-YY") : "",
    duration_in_days: duration,
    duration_in_days_formatted: duration !== undefined ? `${duration}d` : "",
    expiry_in_days: account.expiry_in_days,
    expiry_in_days_formatted:
      account.expiry_in_days !== undefined ? `${account.expiry_in_days}d` : "",
    expired:
      account.expiry_in_days !== undefined
        ? account.expiry_in_days < 0
          ? "Yes"
          : "No"
        : "",
    lite: account.lite,
  };
}
