import { gql, useQuery } from "@apollo/client";

import Spinner from "../misc/Spinner";

import {
  PRESET_ADD,
  PRESET_REMOVE,
  PRESET_QTY,
} from "../../reducers/offlineEvseForm";

import { connect } from "react-redux";

const DEVICE_PRESETS_QUERY = gql`
  query DevicePresetQuery {
    device_presets {
      id
      capabilities
      connectors {
        id
        standard
        format
        max_voltage
        power_type
        max_amperage
        last_updated
      }
      vendor
      model_name
      preset_name
    }
  }
`;

const OfflinePresetSelector = ({
  preset_name,
  preset_qty,
  handleAddPreset,
  handleRemovePreset,
  handleUpdatePresetQty,
}) => {
  const { loading, error, data } = useQuery(DEVICE_PRESETS_QUERY);
  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <h1>Error loading presets</h1>;
  }
  var preset_options = data.device_presets.map((x) => x.preset_name);

  if (preset_name) {
    return (
      <div>
        <DevicePresetTile
          name={preset_name}
          onDeleted={() => {
            handleRemovePreset(preset_name);
          }}
          onQuantityChange={(quantity) => {
            handleUpdatePresetQty(quantity);
          }}
        />
      </div>
    );
  } else {
    return (
      <div className="mt-2" key="device-capability-selector">
        <div className="flex flex-row">
          <select
            className="border rounded mb-1"
            name="preset"
            onChange={(e) => handleAddPreset(e.target.value)}
          >
            <option value="none" label="Select a preset" key="none" />
            {preset_options.map((x) => (
              <option value={x} label={x} key={x} />
            ))}
          </select>
        </div>
      </div>
    );
  }
};

const DevicePresetTile = ({ name, onDeleted, onQuantityChange }) => {
  return (
    <div className="flex flex-row">
      <div className="border w-full rounded flex flex-row gap-x-1">
        <div className="ml-1 flex flex-row">
          <label className="my-auto mr-1" id="quantity">
            Qty:
          </label>
          <input
            className="border my-auto pl-2 w-auto rounded opacity-100"
            type="number"
            id="quantity"
            name="quantity"
            min="1"
            max="99"
            placeholder="1"
            onChange={(e) => {
              onQuantityChange(Number.parseInt(e.target.value));
            }}
          ></input>
        </div>
        <div className="flex flex-row ml-auto mr-2">
          <p className="p-2" key={name}>
            {name}
          </p>
          <button
            key="button"
            type="button"
            className="ml-auto mr-2 hover:underline bg-red-500 border-2 border-black text-white rounded my-1 px-2"
            onClick={onDeleted}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

const handleAddPreset = (preset_name) => ({
  type: PRESET_ADD,
  preset_name,
});

const handleRemovePreset = (preset_name) => ({
  type: PRESET_REMOVE,
  preset_name,
});

const handleUpdatePresetQty = (preset_qty) => ({
  type: PRESET_QTY,
  preset_qty,
});

export const mapStateToPresetProps = (state) => ({
  preset_name: state.offlineEvseForm.preset_name,
  preset_qty: state.offlineEvseForm.preset_qty,
});

export const OfflinePresetsContainer = connect(mapStateToPresetProps, {
  handleAddPreset,
  handleRemovePreset,
  handleUpdatePresetQty,
})(OfflinePresetSelector);
