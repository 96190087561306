import logo from "../../../assets/logo.png";
import { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import * as Joi from "joi";
import Spinner from "../../misc/Spinner";

import Firebase from "firebase/app";

const CREATE_DRIVER = gql`
  mutation CreateUser($email: String!, $name: String!, $password: String!) {
    createUser(email: $email, name: $name, password: $password)
  }
`;

export const CreateDriver = () => {
  const [email, updateEmail] = useState();
  const [password, updatePassword] = useState();
  const [confirmPassword, updateConfirmPassword] = useState();
  const [firstName, updateFirstName] = useState();
  const [lastName, updateLastName] = useState();
  const [createUser, { loading, error, data }] = useMutation(CREATE_DRIVER);
  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-primary flex-none w-full h-screen">
      <div className="flex-none max-w-xs text-white font-bold py-2 px-4 m-auto">
        <img src={logo} alt="Logo" className="m-auto block mt-8" />
        <h1 className="m-auto block text-center py-6 blueDropshadow text-xl">
          CHARGE
        </h1>
        <div className="inline"></div>
        <div className="relative bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 text-gray-500">
          <h1 className="mx-auto text-center brandPrimaryColor bg-primary p-1 rounded mb-4 w-52">
            Create Driver Account
          </h1>
          <label name="first">First Name:</label>
          <input
            name="first"
            type="textarea"
            className="border border-black border-solid mb-2 pl-1 rounded w-full bg-gray-100"
            onChange={(e) => updateFirstName(e.target.value)}
          ></input>
          <label name="last">Last Name:</label>
          <input
            name="last"
            type="textarea"
            className="border border-black border-solid mb-2 pl-1 rounded w-full bg-gray-100"
            onChange={(e) => updateLastName(e.target.value)}
          ></input>
          <label name="email">Email:</label>
          <input
            name="email"
            type="textarea"
            className="border border-black border-solid mb-2 pl-1 rounded w-full bg-gray-100"
            onChange={(e) => updateEmail(e.target.value)}
          ></input>
          <label name="password">Password:</label>
          <input
            name="password"
            type="password"
            className="border border-black border-solid mb-2 pl-1 rounded w-full bg-gray-100"
            onChange={(e) => updatePassword(e.target.value)}
          ></input>
          <label name="confirm">Confirm Password:</label>
          <input
            name="confirm"
            type="password"
            className="border border-black border-solid mb-2 pl-1 rounded w-full bg-gray-100"
            onChange={(e) => updateConfirmPassword(e.target.value)}
          ></input>
          <div className="mb-1 mt-4 flex justify-between">
            <button
              className="bg-green-600 text-white border-2 border-black text-sm font-bold py-2 px-4 rounded block m-auto"
              type="submit"
              onClick={async () => {
                const schema = Joi.object({
                  email: Joi.string().email({ tlds: { allow: false } }),
                  name: Joi.string().pattern(/^[a-zA-Z -]*$/),
                  password: Joi.string().min(6).max(72),
                });

                if (confirmPassword != password) {
                  alert("Passwords do not match");
                }
                if (
                  schema.validate({
                    email: email,
                    name: `${firstName} ${lastName}`,
                    password: password,
                  }).error != null
                ) {
                  alert("Invalid details");
                }

                var result = await createUser({
                  variables: {
                    email: email,
                    name: `${firstName} ${lastName}`,
                    password: password,
                  },
                });

                console.log({ error });

                if (!error) {
                  await Firebase.auth().signInWithEmailAndPassword(
                    email,
                    password
                  );

                  alert("Operation success, account created");
                  window.location.assign(`/ecommerce`);
                }
              }}
            >
              Create Account
            </button>
          </div>
          {error && <div> Error: {error.message} </div>}
        </div>
      </div>
    </div>
  );
};
