import { gql, useQuery } from "@apollo/client";
import Spinner from "../misc/Spinner";

const PRESET_NAMES_QUERY = gql`
  query DevicePresets {
    device_presets {
      id
      vendor
      model_name
      preset_name
    }
  }
`;

export const ChargerPresetSelector = ({ onPresetSelected, preset_id }) => {
  const { data, loading, error } = useQuery(PRESET_NAMES_QUERY, {
    fetchPolicy: "network-only",
  });

  var presets = data?.device_presets;

  if (loading) {
    return <Spinner />;
  }

  return (
    <select
      className="ml-auto border rounded w-2/3 p-2 bg-primary brandPrimaryColor"
      value={preset_id || "none"}
      onChange={(e) => {
        var presetId = e.target.value;
        if (presetId !== "none") {
          var preset = presets.find((x) => x.id === presetId);
          if (preset) {
            onPresetSelected(presetId);
          }
        }
      }}
    >
      <option label="Choose a device preset" value="none" key="label" />
      {presets?.map((x) => (
        <option
          label={`${x.vendor} ${x.model_name} - ${x.preset_name}`}
          value={x.id}
          key={x.id}
        />
      ))}
    </select>
  );
};
