import { loadStripe } from "@stripe/stripe-js";
import { PLAN_COSTS } from "./AdminSignupPlan";
import { useState } from "react";

import { Elements } from "@stripe/react-stripe-js";

import { StripePaymentForm } from "../tenancy/StripePaymentForm";

export const PLAN_TYPES = {
  STANDARD_ANNUAL: "YEARLY",
};

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_SECRET_KEY}`);

export const AddTenancyPayment = ({ subIntentResult, subTerms }) => {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: subIntentResult.clientSecret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripePaymentForm
        subscriptionIntentResult={subIntentResult}
        subscriptionTerms={subTerms}
      />
    </Elements>
  );
};

export const LocationAmountSelect = ({ selectedPlan, onNextPage, onBack }) => {
  var [selectedLocations, updateSelectedLocations] = useState(1);
  return (
    <div>
      <label className=" ml-2">Please select number of locations.</label>
      <div className="flex flex-row my-2 ml-2">
        <input
          className="w-16 border rounded border-black p-1"
          type="number"
          value={selectedLocations}
          // Clamp number from 1-99.
          onChange={(e) =>
            updateSelectedLocations(
              Math.min(Math.max(Number.parseInt(e.target.value), 1), 99)
            )
          }
        />
        <div className="my-auto ml-4 source-sans-pro">{`( = $${calculatePlanCost(
          selectedPlan,
          selectedLocations
        )})`}</div>
      </div>
      <button
        className="ml-2 bg-red-600 text-white py-2 px-4 rounded mt-2 mr-2"
        onClick={(e) => {
          e.preventDefault();
          onBack();
        }}
      >
        Back
      </button>
      <button
        className="mx-auto mt-2 rounded p-2 bg-blue-400 text-white text-md"
        onClick={() => onNextPage(parseInt(selectedLocations))}
      >
        Submit
      </button>
    </div>
  );
};

export function calculatePlanCost(selectedPlan, selectedLocations) {
  if (selectedPlan === PLAN_TYPES.STANDARD_ANNUAL) {
    return `${(selectedLocations * PLAN_COSTS.STANDARD_ANNUAL).toFixed(
      2
    )} + GST - billed annually`;
  } else if (selectedPlan === PLAN_TYPES.STANDARD_MONTHLY) {
    return `${(selectedLocations * PLAN_COSTS.STANDARD_MONTHLY).toFixed(
      2
    )} + GST - billed monthly`;
  }
}
