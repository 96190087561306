import { FaAngleLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";

export const EcommerceHeader = ({ children }) => {
  return (
    <div className="flex flex-row h-20 w-full right-0 left-0 justify-between items-center z-20 absolute bg-primary">
      <Link to="/ecommerce/">
        <FaAngleLeft className="text-white ml-2 blueDropshadow" size={40} />
      </Link>
      <div className="chargerListTitle">
        <span className="align-bottom">{children}</span>
      </div>
      <img src={logo} alt="Logo" className="h-1/2 mr-2" />
    </div>
  );
};
