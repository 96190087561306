import { PLAN_TYPES } from "./AdminSignupPayment";

export const PLAN_COSTS = {
  STANDARD_ANNUAL: 225,
};

export const ChooseTenancyPlan = ({
  quickSignup,
  tenancyCreationInProgress,
  onNextPage,
  onBack,
}) => {
  return (
    <div>
      <h1 className="mx-auto text-center text-xl mb-4">Select a plan</h1>
      <div className="flex flex-row p-2 gap-x-2 justify-center">
        <div
          style={{ width: "40%" }}
          className="flex flex-col rounded p-2 bg-primary border-2 border-black"
        >
          <div className="mx-auto text-md brandPrimaryColor">
            Standard (Annual)
          </div>
          <ul className="text-white text-sm mx-auto my-3 mb-16 list-disc">
            <li>${PLAN_COSTS.STANDARD_ANNUAL} + GST for 1 location</li>
          </ul>
          <button
            className={`mx-auto mb-4 mt-auto rounded p-2 bg-secondary brandSecondaryColor text-md${
              tenancyCreationInProgress ? " opacity-70" : ""
            }`}
            onClick={() => onNextPage(PLAN_TYPES.STANDARD_ANNUAL)}
            disabled={tenancyCreationInProgress}
          >
            {quickSignup
              ? tenancyCreationInProgress
                ? "Creating..."
                : "Create"
              : "Choose"}
          </button>
        </div>
      </div>
      <button
        className="ml-2 bg-red-600 text-white py-2 px-4 rounded mt-2"
        onClick={(e) => {
          e.preventDefault();
          onBack();
        }}
      >
        Back
      </button>
    </div>
  );
};
