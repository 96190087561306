export const PRESET_NAME = "PRESET_NAME";
export const MODEL_NAME = "MODEL_NAME";
export const VENDOR = "VENDOR";
export const ADD_CAPABILITY = "ADD_CAPABILITY";
export const IMAGE = "IMAGE";
export const REMOVE_CAPABILITY = "REMOVE_CAPABILITY";
export const CONNECTORS = "CONNECTORS";
export const CHARGE_POINT_MODELS = "CHARGE_POINT_MODELS";

const INITIAL_STATE = {
  capabilities: [],
  connectors: [],
  charge_point_models: [],
};

export function devicePresetFormReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PRESET_NAME: {
      return {
        ...state,
        preset_name: action.preset_name,
      };
    }
    case MODEL_NAME: {
      return {
        ...state,
        model_name: action.model_name,
      };
    }
    case VENDOR: {
      return {
        ...state,
        vendor: action.vendor,
      };
    }
    case ADD_CAPABILITY: {
      if (
        action.capabilities &&
        action.capabilities !== "none" &&
        state.capabilities?.indexOf(action.capabilities) === -1
      ) {
        return {
          ...state,
          capabilities: [...state.capabilities, action.capabilities],
        };
      }
      break;
    }
    case REMOVE_CAPABILITY: {
      return {
        ...state,
        capabilities: [
          ...state.capabilities.filter((y) => y !== action.capabilities),
        ],
      };
    }
    case CONNECTORS: {
      return {
        ...state,
        connectors: action.connectors,
      };
    }
    case CHARGE_POINT_MODELS: {
      return {
        ...state,
        charge_point_models: action.models,
      };
    }
    case IMAGE: {
      return {
        ...state,
        image: action.image,
      };
    }
  }
  return state;
}
