import React from "react";

import PrivateRoute from "./layout/PrivateRoute";
import PostList from "./locations/List";
import Profile from "./account/Profile";
import ChargingLocationDetail from "./admin/ChargingLocationDetail";
import DeleteForm from "./account/delete";
import LoginForm from "./account/login";

import { SessionsPage } from "./sessions/SessionsPage";
import { LegacySessionsPage } from "./sessions/LegacySessions";
import { MachinesPage } from "./ocpp/MachinesList";
import { MachineDetail } from "./ocpp/SmartMachineDetail";
import { CreateLocation } from "./ocpp/CreateLocation";
import { Tokens } from "./mifare/Cards";
import { UserManager } from "./users/UserManager";
import { Payments } from "../views/payments/Payments";
import { LegacyPayments } from "../views/payments/LegacyPayments";
import { AccountsManager } from "./accounts/AccountsManager";
import { DeviceConfigurationPage } from "./ocpp/DeviceConfiguration";
import { DevicePresets } from "./device_presets/DevicePresets";
import { TariffManager } from "./tariffs/TariffManager";
import { ChargerList } from "./ecommerce/Location";
import { ConnectorSelectedWrapper } from "./ecommerce/ConnectorSelected";
import { CdrList } from "./cdrs/CdrList";
import { MapviewDataLayer } from "./ecommerce/Mapview";
import { AdminSignupPage } from "./whitelabel/AdminSignup";
import { AdminSignupQuick } from "./whitelabel/AdminSignupQuick";
import { OfflineIndicator } from "./layout/Offline";
import { EnrollMFAPage } from "./account/EnrollMFA";
import { TenancyPlanPage } from "./admin/TenantPlans";
import { Switch, Route } from "react-router-dom";
import { Welcome } from "./layout/Welcome";
import { VehiclesPage } from "./ecommerce/driver/UserVehicles";
import { AccountPage } from "./ecommerce/driver/AccountSettings";
import { CreateDriver } from "./ecommerce/driver/CreateDriver";
import { EndUserRFID } from "./ecommerce/driver/EndUserRFID";
import { GroupManager } from "./dlb/GroupManager";
import { BillingPaymentDetails } from "./tenancy/BillingPaymentDetails";
import { PageNotFound } from "./layout/PageNotFound";

const Routes = () => {
  if (!navigator.onLine) {
    return <OfflineIndicator />;
  }
  // Be careful with reordering these - the order of paths with the same prefix DOES matter
  return (
    <Switch>
      <Route exact path="/" component={MapviewDataLayer} />
      <Route path="/delete-account" component={DeleteForm} />
      <Route path="/admin-signup" component={AdminSignupPage} />
      <Route path="/admin-signup-quick" component={AdminSignupQuick} />
      <Route path="/login" component={LoginForm} />
      <Route path="/create-driver" component={CreateDriver} />
      <Route
        path="/ecommerce/:evseId/:connectorId"
        component={ConnectorSelectedWrapper}
      />
      <Route path="/ecommerce/:locationId" component={ChargerList} />
      <Route path="/ecommerce" component={MapviewDataLayer} />

      <Route path="/vehicles" component={VehiclesPage} />
      <Route path="/myaccount" component={AccountPage} />
      <Route path="/myrfid" component={EndUserRFID} />

      {/* Only accessable to admin or sysadmin */}
      <PrivateRoute path="/account" component={Profile} />
      <PrivateRoute path="/enrol-mfa" component={EnrollMFAPage} />
      <PrivateRoute path="/tokens" component={Tokens} />
      <PrivateRoute path="/tenancy" component={UserManager} />
      <PrivateRoute path="/dlb" component={GroupManager} />
      <PrivateRoute
        path="/locations/:slug"
        component={ChargingLocationDetail}
      />
      <PrivateRoute path="/locations" component={PostList} />
      <PrivateRoute path="/createlocation" component={CreateLocation} />
      <PrivateRoute
        path="/machines/:slug/configuration"
        component={DeviceConfigurationPage}
      />
      <PrivateRoute path="/machines/:slug" component={MachineDetail} />
      <PrivateRoute path="/machines" component={MachinesPage} />
      <PrivateRoute path="/device-presets" component={DevicePresets} />
      <PrivateRoute path="/cdrs" component={CdrList} />
      <PrivateRoute path="/tenancyplans" component={TenancyPlanPage} />

      <PrivateRoute path="/sessions/evse/:evse_id" component={SessionsPage} />
      <PrivateRoute
        path="/sessions/location/:loc_id"
        component={SessionsPage}
      />
      <PrivateRoute path="/sessions" component={SessionsPage} />
      <PrivateRoute
        path="/legacysessions/evse/:evse_id"
        component={LegacySessionsPage}
      />
      <PrivateRoute path="/legacysessions" component={LegacySessionsPage} />

      <PrivateRoute path="/legacyAnalytics" component={LegacyPayments} />
      <PrivateRoute
        path="/analytics/location/:location_id"
        component={Payments}
      />
      <PrivateRoute path="/analytics/evse/:evse_id" component={Payments} />
      <PrivateRoute path="/analytics" component={Payments} />

      <PrivateRoute path="/tariffs" component={TariffManager} />
      <PrivateRoute path="/subscribe" component={BillingPaymentDetails} />
      <PrivateRoute path="/accounts" component={AccountsManager} />
      <Route component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
