const initialState = {
  center: {
    lat: -37.840935,
    lng: 144.946457,
  },
  zoom: 11,
};

export const MAPS_CENTER = "MAPS_CENTER";
export const GEOLOCATION = "GEOLOCATION";

export function mapsReducer(state = initialState, action) {
  switch (action.type) {
    case MAPS_CENTER: {
      return {
        ...state,
        center: action.center,
        zoom: action.zoom,
      };
    }
    case GEOLOCATION: {
      return {
        ...state,
        geolocation: action.geolocation,
      };
    }
  }
  return state;
}
