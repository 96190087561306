import { useState } from "react";
import { Modal } from "../layout/Modal";
import { RightModal } from "../layout/rightModal";
import { FlexSubscriptionFlow } from "./SubscriptionFlow";
import { gql, useQuery, useMutation } from "@apollo/client";
import Spinner from "../misc/Spinner";
import { PLAN_TYPES } from "../whitelabel/AdminSignupPayment";
import { PLAN_COSTS } from "../whitelabel/AdminSignupPlan";
import { UpdateBankDetails } from "./UpdateBankDetails";
import moment from "moment";
import { useAuth } from "../../hooks/useAuth";

import { numberWithCommas } from "./SubscriptionFlow";

const TENANCY_PLAN_GQL = gql`
  query GetTenancyPlan {
    getTenancyPlan {
      location_equivalents
      locations_in_use
      ports_in_use
      billing_period
      creation_date
      next_billing_date
      is_trial
      status
      subscription_id
      cancel_at_period_end
    }
  }
`;

const CANCEL_SUB = gql`
  mutation Mutation {
    cancelSubscription
  }
`;

const RESUBSCRIBE = gql`
  mutation Mutation {
    resubscribe
  }
`;

const SUBSCRIPTION_INTENT_GQL = gql`
  mutation SubscriptionIntent(
    $isTrial: Boolean!
    $numLocations: Int
    $billingPeriod: String
  ) {
    subscriptionIntent(
      is_trial: $isTrial
      num_locations: $numLocations
      billing_period: $billingPeriod
    ) {
      subscriptionId
      clientSecret
      errors
    }
  }
`;

export const BillingPaymentDetails = () => {
  const { tenancyState } = useAuth();
  const [showSubscriptionCompleteModal, updateShowSubscriptionCompleteModal] =
    useState(window.location.href.indexOf("complete") > -1);
  const [showModal, updateShowModal] = useState(false);
  const { data, loading, error } = useQuery(TENANCY_PLAN_GQL);
  const [cancelSubscription, cancelResult] = useMutation(CANCEL_SUB);
  const [resubscribe, resubResult] = useMutation(RESUBSCRIBE);
  const [subscriptionIntent, subIntentResult] = useMutation(
    SUBSCRIPTION_INTENT_GQL
  );

  if (
    loading ||
    cancelResult.loading ||
    resubResult.loading ||
    subIntentResult.loading
  ) {
    return <Spinner />;
  }

  if (error) {
    return <h1>{error.toString()}</h1>;
  }
  if (cancelResult.error) {
    return <h1>{cancelResult.toString()}</h1>;
  }
  if (resubResult.error) {
    return <h1>{resubResult.toString()}</h1>;
  }

  console.log("Tenancy Plan: ", data.getTenancyPlan);

  var subWillEnd = (
    <p>
      Subscription will renew on{" "}
      {moment(new Date(data.getTenancyPlan?.next_billing_date)).format(
        "DD-MM-YYYY"
      )}
    </p>
  );
  if (data.getTenancyPlan?.cancel_at_period_end) {
    subWillEnd = (
      <p>
        Note: your subscription will terminate at the end of this billing period
        on{" "}
        {moment(new Date(data.getTenancyPlan.next_billing_date)).format(
          "DD-MM-YYYY"
        )}
      </p>
    );
  }

  var subButton = (
    <button
      className={`ml-4 border rounded px-4 text-white ${
        data.getTenancyPlan?.cancel_at_period_end
          ? "bg-green-500"
          : "bg-red-500"
      }`}
      onClick={async () => {
        var confirm;
        if (data.getTenancyPlan?.cancel_at_period_end) {
          confirm = window.confirm(
            "Are you sure you want to renew your subscription?"
          );
        } else {
          confirm = window.confirm(
            "Are you sure you want to cancel your subscription?"
          );
        }

        if (confirm) {
          var query = data.getTenancyPlan?.cancel_at_period_end
            ? resubscribe
            : cancelSubscription;
          var result = await query();
          console.debug(result);
          alert("Subscription terms updated successfully");
          window.location.reload(false);
        }
      }}
    >
      {data.getTenancyPlan?.cancel_at_period_end
        ? "Renew Subscription"
        : "Unsubscribe"}
    </button>
  );

  var userSubSection = (
    <div>
      <div className="flex flex-row mt-8">
        <h1 className="font-bold text-2xl ">
          Current Subscription - {data.getTenancyPlan?.status}
        </h1>
        {subButton}
      </div>
      <ExistingFlexSubscription
        subscription={{
          tenancyPlan: data.getTenancyPlan,
        }}
      />
      <div className="flex flex-row mt-2">{subWillEnd}</div>
    </div>
  );
  var modalTitle = "Change Flex Subscription Units";

  if (data.getTenancyPlan?.status !== "active") {
    userSubSection = (
      <div className="flex flex-row mt-8">
        <h1 className="font-bold text-2xl ">Current Subscription - None</h1>
        <button
          className={`ml-4 rounded border bg-green-500 px-4 text-white${
            tenancyState && tenancyState.tenancy_tier === "UNLIMITED"
              ? " opacity-50 pointer-events-none"
              : ""
          }`}
          onClick={async () => {
            await subscriptionIntent({
              variables: {
                // subtract the amount of locations they already have from the subscription intent
                isTrial: false,
                numLocations: 1,
                billingPeriod: "YEARLY",
              },
            });
            updateShowModal(true);
          }}
        >
          Subscribe Now
        </button>
      </div>
    );
    modalTitle = "Create Flex Subscription";
  }

  return (
    <div>
      <div id="update-bank" className="fixed z-50 right-0 bottom-0 m-4">
        <RightModal title="Update Bank Details">
          <UpdateBankDetails />
        </RightModal>
      </div>

      <Modal
        onClose={() => updateShowModal(false)}
        isOpen={showModal}
        title={modalTitle}
      >
        <FlexSubscriptionFlow
          subIntentResult={subIntentResult?.data?.subscriptionIntent}
        />
      </Modal>
      <Modal
        onClose={() => updateShowSubscriptionCompleteModal(false)}
        isOpen={showSubscriptionCompleteModal}
        title={"Subscription Confirmed"}
      >
        <h1>Thank you for purchasing the EVUp Charge software.</h1>
      </Modal>
      <h1 className="text-3xl font-bold mb-2">Standard Subscription - Flex</h1>
      {data.getTenancyPlan !== "UNLIMITED" && userSubSection}
    </div>
  );
};

const NoExistingFlexSubscription = ({}) => {};

const ExistingFlexSubscription = ({ subscription }) => {
  const billing_period = subscription?.tenancyPlan?.billing_period;
  const location_equivalents = subscription?.tenancyPlan?.location_equivalents;

  var total_cost;
  if (billing_period === PLAN_TYPES.STANDARD_MONTHLY) {
    total_cost = location_equivalents * PLAN_COSTS.STANDARD_MONTHLY;
  } else if (billing_period === PLAN_TYPES.STANDARD_ANNUAL) {
    total_cost = location_equivalents * PLAN_COSTS.STANDARD_ANNUAL;
  }

  return (
    <div className="border rounded p-2 mt-2">
      <div className="flex flex-row">
        <SummaryItem
          mainCourse={`${subscription?.tenancyPlan?.locations_in_use} / ${subscription?.tenancyPlan?.location_equivalents}`}
          description={"Locations"}
        />
        <SummaryItem
          mainCourse={`${subscription?.tenancyPlan?.ports_in_use} / ${
            subscription?.tenancyPlan?.location_equivalents * 2
          }`}
          description={"Connectors"}
        />
        <h1 className="ml-auto my-auto text-4xl mr-4">
          {`$${numberWithCommas(
            total_cost
          )} + GST - billed ${(subscription?.tenancyPlan?.billing_period).toLowerCase()}`}
        </h1>
      </div>
    </div>
  );
};

const SummaryItem = ({ mainCourse, description }) => {
  return (
    <div className="mr-12">
      <h1 className="text-4xl header font-bold">{mainCourse}</h1>
      <h4>{description}</h4>
    </div>
  );
};
