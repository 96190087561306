import React, { useState } from "react";
import { Link } from "react-router-dom";
import { gql } from "@apollo/client";
import { useTheme } from "../../hooks/useTheme";

import defaultLogo from "../../assets/logo.png";

export const THEME = gql`
  query Account_theme {
    account_theme {
      primary_color
      secondary_color
      logo_url
    }
  }
`;

const Menu = ({ menuProfile }) => {
  const { primaryColor, logo } = useTheme();
  const [selectedItem, updateSelectedItem] = useState(undefined);

  const styles = {
    backgroundColor: primaryColor,
  };

  return (
    <div
      id="header"
      className="relative flex-none flex flex-col justify-start w-1/2 text-white font-bold px-4 max-h-screen"
      style={styles}
    >
      <div className="w-full flex items-center md:block pt-2">
        <Link to="/" className="text-xl	text-center">
          <div className="w-full items-center justify-center flex flex-row">
            <img
              src={logo ?? defaultLogo}
              alt="Logo"
              className="w-32 max-h-48 pt-2 mr-4 py-4"
            />
          </div>
        </Link>
        <Link to="/" className="text-xl text-center py-2">
          <h1 className="font-bold">CHARGE</h1>
        </Link>
        <hr className="block py-1" />
      </div>
      <div className="flex flex-col justify-start">
        {menuProfile.map((x) => (
          <MenuTile
            key={x.name}
            link={x.link}
            name={x.name}
            icon={x.icon}
            selected={selectedItem === x.name}
            onClick={(id) => updateSelectedItem(id)}
          />
        ))}
      </div>
    </div>
  );
};

const MenuTile = ({ link, name, icon, onClick, selected }) => {
  var className = "w-full p-1.5 my-0.5 rounded hover:black";

  if (selected) {
    className += " bg-primary-dark";
  }

  return (
    <div className={className}>
      <Link to={link} onClick={() => onClick(name)}>
        <div
          id={`${name}-menu-option`}
          className="text-center flex items-center ml-1 hover:red"
        >
          {icon}
          <span>{name}</span>
        </div>
      </Link>
    </div>
  );
};

export default Menu;
