import { useState } from "react";
import { FirestoreDocument } from "react-firestore";
import { startCommand } from "../../actions/machines";
import { updateTariff } from "../../actions/tariffs";
import Spinner from "../misc/Spinner";

export const DeviceConfigurationPage = ({ match, history }) => {
  const id = match.params.slug;
  console.debug(id);

  return <DeviceInformationWrapper id={id} />;
};

//This fetches information about the device from the database.
const DeviceInformationWrapper = ({ id }) => {
  return (
    <FirestoreDocument path={`ocpp_logs/${id}`}>
      {({ error, isLoading, data }) => {
        if (isLoading) {
          return <Spinner />;
        }

        return <DeviceConfigurationManager id={id} evse={data} />;
      }}
    </FirestoreDocument>
  );
};

const DeviceConfigurationManager = ({ id, evse }) => {
  var displayName = null;
  var deviceConfiguration = null;

  if (evse.metadata?.displayName) {
    displayName = (
      <h1 className="text-lg font-bold">Device: {evse.metadata.displayName}</h1>
    );
  }

  if (evse.config) {
    deviceConfiguration = (
      <DeviceConfigIssuer
        configuration={evse.config.configurationKey}
        id={id}
      />
    );
  }

  return (
    <div>
      {displayName}
      <h1 className="text-lg font-bold">Configure Device id: {id}</h1>
      {deviceConfiguration}
    </div>
  );
};

//Issue commands to the device
const DeviceConfigIssuer = ({ configuration, id }) => {
  return (
    <div className="rounded bg-white border mt-2 p-2">
      <h1>Issue Device Commands:</h1>
      {configuration.map((x) => (
        <DeviceConfigElement evse_id={id} key={x.key} configObject={x} />
      ))}
    </div>
  );
};

const CONFIG_VALUE_TYPES = ["string", "number"];

const DeviceConfigElement = ({ configObject, evse_id }) => {
  const [value, updateValue] = useState(configObject.value);
  const [type, updateType] = useState("string");

  return (
    <div className="p-2 mt-2 border rounded">
      <h1 className="font-bold text-lg">
        {configObject.key} {configObject.readonly ? "(read only)" : ""}
      </h1>
      <h1>Value: {configObject.value}</h1>
      <div className="flex flex-row mt-2">
        <h1>Type:</h1>
        <select
          className="border rounded ml-2"
          value={type}
          onChange={(e) => {
            updateType(e.target.value);
          }}
        >
          {CONFIG_VALUE_TYPES.map((x) => (
            <option value={x} label={x} />
          ))}
        </select>
      </div>
      <div className="mt-2 p-2">
        <input
          className="border rounded"
          value={value}
          onChange={(e) => updateValue(e.target.value)}
        />
        <button
          className="bg-blue-400 text-white py-1 px-2 rounded ml-2"
          onClick={() => {
            var command = {
              key: configObject.key,
              value:
                type === "number" ? Number.parseInt(value) : value.toString(),
            };
            console.debug(
              type,
              configObject.key,
              command["value"],
              typeof command["value"]
            );
            startCommand(evse_id, "ChangeConfiguration", command);
          }}
        >
          Update
        </button>
      </div>
    </div>
  );
};
