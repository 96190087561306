import React, { useEffect, useLayoutEffect, useRef } from "react";
import Spinner from "../misc/Spinner";
import MediaQuery from "react-responsive";
import Moment from "react-moment";

import { Link } from "react-router-dom";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { RiEqualizerFill } from "react-icons/ri";
import { TbPlugConnected } from "react-icons/tb";
import { FaBolt } from "react-icons/fa";
import { CgUnavailable } from "react-icons/cg";
import { gql, useSubscription } from "@apollo/client";
import { isMobile } from "react-device-detect";
import { STANDARD_TO_TITLE, TYPES, ALLOWED_TYPES } from "./LocationMetadata";
import { CloseButton, GetChargingRate } from "./EcommerceModal";
import { EcommerceHeader } from "../../components/EcommerceHeader";
import { useAuth } from "../../hooks/useAuth";

const STATUS_DISPLAYED = {
  Charging: "Charging",
  Preparing: "Ready",
  Reserved: "Reserved",
  SuspendedEV: "Car Full",
  SuspendedEVSE: "Stopped",
  Finishing: "Finishing",
  Unavailable: "Unavailable",
  Faulted: "Faulted",
  Available: "Plugin To Start",
  Unknown: "Unavailable",
};

const LOCATION_SESSIONS = gql`
  subscription SubLocationSessions($locationId: String!) {
    subLocationSessions(location_id: $locationId) {
      id
      status
      type
      party_id
      connector_number
      evse_id
      location_id
      tariff {
        country_code
        party_id
        id
        currency
        type
        tariff_alt_text {
          language
          text
        }
        elements {
          price_components {
            type
            price
            vat
            step_size
          }
        }
        last_updated
      }
      start_time
      last_modified
      fulfilled
      kwh
      kw
      phases {
        start_time
        last_modified
        type
        kwh
        cost
      }
      user_id
      last_meterupdate
      end_time
      error
      soc
      total_cost
      terminated_time
      terminated_user_id
    }
  }
`;

const LOCATION = gql`
  subscription LocationsApiSingle($locationsApiSingleId: String!) {
    locationsApiSingle(id: $locationsApiSingleId) {
      party_id
      id
      name
      address
      city
      post_code
      soft_blocked
      coordinates {
        lat
        lng
      }
      country
      evses {
        connected
        id
        name
        charge_point_model
        soft_blocked
        vendor
        charging_type
        tariff {
          country_code
          party_id
          id
          currency
          type
          tariff_alt_text {
            language
            text
          }
          tariff_alt_url
          elements {
            price_components {
              type
              price
              vat
              step_size
            }
          }
          start_date_time
          end_date_time
          last_updated
        }
        capabilities
        connectors {
          status
          id
          standard
          format
          power_type
          voltage
          max_amperage
          current_amperage
          session_id
          physical_reference
        }
        last_heartbeat
        last_updated
        authLevel
      }
      time_zone
      images {
        url
        thumbnail
        category
        type
        width
        height
      }
      facilities
      parking_type
      created
      last_updated
    }
  }
`;

export const ChargerList = ({ match }) => {
  const { tenancyState } = useAuth();
  var location_id = match.params.locationId;
  const { error, loading, data } = useSubscription(LOCATION, {
    variables: {
      locationsApiSingleId: location_id,
      source: "ECOMMERCE",
    },
  });
  const sessionSubscription = useSubscription(LOCATION_SESSIONS, {
    variables: {
      locationId: location_id,
    },
  });

  const chargersContainerRef = useRef(null);

  useLayoutEffect(() => {
    if (chargersContainerRef.current) {
      const subscriptionBannerNode = document.getElementsByClassName(
        "subscription-banner"
      )[0];
      chargersContainerRef.current.style.height = `calc(100vh - ${
        subscriptionBannerNode ? subscriptionBannerNode.offsetHeight : 0
      }px)`;
    }
  }, [chargersContainerRef, tenancyState]);

  useEffect(() => {
    // If on mobile, redirect to mobile app
    if (isMobile) {
      window.location.replace(`charge://ecommerce/${location_id}`);
    }
  }, []);

  /* Loading / error checking and assignments */
  if (loading || sessionSubscription.loading) {
    return <Spinner />;
  }
  if (error || sessionSubscription.error) {
    return <p>Error {`${sessionSubscription.error}`}</p>;
  }
  const locationData = data.locationsApiSingle;
  const statusData = locationData.evses.map((x) => {
    return {
      chargingType: x.charging_type,
      uid: x.id,
      connectors: x.connectors.map((y) => {
        return {
          connectorId: y.id,
          status: y.status,
          sessionStatus: y.session_id ? "CONTRACT_IN_PROGRESS" : "NO_CONTRACT",
        };
      }),
    };
  });
  const sessionData = sessionSubscription.data.subLocationSessions;

  return (
    <div id="charger-list" className="w-full sm:flex overflow-y-auto">
      <div ref={chargersContainerRef} className="bg-white w-full overflow-auto">
        <EcommerceHeader>SELECT CHARGER</EcommerceHeader>
        <div className="cardSection mt-20 mb-32 mx-2">
          {getLocationChargers(locationData, statusData, sessionData).map(
            (x) => (
              <ChargerMeta
                key={x.key}
                device={x.device}
                connector={x.connector}
                sessionStatus={x.sessionStatus}
                connectorSession={x.session}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

function getSessionForConnector(sessionData, connectorId, evseId) {
  if (!sessionData) {
    return null;
  }
  /* Check if this connector has a session */
  for (var session of sessionData) {
    if (
      parseInt(connectorId) === session.connector_number &&
      evseId === session.evse_id
    ) {
      console.debug(evseId, connectorId, "HAS SESSION");
      return session;
    }
  }
}

function getLocationChargers(locationData, statusData, sessionData) {
  var chargers = [];
  /* Get every an array of every charger card, including status and session if exists */
  for (let device of locationData.evses) {
    for (let connector of device?.connectors) {
      var connectorSession = null;
      var status = "Unknown";
      var sessionStatus = "NO_CONTRACT";

      if (statusData.length > 0) {
        /* Append session subscription */
        if (sessionData.length > 0) {
          connectorSession = getSessionForConnector(
            sessionData,
            connector.id,
            device.id
          );
        }

        /* Append the status subscription */
        var connectorStatus = statusData
          .find((subbedEvse) => {
            return device.id === subbedEvse.uid;
          })
          .connectors?.find((subbedConnector) => {
            return subbedConnector.connectorId === connector.id;
          });
        status = connectorStatus?.status;
        sessionStatus = connectorStatus?.sessionStatus;
      }

      /* Push the component */
      if (ALLOWED_TYPES.includes(connector.standard)) {
        chargers.push({
          key: chargers.length + 1,
          device: device,
          connector: connector,
          status: status,
          sessionStatus: sessionStatus,
          session: connectorSession,
        });
      }
    }
  }
  return chargers.sort(sortConnectors);
}

export const ChargerMeta = ({
  device,
  connector,
  sessionStatus,
  connectorSession,
}) => {
  var displayName = device.name;
  if (!displayName) {
    displayName = device.id;
  }

  return (
    <div className="metadataCard border-2 border-black inline-block mx-4 mb-2 overflow-y-hidden">
      <div>
        <div className="flex flex-row justify-start mt-2 ml-2 mr-4 gap-x-1.5">
          <img
            src={TYPES[STANDARD_TO_TITLE[connector.standard]].logo}
            alt={TYPES[STANDARD_TO_TITLE[connector.standard]].title}
            className="h-12"
          />
          <div className="chargerPortTitle my-1 py-auto py-2 rounded-lg">
            <p className="ml-2">{displayName}</p>
          </div>
        </div>
        <div className="flex flex-row mx-3 justify-evenly">
          <MediaQuery minWidth={400}>
            <ChargerOCPPInfo device={device} connector={connector} />
          </MediaQuery>
          <ChargerEcomInfo device={device} connector={connector} />
          <ActivateSection
            connector={connector}
            logonRequired={device.authLevel > 0}
            sessionStatus={sessionStatus}
            connectorSession={connectorSession}
            path={`/ecommerce/${device.id}/${connector.id}`}
          />
        </div>
      </div>
    </div>
  );
};

// The props of these components are disorganised.
// They were built when we had less experience and deserve a rework later.
const ActivateSection = ({
  connector,
  logonRequired,
  sessionStatus,
  connectorSession,
  path,
}) => {
  // Show session link if there is an existing contract
  var loggedIn = !!useAuth().uid;
  var seeMore = null;
  if (sessionStatus === "CONTRACT_IN_PROGRESS") {
    seeMore = (
      <Link to={path} className="chargerCardDataCategory underline">
        See More
      </Link>
    );
  }

  // Show session data if a session is in progress
  if (connectorSession) {
    return (
      <div className="chargerEcomInfo p-2 w-full mr-3 border border-solid mb-1 border-black rounded">
        <span className="chargerCardDataCategory">SESSION</span>
        <br />
        Gained {connectorSession.kwh.toFixed(3)} kWh
        <br />
        Duration{" "}
        <Moment
          date={new Date(connectorSession.start_time)}
          format="hh:mm:ss"
          interval={1000}
          durationFromNow
        />
        <br />
        {seeMore}
      </div>
    );
  }

  /* Conditionally render start button or sub endUser session */
  switch (connector.status) {
    case "Preparing": {
      if (logonRequired && !loggedIn) {
        return (
          <button className="chargerStartButton w-full text-md h-16 ml-auto mr-3 mt-4">
            <Link to={"/login"} className="opacity-70">
              Login Required
            </Link>
          </button>
        );
      } else {
        return (
          <button className="chargerStartButton w-full h-12 text-lg ml-auto mr-3 mt-4">
            <Link to={path}>Start</Link>
          </button>
        );
      }
    }
    case "Reserved":
    case "Unavailable":
    case "Faulted": {
      var icon = <CgUnavailable color="#2fdce1" size={40} />;
      break;
    }
    case "Available": {
      var icon = <TbPlugConnected color="#2fdce1" size={40} />;
      break;
    }
    default: {
      var icon = <FaBolt color="#2fdce1" size={35} />;
    }
  }

  var textsize = connector.status === "Available" ? "text-sm" : "text-base";

  return (
    <div className="flex flex-col gap-y-1 w-full justify-center">
      <div className="mx-auto">{icon}</div>
      <p className={`text-center brandPrimaryColor ${textsize}`}>
        {STATUS_DISPLAYED[connector.status]}
      </p>
    </div>
  );
};

function GetParkingRate(tariff) {
  var parkingCost = "$FREE";
  if (!tariff) return parkingCost;
  for (var element of tariff.elements) {
    for (var price_component of element.price_components) {
      if (price_component.type === "PARKING_TIME") {
        if (price_component.price > 1) {
          parkingCost = `$${price_component.price}/hour`;
        } else {
          parkingCost = `${100 * price_component.price}c/hour`;
        }
      }
    }
    if (parkingCost) break;
  }
  return parkingCost;
}

const ChargerEcomInfo = ({ device, connector }) => {
  const power_type = connector.power_type;
  var max_power = (connector.voltage * connector.max_amperage) / 1000;

  if (power_type === "AC_3_PHASE") {
    max_power *= 3;
  }
  /* INCLUDE AVAILABLE HOURS */

  return (
    <div className="chargerEcomInfo w-full p-2 mr-3 border border-solid mb-1 border-black rounded">
      <span className="chargerCardDataCategory">PRICING</span>
      <br />
      <div className="w-full">
        <span>Max Rate {max_power}kW</span> <br />
        Charging {GetChargingRate(device.tariff)}
      </div>
    </div>
  );
};

const ChargerOCPPInfo = ({ connector }) => {
  return (
    <div className="chargerEcomInfo py-2 pl-2 w-full">
      <span className="chargerCardDataCategory text-sm underline">
        {TYPES[STANDARD_TO_TITLE[connector.standard]].title.toUpperCase()}
      </span>{" "}
      <ul className="list-disc ml-4">
        <li key="Connector">{`Connector ${connector.id}`}</li>
        <li key="Powertype">{connector.power_type.replaceAll("_", " ")}</li>
        <li key="Format">{connector.format}</li>
      </ul>
    </div>
  );
};

function sortConnectors(a, b) {
  const StatusPriority = {
    Available: 4,
    Reserved: 2,
    Preparing: 3,
    Finishing: 5,
    Charging: 1,
    SuspendedEV: 6,
    SuspendedEVSE: 7,
    Unavailable: 8,
  };
  var connectorA = a.connector;
  var connectorB = b.connector;
  if (!Object.keys(StatusPriority).includes(connectorA.status)) {
    return 1;
  } else if (!Object.keys(StatusPriority).includes(connectorB.status)) {
    return -1;
  } else {
    return (
      StatusPriority[connectorA.status] - StatusPriority[connectorB.status]
    );
  }
}
