import { FaCheck, FaExclamationCircle } from "react-icons/fa";

/*
 *   A pure component that shows OCPP machine
 *   status, is it currently connected to server?
 *   or not.
 */
const MachineStatus = ({ active }) => {
  if (active) {
    return (
      <p>
        <FaCheck size="25" className="inline text-green-600 mr-2" />
        Connected!
      </p>
    );
  }
  return (
    <p>
      <FaExclamationCircle size="25" className="inline text-red-600 mr-2" />
      Connection lost!
    </p>
  );
};

export default MachineStatus;
