import { PresetSelect } from "./PresetSelect";
import { ConnectDevice } from "./ConnectDeviceSlide";
import { LinkLocation } from "./LinkLocationSlide";
import { Stepper } from "react-form-stepper";
import { useState } from "react";
import { FaWindowClose } from "react-icons/fa";
import { StylableModal } from "../../../components/Modals";
import { gql, useMutation } from "@apollo/client";

import Spinner from "../../misc/Spinner";

const ENROLMENT_STEPPER = {
  FIND_PRESET: 0,
  CONNECT_OCPP: 1,
  SELECT_LOCATION: 2,
  SUBMITTED: 3,
};

const ENROL_DEVICE = gql`
  mutation EnrolDeviceWithPreset(
    $presetId: String!
    $evseId: String!
    $locationId: String!
  ) {
    enrolDeviceWithPreset(
      preset_id: $presetId
      evse_id: $evseId
      location_id: $locationId
    )
  }
`;

export const OCPPEnrolment = ({ onClose, showModal }) => {
  const [step, updateStep] = useState(0);
  const [selectedPreset, updateSelectedPreset] = useState(undefined);
  const [chargePointId, updateChargePointId] = useState(undefined);
  const [enrolDeviceWithPreset, enrolmentResult] = useMutation(ENROL_DEVICE);

  if (!showModal) return null;
  if (enrolmentResult.loading) return <Spinner />;
  if (enrolmentResult.error)
    return <p>Error pertaining to enrolment mutation</p>;

  var currentStep;
  switch (step) {
    case ENROLMENT_STEPPER.FIND_PRESET: {
      currentStep = (
        <PresetSelect
          selectedPreset={selectedPreset}
          updateSelectedPreset={updateSelectedPreset}
          onNext={() => {
            if (selectedPreset) updateStep(step + 1);
          }}
        />
      );
      break;
    }
    case ENROLMENT_STEPPER.CONNECT_OCPP: {
      currentStep = (
        <ConnectDevice
          onNext={(id) => {
            updateChargePointId(id);
            updateStep(step + 1);
          }}
        />
      );
      break;
    }
    case ENROLMENT_STEPPER.SELECT_LOCATION: {
      currentStep = (
        <LinkLocation
          onNext={async (location_id) => {
            console.log("Enrolling device.");
            var result = await enrolDeviceWithPreset({
              variables: {
                evseId: chargePointId,
                locationId: location_id,
                presetId: selectedPreset.id,
              },
            });

            if (result?.data?.enrolDeviceWithPreset) {
              alert("Device successfully enrolled");
              window.location.reload(false);
            }
          }}
        />
      );
      break;
    }
    case ENROLMENT_STEPPER.SUBMITTED: {
      return null;
    }
  }
  return (
    <StylableModal
      isOpen={showModal}
      header={
        <DeviceEnrolmentHeader
          title={"Enrol OCPP Device"}
          onClose={() => onClose()}
          step={step}
        />
      }
    >
      <div className="width_35_rem">{currentStep}</div>
    </StylableModal>
  );
};

const DeviceEnrolmentHeader = ({ title, onClose, step }) => {
  return (
    <div className="width_35_rem flex flex-row gap-x-4">
      <h1 className="py-1 px-6 whitespace-nowrap h-auto rounded mr-auto text-center text-xl bg-primary brandPrimaryColor font-bold mt-1">
        {title}
      </h1>
      <div className="h-8 relative bottom-4">
        <Stepper
          steps={[
            { label: "", active: step === 0, completed: step >= 0 },
            { label: "", active: step === 1, completed: step >= 1 },
            { label: "", active: step === 2 },
          ]}
          activeStep={step}
          styleConfig={{
            activeTextColor: "#2fedc1",
            inactiveTextColor: "#FFF",
            activeBgColor: "#333",
            inactiveBgColor: "#333",
            completedBgColor: "#333",
            completedTextColor: "#2fedc1",
          }}
        />
      </div>
      <button className="ml-auto rounded" onClick={onClose}>
        <FaWindowClose size={25} color="red" />
      </button>
    </div>
  );
};
