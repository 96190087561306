import appstoreBadge from "../assets/appstore-badge.svg";
import playstoreBadge from "../assets/google-play-badge.png";

export const DownloadApp = () => {
  return (
    <div className="fixed w-5/6 bottom-0 z-100 flex flex-row h-18 items-center justify-start">
      <div className="ml-3"></div>
      <a
        className="badge-button-apple"
        id="apple-link"
        href="https://apps.apple.com/sg/app/upcharge/id1636227214"
      >
        <img className="h-full" src={appstoreBadge} />
      </a>
      <a
        className="badge-button-android"
        id="android-link"
        href="https://play.google.com/store/apps/details?id=com.evup.charge"
      >
        <img className="h-full" src={playstoreBadge} />
      </a>
    </div>
  );
};
