import { useState } from "react";
import { Stepper } from "react-form-stepper";
import moment from "moment";
import { CompanyInfo } from "./AdminSignupCompany";
import { UserInfo } from "./AdminSignupUser";
import { ChooseTenancyPlan } from "./AdminSignupPlan";

import { gql, useMutation } from "@apollo/client";

import { Redirect } from "react-router";
import { AddTenancyPayment, LocationAmountSelect } from "./AdminSignupPayment";

import { loginWithToken } from "../../middleware/loginMiddleware";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import Spinner from "../misc/Spinner";
import { DeviceCheck } from "./SignupDeviceCheck";

const CREATE_TENANCY = gql`
  mutation CreateLiteTenancy(
    $companyName: String!
    $abn: String!
    $address: String!
    $postcode: String!
    $contactNumber: String!
    $state: String!
    $partyId: String!
    $evseId: String!
    $email: String!
    $password: String!
    $name: String!
    $subscriptionIntent: TenancySubscriptionIntent
    $bsb: String
    $accountNumber: String
    $startDate: String
    $endDate: String
  ) {
    createLiteTenancy(
      company_name: $companyName
      ABN: $abn
      address: $address
      postcode: $postcode
      contact_number: $contactNumber
      state: $state
      party_id: $partyId
      evse_id: $evseId
      email: $email
      password: $password
      name: $name
      subscription_intent: $subscriptionIntent
      bsb: $bsb
      account_number: $accountNumber
      start_date: $startDate
      end_date: $endDate
    ) {
      subscriptionId
      clientSecret
      signinToken
      errors
    }
  }
`;

//create a centered wrapper component
export const AdminSignupPage = ({ quick }) => {
  const quickSignup = !!quick;

  return (
    <div className="bg-primary overflow-auto w-full flex flex-row justify-center items-center">
      <div className="mb-8">
        <div className="mt-8 bg-white border-2 rounded-2xl p-4 border-black">
          <AdminSignupHeader quickSignup={quickSignup} />
          <SignupFlow quickSignup={quickSignup} />
        </div>
      </div>
    </div>
  );
};

/*
 *  Right now, only the bottom
 *  4 are used.
 */
const COMPANY_INFO = 0;
const USER_INFO = 1;
const DEVICE_CHECK = 2;
const COMPANY_PLAN = 3;
// This step is only visited if user doesnt select free trial
const ADD_PAYMENT = 4;
const CREATED = 5;
const REDIRECT = 6;

const ACTION_COMPANY_INFO = "ACTION_COMPANY_INFO";
const ACTION_USER_INFO = "ACTION_USER_INFO";
const ACTION_TENANCY_PLAN = "ACTION_TENANCY_PLAN";
const ACTION_NEXT_STEP = "ACTION_NEXT_STEP";
const ACTION_REQUIRE_PAYMENT = "ACTION_REQUIRE_PAYMENT";
const ACTION_BACK_STEP = "ACTION_BACK_STEP";
const ACTION_LOCATION_COUNT = "ACTION_LOCATION_COUNT";

const signupInfoReducer = (action, current_state) => {
  switch (action.type) {
    case ACTION_COMPANY_INFO: {
      return {
        ...current_state,
        company_info: action.payload,
        current_step: current_state.current_step + 1,
      };
    }
    case ACTION_USER_INFO: {
      return {
        ...current_state,
        user_info: action.payload,
        current_step: current_state.current_step + 1,
      };
    }
    case ACTION_NEXT_STEP: {
      return {
        ...current_state,
        current_step: current_state.current_step + 1,
      };
    }
    case ACTION_REQUIRE_PAYMENT: {
      console.debug(current_state);

      var new_state = {
        client_secret: action.payload.client_secret,
        sign_in_token: action.payload.sign_in_token,
        subscription_id: action.payload.subscription_id,
        current_step: current_state.current_step + 1,
        billing_period: current_state.subscription_intent.billing_period,
        num_locations: current_state.subscription_intent.num_locations,
        is_trial: current_state.subscription_intent.is_trial,
        processed_creation: true,
      };

      console.debug(new_state);

      return new_state;
    }
    case ACTION_TENANCY_PLAN: {
      // If the user selects the free plan, we step
      // past the location count selector.
      var is_free = action.payload === "FREE";

      var new_state = {
        ...current_state,
        plan_type: action.payload,
        subscription_intent: {
          is_trial: is_free,
          num_locations: is_free ? 1 : 1,
          billing_period: is_free ? "MONTHLY" : action.payload,
        },
        ready: is_free,
      };

      return new_state;
    }
    case ACTION_LOCATION_COUNT: {
      var new_state = {
        ...current_state,
        ready: true,
      };
      new_state.subscription_intent.num_locations = action.payload;
      return new_state;
    }
    case ACTION_BACK_STEP: {
      return {
        ...current_state,
        current_step: current_state.current_step - 1,
      };
    }
    default: {
      return current_state;
    }
  }
};

const SignupFlow = ({ quickSignup }) => {
  const [current_state, updateState] = useState({
    current_step: 0,
    created: false,
    ready: false,
  });
  const [quickTenancyCreationInProgress, setQuickTenancyCreationInProgress] =
    useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // When the state goes to ready (for payment),
    // It's time to process the creation request etc.
    console.log("Creation processed?", current_state.processed_creation);
    if (!current_state.ready || current_state.processed_creation) {
      return;
    }

    console.log(`State is ready and unprocessed, procesing creation request.`);
    processTenancyCreation().then(async (result) => {
      if (result) {
        var { clientSecret, signinToken, subscriptionId } = result;
        console.log("tenancy creation result:", result);

        // If it's a quick signup process, then the payment step is not required, and we can redirect the user to the locations page
        if (quickSignup) {
          //Log the user in.
          dispatch(loginWithToken(signinToken)).then(() => {
            // Wait for the user data to be fetched so that private routes can be accessible for navigation
            // TODO: Refactor this code
            setTimeout(() => {
              updateState({
                ...current_state,
                current_step: REDIRECT,
              });
            }, 200);
          });
        } else {
          //Log the user in.
          dispatch(loginWithToken(signinToken));

          updateState(
            signupInfoReducer(
              {
                type: ACTION_REQUIRE_PAYMENT,
                payload: {
                  client_secret: clientSecret,
                  sign_in_token: signinToken,
                  subscription_id: subscriptionId,
                },
              },
              current_state
            )
          );
        }
      }
    });
  }, [current_state.ready]);

  const [createLiteTenancy, { loading, error, data }] =
    useMutation(CREATE_TENANCY);

  console.log("mutation errors:", error);

  const processTenancyCreation = async () => {
    console.log("processing creation with state", current_state);
    const { company_info, user_info, subscription_intent, evse_id } =
      current_state;
    const options = {
      variables: {
        companyName: company_info.companyname,
        abn: company_info.abn + "",
        address: company_info.address,
        postcode: company_info.postcode,
        contactNumber: company_info.phonenumber + "",
        state: company_info.state,
        partyId: company_info.party_id,
        email: user_info.email,
        password: user_info.password,
        name: user_info.name,
        evseId: evse_id,
        subscriptionIntent: subscription_intent,
        bsb: company_info.bsb + "",
        accountNumber: company_info.account_number + "",
        // If it's a quick signup process, then it's an evup-managed payment, and we can set the subscription dates for the new lite tenancy account
        ...(quickSignup &&
          (() => {
            setQuickTenancyCreationInProgress(true);

            const currentDate = new Date().toISOString().split("T")[0];
            // One year from the current date
            const targetDate = moment(new Date(currentDate))
              .add(1, "Y")
              .toISOString()
              .split("T")[0];
            return {
              startDate: currentDate,
              endDate: targetDate,
            };
          })()),
      },
    };
    try {
      var result = await createLiteTenancy(options);
      console.log(
        "completed mutation with result",
        result?.data?.createLiteTenancy
      );
      return result?.data?.createLiteTenancy;
    } catch (err) {
      setQuickTenancyCreationInProgress(false);
      return;
    }
  };

  if (loading)
    return (
      <div className="flex flex-col m-2 p-2 items-center align-middle">
        <h1>Creating your tenancy...</h1>
        <Spinner />
      </div>
    );
  if (error) return `Submission error! ${error.message}`;

  var currentPage = null;
  switch (current_state.current_step) {
    case COMPANY_INFO: {
      currentPage = (
        <CompanyInfo
          onNextPage={async (companyInfo) =>
            updateState(
              await signupInfoReducer(
                {
                  type: ACTION_COMPANY_INFO,
                  payload: companyInfo,
                },
                current_state
              )
            )
          }
        />
      );
      break;
    }
    case USER_INFO: {
      currentPage = (
        <UserInfo
          onNextPage={async (userInfo) =>
            updateState(
              await signupInfoReducer(
                {
                  type: ACTION_USER_INFO,
                  payload: userInfo,
                },
                current_state
              )
            )
          }
          onBack={() =>
            updateState({
              ...current_state,
              current_step: current_state.current_step - 1,
            })
          }
        />
      );
      break;
    }
    case DEVICE_CHECK: {
      currentPage = (
        <DeviceCheck
          onNextPage={(evse_id) => {
            updateState({
              ...current_state,
              evse_id,
              current_step: current_state.current_step + 1,
            });
          }}
          onBack={() =>
            updateState({
              ...current_state,
              current_step: current_state.current_step - 1,
            })
          }
        />
      );
      break;
    }
    case COMPANY_PLAN: {
      currentPage = (
        <ChooseTenancyPlan
          quickSignup={quickSignup}
          tenancyCreationInProgress={quickTenancyCreationInProgress}
          onNextPage={(plan_type) => {
            var new_state = signupInfoReducer(
              {
                type: ACTION_TENANCY_PLAN,
                payload: plan_type,
              },
              current_state
            );

            updateState({ ...new_state, ready: true });
          }}
          onBack={() =>
            updateState({
              ...current_state,
              current_step: current_state.current_step - 1,
            })
          }
        />
      );
      break;
    }
    case ADD_PAYMENT: {
      currentPage = (
        <AddTenancyPayment
          subIntentResult={{
            clientSecret: current_state.client_secret,
          }}
          subTerms={{
            billing_period: "YEARLY", //current_state.billing_period,
            num_locations: 1, // current_state.num_locations,
            is_trial: false,
          }}
        />
      );
      break;
    }
    case CREATED: {
      return "Signing you in...";
    }
    case REDIRECT: {
      return <Redirect to="/locations" />;
    }
  }
  return (
    <div>
      <Stepper
        steps={[
          { label: "Enter Business Information" },
          { label: "Enter Your Information" },
          { label: "Enter your Device" },
          { label: "Pricing" + (quickSignup ? " (Info)" : "") },
        ]}
        activeStep={mapCurrentStepToProgressBar(current_state.current_step)}
        styleConfig={{
          activeBgColor: "#63b3ed",
          completedBgColor: "#059669",
        }}
      />
      {currentPage}
    </div>
  );
};

function mapCurrentStepToProgressBar(current_step) {
  const map = {};

  map[COMPANY_INFO] = 0;
  map[USER_INFO] = 1;
  map[DEVICE_CHECK] = 2;
  map[COMPANY_PLAN] = 3;
  map[ADD_PAYMENT] = 3;
  map[CREATED] = 3;
  map[REDIRECT] = 3;

  return map[current_step];
}

const AdminSignupHeader = ({ quickSignup }) => {
  return (
    <div className="text-center bg-primary brandPrimaryColor p-2 rounded text-3xl font-bold">
      Create Tenancy{quickSignup && " (Quick)"}
    </div>
  );
};

//create a component that allows users to upload a logo and set a color palette for their dashboard
const Customize = ({ onNextPage, onBack }) => {
  return (
    <div className="">
      <p className="text-center">
        This step is optional. <br />
        Click next to use EVUp default color palette and logos.
      </p>
      <form className="mt-2">
        <h1>Upload Logo</h1>
        <div className="flex flex-row">
          <label className="w-1/4">Logo</label>
          <input type="file" id="logo" />
        </div>
        <h1 className="mt-2 text-center">
          Choose a color palette to use for your dashboard. <br />
          You can change the colors later in the settings page. <br />
          Enter the color in hexidecimal format, <br /> beginning with a hash,
          for example: #03aaf9 <br />
          Alternatively, click next to use EVUP default color palette.
        </h1>
        <div className="flex flex-row mt-2">
          <label className="w-1/4">Primary Color</label>
          <input type="text" id="primary_color" placeholder="Primary Color" />
        </div>
        <div className="flex flex-row mt-2">
          <label className="w-1/4">Secondary Color</label>
          <input
            type="text"
            id="secondary_color"
            placeholder="Secondary Color"
          />
        </div>
        <button
          className="bg-blue-400 text-white py-2 px-4 rounded mt-2"
          onClick={async (e) => {
            e.preventDefault();
            onNextPage();
          }}
        >
          Next
        </button>

        <button
          className="ml-2 bg-red-600 text-white py-2 px-4 rounded mt-2"
          onClick={(e) => {
            e.preventDefault();
            onBack();
          }}
        >
          Back
        </button>
      </form>
    </div>
  );
};

//create a component that allows users to check with a checkbox which features they wish to pay for
//there should be bronze silver and gold tiers
const Features = ({ onSubmit, onBack }) => {
  return (
    <div className="">
      <p className="text-center">Click next to use EVUP default features.</p>
      <form className="mt-2">
        <h1>Select Features</h1>
        <div className="flex flex-row">
          <label className="w-1/4">Bronze</label>
          <input type="checkbox" id="bronze" />
        </div>
        <div className="flex flex-row">
          <label className="w-1/4">Silver</label>
          <input type="checkbox" id="silver" />
        </div>
        <div className="flex flex-row">
          <label className="w-1/4">Gold</label>
          <input type="checkbox" id="gold" />
        </div>
        <button
          type="submit"
          className="bg-blue-400 text-white py-2 px-4 rounded mt-2"
          onClick={async (e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          Submit
        </button>
        <button
          className="ml-2 bg-red-600 text-white py-2 px-4 rounded mt-2"
          onClick={(e) => {
            e.preventDefault();
            onBack();
          }}
        >
          Back
        </button>
      </form>
    </div>
  );
};
