import { ButtonlessModal, ActionButton } from "../layout/rightModal";
import { DevicePresetFormContainer } from "./DevicePresetForm";
import { DevicePresetCard } from "./DevicePreset";
import { gql, useQuery } from "@apollo/client";
import { useState } from "react";

import Spinner from "../misc/Spinner";

const DEVICE_PRESETS_QUERY = gql`
  query Device_presets {
    device_presets {
      capabilities
      connectors {
        id
        standard
        format
        power_type
        max_voltage
        max_amperage
        max_electric_power
        tariff_ids
        terms_and_conditions
        last_updated
      }
      vendor
      model_name
      preset_name
      id
    }
  }
`;

export const DevicePresets = () => {
  const [showCreatePresetModal, updateShowCreatePresetModal] = useState(false);
  const { loading, error, data, refetch } = useQuery(DEVICE_PRESETS_QUERY, {
    fetchPolicy: "no-cache",
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <h1>{error?.message}</h1>;
  }

  const preset_tiles = data?.device_presets?.map((x) => (
    <DevicePresetCard preset={x} key={x.id} onDelete={refetch} />
  ));

  return (
    <div>
      <ButtonlessModal
        title="New Device Preset"
        scrollOnOpen={true}
        onClose={() => updateShowCreatePresetModal(false)}
        show={showCreatePresetModal}
      >
        <DevicePresetFormContainer
          onComplete={() => {
            refetch();
            updateShowCreatePresetModal(false);
          }}
        />
      </ButtonlessModal>
      <div className="flex flex-row ml-1 mr-1 mb-2">
        <h1 className="text-3xl font-bold mt-2">Device Presets</h1>
        <div id="create-preset-button" className="ml-auto">
          <ActionButton
            toggleOpen={() =>
              updateShowCreatePresetModal(!showCreatePresetModal)
            }
            isOpen={showCreatePresetModal}
            title="New Device Preset"
            showIcon={true}
            className="mr-0"
          />
        </div>
      </div>
      <hr className="border-gray-400 border" />
      <div className="mt-4">{preset_tiles}</div>
    </div>
  );
};
