import React from "react";
import { FaSpinner } from "react-icons/fa";

const Spinner = ({ size }) => {
  size = size ? size : 60;

  return (
    <div className="z-50 text-black flex flex-row w-full h-full p-3 m-auto rounded">
      <FaSpinner size={size} className="icon-spin mx-auto" />
    </div>
  );
};

export const RestrictedSpinner = ({ size, className }) => {
  size = size ? size : 60;

  var className = `z-50 text-black rounded ${className}`;

  return (
    <div className={className}>
      <FaSpinner size={size} className="icon-spin mx-auto" />
    </div>
  );
};

export default Spinner;
