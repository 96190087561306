export const StylableModal = ({ isOpen, children, header }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="w-auto h-auto border border-black inline-block align-middle bg-white rounded-lg text-left shadow-xl transform transition-all items-center">
          <div className="p-4">
            {header}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
