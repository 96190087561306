import React, { useState } from "react";

import EVSEList from "./EVSEList";
import DestinationMeta from "./LocationMeta";
import Spinner from "../misc/Spinner";

import { RightModal } from "../layout/rightModal";
import { EditLocation } from "../locations/LocationUtils";
import { AddOfflineEVSE } from "../locations/OfflineDeviceUtils";
import { EDIT_LOCATION, DELETE_EVSE, hasUAM } from "../../utils/UAMRoles";
import { gql, useQuery } from "@apollo/client";
import { EditOCPILocationContainer } from "./EditOCPILocation";
import { useAuth } from "../../hooks/useAuth";
import { Redirect } from "react-router-dom";

const LOCATION_GQL = gql`
  query LocationsApiSingle($locationsApiSingleId: String!) {
    locationsApiSingle(id: $locationsApiSingleId) {
      party_id
      id
      name
      address
      city
      post_code
      coordinates {
        lat
        lng
      }
      country
      evses {
        connected
        id
        name
        charge_point_model
        vendor
        charging_type
        capabilities
        soft_blocked
        connectors {
          status
          id
          standard
          format
          power_type
          voltage
          max_amperage
          current_amperage
          session_id
          physical_reference
        }
        last_heartbeat
        last_updated
      }
      time_zone
      images {
        url
        thumbnail
        category
        type
        width
        height
      }
      facilities
      parking_type
      created
      publish
      last_updated
    }
  }
`;

const ChargingDestinationDetail = ({ match }) => {
  const { permissions, uam } = useAuth();

  var editAllowed = hasUAM(
    {
      uam,
    },
    EDIT_LOCATION
  );
  var deleteDeviceAllowed = hasUAM(
    {
      uam,
    },
    DELETE_EVSE
  );

  const [showAddCustomer, toggleShowAddCustomer] = useState(false);
  const locationId = match.params.slug;

  const { loading, error, data, refetch } = useQuery(LOCATION_GQL, {
    variables: {
      locationsApiSingleId: `${locationId}`,
    },
    fetchPolicy: "no-cache",
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Redirect to="/notfound" />;
    // <div>Error {error}</div>;
  }
  var location = data.locationsApiSingle;

  var body = (
    <EVSEList
      deleteEVSE={deleteDeviceAllowed === true}
      evses={location.evses}
      onReloadMachines={() => refetch()}
      location_id={locationId}
    />
  );

  return (
    <div>
      <LocationHeader
        editAllowed={editAllowed}
        location={location}
        toggleShowAddCustomer={toggleShowAddCustomer}
        showAddCustomer={showAddCustomer}
        role={permissions?.role}
      />
      <div className="md:flex w-full justify-start items-start">
        <LocationSideBar locationData={location} role={permissions?.role} />
        <div className="flex flex-col w-full">{body}</div>
      </div>
    </div>
  );
};

const LocationSideBar = ({ locationData, role }) => {
  return (
    <div className="w-1/3 mr-2">
      <DestinationMeta loc={locationData} />
      <div key="editLocation" className="z-20 my-4 flex">
        <RightModal
          title="Edit Location Data"
          showIcon={false}
          className="brandPrimaryColor bg-primary w-full buttonShadow"
        >
          <EditLocation location={locationData} role={role} />
        </RightModal>
      </div>
    </div>
  );
};

const LocationHeader = ({ location, editAllowed, role, status }) => {
  var displayName = location.address;
  displayName ??= "Location";

  return (
    <div className="mb-4 flex">
      <div className="mr-4">
        <h2 className="heading">{displayName}</h2>
        <LocationControls
          location={location}
          editAllowed={editAllowed}
          role={role}
          status={status}
        />
        <p className="text-xs my-2">
          <span className="font-bold">#</span>
          <span>{location.id}</span>
        </p>
      </div>
    </div>
  );
};

const LocationControls = ({ editAllowed, location, role }) => {
  if (!editAllowed) {
    return null;
  }

  var items = [
    <div key="editOCPILocation" className="fixed right-0 z-20 top-0 m-4 flex">
      <RightModal title="Edit Metadata">
        <EditOCPILocationContainer locationId={location.id} role={role} />
      </RightModal>
    </div>,
    <div key="addEVSE" className="fixed right-48 z-20 top-0 m-4 flex">
      <RightModal title="Add Offline EVSE">
        <AddOfflineEVSE locationId={location.id} />
      </RightModal>
    </div>,
  ];

  return <div>{items}</div>;
};

export default ChargingDestinationDetail;
