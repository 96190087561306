import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBolt } from "react-icons/fa";
import { gql, useMutation, useSubscription } from "@apollo/client";

import Moment from "react-moment";
import Spinner, { RestrictedSpinner } from "../misc/Spinner";
import { FaSpinner } from "react-icons/fa";
import { useAuth } from "../../hooks/useAuth";

const EVSE_SESSION = gql`
  subscription Sub_current_session($evseId: String, $connectorId: String) {
    sub_current_session(evse_id: $evseId, connector_id: $connectorId) {
      id
      status
      type
      party_id
      connector_number
      evse_id
      expiry
      location_id
      tariff {
        country_code
        party_id
        id
        currency
        type
        tariff_alt_text {
          language
          text
        }
        elements {
          price_components {
            type
            price
            vat
            step_size
          }
        }
      }
      start_time
      last_modified
      fulfilled
      kwh
      kw
      phases {
        start_time
        last_modified
        type
        kwh
        cost
      }
      user_id
      total_cost
      last_meterupdate
      end_time
      error
      payment_metric
      stripe_id
      agreed_duration
      energy_limit
      contract_abandoned
      terminated_time
      terminated_user_id
    }
  }
`;

const STOP_CHARGING = gql`
  mutation Mutation {
    stopPaidCharging
  }
`;

const REMOTE_STOP = gql`
  mutation RemoteStop($evseId: String!, $connectorId: Int!) {
    remoteStop(evse_id: $evseId, connector_id: $connectorId)
  }
`;

export const SessionStatus = ({ evse_id, connector_id }) => {
  const { loading, error, data } = useSubscription(EVSE_SESSION, {
    variables: {
      evseId: evse_id,
      connectorId: `${connector_id}`, // Need this as string.
    },
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {`${error}`}</p>;
  }

  return <SessionBody session={data.sub_current_session} />;
};

const SessionBody = ({ session }) => {
  const [stopCharging, { loading, error, data }] = useMutation(STOP_CHARGING);
  const [remoteStop, remoteStopResult] = useMutation(REMOTE_STOP);
  const user = useAuth().uid;

  var progressIndicator = <DefaultProgressIndicator session={session} />;

  if (session?.status === "ERROR") {
    return <SessionError session={session} />;
  }

  if (session?.status === "TERMINATED") {
    return <SessionTerminated />;
  }

  if (session?.status === "PENDING") {
    return <SessionPending />;
  }

  if (session.type === "PREPAID") {
    if (session.payment_metric === "TIME" && session.expiry) {
      progressIndicator = (
        <TimeProgressIndicator
          kwh={session.kwh}
          kw={session.kw}
          expiry={new Date(session.expiry)}
        />
      );
    } else if (session.payment_metric === "ENERGY") {
      progressIndicator = (
        <EnergyProgressIndicator
          kwh={session.kwh}
          kw={session.kw}
          kwhLimit={session.energy_limit}
        />
      );
    }
  }

  var whiteGlow =
    session.phases[session.phases.length - 1].type !== "PARKING_TIME"
      ? "white-glow"
      : "white-shadow";

  return (
    <div className="bg-black overflow-auto h-screen flex flex-col w-full items-center justify-center px-4">
      <div className="flex flex-col overflow-auto items-center justify-center bg-primary p-6 border rounded-lg border-secondary">
        <FaBolt
          className={`m-2 mb-8 brandPrimaryColor ${whiteGlow}`}
          size={108}
        />
        <h1 className="font-bold brandPrimaryColor text-center text-2xl">
          {ToTitleCase(session.type)} Session in progress
        </h1>
        {session.phases[session.phases.length - 1].type === "PARKING_TIME" && (
          <h1 className="brandPrimaryColor text-center">
            Vehicle appears fully charged
          </h1>
        )}
        <div className="text-white font-sans mt-4">{progressIndicator}</div>
      </div>
      {session.user_id === user && (
        <button
          className="p-3 w-72 mt-2 bg-secondary text-black border border-black hover:border-white rounded-xl"
          onClick={async () => {
            var variables = {
              evseId: session.evse_id,
              connectorId: ~~session.connector_number,
            };

            if (
              session.type === "POSTPAID" ||
              session.type === "PREPAID" ||
              session.type == "WHITELIST"
            )
              stopCharging();
            else {
              await remoteStop({
                variables,
              });
            }
          }}
        >
          {error ? error : "Stop Session"}
        </button>
      )}
      {(loading || remoteStopResult.loading) && <Spinner />}
    </div>
  );
};

const SessionError = ({ session }) => {
  return (
    <div className="bg-black overflow-auto h-screen flex flex-col w-full items-center justify-center px-4">
      <div className="overflow-auto text-center items-center justify-center bg-primary brandPrimaryColor p-6 border rounded">
        <div className=" mb-4">
          Apologies, an error occurred with your session:
        </div>
        <div>{session.error}</div>
        <div>Please contact EVUp if you wish to refund a prepaid session.</div>
        <FaSpinner size={40} className="icon-spin text-white my-4 mx-auto" />
        <div>Awaiting reconnection...</div>
        <Link to={"./"}>
          <div className="bg-secondary text-black text-center py-2 px-4 rounded mt-6 mb-2 mx-auto">
            Home
          </div>
        </Link>
      </div>
    </div>
  );
};

const SessionTerminated = ({ session }) => {
  return (
    <div className="bg-black overflow-auto h-screen flex flex-col w-full items-center justify-center px-4">
      <div className="overflow-auto text-center items-center justify-center bg-primary brandPrimaryColor p-6 border rounded">
        <div className=" mb-4">
          Apologies, an error occurred with your session, and it was terminated
        </div>
        <div>Please contact EVUp if you wish to refund a prepaid session</div>
        <FaSpinner size={40} className="icon-spin text-white my-4 mx-auto" />
        <Link to={"./"}>
          <div className="bg-secondary text-black text-center py-2 px-4 rounded mt-6 mb-2 mx-auto">
            Home
          </div>
        </Link>
      </div>
    </div>
  );
};

const SessionPending = ({}) => {
  return (
    <div className="bg-primary overflow-auto h-screen flex w-full items-center justify-center">
      <div className="overflow-auto items-center justify-center bg-white p-2 border rounded">
        <div>Session start pending...</div>
        <div>Please ensure the charger is plugged in at both ends</div>
      </div>
    </div>
  );
};

const EnergyProgressIndicator = ({ kwh = 0, kwhLimit = 0, kw }) => {
  return (
    <div>
      <div className="text-center">
        Will terminate at {kwhLimit.toFixed(2)} kWh
      </div>
      <DeliveryValues kw={kw} kwh={kwh} />
    </div>
  );
};

const TimeProgressIndicator = ({ expiry, kwh, kw }) => {
  const [time, updateTime] = useState(expiry - new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      updateTime(expiry - new Date());
    }, 1 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, []);

  var expiryMessage = (
    <div className="text-center">
      Time remaining - {<Moment format="mm:ss">{time}</Moment>} mins
    </div>
  );

  if (expiry <= new Date()) {
    expiryMessage = <div className="text-center">Session terminating..</div>;
  }

  return (
    <div>
      {expiryMessage}
      <DeliveryValues kw={kw} kwh={kwh} />
    </div>
  );
};

const DefaultProgressIndicator = ({ session }) => {
  return (
    <div>
      <DeliveryValues kw={session.kw} kwh={session.kwh} />
    </div>
  );
};

const DeliveryValues = ({ kw, kwh }) => {
  return (
    <div>
      <div className="text-center">Charging at {kw.toFixed(2)} kW</div>
      <div className="text-center">Delivered {kwh?.toFixed(2)} kWh</div>
    </div>
  );
};

export function ToTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
