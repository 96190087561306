import { useMutation, gql } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Spinner from "../misc/Spinner";
import { useAuth } from "../../hooks/useAuth";

const STARTCHARGING_MUTATION = gql`
  mutation InitiatePrepaidCharging($prop: ContractProposition) {
    initiatePrepaidCharging(prop: $prop)
  }
`;


// The old prepayment system running through Square

export const PaymentForm = ({
  contractProposition,
  evse_id,
  connector_number,
  onNext,
  onBack,
}) => {
  const [loading, setLoading] = useState(true);
  const [card, setCard] = useState(undefined);
  const cardButton = document.getElementById("card-button");
  const [paymentProcessing, setPaymentProcessing] = useState(false);

  const [startCharging, startChargingStatus] = useMutation(
    STARTCHARGING_MUTATION
  );

  const user = useAuth();
  //Set these required values on our contract proposition.
  contractProposition.evse_id = evse_id;
  contractProposition.connector_number = connector_number;
  contractProposition.user_uid = user?.uid;

  const initialiseCard = async (payments) => {
    let card;
    try {
      card = await initializeCard(payments);

      if (!card) {
        throw "Initializing Card failed";
      }
      setCard(card);
      setLoading(false);
    } catch (e) {
      console.error("Initializing Card failed", e);
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    if (!window.Square) {
      throw new Error("Square.js failed to load properly");
    }

    let payments;
    try {
      payments = window.Square.payments(
        process.env.REACT_APP_SQUARE_APP_ID,
        process.env.REACT_APP_SQUARE_LOCATION_ID
      );
    } catch (err) {
      console.debug(`Cannot load square payments! ${err}`);
      const statusContainer = document.getElementById(
        "payment-status-container"
      );
      statusContainer.className = "missing-credentials";
      statusContainer.style.visibility = "visible";
      return;
    }
    // Async in useEffect will not work well, replace with this.
    initialiseCard(payments);
  }, []);

  /*
   *  Please note that the Spinner is inline
   *  as when the card loads, it needs a DOM
   *  target to attach to. Therefore, card container
   *  must be in the DOM when the card is loaded.
   */

  return (
    <div>
      {loading === true && <Spinner />}
      <form id="payment-form">
        <div id="card-container"></div>
        <button
          id="card-button"
          type="button"
          onClick={async (event) => {
            setPaymentProcessing(true);
            console.debug(`Payment button clicked`);
            await handlePaymentMethodSubmission(
              event,
              card,
              cardButton,
              contractProposition,
              startCharging
            );
            console.log("STATUS:", startChargingStatus);
            if (!startChargingStatus.error) onNext();
          }}
        >
          Pay ${contractProposition.total_price}
        </button>
        {!paymentProcessing && (
          <button className="btn-red mt-2" onClick={() => onBack()}>
            Back
          </button>
        )}
      </form>
      <div id="payment-status-container"></div>
    </div>
  );
};

async function initializeCard(payments) {
  const card = await payments.card();
  await card.attach("#card-container");

  return card;
}

async function createPayment(token, contractProposition, startCharging) {
  const result = await startCharging({
    variables: {
      prop: {
        total_price: contractProposition.total_price,
        type: contractProposition.type,
        units: contractProposition.units,
        evse_id: contractProposition.evse_id,
        connector_number: Number.parseInt(contractProposition.connector_number),
        source_id: token,
        tariff_id: contractProposition.tariff.id,
        user_uid: contractProposition.user_uid || null,
      },
    },
  });

  if (result.error) {
    throw new Error(`${result.error}`);
  }
}

async function tokenize(paymentMethod) {
  const tokenResult = await paymentMethod.tokenize();
  if (tokenResult.status === "OK") {
    return tokenResult.token;
  } else {
    console.log("TOKENISATION ERROR");
    let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
    if (tokenResult.errors) {
      errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
    }

    throw new Error(errorMessage);
  }
}

function displayPaymentResults(status) {
  const statusContainer = document.getElementById("payment-status-container");
  if (!statusContainer) return;
  if (status === "SUCCESS") {
    statusContainer.classList.remove("is-failure");
    statusContainer.classList.add("is-success");
  } else {
    statusContainer.classList.remove("is-success");
    statusContainer.classList.add("is-failure");
  }

  statusContainer.style.visibility = "visible";
}

async function handlePaymentMethodSubmission(
  event,
  paymentMethod,
  cardButton,
  contractProposition,
  startCharging
) {
  event.preventDefault();

  try {
    // disable the submit button as we await tokenization and make a payment request.
    cardButton.disabled = true;
    const token = await tokenize(paymentMethod);
    console.log(`Created token`);
    await createPayment(token, contractProposition, startCharging);
    console.log(`Created payment`);
    displayPaymentResults("SUCCESS");
  } catch (e) {
    cardButton.disabled = false;
    displayPaymentResults("FAILURE");
    console.error(e.message);
  }
}
