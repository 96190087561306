import { useState } from "react";
import { createToken } from "../../actions/cards";

const TOKEN_TYPES = ["AD_HOC_USER", "APP_USER", "OTHER", "RFID"];

const WHITELIST_TYPES = ["ALWAYS", "ALLOWED", "ALLOWED_OFFLINE", "NEVER"];

/*
 *   Allows the user to change select
 *   fields on the token. Once complete
 *   they may save their changes, which
 *   mutates the token on the DB.
 */
export const EditToken = ({ initialToken }) => {
  const [card, updateCard] = useState(initialToken);

  return (
    <div>
      <div className="flex mt-2" key="party_id">
        <h1 className="p-2">Party ID</h1>
        <input
          value={card.party_id}
          className="border rounded ml-auto p-2"
          onChange={(e) => {
            updateCard({
              ...card,
              party_id: e.target.value,
            });
          }}
        ></input>
      </div>
      <div className="flex mt-2" key="type">
        <h1 className="p-2">Type</h1>
        <select
          value={card.type}
          className="border rounded ml-auto p-2"
          name="Type"
          onChange={(e) => {
            updateCard({
              ...card,
              type: e.target.value,
            });
          }}
        >
          <option value="none" label="Type" />
          {TOKEN_TYPES.map((x) => (
            <option value={x} label={x} />
          ))}
        </select>
      </div>
      <div className="flex mt-2" key="contract_id">
        <p className="p-2">Contract ID</p>
        <input
          value={card.contract_id}
          className="border rounded ml-auto p-2"
          onChange={(e) => {
            updateCard({
              ...card,
              contract_id: e.target.value,
            });
          }}
        ></input>
      </div>
      <div className="flex mt-2" key="visual_number">
        <h1 className="p-2">Visual Number</h1>
        <input
          value={card.visual_number}
          className="border rounded ml-auto p-2"
          onChange={(e) => {
            updateCard({
              ...card,
              visual_number: e.target.value,
            });
          }}
        ></input>
      </div>
      <div className="flex mt-2" key="issuer">
        <p className="p-2">Issuer</p>
        <input
          value={card.issuer}
          className="border rounded ml-auto p-2"
          onChange={(e) =>
            updateCard({
              ...card,
              issuer: e.target.value,
            })
          }
        ></input>
      </div>
      <div className="flex mt-2" key="group_id">
        <p className="p-2">Group ID</p>
        <input
          value={card.group_id}
          className="border rounded ml-auto p-2"
          onChange={(e) =>
            updateCard({
              ...card,
              group_id: e.target.value,
            })
          }
        ></input>
      </div>
      <div className="flex mt-2" key="valid">
        <p className="p-2">Valid</p>
        <input
          id="valid"
          type="checkbox"
          className="p-2 mt-3 ml-auto"
          checked={card.valid}
          onChange={(e) =>
            updateCard({
              ...card,
              valid: !card.valid,
            })
          }
        />
      </div>
      <div className="flex mt-2" key="whitelist">
        <p className="p-2">Whitelist</p>
        <select
          value={card.whitelist}
          className="border rounded p-2 ml-auto"
          name="Whitelist"
          onChange={(e) =>
            updateCard({
              ...card,
              whitelist: e.target.value,
            })
          }
        >
          <option value="none" label="Whitelist" />
          {WHITELIST_TYPES.map((x) => (
            <option value={x} label={x} />
          ))}
        </select>
      </div>
      <div key="submit">
        <button
          onClick={async () => {
            /*
             *   CreateToken function may
             *   be used for updating a token,
             *   as it's a firestore set.
             */
            var result = await createToken(card);
            if (result) {
              alert("Operation Success!");
            } else {
              alert("Operation Failure!");
            }
          }}
          className="bg-blue-400 text-white py-2 px-4 rounded mt-2"
        >
          Save
        </button>
      </div>
    </div>
  );
};
