import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import notfound from "../../assets/notfound.webp";

export const PageNotFound = () => {
  return (
    <div className="bg-primary flex flex-row items-center justify-center w-full">
      <div className="flex flex-col items-center bg-dark-primary p-6 border rounded-xl">
        <h1 className="text-4xl mb-2 brandPrimaryColor ">
          Error 404, page not found.
        </h1>
        <p className="mb-4 text-white">
          The requested page does not exist, please contact us if you think this
          is an error.
        </p>
        <Link to="/" className="mt-2 px-4 py-2 bg-green-600 rounded text-white">
          Home
        </Link>
      </div>
    </div>
  );
};
