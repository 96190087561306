import { EcommerceHeader } from "../../../components/EcommerceHeader";
import { GiCircle } from "react-icons/gi";
import { MdEdit } from "react-icons/md";

export const AccountPage = () => {
  /* Have state which contains acc information */
  return (
    <div className="w-full sm:flex overflow-y-auto">
      <div className="bg-white w-full h-full overflow-auto">
        <EcommerceHeader>MY ACCOUNT</EcommerceHeader>
        <div className="flex flex-col mt-24 gap-y-4 w-4/5 ml-8">
          <div className="w-fit">
            <GiCircle size={150} />
          </div>
          <div className="w-fit">
            <div className="flex flex-row gap-x-2 font-bold text-xl">
              Name:
              <MdEdit />
            </div>
            <div> User's Name</div>
          </div>
          <div className="w-fit">
            <div className="flex flex-row gap-x-2 font-bold text-xl">
              Email:
              <MdEdit />
            </div>
            <div> userstestemail@gmail.com</div>
          </div>
          <div className="w-fit">
            <div className="flex flex-row gap-x-2 font-bold text-xl">
              Phone Number:
              <MdEdit />
            </div>
            <div> 043428492</div>
          </div>
        </div>
      </div>
    </div>
  );
};
