import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { useMutation, gql } from "@apollo/client";

import Spinner from "../misc/Spinner";
import moment from "moment";

const GENERATE_STATEMENT = gql`
  mutation GenerateStatement($startDate: String!, $endDate: String!) {
    generateStatement(startDate: $startDate, endDate: $endDate) {
      url
      errors
    }
  }
`;

export const StatementDownSpinner = () => {
  const [selection, updateSelection] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [generateStatement, { loading, error, data }] =
    useMutation(GENERATE_STATEMENT);

  if (loading) {
    return <Spinner className="p-16" />;
  }

  return (
    <div>
      <h1>
        Select the date range for your statement <br /> to be generated within.
      </h1>
      <div className="flex flex-col justify-center items-center">
        <DateRangePicker
          ranges={[selection]}
          maxDate={new Date()}
          showMonthAndYearPickers={false}
          showDateDisplay={false}
          date={new Date()}
          onChange={(e) => updateSelection(e.selection)}
        />
        <div className="flex flex-row mt-4 w-full">
          <button
            className="bg-green-600 text-white text-sm font-bold py-2 px-4 rounded"
            onClick={async () => {
              var startDate = selection.startDate;
              var endDate = selection.endDate;
              console.log("Generating statement");

              var result = await generateStatement({
                variables: {
                  startDate: startDate.toISOString(),
                  endDate: endDate.toISOString(),
                },
              });

              var response = result?.data?.generateStatement;
              console.log("Got response", response);
              var url = response?.url;
              var errors = response?.errors;

              if (errors?.length > 0) {
                var errorMessage = errors.join("\n");
                alert(`Error generating statement! \n ${errorMessage}`);
                return;
              }

              if (!url) {
                alert(`Error generating statement!`);
                return;
              }

              fetch(url, {
                method: "GET",
                mode: "cors",
                headers: {
                  "Content-Type": "application/pdf",
                },
              })
                .then((response) => response.blob())
                .then((blob) => {
                  // Create blob link to download
                  const url = window.URL.createObjectURL(new Blob([blob]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", `statement.pdf`);

                  // Append to html link element page
                  document.body.appendChild(link);

                  // Start download
                  link.click();

                  // Clean up and remove the link
                  link.parentNode.removeChild(link);
                })
                .catch((err) => {
                  alert(
                    `There was an error downloading your statement! Please contact support!`
                  );
                });
            }}
            type="submit"
          >
            Download Statement
          </button>
        </div>
      </div>
    </div>
  );
};
