import { useState } from "react";
import { Modal } from "../layout/Modal";
import { FirestoreCollection } from "react-firestore";
import Spinner from "../misc/Spinner";
import { FaFilter } from "react-icons/fa";

export const SearchBar = ({
  searchTermUpdated,
  filtersUpdated,
  permissions,
}) => {
  const [searchTerm, updateSearchTerm] = useState("");
  //is the filter modal open
  const [filtersOpen, updateFiltersOpen] = useState(false);
  //are filters active
  const [filtersOn, updateFiltersOn] = useState(false);

  return (
    <div className="flex-row m-4 flex">
      <div className="">
        <button
          className="flex flex-row border border-black bg-primary py-2 px-4 rounded brandPrimaryColor"
          onClick={() => updateFiltersOpen(!filtersOpen)}
        >
          <FaFilter className="inline brandPrimaryColor mr-2 mt-1" />
          Filter Sessions
        </button>
        <ClearFilters
          filtersOn={filtersOn}
          onClick={() => {
            updateFiltersOn(false);
            filtersUpdated({});
          }}
        />
      </div>
      <Modal
        title="Filter Sessions"
        isOpen={filtersOpen}
        onClose={() => updateFiltersOpen(false)}
      >
        <PermissionWrapper
          permissions={permissions}
          filtersUpdated={(filters) => {
            updateFiltersOpen(false);
            updateFiltersOn(Object.keys(filters).filter((x) => x).length > 0);
            filtersUpdated(filters);
          }}
        />
      </Modal>
    </div>
  );
};

const ClearFilters = ({ filtersOn, onClick }) => {
  if (!filtersOn) {
    return null;
  }

  return (
    <button
      className="border border-black py-2 px-4 rounded ml-2"
      onClick={onClick}
    >
      Clear Filters
    </button>
  );
};

const PermissionWrapper = ({ permissions, filtersUpdated }) => {
  return (
    <FirestoreCollection path="locations">
      {({ isLoading, data, error }) => {
        if (isLoading) {
          return <Spinner />;
        }
        var locations = data;

        //filter out offline locations
        locations = locations.filter((x) => x.type !== "OFFLINE");

        if (permissions?.role === "admin") {
          locations = locations?.filter(
            (x) => permissions?.locations?.indexOf(x.id) !== -1
          );
        }

        return (
          <FirestoreCollection path="ocpp_logs">
            {({ isLoading, data, error }) => {
              if (isLoading) {
                return <Spinner />;
              }

              var evses = data;

              return (
                <SessionFilter
                  allowedEVSES={evses
                    .map((x) => reduceAllowedEVSE(x))
                    .filter((x) => x.id && x.name)}
                  allowedLocations={locations
                    .map((x) => reduceAllowedLocation(x))
                    .filter((x) => x.id && x.name)}
                  filtersUpdated={filtersUpdated}
                />
              );
            }}
          </FirestoreCollection>
        );
      }}
    </FirestoreCollection>
  );
};

function reduceAllowedEVSE(evse) {
  var evseName = evse?.metadata?.displayName;
  return {
    id: evse?.id,
    name: evseName,
  };
}

function reduceAllowedLocation(location) {
  var evseName = location?.metadata?.displayName;
  return {
    id: location?.id,
    name: evseName,
  };
}

const SessionFilter = ({ allowedLocations, allowedEVSES, filtersUpdated }) => {
  const [evseID, updateEVSEID] = useState(undefined);
  const [locationID, updateLocationID] = useState(undefined);
  const [minKWH, updateMinKWH] = useState(undefined);
  const [maxKWH, updateMaxKWH] = useState(undefined);
  const [sessionStatus, updateSessionStatus] = useState(undefined);
  const [startingDate, updateStartingDate] = useState(undefined);
  const [finishingDate, updateFinishingDate] = useState(undefined);
  const [minDuration, updateMinDuration] = useState(undefined);
  const [maxDuration, updateMaxDuration] = useState(undefined);

  return (
    <div>
      <div className="mt-2" key="evse">
        <div>EVSE:</div>
        <select
          className="border rounded mb-1 mt-1 p-1"
          name="evse"
          onChange={(e) => updateEVSEID(e.target.value)}
        >
          <option value="none" label="Select EVSE" />
          {allowedEVSES.map((x) => (
            <option value={x.id} label={x.name} />
          ))}
        </select>
      </div>
      <div className="mt-2" key="location">
        <div>Location:</div>
        <select
          className="border rounded mb-1 mt-1 p-1"
          name="location"
          onChange={(e) => updateLocationID(e.target.value)}
        >
          <option value="none" label="Select Location" />
          {allowedLocations.map((x) => (
            <option value={x.id} label={x.name} />
          ))}
        </select>
      </div>
      <div className="mt-2" key="kwh">
        <div>Total kWh:</div>
        <div className="flex">
          <div className="mr-2">
            <input
              type="number"
              className="border rounded p-1"
              placeholder="min"
              onChange={(e) => updateMinKWH(e.target.value)}
            />
          </div>
          <div>
            <input
              type="number"
              className="border rounded p-1"
              placeholder="max"
              onChange={(e) => updateMaxKWH(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="mt-2" key="status">
        <div>Session Status:</div>
        <select
          className="border rounded mb-1 mt-1 p-1"
          name="status"
          onChange={(e) => updateSessionStatus(e.target.value)}
        >
          <option value="none" label="Select Session Status" />
          <option value="ACTIVE" label="Active Sessions" />
          <option value="COMPLETED" label="Completed Sessions" />
        </select>
      </div>
      <div className="mt-2" key="daterange">
        <div>Date Range:</div>
        <div className="flex">
          <div className="mr-2">
            <h1>From</h1>
            <input
              type="date"
              className="border rounded p-1"
              onChange={(e) => updateStartingDate(e.target.value)}
            />
          </div>
          <div>
            <h1>To</h1>
            <input
              type="date"
              className="border rounded p-1"
              onChange={(e) => updateFinishingDate(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="mt-2" key="duration">
        <div>Session Duration (hours):</div>
        <div className="flex">
          <div className="mr-2">
            <input
              type="number"
              className="border rounded p-1"
              placeholder="min"
              onChange={(e) => updateMinDuration(e.target.value)}
            />
          </div>
          <div>
            <input
              type="number"
              className="border rounded p-1"
              placeholder="max"
              onChange={(e) => updateMaxDuration(e.target.value)}
            />
          </div>
        </div>
      </div>
      <button
        className="btn-blue mt-2"
        onClick={() => {
          filtersUpdated({
            evseID: evseID,
            locationID: locationID,
            minKWH: minKWH,
            maxKWH: maxKWH,
            sessionStatus: sessionStatus,
            startingDate: startingDate,
            finishingDate: finishingDate,
            minDuration: minDuration,
            maxDuration: maxDuration,
          });
        }}
      >
        Submit
      </button>
    </div>
  );
};
