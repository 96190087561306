import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

import { PricingCalculator } from "./SubscriptionFlow";
import { FaCreditCard } from "react-icons/fa";

import { useState } from "react";

export const StripePaymentForm = ({ subscriptionTerms }) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const return_url = `${window.location.origin}/subscribe/complete`;
    console.log("URL:", return_url);

    // If the customer selects trial, we want to save their payment details.
    // If the customer wishes to pay straight away, do a payment intent.
    if (!subscriptionTerms.is_trial) {
      console.log("Confirming payment");
      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: return_url,
        },
      });
      console.log("Result:", result);

      if (result.error) {
        setErrorMessage(result.error.message);
      }
    } else {
      console.log("Confirming setup");
      const result = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: return_url,
        },
      });

      if (result.error) {
        setErrorMessage(result.error.message);
      }
    }
    console.log("Errors?", errorMessage);
  };

  return (
    <div>
      <form className="flex flex-col" onSubmit={handleSubmit}>
        <div className="mb-4">
          <PricingCalculator
            billing_period={subscriptionTerms.billing_period}
            locations_asked={subscriptionTerms.num_locations}
            is_trial={subscriptionTerms.is_trial}
          />
        </div>
        <PaymentElement />
        <button
          className="p-2 bg-green-600 text-white rounded border mt-2 flex flex-row justify-center"
          disabled={!stripe}
        >
          Subscribe
          <FaCreditCard size={18} className="mt-0.5 ml-2" />
        </button>
      </form>
    </div>
  );
};
