import React from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  ZoomControl,
} from "react-leaflet";

import { Link } from "react-router-dom";

export const LeafletMap = ({
  fullscreen = false,
  locations = [],
  center,
  isAdmin = false,
  zoom = 11,
}) => {
  //Put in locations get out markers on map!
  //A invalid lat / long will return null so filter invalid entries;
  var locationMarkers = locations
    .map((x) => mapLocationToMarker(x, isAdmin))
    .filter((x) => x);
  return (
    <MapView
      zoom={zoom}
      fullscreen={fullscreen}
      markers={locationMarkers}
      center={center}
    />
  );
};

export function mapLocationToMarker(location, isAdmin) {
  var latitude = location?.coordinates?.lat;
  var longitude = location?.coordinates?.lng;
  var locationName = location?.name;
  var locationId = location.id;

  if (!latitude || !longitude) {
    return;
  }

  var routeIntermediate = "";

  if (isAdmin) {
    routeIntermediate = "locations/";
  }
  //dynamic route based on are we in ecommerce or locations page.
  var route = `./${routeIntermediate}${locationId}`;

  return (
    <Marker
      key={locationId}
      position={[Number.parseFloat(latitude), Number.parseFloat(longitude)]}
    >
      <Popup>
        <Link className="font-bold" to={route}>
          {locationName}
        </Link>
      </Popup>
    </Marker>
  );
}

export const MapView = ({
  center = [-37.8136, 144.9631],
  zoom = 8,
  markers = [],
}) => {
  return (
    <MapContainer
      center={center}
      zoom={zoom}
      scrollWheelZoom={true}
      zoomControl={false}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {markers}
      <ZoomControl position="bottomright" />
    </MapContainer>
  );
};
