import logo from "../../assets/logo.png";
import Spinner from "../misc/Spinner";
import GoogleMapReact from "google-map-react";

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { gql, useQuery } from "@apollo/client";
import { AiOutlineLogin } from "react-icons/ai";
import { FaBars, FaCircle, FaMapMarker } from "react-icons/fa";
import { MAPS_CENTER, GEOLOCATION } from "../../reducers/maps";
import { MetaDisplay } from "./LocationMetadata";
import { Link } from "react-router-dom";
import { LoginModal } from "./driver/LoginModal";
import { useAuth } from "../../hooks/useAuth";
import { DownloadApp } from "../../components/DownloadApp";
import useSupercluster from "use-supercluster";

const LOCATIONS = gql`
  query LocationsApi($source: String) {
    locationsApi(source: $source) {
      party_id
      id
      name
      address
      city
      post_code
      soft_blocked
      coordinates {
        lat
        lng
      }
      country
      evses {
        connected
        id
        name
        charge_point_model
        soft_blocked
        vendor
        charging_type
        tariff {
          country_code
          party_id
          id
          currency
          type
          tariff_alt_text {
            language
            text
          }
          tariff_alt_url
          elements {
            price_components {
              type
              price
              vat
              step_size
            }
          }
          start_date_time
          end_date_time
          last_updated
        }
        capabilities
        connectors {
          status
          id
          standard
          format
          power_type
          voltage
          max_amperage
          current_amperage
          session_id
          session_type
          physical_reference
        }
        last_heartbeat
        last_updated
      }
      time_zone
      images {
        url
        thumbnail
        category
        type
        width
        height
      }
      facilities
      parking_type
      created
      last_updated
    }
  }
`;

const mapStateToProps = (state) => {
  const maps = state.maps;
  return {
    center: state.maps.center,
    zoom: state.maps.zoom,
    geolocation: state.maps.geolocation,
  };
};

const handleUpdateCenter = (center, zoom) => ({
  type: MAPS_CENTER,
  center,
  zoom,
});

const handleUpdateGeolocation = (geolocation) => ({
  type: GEOLOCATION,
  geolocation,
});

var options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

function success(pos) {
  console.debug(`got geolocation`);
  var crd = pos.coords;

  this({
    lat: crd.latitude,
    lng: crd.longitude,
  });
}

function errors(err) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
}

export const MapviewDataLayer = ({}) => {
  // Data layer before the Mapview is called, otherwise you get 'hooks called conditionally' errors with clustering hook.
  const { loading, error, data } = useQuery(LOCATIONS, {
    variables: {
      source: "ECOMMERCE",
    },
    fetchPolicy: "no-cache",
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) return <p>Error :( {`${error}`}</p>;

  const locations = data.locationsApi.filter(
    (x) => x?.coordinates?.lat && x?.coordinates?.lng && x.id
  );
  return <MapContainer locations={locations} />;
};

/*
 *   The screen users see when first navigating to charge
 */
export const Mapview = ({ locations }) => {
  const { tenancyState } = useAuth();
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [center, setCenter] = useState({
    lat: -28.5005721,
    lng: 138.5074049,
  });
  const [zoom, setZoom] = useState(4);
  const [geolocation, setGeolocation] = useState(null);
  const [selectedLocation, updateSelectedLocation] = useState(undefined);
  const [showLoginModal, updateLoginModal] = useState(false);

  const mapsContainerRef = useRef(null);

  useLayoutEffect(() => {
    if (mapsContainerRef.current) {
      const subscriptionBannerNode = document.getElementsByClassName(
        "subscription-banner"
      )[0];
      const mapsToolbarNode = document.getElementsByClassName(
        "ecommerce-maps-toolbar"
      )[0];
      mapsContainerRef.current.style.height = `calc(100vh - ${
        subscriptionBannerNode ? subscriptionBannerNode.offsetHeight : 0
      }px - ${mapsToolbarNode ? mapsToolbarNode.offsetHeight : 0}px)`;
    }
  }, [mapsContainerRef, tenancyState]);

  //Request the user's current location,
  //if granted then save to store
  useEffect(() => {
    const fetchLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation?.getCurrentPosition(
          success.bind(setGeolocation),
          errors,
          options
        );
        setCenter(geolocation);
      } else {
        alert("Location not supported by browser");
      }
    };
    fetchLocation();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const points = locations.map((x) => ({
    type: "Feature",
    properties: {
      cluster: false,
      locationId: x.id,
      locationData: x,
      category: "location",
    },
    geometry: {
      type: "Point",
      coordinates: [x.coordinates.lng, x.coordinates.lat],
    },
  }));

  const { clusters } = useSupercluster({
    points,
    bounds,
    zoom,
    options: {
      radius: 75,
      maxzoom: 20,
    },
  });

  const markers = clusters.map((cluster) => {
    const [longitude, latitude] = cluster.geometry.coordinates;
    const { cluster: isCluster, point_count: pointCount } = cluster.properties;

    if (isCluster) {
      return (
        <ClusterMarker
          total={pointCount}
          key={cluster.id}
          lat={latitude}
          lng={longitude}
          onSelected={() => {
            setCenter({ lat: latitude, lng: longitude });
            setZoom(zoom + 2);
          }}
        />
      );
    } else {
      const x = cluster.properties.locationData;
      return (
        <LocationMarker
          key={x.id}
          locationData={x}
          lng={x.coordinates.lng}
          lat={x.coordinates.lat}
          showMeta={x.id === selectedLocation}
          onSelected={() => {
            if (selectedLocation === x.id) {
              updateSelectedLocation(undefined);
            } else {
              updateSelectedLocation(x.id);
            }
          }}
          onDismiss={() => updateSelectedLocation(undefined)}
        />
      );
    }
  });

  return (
    <div className="w-full">
      <LoginModal
        showLoginModal={showLoginModal}
        updateLoginModal={updateLoginModal}
      />
      <MapviewToolbar
        updateLoginModal={updateLoginModal}
        showLoginModal={showLoginModal}
      />
      <div ref={mapsContainerRef} className="bg-white ecommerce-maps-body z-0">
        <GoogleMapReact
          options={{
            gestureHandling: "greedy",
            fullscreenControl: false,
          }}
          onChange={({ center, zoom, bounds }) => {
            setCenter({
              lat: center.lat,
              lng: center.lng,
            });
            setZoom(zoom);
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat,
            ]);
          }}
          bootstrapURLKeys={{ key: process.env.REACT_APP_FIREBASE_API_KEY }}
          center={center}
          zoom={zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => (mapRef.current = map)}
        >
          {markers}
          {geolocation && (
            <PositionMarker lat={geolocation.lat} lng={geolocation.lng} />
          )}
        </GoogleMapReact>
        <DownloadApp />
      </div>
    </div>
  );
};

/*
<MapviewSearchbar
  queryUpdate={(query) => {
    console.debug(query);
  }}
/>;
*/

export const MapContainer = connect(mapStateToProps, {
  handleUpdateCenter,
  handleUpdateGeolocation,
})(Mapview);

const MapviewToolbar = ({ updateLoginModal }) => {
  const { uid, permissions } = useAuth();

  var topRightButton;

  if (uid) {
    topRightButton = (
      <div className="flex flex-row">
        {permissions?.role !== "user" && (
          <Link
            to="/locations"
            className="mr-8 items-center text-center rounded-lg p-2 px-4 bg-primary border"
          >
            <p
              id="admin-portal"
              className="text-lg font-bold text-secondary text-white"
            >
              Admin Portal
            </p>
          </Link>
        )}
        <button
          id="menu-bars"
          className="mr-2"
          onClick={() => updateLoginModal(true)}
        >
          <FaBars color="white" size={28} />
        </button>
      </div>
    );
  } else {
    topRightButton = (
      <Link id="login" to="/login" className="mr-2 items-center text-center">
        <AiOutlineLogin
          color="white"
          size={20}
          className="text-center my-0 relative top-1 mx-auto"
        />
        <span className="text-xs text-white text-center my-0">Login</span>
      </Link>
    );
  }

  return (
    <div className="bg-primary flex relative p-8 z-30 ecommerce-maps-toolbar flex-row items-center">
      <Link to="/ecommerce">
        <img src={logo} alt="Logo" className="logo" />
      </Link>
      <h1 className="charge"> - </h1>
      <h1 className="charge blueDropshadow">Charge</h1>
      <div className="ml-auto flex flex-row p-1">{topRightButton}</div>
    </div>
  );
};

/*
 * NOT IN USE
const MapviewSearchbar = ({ queryUpdate }) => {
  const [visible, changeVisible] = useState(false);
  if (visible) {
    return (
      <div className="z-10 absolute left-0 top-18 m-2 rounded border flex flex-row">
        <input
          type="text"
          className="p-2"
          placeholder="Search Nearest Location"
          onChange={(e) => {
            queryUpdate(e.target.value);
          }}
        />
      </div>
    );
  } else {
    return;
  }
};
*/

const LocationMarker = ({ locationData, onSelected, showMeta, onDismiss }) => {
  return (
    <div>
      <MetaDisplay
        show={showMeta}
        locationData={locationData}
        onDismiss={onDismiss}
      />
      <button
        id={locationData.id}
        className="location-marker"
        onClick={onSelected}
      >
        <FaMapMarker style={{ color: "#2fdce1" }} size={40} />
      </button>
    </div>
  );
};

const ClusterMarker = ({ total, onSelected, showMeta, onDismiss }) => {
  // The determination of text size here is not an ideal solution at all, but I'm in a rush and frankly don't care.
  const textSize =
    total >= 100 ? "text-xs top-6 p-0.5" : "text-sm top-7 px-1 h-6 pt-0.5";
  return (
    <div>
      <button className="location-marker" onClick={onSelected}>
        <div
          className={`relative left-2  ${textSize} text-white bg-primary rounded-3xl w-6`}
        >
          <p>{total}</p>
        </div>
        <FaMapMarker
          style={{ color: "#2fdce1" }}
          size={40}
          onClick={() => onSelected}
        />
      </button>
    </div>
  );
};

const PositionMarker = ({ lat, lng }) => {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <div>
      <PositionalInfo showMoreInfo={showInfo} />
      <button
        className="position-marker"
        lat={lat}
        lng={lng}
        onMouseUp={() => setShowInfo(!showInfo)}
      >
        <FaCircle style={{ color: "red" }} size={18} />
      </button>
    </div>
  );
};

const PositionalInfo = ({ showMoreInfo }) => {
  if (showMoreInfo) {
    return <div className="posInfo">You are here!</div>;
  } else {
    return null;
  }
};
