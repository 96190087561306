import { gql, useLazyQuery } from "@apollo/client";
import { useState } from "react";
import { RestrictedSpinner } from "../misc/Spinner";

const GET_DEVICE = gql`
  query Query($id: String!) {
    getDevice(id: $id) {
      id
    }
  }
`;

export const DeviceCheck = ({ onNextPage, onBack }) => {
  const [verified, updateVerified] = useState(false);
  const [deviceId, updateDeviceId] = useState("");
  const [getDevice, { loading, error, data }] = useLazyQuery(GET_DEVICE);

    if (error) alert("Device not found. Please contact EVUp administration.");

    console.log("device id", deviceId)

  return (
    <div>
      <div className="flex flex-row mb-2">
        <div className="flex flex-col">
          <p>
            Enter the ID of your device. For Zappi users, this is your serial
            number.
          </p>
          <input
            type="text"
            placeholder="Device ID"
            className="bg-primary brandPrimaryColor rounded  p-2"
            onChange={(e) => updateDeviceId(e.target.value)}
          />
        </div>
        <button
          className={`ml-2 bg-green-600 text-white py-2 px-4 rounded mt-2`}
          onClick={async () => {
            await getDevice({
              variables: {
                id: deviceId,
              },
            });
          }}
        >
          {loading ? <RestrictedSpinner size={20} /> : (data?.getDevice?.id ? "Validated!" : "Validate")}
        </button>
      </div>
      <button
        className="bg-red-600 text-white py-2 px-4 rounded mt-2"
        onClick={(e) => {
          e.preventDefault();
          onBack();
        }}
      >
        Back
      </button>
      {data?.getDevice?.id && (
        <button
          className={`ml-2 bg-green-600 text-white py-2 px-4 rounded mt-2`}
          onClick={(e) => {
            e.preventDefault();
            onNextPage(deviceId);
          }}
        >
          Continue
        </button>
      )}
    </div>
  );
};
