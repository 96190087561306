import Firebase from "firebase";

import { useEffect, useState, createContext, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { gql } from "@apollo/client";
import { client } from "../apollo/client";
import { logout } from "../middleware/loginMiddleware";

const CURRENT_USER = gql`
  query Current_user {
    current_user {
      uid
      email
      name
      permissions {
        role
      }
      party_id
      phone_number
      company
      uam {
        key
        value
      }
      mfaEnrolled
      canEdit
      session_id
      customer_id
      lite
      tenancyState {
        tenancy_tier
        active
        closed
        evupManaged
      }
    }
  }
`;

const AuthContext = createContext(null);

export function ProvideAuth({ children }) {
  return (
    <AuthContext.Provider value={useProvideAuth()}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
};

export function useProvideAuth() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [user, updateUser] = useState({
    uid: undefined,
    email: undefined,
    name: undefined,
    permissions: undefined,
    party_id: undefined,
    phone_number: undefined,
    company: undefined,
    uam: undefined,
    mfaEnrolled: undefined,
    canEdit: undefined,
    session_id: undefined,
    customer_id: undefined,
    loading: true,
    lite: undefined,
  });

  async function fetchUser() {
    try {
      /*
       *  anything here will run once everytime the hook is called, so
       *  please handle with care, it is ok that client.query is called
       *  once for each location card, as gql client only fetches once :)
       */
      updateUser({
        ...user,
        loading: true,
      });
      const result = await client.query({
        query: CURRENT_USER,
        fetchPolicy: "no-cache",
      });

      var data = result?.data?.current_user;

      if (data?.tenancyState?.closed) {
        // If the tenancy account is closed, immediately log out of the application.
        await dispatch(logout());
        history.push("/");
      } else {
        updateUser({
          ...user,
          ...data,
          loading: false,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchUser();
    Firebase.auth().onIdTokenChanged(() => sleep().then((x) => fetchUser()));
  }, []);

  return user;
}

function sleep(ms = 100) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
