import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { Link, Redirect } from "react-router-dom";

import { FaAngleLeft, FaAngleRight, FaCog } from "react-icons/fa";
import Error from "../misc/Error";
import Spinner from "../misc/Spinner";
import { RightModal } from "../layout/rightModal";

import AdminCommands from "./Commands";
import Commands from "./PublicCommands";

import { EditEvseRightModal } from "../admin/EVSEList";
import { EcommerceSettingsRightModal } from "../admin/EcommerceSettings";
import MachineStatus from "./MachineStatus";
import { FirestoreDocument } from "react-firestore";

import { gql, useQuery } from "@apollo/client";

const DEVICE_LOGS = gql`
  query GetDeviceLogs($evseId: String!, $bookmark: Int!, $rows: Int) {
    getDeviceLogs(evse_id: $evseId, bookmark: $bookmark, rows: $rows) {
      uuid
      evse_id
      timestamp
      category
      message
    }
  }
`;

export const MachineDetail = ({ match, history }) => {
  const id = match.params.slug;

  return (
    <div>
      <FirestoreDocument path={`ocpp_logs/${id}`}>
        {({ error, isLoading, data }) => {
          console.log("ERROR", error);
          console.log("DATA", data);
          if (error || (!isLoading && !data.uid)) {
            // return <Error error={error} />;
            return <Redirect to="/notfound" />;
          }

          if (isLoading) {
            return <p>...</p>;
          }

          return (
            <div>
              <Toolbar machine={data} history={history} />
              <div className="flex">
                <MachineMetadata machine={data} />
                <LogsList machine={data} />
              </div>
            </div>
          );
        }}
      </FirestoreDocument>
    </div>
  );
};

/*
 *	The toolbar which is on the top of the page,
 *  allows user to access device configuration
 *  pages.
 */
const Toolbar = ({ machine, history }) => {
  const [showEditmeta, toggleShowEditMeta] = useState(false);
  const [showEcommerceSettings, toggleShowEcommerce] = useState(false);

  var heading = <p>{machine.id}</p>;

  if (machine?.metadata?.displayName) {
    heading = <p className="heading mr-2">{machine.metadata.displayName}</p>;
  }

  return (
    <div>
      <div className="flex flex-row">
        <div className="w-1/4 border rounded p-2 bg-white">
          {heading}
          <MachineStatus active={machine.active} />
        </div>
        <div className="ml-auto">
          <button
            className="btn bg-primary flex mx-1 border rounded p-2"
            onClick={() => toggleShowEcommerce(true)}
          >
            <FaCog className="inline text-white mr-2 mt-1" />
            <span className="inline brandPrimaryColor">Ecommerce Settings</span>
          </button>
        </div>
        <div className="ml-2">
          <RightModal title="Admin Commands">
            <AdminCommands chargePointId={machine.id} />
          </RightModal>
        </div>
        <div className="ml-2">
          <button
            className="btn bg-primary flex mx-1 border rounded p-2"
            onClick={() =>
              history.push(`${history.location.pathname}/configuration`)
            }
          >
            <FaCog className="inline text-white mr-2 mt-1" />
            <span className="inline brandPrimaryColor">Configure Device</span>
          </button>
        </div>
        <div className="ml-2">
          <button
            className="btn bg-primary flex mx-1 border rounded p-2"
            onClick={() => toggleShowEditMeta(!showEditmeta)}
          >
            <FaCog className="inline text-white mr-2 mt-1" />
            <span className="inline brandPrimaryColor">Edit Meta</span>
          </button>
        </div>
      </div>
      <EcommerceSettingsRightModal
        evse_id={machine.id}
        locationId={machine.location}
        show={showEcommerceSettings}
        onClose={() => toggleShowEcommerce(false)}
      />
      <EditEvseRightModal
        evse={machine}
        show={showEditmeta}
        onClose={() => toggleShowEditMeta(false)}
      />
    </div>
  );
};

/*
 *  Shows the user basic information about the device.
 */
const MachineMetadata = ({ machine }) => {
  return (
    <div className="w-1/4 bg-white border rounded mt-2">
      <p className="text-center p-2 mt-2">
        <Link className="btn-green" to={`/locations/${machine?.location}`}>
          View Location
        </Link>
      </p>
      <div className="p-2 mr-2 text-sm">
        <p className="mr-2 text-bold uppercase">ID: {machine.id}</p>
      </div>
      <div className=" p-2 mr-2 text-xs">
        <div className="flex justify-between">
          <span className="text-gray-500 mr-2">spSerialNo: </span>
          {machine.chargePointSerialNumber}
        </div>
        <div className="flex justify-between">
          <span className="text-gray-500 mr-2">chargePointVendor: </span>{" "}
          {machine.chargePointVendor}
        </div>
        <div className="flex justify-between">
          <span className="text-gray-500 mr-2">meterType: </span>{" "}
          {machine.meterType}
        </div>
        <div className="flex justify-between">
          <span className="text-gray-500 mr-2">chargePointModel: </span>{" "}
          {machine.chargePointModel}
        </div>
        <div className="flex justify-between">
          <span className="text-gray-500 mr-2">meterSerialNumber: </span>{" "}
          {machine.meterSerialNumber}
        </div>
        <div className="flex justify-between">
          <span className="text-gray-500 mr-2">chargeBoxSerialNumber: </span>{" "}
          {machine.chargeBoxSerialNumber}
        </div>
        <div className="flex justify-between">
          <span className="text-gray-500 mr-2">firmwareVersion: </span>{" "}
          {machine.firmwareVersion}
        </div>
      </div>
      <Commands evse_id={machine.id} />
    </div>
  );
};

/*
 *  List logs by timestamp in a descending fashion.
 */
const LogsList = ({ machine }) => {
  const [selectedLog, setSelectedLog] = useState(undefined);
  const [page, updatePage] = useState(1);
  const [rows, updateRows] = useState(50);
  const { loading, data, error, refetch } = useQuery(DEVICE_LOGS, {
    variables: {
      evseId: machine.id,
      bookmark: (page - 1) * 50,
      rows: rows,
    },
    pollInterval: 5000,
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error message={error?.message} />;
  }

  const deviceLogs = data?.getDeviceLogs ?? [];

  return (
    <div className="ml-2 w-full overflow-y-scroll heading mt-2">
      <PaginationBar
        page={page}
        onNewPage={(newPage) => {
          updatePage(newPage);
          refetch();
        }}
      />
      <h2 className="mt-1">OCPP Logs</h2>
      <div className="w-full flex items-stretch">
        <div className="w-2/3 text-sm bg-white">
          <div className="flex bg-gray-400 text-white">
            <div className="mr-4 p-1 w-20">Date</div>
            <div className="mr-4 p-1 w-20">Time</div>
            <div className="p-1 w-48">Message Type</div>
            <div className="mr-2 p-1 w-16">Action</div>
          </div>
          <div>
            {deviceLogs.map((log, index) => (
              <LogEntry
                log={log}
                index={index}
                key={index}
                onClick={() => setSelectedLog(log)}
              />
            ))}
          </div>
          <p className="p-2">No logs to show!</p>
        </div>
        <div className="w-full pl-2 h-full">
          <div className="border rounded sticky text">
            <pre style={{ fontSize: "12px", fontFamily: "sans-serif" }}>
              {selectedLog &&
                JSON.stringify(JSON.parse(selectedLog.message), null, 4)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};

const PaginationBar = ({ page, onNewPage }) => {
  return (
    <div className="w-full bg-white border rounded items-center flex flex-row py-2 gap-4">
      <button
        className="flex flex-row items-center"
        onClick={() => onNewPage(Math.max(page - 1, 0))}
      >
        <FaAngleLeft size={24} />
        Prev
      </button>
      <button
        className="flex flex-row items-center"
        onClick={() => onNewPage(page + 1)}
      >
        Next
        <FaAngleRight size={24} />
      </button>
    </div>
  );
};

/*
 *  A single log entry, is created when the device
 *  communicates with the server.
 */
const LogEntry = ({ log, index, onClick }) => {
  const date = new Date(parseInt(log.timestamp)).toISOString();

  return (
    <div
      key={index}
      className="even:bg-gray-200 flex border-b-2 border-gray-500"
    >
      <div className="p-1 w-24">
        <Moment format="DD-MM-YYYY">{date}</Moment>
      </div>
      <div className="mr-12 p-1 w-12">
        <Moment format="HH:mm:ss">{date}</Moment>
      </div>
      <div className="mr-8 p-1 w-40">{log.category.replaceAll("_", " ")}</div>
      <div className="mr-8 p-1 w-32">
        {JSON.parse(log.message).action
          ? JSON.parse(log.message).action
          : "Info"}
      </div>
      <button className="w-24 border p-1 inline ml-auto" onClick={onClick}>
        {"{ See log }"}
      </button>
    </div>
  );
};
