import { Formik, Form, Field } from "formik";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../../middleware/loginMiddleware";

export const ForgotPassword = ({}) => {
  const dispatch = useDispatch();

  return (
    <div>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={({ email }) => dispatch(forgotPassword(email))}
      >
        <Form className="relative bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 ">
          <p className="text-black text-sm">
            Enter your email and we will send you a password reset link if the
            user exists.
          </p>
          <div className="mb-4 text-lg mt-2">
            <Field
              type="text"
              name="email"
              placeholder="Email"
              className="border-2 rounded"
            />
          </div>
          <div className="mb-4 flex justify-between">
            <button
              className="btn-green block m-auto border border-black"
              type="submit"
            >
              Send Email
            </button>
          </div>
        </Form>
      </Formik>
      <button className="text-xs block m-auto"> Sign In</button>
    </div>
  );
};
