import { useEffect, useState } from "react";

import { gql } from "@apollo/client";
import { client } from "../apollo/client";

const THEME = gql`
  query Account_theme {
    account_theme {
      primary_color
      secondary_color
      logo_url
    }
  }
`;

export function useTheme() {
  // DEPRECATED - not currently functional
  const [theme, updateTheme] = useState({
    primaryColor: "#333",
    secondaryColor: "#2fdce1",
  });

  async function fetchTheme() {
    try {
      /*
       *  anything here will run once everytime the hook is called, so
       *  please handle with care, it is ok that client.query is called
       *  once for each location card, as gql client only fetches once :)
       */
      const result = await client.query({ query: THEME });
      var data = result.data.account_theme;
      updateTheme({
        ...theme,
        primaryColor: data.primary_color,
        secondaryColor: data.secondary_color,
        logo: data.logo_url,
      });
    } catch (err) {
      //console.log(err);
    }
  }

  useEffect(() => {
    fetchTheme();
  }, []);

  return theme;
}
