export const ADD_FACILITY = "ADD_FACILITY";
export const REMOVE_FACILITY = "REMOVE_FACILITY";
export const IMAGE = "IMAGE";
export const DIRECTIONS = "DIRECTIONS";

const INITIAL_STATE = {
  facilities: [],
};

export function locationOCPIInfoFormReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_FACILITY: {
      if (
        action.facility &&
        action.facility !== "none" &&
        state.facilities?.indexOf(action.facility) === -1
      ) {
        return {
          ...state,
          facilities: [...state.facilities, action.facility],
        };
      }
      break;
    }
    case REMOVE_FACILITY: {
      return {
        ...state,
        facilities: [...state.facilities.filter((y) => y !== action.facility)],
      };
    }
    case DIRECTIONS: {
      return {
        ...state,
        directions: action.directions,
      };
    }
    case IMAGE: {
      return {
        ...state,
        images: action.image,
      };
    }
  }
  return state;
}
