import Spinner from "../misc/Spinner";

import { FaMinusCircle, FaTrash, FaMinus, FaPlus } from "react-icons/fa";
import { useState } from "react";
import { ALLOWED_TYPES } from "../ecommerce/LocationMetadata";
import { gql, useMutation } from "@apollo/client";
import { Tooltip } from "../../components/Tooltip";
import Joi from "joi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  PRESET_NAME,
  MODEL_NAME,
  VENDOR,
  ADD_CAPABILITY,
  REMOVE_CAPABILITY,
  CONNECTORS,
  IMAGE,
  CHARGE_POINT_MODELS,
} from "../../reducers/devicePresetsReducer";

import { STANDARD_TO_TITLE, TYPES } from "../ecommerce/LocationMetadata";

import { connect } from "react-redux";
import { AiFillDribbbleSquare } from "react-icons/ai";

export const DEVICE_CAPABILITIES = [
  "CHARGING_PROFILE_CAPABLE",
  "CHARGING_PREFERENCES_CAPABLE",
  "CHIP_CARD_SUPPORT",
  "CONTACTLESS_CARD_SUPPORT",
  "CREDIT_CARD_PAYABLE",
  "DEBIT_CARD_PAYABLE",
  "PED_TERMINAL",
  "REMOTE_START_STOP_CAPABLE",
  "RESERVABLE",
  "RFID_READER",
  "TOKEN_GROUP_CAPABLE",
  "UNLOCK_CAPABLE",
];

export const CONNECTOR_TYPES = [
  "CHADEMO",
  "IEC_62196_T1",
  "IEC_62196_T1_COMBO",
  "IEC_62196_T2",
  "IEC_62196_T2_COMBO",
];

export const POWER_TYPE = ["AC_1_PHASE", "AC_3_PHASE", "DC"];

export const CONNECTOR_FORMAT = ["SOCKET", "CABLE"];

const CREATE_DEVICE_PRESET = gql`
  mutation createDevicePreset(
    $preset_name: String!
    $vendor: String!
    $capabilities: [String]!
    $connectors: [OCPIConnectorInput]!
    $model_name: String!
    $image: Upload
    $charge_point_models: [String]!
  ) {
    createDevicePreset(
      preset_name: $preset_name
      vendor: $vendor
      capabilities: $capabilities
      connectors: $connectors
      model_name: $model_name
      image: $image
      charge_point_models: $charge_point_models
    )
  }
`;

const DevicePresetForm = ({ device_preset, onComplete }) => {
  const [createDevicePreset, { loading, error }] =
    useMutation(CREATE_DEVICE_PRESET);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <h1>{error?.message}</h1>;
  }

  return (
    <div>
      <DevicePresetMetaContainer />
      <div className="mt-2">
        <h1 className="formSectionTitle">Device Capabilities</h1>
        <PresetCapabilitySelectorContainer />
      </div>
      <div className="mt-2">
        <h1 className="formSectionTitle">Device Connectors</h1>
        <PresetConnectorContainer />
      </div>
      <div className="mt-2">
        <h1 className="formSectionTitle">Applicable Charge Point Models</h1>
        <PresetChargePointModelsContainer />
      </div>
      <div className="mt-2">
        <h1 className="formSectionTitle">Image (optional)</h1>
        <PresetImageContainer />
      </div>
      <button
        id="create-preset"
        className="mt-2 btn-green"
        onClick={async () => {
          var valid = validateDevicePreset(device_preset);

          if (!valid) {
            return;
          }

          await createDevicePreset({
            variables: {
              preset_name: device_preset.preset_name,
              model_name: device_preset.model_name,
              vendor: device_preset.vendor,
              capabilities: device_preset.capabilities,
              connectors: Object.keys(device_preset.connectors).map(
                (x) => device_preset.connectors[x]
              ),
              image: device_preset.image,
              charge_point_models: device_preset.charge_point_models,
            },
          });
          if (!error) {
            alert("Preset Created Successfully.");
            onComplete(true);
          }
        }}
      >
        Submit
      </button>
    </div>
  );
};

const mapStateToPresetProps = (state) => {
  const devicePresets = state.devicePresets;
  return {
    device_preset: devicePresets,
  };
};

export const DevicePresetFormContainer = connect(
  mapStateToPresetProps,
  {}
)(DevicePresetForm);

const DevicePresetMeta = ({
  handleUpdatePresetName,
  handleUpdateModelName,
  handleUpdateVendor,
}) => {
  return (
    <div className="mt-2 border rounded p-2" key="preset-name">
      <div className="flex flex-row">
        <h1 className="mr-2 w-1/2 p-1">Preset Name:</h1>
        <input
          id="preset-name"
          className="border rounded ml-auto mr-1 pl-1"
          type="text"
          onChange={(e) => handleUpdatePresetName(e.target.value)}
        />
      </div>
      <div className="flex flex-row pt-2" key="model-name">
        <h1 className="mr-2 w-1/2 p-1">Model Name:</h1>
        <input
          id="model-name"
          className="border rounded ml-auto mr-1 pl-1"
          type="text"
          onChange={(e) => handleUpdateModelName(e.target.value)}
        />
      </div>
      <div className="flex flex-row pt-2" key="device-vendor">
        <h1 className="mr-2 w-1/2 p-1">Device Vendor:</h1>
        <input
          id="device-vendor"
          className="border rounded ml-auto mr-1 pl-1"
          type="text"
          onChange={(e) => handleUpdateVendor(e.target.value)}
        />
      </div>
    </div>
  );
};

const handleUpdatePresetName = (preset_name) => ({
  type: PRESET_NAME,
  preset_name,
});

const handleUpdateModelName = (model_name) => ({
  type: MODEL_NAME,
  model_name,
});

const handleUpdateVendor = (vendor) => ({
  type: VENDOR,
  vendor,
});

const mapStateToDevicePresetMetaProps = (state) => {
  return {};
};

const DevicePresetMetaContainer = connect(mapStateToDevicePresetMetaProps, {
  handleUpdatePresetName,
  handleUpdateModelName,
  handleUpdateVendor,
})(DevicePresetMeta);

/*                CAPABILITY FORM                          */

export const PresetCapabilitySelector = ({
  capabilities,
  handleAddCapabilities,
  handleRemoveCapabilities,
}) => {
  /* A local state is used to know which capability is selected from dropdown */
  const [selectedCapability, updateSelectedCapability] = useState(undefined);

  var capabilityList = null;

  if (capabilities?.length) {
    capabilityList = capabilities.sort().map((x) => (
      <PresetCapabilityTile
        key={x}
        name={x}
        onDeleted={() => {
          handleRemoveCapabilities(x);
        }}
      />
    ));
  }

  return (
    <div className="mt-2" key="device-capability-selector">
      {capabilityList}
      <h1 key="title">Add capability:</h1>
      <div className="flex flex-row">
        <select
          id="capabilities"
          className="border rounded mb-1"
          name="location"
          onChange={(e) => updateSelectedCapability(e.target.value)}
        >
          <option value="none" label="Select a capability" key="none" />
          {DEVICE_CAPABILITIES.map((x) => (
            <option value={x} label={x} key={x} />
          ))}
        </select>
        <div className="ml-2 mt-0.5">
          <button
            id="add-capability"
            type="button"
            onClick={() => {
              handleAddCapabilities(selectedCapability);
            }}
          >
            <FaPlus size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

const PresetCapabilityTile = ({ name, onDeleted }) => {
  return (
    <div className="m-2 border rounded flex flex-row">
      <p className="p-2" key={name}>
        {name}
      </p>
      <button
        id="delete-capability"
        key="button"
        type="button"
        className="ml-auto mr-2"
        onClick={onDeleted}
      >
        <FaMinusCircle />
      </button>
    </div>
  );
};

const mapStateToCapabilityProps = (state) => {
  const devicePresets = state.devicePresets;
  return {
    capabilities: devicePresets.capabilities,
  };
};

/* Add a single capability to capability state */
const handleAddCapabilities = (capabilities) => ({
  type: ADD_CAPABILITY,
  capabilities,
});

/* Remove a capability from capability state */
const handleRemoveCapabilities = (capabilities) => ({
  type: REMOVE_CAPABILITY,
  capabilities,
});

const PresetCapabilitySelectorContainer = connect(mapStateToCapabilityProps, {
  handleAddCapabilities,
  handleRemoveCapabilities,
})(PresetCapabilitySelector);

/*                CONNECTOR FORM                          */

const PresetConnectorEditor = ({ connectors, handleUpdateConnectors }) => {
  return (
    <div>
      {Object.keys(connectors).map((x) => (
        <PresetConnectorTile
          key={x}
          connector={connectors[x]}
          index={x}
          //Remove ith connector
          deleteConnector={() => {
            var connectorsClone = {
              ...connectors,
            };
            //remove connector from object.
            delete connectorsClone[connectors[x].id];
            handleUpdateConnectors(connectorsClone);
          }}
        />
      ))}
      <PresetConnectorForm
        createDeviceConnector={(conn) => {
          var connectorId = conn.connectorId;
          //dont mutate state directly!
          var connectorsClone = { ...connectors };
          if (!connectorId) {
            connectorId = Object.keys(connectorsClone).length + 1;
          }
          connectorsClone[connectorId] = {
            id: String(connectorId),
            standard: conn.connectorStandard,
            format: conn.connectorFormat,
            power_type: conn.connectorPowerType,
            max_voltage: conn.maxVoltage,
            max_amperage: conn.maxAmperage,
          };
          handleUpdateConnectors(connectorsClone);
        }}
      />
    </div>
  );
};

const PresetConnectorTile = ({ connector, index, deleteConnector }) => {
  return (
    <div key={index} className="p-2 border rounded mt-2">
      <div className="flex flex-row">
        <h1> Connector #{parseInt(index)}</h1>
        <button
          id="delete-connector"
          className="ml-auto mr-2"
          type="button"
          onClick={deleteConnector}
        >
          <FaTrash />
        </button>
      </div>
      <h1> Standard: {TYPES[STANDARD_TO_TITLE[connector.standard]].title}</h1>
      <h1> Format: {connector.format}</h1>
      <h1> Power Type: {connector.power_type.replace(/_/g, " ")}</h1>
      <h1> Max Voltage: {connector.max_voltage}V</h1>
      <h1> Max Amperage: {connector.max_amperage}A</h1>
    </div>
  );
};

const PresetConnectorForm = ({ createDeviceConnector }) => {
  const [connectorStandard, updateConnectorStandard] = useState(undefined);
  const [connectorFormat, updateConnectorFormat] = useState(undefined);
  const [connectorPowerType, updateConnectorPowerType] = useState(undefined);
  const [maxVoltage, updateConnectorVoltage] = useState(undefined);
  const [maxAmperage, updateConnectorAmperage] = useState(undefined);

  return (
    <div className="mt-2">
      <h1 className="font-bold">Add Connector</h1>
      <h1>Connector Standard</h1>
      <select
        id="connector-standard"
        className="border rounded mb-1 mt-1"
        name="standard"
        onChange={(e) => updateConnectorStandard(e.target.value)}
      >
        <option value="none" key="none" label="Select a standard" />
        {ALLOWED_TYPES.map((x) => (
          <option value={x} label={TYPES[STANDARD_TO_TITLE[x]].title} key={x} />
        ))}
      </select>
      <h1>Connector Format</h1>
      <select
        id="connector-format"
        className="border rounded mb-1 mt-1"
        name="format"
        onChange={(e) => updateConnectorFormat(e.target.value)}
      >
        <option value="none" key="none" label="Select a format" />
        {CONNECTOR_FORMAT.map((x) => (
          <option value={x} label={x} key={x} />
        ))}
      </select>
      <h1>Connector Power Type</h1>
      <select
        id="power-type"
        className="border rounded mb-1 mt-1"
        name="powerType"
        onChange={(e) => updateConnectorPowerType(e.target.value)}
      >
        <option value="none" key="none" label="Select a power type" />
        {POWER_TYPE.map((x) => (
          <option value={x} label={x.replace(/_/g, " ")} key={x} />
        ))}
      </select>
      <div className="flex flex-row">
        <h1>Max Voltage</h1>
        <Tooltip className="ml-2 my-auto">
          Note: This must be an integer
        </Tooltip>
      </div>
      <input
        id="max-voltage"
        className="border rounded mb-1 mt-1"
        type="number"
        onChange={(e) => updateConnectorVoltage(e.target.value)}
      />
      <div className="flex flex-row">
        <h1>Max Amperage</h1>
        <Tooltip className="ml-2 my-auto">
          Note: This must be an integer
        </Tooltip>
      </div>
      <input
        id="max-amperage"
        className="border rounded mb-1 mt-1"
        type="number"
        onChange={(e) => updateConnectorAmperage(e.target.value)}
      />
      <br />
      <button
        id="add-connector"
        className="bg-blue-400 text-white text-sm font-bold py-1 px-2 rounded mt-2"
        type="button"
        onClick={() => {
          if (
            !connectorStandard ||
            !connectorFormat ||
            !connectorPowerType ||
            !maxVoltage ||
            !maxAmperage
          ) {
            alert("Ensure all fields are filled out!");
            return;
          }

          var deviceConnectorObject = {
            connectorStandard,
            connectorFormat,
            connectorPowerType,
            maxVoltage: parseFloat(maxVoltage),
            maxAmperage: parseFloat(maxAmperage),
          };

          createDeviceConnector(deviceConnectorObject);
        }}
      >
        Add Connector
      </button>
    </div>
  );
};

const mapStateToConnectorProps = (state) => {
  const devicePresets = state.devicePresets;
  return {
    connectors: devicePresets.connectors,
  };
};

const handleUpdateConnectors = (connectors) => ({
  type: CONNECTORS,
  connectors,
});

const PresetConnectorContainer = connect(mapStateToConnectorProps, {
  handleUpdateConnectors,
})(PresetConnectorEditor);

/*                  IMAGE FORM                          */

const mapStateToImageProps = (state) => {
  const devicePresets = state.devicePresets;
  return {
    image: devicePresets.image,
  };
};

const PresetImageSelector = ({ image, handleUpdateImage }) => {
  var imageList = null;

  return (
    <div className="mt-2" key="device-capability-selector">
      {imageList}
      <h1 key="title">Add image:</h1>
      <div className="flex flex-row">
        <input
          type="file"
          id="img"
          name="img"
          accept="image/*"
          onChange={(e) => handleUpdateImage(e.target.files[0])}
        />
      </div>
    </div>
  );
};

const handleUpdateImage = (image) => ({
  type: IMAGE,
  image,
});

const PresetImageContainer = connect(mapStateToImageProps, {
  handleUpdateImage,
})(PresetImageSelector);

/*                Charge Point Models              */

const mapStateToChargePointModelsProps = (state) => {
  const devicePresets = state.devicePresets;
  return {
    charge_point_models: devicePresets.charge_point_models,
  };
};

const PresetChargePointModels = ({
  charge_point_models = [],
  handleUpdateChargePointModels,
}) => {
  const [current_model, updateCurrentModel] = useState("");

  return (
    <div className="mt-2" key="device-capability-selector">
      {charge_point_models.map((x) => (
        <div key={x} className="border rounded mb-2 p-2 flex flex-row">
          <h1>{x}</h1>
          <button
            id="delete-charger-point"
            className="ml-auto mr-2"
            onClick={() =>
              handleUpdateChargePointModels(
                charge_point_models.filter((y) => x !== y)
              )
            }
          >
            <FaMinus />
          </button>
        </div>
      ))}
      <h1 key="title">Add Charge Point Model:</h1>
      <div className="flex flex-row">
        <input
          id="charger-point"
          value={current_model}
          onChange={(e) => updateCurrentModel(e.target.value)}
          type="text"
          className="border rounded p-2"
          placeholder="Charge Point Model"
        />
        <button
          id="add-charger-point"
          className="ml-auto border rounded bg-blue-400 px-4 py-2 text-white"
          onClick={() => {
            if (
              current_model.length > 0 &&
              charge_point_models.indexOf(current_model) === -1
            ) {
              handleUpdateChargePointModels([
                ...charge_point_models,
                current_model,
              ]);
            }
          }}
        >
          Add
        </button>
      </div>
    </div>
  );
};

const handleUpdateChargePointModels = (models) => ({
  type: CHARGE_POINT_MODELS,
  models,
});

const PresetChargePointModelsContainer = connect(
  mapStateToChargePointModelsProps,
  {
    handleUpdateChargePointModels,
  }
)(PresetChargePointModels);

const validateDevicePreset = (device_preset) => {
  console.log(device_preset.connectors);

  const schema = Joi.object().keys({
    preset_name: Joi.string().required(),
    model_name: Joi.string().required(),
    vendor: Joi.string().required(), //The info icon and checkbox next to this should be styled to be inline with 'OCPI Sharing'

    // Device Capabilities
    capabilities: Joi.array()
      .items(Joi.string().allow(...DEVICE_CAPABILITIES))
      .required(),

    // Device Connectors

    connectors: Joi.object()
      .pattern(
        Joi.string()
          .pattern(/^[0-9]+$/)
          .required(),
        Joi.object({
          id: Joi.string().required(),
          standard: Joi.string()
            .required()
            .allow(...ALLOWED_TYPES),

          format: Joi.string()
            .required()
            .allow(...CONNECTOR_FORMAT),
          power_type: Joi.string()
            .required()
            .allow(...POWER_TYPE),
          max_voltage: Joi.number().min(1),
          max_amperage: Joi.number().min(1),
        })
      )
      .required(),

    image: Joi.any(),
    charge_point_models: Joi.array().min(1).items(Joi.string()).required(),
  });

  // console.log(schema);

  var result = schema.validate(device_preset);
  if (result.error) {
    alert(result.error);
  }
  return !result.error;
};
