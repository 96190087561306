import { useState } from "react";
import { createToken } from "../../actions/cards";

const TOKEN_TYPES = ["AD_HOC_USER", "APP_USER", "OTHER", "RFID"];

const WHITELIST_TYPES = ["ALWAYS", "ALLOWED", "ALLOWED_OFFLINE", "NEVER"];

export const NewCard = ({}) => {
  const [card, updateCard] = useState({
    country_code: "AU",
    party_id: "EUP",
  });

  return (
    <div>
      <div className="flex mt-2">
        <p className="p-2">ID</p>
        <input
          className="border rounded ml-auto"
          onChange={(e) => {
            updateCard({
              ...card,
              id: e.target.value,
            });
          }}
        ></input>
      </div>
      <div className="flex mt-2">
        <p className="p-2">Party ID</p>
        <input
          className="border rounded ml-auto"
          onChange={(e) => {
            updateCard({
              ...card,
              party_id: e.target.value,
            });
          }}
        ></input>
      </div>
      <div className="flex mt-2">
        <p className="p-2">Type</p>
        <select
          className="border rounded ml-auto"
          name="Type"
          onChange={(e) => {
            updateCard({
              ...card,
              type: e.target.value,
            });
          }}
        >
          <option value="none" label="Type" />
          {TOKEN_TYPES.map((x) => (
            <option value={x} label={x} />
          ))}
        </select>
      </div>
      <div className="flex mt-2">
        <p className="p-2">Contract ID</p>
        <input
          className="border rounded ml-auto"
          onChange={(e) => {
            updateCard({
              ...card,
              contract_id: e.target.value,
            });
          }}
        ></input>
      </div>
      <div className="flex mt-2">
        <p className="p-2">Visual Number</p>
        <input
          className="border rounded ml-auto"
          onChange={(e) => {
            updateCard({
              ...card,
              visual_number: e.target.value,
            });
          }}
        ></input>
      </div>
      <div className="flex mt-2">
        <p className="p-2">Issuer</p>
        <input
          className="border rounded ml-auto"
          onChange={(e) => {
            updateCard({
              ...card,
              issuer: e.target.value,
            });
          }}
        ></input>
      </div>
      <div className="flex mt-2">
        <p className="p-2">Group ID</p>
        <input
          className="border rounded ml-auto"
          onChange={(e) => {
            updateCard({
              ...card,
              group_id: e.target.value,
            });
          }}
        ></input>
      </div>
      <div className="flex mt-2">
        <p className="p-2">Valid</p>
        <input
          id="valid"
          type="checkbox"
          className="ml-auto mt-4 mr-2"
          checked={card.valid}
          onChange={(e) => {
            updateCard({
              ...card,
              valid: !card.valid,
            });
          }}
        />
      </div>
      <div className="flex mt-2">
        <p className="p-2">Whitelist</p>
        <select
          className="border rounded ml-auto"
          name="Whitelist"
          onChange={(e) => {
            updateCard({
              ...card,
              whitelist: e.target.value,
            });
          }}
        >
          <option value="none" label="Whitelist" />
          {WHITELIST_TYPES.map((x) => (
            <option value={x} label={x} />
          ))}
        </select>
      </div>
      <div>
        <button
          onClick={async () => {
            console.debug(card);
            var result = await createToken(card);
            if (result) {
              alert("Operation Success!");
            } else {
              alert("Operation Failure!");
            }
          }}
          className="bg-blue-400 text-white py-2 px-4 rounded mt-2"
        >
          Save
        </button>
      </div>
    </div>
  );
};
