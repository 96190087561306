import React, { useLayoutEffect, useRef } from "react";
import { TariffWrapper } from "./PaymentFlow";
import { SessionStatus } from "./SessionStatus";
import { FaChargingStation } from "react-icons/fa";
import { useQuery, gql, useMutation } from "@apollo/client";
import Spinner from "../misc/Spinner";
import ErrorMessage from "../misc/ErrorMessage";
import { useAuth } from "../../hooks/useAuth";

const GET_DEVICE = gql`
  query GetDevice($getDeviceId: String!) {
    getDevice(id: $getDeviceId) {
      connected
      id
      name
      charge_point_model
      soft_blocked
      vendor
      charging_type
      tariff {
        country_code
        party_id
        id
        currency
        type
        tariff_alt_text {
          language
          text
        }
        tariff_alt_url
        elements {
          price_components {
            type
            price
            vat
            step_size
          }
        }
        last_updated
      }
      capabilities
      connectors {
        status
        id
        standard
        format
        power_type
        voltage
        max_amperage
        current_amperage
        session_id
        physical_reference
      }
      last_heartbeat
      last_updated
      ocpp
      start_on_preparing
      authLevel
      whitelist
    }
  }
`;

const REMOTE_START = gql`
  mutation RemoteStart($evseId: String!, $connectorId: Int!) {
    remoteStart(evse_id: $evseId, connector_id: $connectorId)
  }
`;

const WHITELIST_START = gql`
  mutation InitiateWhitelistCharging($evseId: String!, $connectorId: Int!) {
    initiateWhitelistCharging(evse_id: $evseId, connector_id: $connectorId)
  }
`;

export const ConnectorSelectedWrapper = ({ match, userProfile }) => {
  var evseId = match.params.evseId;
  var connectorId = match.params.connectorId;
  const { error, loading, data } = useQuery(GET_DEVICE, {
    variables: {
      getDeviceId: evseId,
    },
    fetchPolicy: "no-cache",
    pollInterval: 5000,
  });
  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <ErrorMessage message={`${error.message}`} />;
  }
  var evse = data.getDevice;

  return <ConnectorSelected evse={evse} connectorId={parseInt(connectorId)} />;
};

const ConnectorSelected = ({ evse, connectorId }) => {
  var session = evse.connectors[connectorId - 1].session_id;
  const user = useAuth();

  const containerRef = useRef(null);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const subscriptionBannerNode = document.getElementsByClassName(
        "subscription-banner"
      )[0];
      containerRef.current.style.height = `calc(100vh - ${
        subscriptionBannerNode ? subscriptionBannerNode.offsetHeight : 0
      }px)`;
    }
  }, [containerRef, user.tenancyState]);

  // Session exists for connector
  if (session) {
    return <SessionStatus evse_id={evse.id} connector_id={connectorId} />;
  }

  // First check the device is online
  if (
    evse.connectors[connectorId - 1].status === "Unavailable" ||
    evse.connectors[connectorId - 1].status === "Faulted" ||
    evse.connectors[connectorId - 1].status === "Unknown"
  ) {
    return (
      <div
        ref={containerRef}
        className="bg-primary overflow-auto flex w-full items-center justify-center"
      >
        <div className="text-center text-white justify-center">
          This machine is offline. Please contact support.
        </div>
      </div>
    );
  }

  // Show free charging UI if charger is free, otherwise check user is whitelisted and show whitelist UI
  var free = !evse.tariff;
  var whitelisted =
    evse.whitelist &&
    (evse.whitelist.includes(user?.email) ||
      evse.whitelist.includes(user?.party_id));
  if (free) {
    return (
      <FreeCharging
        evseId={`${evse.id}`}
        connectorId={connectorId}
        elemRef={containerRef}
      />
    );
  } else if (whitelisted) {
    return (
      <WhitelistCharging
        evseId={`${evse.id}`}
        connectorId={connectorId}
        elemRef={containerRef}
      />
    );
  }

  return (
    <div
      ref={containerRef}
      className="bg-primary overflow-auto flex w-full items-center justify-center"
    >
      <div className="">
        <CheckConnectorStatus evse={evse} connectorId={connectorId}>
          <TariffWrapper evse={evse} connector_id={connectorId} />
        </CheckConnectorStatus>
      </div>
    </div>
  );
};

const CheckConnectorStatus = ({ evse, connectorId, children }) => {
  var connector = evse.connectors[connectorId - 1];

  var message;

  if (connector.status === "Preparing") {
    return children;
  } else if (connector.status === "Available") {
    message = <h1>Plug in to start.</h1>;
  } else {
    message = <h1>Please unplug and plug back in.</h1>;
  }

  return (
    <div className="border rounded bg-white p-8 flex-col">
      <div className="flex flex-row justify-center mb-2">
        <FaChargingStation size={72} style={{ color: "#333" }} />
      </div>
      <div>{message}</div>
    </div>
  );
};

const FreeCharging = ({ evseId, connectorId, elemRef }) => {
  const [remoteStart, remoteStartResult] = useMutation(REMOTE_START);

  return (
    <div
      ref={elemRef}
      className="bg-primary overflow-auto flex w-full items-center justify-center"
    >
      <div className="justify-center align-center flex flex-col gap-y-6">
        <h1 className="font-bold text-white mx-auto text-center px-3">
          Ensure the charger is plugged in at both ends, then activate your
          session below!
        </h1>

        {remoteStartResult.loading ? (
          <Spinner />
        ) : (
          <button
            className="rounded-lg p-4 w-48 mx-auto brandSecondaryColor bg-secondary border border-black"
            onClick={async () => {
              await remoteStart({
                variables: {
                  evseId,
                  connectorId: ~~connectorId,
                },
              });
              if (!remoteStartResult.error)
                // window.location.reload(false);
                remoteStartResult?.refetch();
            }}
          >
            Activate charger
          </button>
        )}
        {remoteStartResult.error && (
          <div className="font-bold text-red mx-auto text-center px-3">
            Error: {remoteStartResult.error}
          </div>
        )}
        <h1 className="font-bold text-white mx-auto text-center px-3">
          Come back to this page at anytime to stop the session.
        </h1>
      </div>
    </div>
  );
};

const WhitelistCharging = ({ evseId, connectorId, elemRef }) => {
  const [initiateWhitelistCharging, initiateResult] =
    useMutation(WHITELIST_START);

  return (
    <div
      ref={elemRef}
      className="bg-primary overflow-auto flex w-full items-center justify-center"
    >
      <div className="justify-center align-center flex flex-col gap-y-6">
        <h1 className="font-bold brandPrimaryColor mx-auto text-center px-3">
          You are whitelisted for free charging on this device!
        </h1>
        <h1 className="font-bold text-white mx-auto text-center px-3">
          Ensure the charger is plugged in at both ends, then activate your
          session below
        </h1>

        {initiateResult.loading ? (
          <Spinner />
        ) : (
          <button
            className="rounded-lg p-4 w-48 mx-auto brandSecondaryColor bg-secondary border border-black"
            onClick={async () => {
              await initiateWhitelistCharging({
                variables: {
                  evseId,
                  connectorId,
                },
              });
              if (!initiateResult.error) window.location.reload(false);
            }}
          >
            Activate charger
          </button>
        )}
        {initiateResult.error && (
          <div className="font-bold text-red mx-auto text-center px-3">
            Error: {initiateResult.error}
          </div>
        )}
        <h1 className="font-bold text-white mx-auto text-center px-3">
          Come back to this page at anytime to stop the session.
        </h1>
      </div>
    </div>
  );
};
