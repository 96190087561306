import { React, useState, useLayoutEffect, useRef, useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { FirestoreCollection, FirestoreDocument } from "react-firestore";
import { withRouter } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";
import DataGrid from "react-data-grid";
import _ from "lodash";

import { ButtonlessModal } from "../layout/rightModal";
import { CreateUser, EditUser } from "./UserActions";
import { UAMEditor } from "./UAMEditor";
import Spinner from "../misc/Spinner";
import ErrorMessage from "../misc/ErrorMessage";
import { useAuth } from "../../hooks/useAuth";
import { RightModal } from "../layout/rightModal";
import { UpdateBankDetails } from "../tenancy/UpdateBankDetails";
import { ExportButton } from "../sessions/SessionsPage";

const USERS = gql`
  query Users {
    users {
      uid
      email
      name
      permissions {
        role
      }
      party_id
      phone_number
      company
      uam {
        key
        value
      }
      mfaEnrolled
      canEdit
      session_id
      customer_id
    }
  }
`;

// This has been migrated to act as a tenancy managing page (each tenancy managers their users here)
export const UserManager = () => {
  const { loading, error, data } = useQuery(USERS, {
    fetchPolicy: "no-cache",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [partyId, setPartyId] = useState("");
  const [roleType, setRoleType] = useState("");
  const searchInputRef = useRef();

  var role = useAuth()?.permissions?.role;

  const partyIdsMemoized = useMemo(
    () =>
      _.sortBy(
        _.uniqBy(data?.users, "party_id").map((x) => ({
          label: x.party_id,
          value: x.party_id,
        })),
        (x) => x.label.toLowerCase()
      ),
    [data?.users]
  );

  const roleTypesMemoized = useMemo(
    () =>
      _.sortBy(
        _.uniqBy(data?.users, "permissions.role")
          .filter((x) => x.permissions?.role)
          .map((x) => {
            const value = x.permissions.role;
            switch (value) {
              case "sysadmin":
                return {
                  label: "System Admin",
                  value,
                };
              case "tenancyadmin":
                return {
                  label: "Tenancy Admin",
                  value,
                };
              case "locationadmin":
                return {
                  label: "Location Admin",
                  value,
                };
              case "user":
                return {
                  label: "User",
                  value,
                };
              default:
                return {
                  label: _.startCase(_.lowerCase(value)),
                  value,
                };
            }
          }),
        ["label"]
      ),
    [data?.users]
  );

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  // All data filters
  const parsedSearchQuery = searchQuery.toLowerCase().trim();
  const searchResults = (data?.users || []).filter(
    (x) =>
      (x.party_id || "").toLowerCase().includes(parsedSearchQuery) ||
      (x.name || "").toLowerCase().includes(parsedSearchQuery) ||
      (x.email || "").toLowerCase().includes(parsedSearchQuery)
  );
  const filterResults = searchResults.filter(
    (x) =>
      (!partyId || x.party_id === partyId) &&
      (!roleType || x.permissions?.role === roleType)
  );
  const gridData = filterResults
    .map((x) => mapUserToRow(x, [], roleTypesMemoized))
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div>
      <UserManagerHeader role={role} users={gridData} />
      <hr className="my-2 border border-gray-400" />
      <div className="flex">
        <div className="flex-column">
          <div style={{ width: "20.1rem" }}>
            <div className="relative flex w-full flex-wrap items-stretch">
              <input
                ref={searchInputRef}
                type="search"
                className="relative m-0 block flex-auto rounded border border-solid border-neutral-300 bg-transparent bg-clip-padding pl-3 pr-1 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_#2fdce1] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
                placeholder="Search by party id/name/email"
                aria-label="Search"
                autoFocus
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <IoIosSearch className="brandPrimaryColor mt-1" size={30} />
            </div>
          </div>
        </div>
        {role === "sysadmin" && (
          <>
            <div className="flex-column">
              <div
                style={{ height: 36 }}
                className="ml-4 flex flex-row bg-primary brandPrimaryColor p-2 rounded"
              >
                <label className="mr-2 my-auto">Party ID:</label>
                <select
                  className="border rounded"
                  name="party-id"
                  value={partyId}
                  onChange={(e) => setPartyId(e.target.value)}
                >
                  <option label="All" />
                  {partyIdsMemoized.map((x) => (
                    <option value={x.value} label={x.label} key={x.value} />
                  ))}
                </select>
              </div>
            </div>
            <div className="flex-column">
              <div
                style={{ height: 36 }}
                className="ml-5 flex flex-row bg-primary brandPrimaryColor p-2 rounded"
              >
                <label className="mr-2 my-auto">Role:</label>
                <select
                  className="border rounded"
                  name="role-type"
                  value={roleType}
                  onChange={(e) => setRoleType(e.target.value)}
                >
                  <option label="All" />
                  {roleTypesMemoized.map((x) => (
                    <option value={x.value} label={x.label} key={x.value} />
                  ))}
                </select>
              </div>
            </div>
          </>
        )}
        <div className="flex-column">
          <button
            style={{ height: 36 }}
            className="ml-5 flex flex-row border border-black bg-primary py-2 px-4 rounded brandPrimaryColor align-center"
            onClick={() => {
              setSearchQuery("");
              setPartyId("");
              setRoleType("");
              if (searchInputRef.current) searchInputRef.current.focus();
            }}
          >
            <p style={{ marginTop: -2 }} className="mt-0.5">
              Reset
            </p>
          </button>
        </div>
      </div>
      <UserDataGrid role={role} users={gridData} loading={loading} />
    </div>
  );
};

const UserDataGrid = withRouter(({ users, loading, role }) => {
  const [selectedUser, updateSelctedUser] = useState(undefined);
  const [subscriptionBannerHeight, setSubscriptionBannerHeight] = useState(152);
  const user = useAuth();

  useLayoutEffect(() => {
    const subscriptionBannerNode = document.getElementsByClassName(
      "subscription-banner"
    )[0];
    if (subscriptionBannerNode) {
      setSubscriptionBannerHeight(
        subscriptionBannerNode.offsetHeight + subscriptionBannerHeight
      );
    }
  }, [user.tenancyState]);

  const columns = [
    { key: "party_id", headerCellClass: "brandPrimaryColor", name: "Party ID" },
    { key: "name", headerCellClass: "brandPrimaryColor", name: "Name" },
    { key: "email", headerCellClass: "brandPrimaryColor", name: "Email" },
    {
      key: "phone_number",
      headerCellClass: "brandPrimaryColor",
      name: "Phone Number",
    },
    { key: "company", headerCellClass: "brandPrimaryColor", name: "Company" },
    { key: "role_type", headerCellClass: "brandPrimaryColor", name: "Role" },
    { key: "id", headerCellClass: "brandPrimaryColor", name: "Id" },
  ];

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="w-full pr-4 mt-2">
      <ButtonlessModal
        show={selectedUser}
        onClose={() => {
          updateSelctedUser(undefined);
        }}
        title={"Edit User"}
      >
        <EditUser
          defaultValue={{
            permissions: {
              role: "tenancyadmin",
            },
          }}
          role={role}
          id={selectedUser}
        />
      </ButtonlessModal>
      <div>
        <style>
          {`.rdg { height: 100vh !important; max-height: calc(100vh - ${subscriptionBannerHeight}px) }`}
        </style>
        <DataGrid
          columns={columns}
          rows={users}
          onRowClick={(row) => updateSelctedUser(row.id)} //row.id
          className="rdg-dark"
        />
      </div>
    </div>
  );
});

//Wrap the user so we can grab data about them from firestore
export const EditUserWrapper = ({ uid }) => {
  return (
    <FirestoreCollection
      path={"accounts"}
      //Get all the company options which can be selected
    >
      {({ error, isLoading, data }) => {
        if (isLoading) {
          return <p>...</p>;
        }

        var companies = data;
        //Get the selected user information
        return (
          <FirestoreDocument path={`users/${uid}`}>
            {({ isLoading, data }) => {
              if (isLoading) {
                return <p>...</p>;
              }
              var userData = data;

              return (
                <FetchLocationsWrapper
                  companies={companies}
                  userData={userData}
                />
              );
            }}
          </FirestoreDocument>
        );
      }}
    </FirestoreCollection>
  );
};

export const FetchLocationsWrapper = ({ companies, userData }) => {
  return (
    <FirestoreCollection
      path={"locations"}
      filter={["type", "==", "ONLINE"]}
      //Get all the company options which can be selected
    >
      {({ error, isLoading, data }) => {
        if (isLoading) {
          return <p>...</p>;
        }

        return (
          <EditUser
            userData={userData}
            companies={companies}
            locations={data}
          />
        );
      }}
    </FirestoreCollection>
  );
};

function mapUserToRow(user, accounts, roleTypesMemoized) {
  var company = user.company;

  if (company) {
    var accountIndex = accounts.map((x) => x.id).indexOf(company);
    if (accountIndex !== -1) {
      company = accounts[accountIndex].name;
    }
  }

  company ??= "None";
  return {
    party_id: user.party_id,
    name: user.name,
    email: user.email,
    phone_number: user.phone_number,
    company: company,
    role: user.permissions?.role,
    role_type:
      roleTypesMemoized.find((x) => x.value === user.permissions?.role)
        ?.label || "",
    id: user.uid,
  };
}

const UserManagerHeader = ({ role, users }) => {
  const { tenancyState } = useAuth();
  const [showAddUser, toggleAddUser] = useState(false);
  const [showUAM, toggleUAM] = useState(false);

  const exportData = users.map((t) => ({
    party_id: t.party_id,
    name: t.name,
    email: t.email,
    contact_number: `'${t.phone_number}`,
    company: t.company,
    role_type: t.role_type,
    id: t.id,
  }));

  return (
    <div className="flex flex-row py-2">
      <h1 className="text-3xl font-bold">My Tenancy ({users.length})</h1>
      <div className="flex flex-row ml-auto mr-4 gap-x-1">
        <div id="update-bank">
          <RightModal title="Update Bank Details">
            <UpdateBankDetails />
          </RightModal>
        </div>
        <div>
          <button
            className={`bg-primary brandPrimaryColor text-sm border border-black font-bold py-2.5 px-4 rounded${
              tenancyState && !tenancyState.active
                ? " opacity-50 pointer-events-none"
                : ""
            }`}
            onClick={() => toggleAddUser(!showAddUser)}
          >
            Create User
          </button>
          {/* <button
          className="ml-2 bg-blue-400 text-white border border-black text-sm font-bold py-2 px-4 rounded"
          onClick={() => toggleUAM(!showUAM)}
        >
          Edit Permissions
        </button> */}
          <ButtonlessModal
            title="Edit Permissions"
            onClose={() => {
              toggleUAM(false);
            }}
            show={showUAM}
          >
            <UAMEditor role={role} />
          </ButtonlessModal>
          <ButtonlessModal
            title="Create New User"
            onClose={() => {
              toggleAddUser(false);
            }}
            show={showAddUser}
          >
            <CreateUser role={role} />
          </ButtonlessModal>
        </div>
        <div>
          <ExportButton
            data={exportData}
            fields={Object.keys(exportData[0] || {})}
            title={`tenancy-user-accounts-(${exportData.length})`}
          />
        </div>
      </div>
    </div>
  );
};
