import Spinner from "../misc/Spinner";

import { StatementDownSpinner } from "./Statement";
import { Link } from "react-router-dom";

import { FaCog } from "react-icons/fa";
import { Modal } from "../layout/Modal";

import { LegacyPaymentsCheckbox } from "./Payments";

import {
  Line,
  LineChart,
  XAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

import { gql, useQuery } from "@apollo/client";
import { useState } from "react";

//Date range css.
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const PAYMENTS_QUERY = gql`
  query Payments_summary {
    payments_summary {
      total_revenue
      total_sessions
      locations
      kwh_delivered
      hours_spent
      daily_summary {
        index
        amount
        label
      }
    }
  }
`;

export const LegacyPayments = ({}) => {
  const { loading, error, data } = useQuery(PAYMENTS_QUERY, {
    fetchPolicy: "no-cache",
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <h1>Error!</h1>;
  }

  return (
    <div className="overflow-y-scroll">
      <div className="flex flex-row">
        <h1 className="text-3xl font-bold my-1">Legacy Analytics Dashboard</h1>

        <div className="flex-row flex ml-auto h-12">
          <LegacyPaymentsCheckbox checked={true} />

          {/* <MerchantControls account={data.account} /> */}
        </div>
      </div>
      <hr className="border border-gray-400 my-2" />
      <SummaryGraphics
        payments_summary={data.payments_summary}
        daily_summary={data.payments_summary.daily_summary}
      />
    </div>
  );
};

const MerchantControls = ({ account }) => {
  const [showTransactionStatementModal, updateShowTransactionStatementModal] =
    useState(false);

  return (
    <div>
      <Modal
        title="Select Time Range"
        isOpen={showTransactionStatementModal}
        onClose={() => updateShowTransactionStatementModal(false)}
      >
        <StatementDownSpinner />
      </Modal>
      <div>
        <div className=" mx-2 ">
          <div className="ml-auto align-top h-full">
            <button
              className="ml-auto btn bg-primary border brandPrimaryColor border-black rounded"
              onClick={() => updateShowTransactionStatementModal(true)}
            >
              <FaCog className="inline text-primary-600 mr-2 mb-1" />
              <span className="inline">Download Transaction Statement</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SummaryItem = ({ mainCourse, description }) => {
  return (
    <div className="mr-12 brandPrimaryColor">
      <h1 className="text-4xl header font-bold">{mainCourse}</h1>
      <h4>{description}</h4>
    </div>
  );
};

const SummaryGraphics = ({ daily_summary, payments_summary }) => {
  return (
    <div className="mt-4 border rounded-xl bg-primary">
      <div className="flex flex-row p-4">
        <SummaryItem
          mainCourse={`$${payments_summary.total_revenue}`}
          description={"Total Revenue"}
        />
        <SummaryItem
          mainCourse={`${payments_summary.total_sessions}`}
          description={"Total Sessions"}
        />
        <SummaryItem
          mainCourse={`${payments_summary.locations}`}
          description={"Locations"}
        />
        <SummaryItem
          mainCourse={`${payments_summary.kwh_delivered}`}
          description={"kWh Delivered"}
        />
        <SummaryItem
          mainCourse={`${payments_summary.hours_spent}`}
          description={"Hours Spent Charging"}
        />
        <Link
          to="/legacySessions"
          className="ml-auto px-5 py-4 h-auto bg-green-600 rounded-md border-2 text-white"
        >
          View As List
        </Link>
      </div>
      <div className="pl-4 pr-3 pb-4">
        <ResponsiveContainer width="95%" height={400}>
          <LineChart
            data={daily_summary}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          >
            <XAxis dataKey="label" />
            <Tooltip />
            <CartesianGrid stroke="#FFFFF" />
            <Line
              type="monotone"
              dataKey="amount"
              stroke="#2fdce1"
              yAxisId={1}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
