import React from "react";

import { Formik, Form, Field } from "formik";
import {
  LOGIN_USER,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  FORGOT_PASSWORD,
  EMAIL_LOGIN,
} from "../../../reducers/userState";

import { connect, useDispatch } from "react-redux";
import { loginUser } from "../../../middleware/loginMiddleware";
import { LoginError } from "./LoginError";

import { useAuth } from "../../../hooks/useAuth";
import { Redirect } from "react-router-dom";

export const LoginUserWithEmail = ({
  onDeleteAccountPage,
  action,
  message,
  handleForgotPassword,
}) => {
  const dispatch = useDispatch();
  const { loading, uid } = useAuth();

  if (!onDeleteAccountPage && !loading && uid) {
    return <Redirect to="/ecommerce" />;
  }

  var extraInfo = null;

  switch (action) {
    case LOGIN_ERROR: {
      extraInfo = <LoginError message={message} />;
      break;
    }
    case LOGIN_SUCCESS: {
      extraInfo = <LoginSuccess message={message} />;
    }
  }

  return (
    <>
      {extraInfo}
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={({ email, password }) =>
          dispatch(loginUser({ provider: EMAIL_LOGIN, email, password }))
        }
      >
        <Form
          className={`relative bg-white shadow-md rounded px-8 pt-6 mb-4 text-gray-500 ${
            onDeleteAccountPage ? "pb-5" : "pb-8"
          }`}
        >
          <div className="mb-4">
            <label>Email</label>
            <Field
              type="text"
              name="email"
              placeholder="email"
              className="w-full border rounded p-1"
            />
          </div>
          <div className="mb-4 rounded">
            <label>Password</label>
            <Field
              type="password"
              name="password"
              placeholder="password"
              className="w-full border rounded p-1"
            />
          </div>
          <div
            className={`flex justify-between${
              onDeleteAccountPage ? "" : " mb-4 "
            }`}
          >
            <button
              className="bg-green-600 text-white text-sm border-1 font-bold py-2 px-4 rounded-md block m-auto"
              type="submit"
            >
              {onDeleteAccountPage ? "Verify" : "Login"}
            </button>
          </div>
          {!onDeleteAccountPage && (
            <button
              className="text-xs block m-auto"
              type="button"
              onClick={handleForgotPassword}
            >
              {" "}
              Forgot your password?
            </button>
          )}
        </Form>
      </Formik>
    </>
  );
};

const LoginSuccess = ({ message }) => {
  return (
    <div className="relative bg-white shadow-md rounded px-8 pt-4 pb-4 mb-4 ">
      <p className="text-center text-black">{message}</p>
    </div>
  );
};

/*
 *  Dispatch username and password to log user in.
 */
const dispatchLogin = ({ email, password }) => ({
  type: LOGIN_USER,
  email,
  password,
});

const handleForgotPassword = () => ({
  type: FORGOT_PASSWORD,
});

const mapStateToLoginUserProps = ({ userState }) => ({
  loading: userState.loading,
  action: userState.action,
  message: userState.message,
});

export const LoginUserContainer = connect(mapStateToLoginUserProps, {
  dispatchLogin,
  handleForgotPassword,
})(LoginUserWithEmail);
