import { useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { gql, useMutation } from "@apollo/client";
import { FaFacebook } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";

import { LoginUserContainer } from "../login/LoginForm";
import { MFAContainer } from "../login/MFA";
import Spinner from "../../misc/Spinner";
import logo from "../../../assets/logo.png";
import { loginUser, logout } from "../../../middleware/loginMiddleware";
import {
  LOGIN_READY,
  MFA_REQUIRED,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  FACEBOOK_LOGIN,
  GOOGLE_LOGIN,
} from "../../../reducers/userState";

const DELETE_ME = gql`
  mutation Mutation {
    deleteMe
  }
`;

const DeleteForm = ({ action, loading }) => {
  const dispatch = useDispatch();

  const [deleteMe, deleteMeResult] = useMutation(DELETE_ME);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (deleteMeResult.data?.deleteMe) {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop =
          scrollContainerRef.current.scrollHeight;
      }
      dispatch(logout());
    }
  }, [deleteMeResult.data]);

  if (loading) {
    return <Spinner />;
  }

  var content = null;
  var loggedIn = action === LOGIN_SUCCESS;

  switch (action) {
    case LOGIN_ERROR:
    case LOGIN_READY: {
      content = <LoginUserContainer onDeleteAccountPage />;
      break;
    }
    case MFA_REQUIRED: {
      content = <MFAContainer />;
      break;
    }
  }

  return (
    <>
      <div
        ref={scrollContainerRef}
        className="bg-primary flex-none w-full h-screen overflow-y-scroll"
      >
        <div className="text-white font-bold pb-2 px-4 m-auto">
          <img src={logo} alt="Logo" className="m-auto block mt-5" />
          <h1 className="m-auto block text-center pt-2 pb-6 blueDropshadow text-xl">
            CHARGE
          </h1>
          <h1 className="m-auto block text-center pb-3 text-xl">
            DELETE ACCOUNT
          </h1>
          <div className="pb-3">
            <p className="font-normal">
              We understand that you may choose to delete your account for
              various reasons. Deleting your account is a two-step process to
              ensure the security and privacy of your data.
            </p>
            <p className="pt-4">Step 1: Verify Account Deletion</p>
            <p className="font-normal pt-1">
              - Before proceeding with the deletion process, we need to verify
              that you are the rightful owner of this account. Please verify
              your account below to confirm your identity.
            </p>
          </div>
          {loggedIn || deleteMeResult.data?.deleteMe ? (
            <div
              style={{ width: "28rem" }}
              className="relative bg-white shadow-md rounded px-8 pt-4 pb-4 mb-4"
            >
              <p className="text-center text-black">Verification Success!</p>
            </div>
          ) : (
            <div className="flex">
              <div style={{ width: "28rem" }}>
                <div className="relative">{content}</div>
              </div>
              <div
                style={{ borderLeft: "1px solid", height: 150, marginTop: 38 }}
                className="ml-5"
              ></div>
              <div>
                <div className="ml-5 flex flex-col">
                  <div className="mt-12">Verify With</div>
                  <div className="pt-1">
                    <button
                      className="py-2 facebook-background px-3 border-1 rounded"
                      onClick={() =>
                        dispatch(loginUser({ provider: FACEBOOK_LOGIN }))
                      }
                    >
                      <div className="inline my-auto">
                        <FaFacebook className="inline mr-2" />
                        <span className="relative top-0.5">Facebook</span>
                      </div>
                    </button>
                  </div>
                  <div className="pt-3">
                    <button
                      className="bg-white py-2 px-3 border-1 rounded"
                      onClick={() =>
                        dispatch(loginUser({ provider: GOOGLE_LOGIN }))
                      }
                    >
                      <div className="inline my-auto">
                        <FcGoogle className="inline mr-2" />
                        <span className="relative text-black top-0.5">
                          Google
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div>
            <p>Step 2: Confirm Account Deletion</p>
            <p className="font-normal pt-1">
              - Once your identity is verified, your account deletion request
              will be processed immediately. Please note that this action is
              irreversible and all your data associated with this account will
              be permanently removed from our system.
            </p>
            <p className="pt-3">
              <span className="font-normal">- </span>
              By confirming account deletion, you acknowledge and agree to the
              following:
            </p>
            <ul className="font-normal list-disc ml-8 mt-1">
              <li>
                All data related to your account, including but not limited to
                personal information, payment information, and history, will be
                permanently deleted.
              </li>
              <li>
                You will lose access to all features and services associated
                with this account.
              </li>
            </ul>
            <p className="font-normal pt-2">
              - If you wish to proceed with deleting your account, please click
              the "Confirm Deletion" button below. If you have changed your
              mind, you can cancel this process by simply closing this page.
            </p>
          </div>
          <div className="pt-4">
            <p>Data Retention Policy:</p>
            <p className="font-normal pt-1">
              - We value your privacy and adhere to strict data retention
              practices. Upon account deletion, we permanently erase all data
              associated with your account from our systems. We do not retain
              any information unless required for legitimate reasons such as
              security, fraud prevention, or regulatory compliance. For more
              information about our data retention practices, please refer to
              our{" "}
              <a
                href="https://www.evup.com.au/EULA"
                target="_blank"
                rel="noreferrer"
                className="my-1 text-blue-400 hover:text-blue-500"
              >
                Privacy Policy
              </a>
              .
            </p>
            <p className="font-normal pt-2">
              - If you have any questions or concerns regarding the deletion
              process or our data retention practices, please{" "}
              <a
                href="https://www.evup.com.au/contact-us"
                target="_blank"
                rel="noreferrer"
                className="my-1 text-blue-400 hover:text-blue-500"
              >
                Contact us
              </a>
              .
            </p>
            <p className="font-normal pt-2">- Thank you for using our app.</p>
          </div>
          <div className="mt-4 mb-5">
            {deleteMeResult?.error?.message && (
              <h1 className="text-red-600 mb-2">
                {deleteMeResult.error.message}
              </h1>
            )}
            {(action === LOGIN_ERROR || action === LOGIN_READY) &&
              !deleteMeResult.data?.deleteMe && (
                <h1 className="text-red-600 mb-2">
                  Please verify your account to enable the "Confirm Deletion"
                  button.
                </h1>
              )}
            {deleteMeResult.data?.deleteMe ? (
              <div
                style={{ width: "28rem" }}
                className="relative bg-white shadow-md rounded px-8 pt-4 pb-4 mb-4"
              >
                <p className="text-center text-black">
                  Deletion process completed successfully!
                </p>
                <p className="text-center text-black">
                  You can now safely close this page.
                </p>
              </div>
            ) : (
              <button
                className={`bg-red-600 text-white font-bold py-2 px-4 rounded ml-auto${
                  !loggedIn || deleteMeResult.loading ? " opacity-70" : ""
                }`}
                type="button"
                onClick={() => deleteMe()}
                disabled={!loggedIn || deleteMeResult.loading}
              >
                Confirm Deletion
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToDeleteFormProps = (state) => {
  const userState = state.userState;
  return {
    loading: userState.loading,
    action: userState.action,
  };
};

const DeleteFormContainer = connect(mapStateToDeleteFormProps)(DeleteForm);

export default DeleteFormContainer;
