export const REFERENCE_NUMBER = "REFERENCE_NUMBER";
export const EVSE_ID = "EVSE_ID";
export const ADD_CAPABILITY = "ADD_CAPABILITY";
export const IMAGE = "IMAGE";
export const DIRECTIONS = "DIRECTIONS";
export const REMOVE_CAPABILITY = "REMOVE_CAPABILITY";
export const FLOOR = "FLOOR";
export const CONNECTORS = "CONNECTORS";
export const PRESET_ADD = "PRESET_ADD";
export const PRESET_REMOVE = "PRESET_REMOVE";
export const PRESET_QTY = "PRESET_QTY";

const INITIAL_STATE = {
  capabilities: [],
  connectors: [],
  status: "UNKNOWN",
  preset_name: null,
  preset_num: null
};

export function offlineEvseFormReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PRESET_ADD: {
      return {
        ...state,
        preset_name: action.preset_name,
      };
      break;
    }
    case PRESET_REMOVE: {
      var newState = {
        ...state,
      };

      delete newState.preset_name;

      return newState;
    }
    case PRESET_QTY: {
      return {
        ...state,
        preset_qty: action.preset_qty,
      };
    }
    case REFERENCE_NUMBER: {
      return {
        ...state,
        physical_reference: action.physical_reference,
      };
      break;
    }
    case EVSE_ID: {
      return {
        ...state,
        evse_id: action.evse_id,
      };
      break;
    }
    case ADD_CAPABILITY: {
      if (
        action.capabilities &&
        action.capabilities !== "none" &&
        state.capabilities?.indexOf(action.capabilities) === -1
      ) {
        return {
          ...state,
          capabilities: [...state.capabilities, action.capabilities],
        };
      }
      break;
    }
    case REMOVE_CAPABILITY: {
      return {
        ...state,
        capabilities: [
          ...state.capabilities.filter((y) => y !== action.capabilities),
        ],
      };
    }
    case FLOOR: {
      return {
        ...state,
        floor_level: String(action.floor_level),
      };
      break;
    }
    case DIRECTIONS: {
      return {
        ...state,
        directions: action.directions,
      };
      break;
    }
    case CONNECTORS: {
      return {
        ...state,
        connectors: action.connectors,
      };
      break;
    }
    case IMAGE: {
      return {
        ...state,
        images: action.image,
      };
      break;
    }
  }
  return state;
}
