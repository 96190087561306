import { gql, useQuery, useMutation } from "@apollo/client";
import Spinner from "../misc/Spinner";
import ErrorMessage from "../misc/ErrorMessage";
import { WarningTooltip } from "../../components/Tooltip";
import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";

const GROUP_LOCATION = gql`
  query LocationsApi($locationsApiSingleId: String!) {
    locationsApiSingle(id: $locationsApiSingleId) {
      name
      address
      city
    }
  }
`;

const DELETE_GROUP = gql`
  mutation DeleteDLBGroup($groupId: String!) {
    deleteDLBGroup(group_id: $groupId)
  }
`;

export const TenancyGroups = ({ data }) => {
  const groups =
    data.getTenancyGroups.length !== 0 ? (
      data.getTenancyGroups.map((x) => {
        return <DLBGroup key={x.group_id} group={x} />;
      })
    ) : (
      <div className="text-lg text-center my-6 w-full">
        No groups created yet
      </div>
    );

  return (
    <div>
      <h1 className="text-3xl font-bold">Your Tenancy Groups</h1>
      <hr className="border border-gray-400 my-2" />
      <div>{groups}</div>
      <hr className="border border-gray-400 my-2" />
    </div>
  );
};

export const DLBGroup = ({ group }) => {
  const { loading, error, data } = useQuery(GROUP_LOCATION, {
    variables: {
      locationsApiSingleId: group.location_id,
    },
  });
  if (loading)
    return (
      <div className="border-2 border-black bg-primary p-4 my-3 rounded-lg flex flex-col">
        <Spinner />
      </div>
    );
  if (error) return <ErrorMessage>{`${error}`}</ErrorMessage>;

  var location_name = `${data.locationsApiSingle.address} (${data.locationsApiSingle.city})`;
  // A preferable formatting
  if (data.locationsApiSingle.name) {
    location_name = `${data.locationsApiSingle.name} (${data.locationsApiSingle.address})`;
  }

  const devices = group.devices.map((x) => (
    <DLBDevice device={x} key={x.evse_id} />
  ));

  return (
    <div className="border-2 border-black bg-primary p-4 my-3 rounded-lg flex flex-col">
      <div className="flex flex-row">
        <h1 className="brandPrimaryColor text-xl ml-2">{group.group_name}</h1>
        <DeleteGroup group_id={group.group_id} />
      </div>
      <ul className="text-white ml-8 mb-3 text-lg source-sans-pro list-disc weight-500">
        <li>At {location_name}</li>
        <li>Allocated {group.max_amperage}A from the grid</li>
      </ul>
      <div>{devices}</div>
    </div>
  );
};

export const DLBDevice = ({ device }) => {
  const connectors = device.connectors.map((x) => (
    <DLBConnector connector={x} key={x.connector_id} />
  ));
  var warning = null;
  if (device.disconnected) {
    warning = (
      <WarningTooltip className="ml-4 comfortaa" id="dlbwarning">
        Warning: This device is disconnected. Since DLB promises safe charging,
        <br></br>
        this may significantly impact the charging efficiency of other group
        devices.
      </WarningTooltip>
    );
  }

  return (
    <div className="rounded-md mt-2 border border-gray-500 bg-black">
      <div className="flex p-2 flex-row source-sans-pro">
        <div className="brandPrimaryColor text-xl ml-2">
          ID: {device.evse_id}
        </div>
        {warning}
        <div className="ml-auto mr-4 text-gray-400">
          Max Amperage: {device.max_amperage}A
        </div>
      </div>
      <div className="flex flex-row px-2 pb-4">{connectors}</div>
    </div>
  );
};

export const DLBConnector = ({ connector }) => {
  var phase = connector.power_type.replaceAll("_", " ");
  if (phase === "AC 1 PHASE") {
    phase += ` (L${connector.phase_mapping["One"]})`;
  }

  return (
    <div className="bg-primary border border-gray-500 rounded w-96 mx-2 p-2">
      <div className="flex flex-row">
        <div className="brandPrimaryColor source-sans-pro text-lg">
          Connector {connector.connector_id}
        </div>
        <div className="text-gray-400 source-sans-pro ml-auto mr-2">
          Max Amperage: {connector.max_amperage}A
        </div>
      </div>
      <ul className="list-disc ml-6 text-white source-sans-pro text-lg">
        <li>{phase}</li>
        <li>Status: {connector.connector_status}</li>
        <li>Allocated Amperage: {connector.allocated_amperage.toFixed(2)}</li>
      </ul>
    </div>
  );
};

export const DeleteGroup = ({ group_id }) => {
  const { tenancyState } = useAuth();
  const [deleteDLBGroup, { loading, error, data }] = useMutation(DELETE_GROUP);
  const [deleteText, updateDeleteText] = useState("Delete");
  if (loading) return <Spinner />;
  if (error) return <ErrorMessage message={error.message} />;

  return (
    <button
      className={`bg-red-500 ml-auto mr-2 py-1 px-4 rounded border-black border${
        tenancyState && !tenancyState.active
          ? " opacity-50 pointer-events-none"
          : ""
      }`}
      onClick={async () => {
        if (deleteText === "Deleting...") return;
        const confirm = prompt(
          "Are you sure you to delete this DLB group? Enter 'DELETE' to confirm group deletion:"
        );
        if (confirm === "DELETE") {
          deleteDLBGroup({
            variables: {
              groupId: group_id,
            },
          });
          updateDeleteText("Deleting...");
        }
      }}
    >
      {deleteText}
    </button>
  );
};
