import React, { useEffect, useState } from "react";
import Spinner from "../misc/Spinner";

import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { LeafletMap } from "../misc/Map";
import { RightModal } from "../layout/rightModal";
import { NewLocation } from "./LocationUtils";
import { CREATE_LOCATION, hasUAM } from "../../utils/UAMRoles";
import { useTheme } from "../../hooks/useTheme";
import { useAuth } from "../../hooks/useAuth";

const CAN_CREATE_LOCATION = gql`
  query CanCreateLocation {
    canCreateLocation
  }
`;

const LOCATIONS = gql`
  query LocationsApi {
    locationsApi {
      party_id
      id
      name
      address
      city
      post_code
      soft_blocked
      country
      time_zone
      facilities
      parking_type
      created
      last_updated
      coordinates {
        lat
        lng
      }
    }
  }
`;

const PostList = () => {
  const { uam, permissions, tenancyState } = useAuth();
  const [currentPos, updateCurrentPos] = useState([-37.8136, 144.9631]);
  //Does UAM allow this user to create a new location?
  const canCreateLocationResult = useQuery(CAN_CREATE_LOCATION, {
    fetchPolicy: "no-cache",
  });
  const { loading, error, data } = useQuery(LOCATIONS, {
    fetchPolicy: "no-cache",
    // This is important because the above query updates the soft_blocked property in the location cache. So, parallel fetch results in race conditions and inconsistent results.
    skip: canCreateLocationResult.loading,
  });

  useEffect(() => {
    const fetchLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation?.getCurrentPosition(
          (position) =>
            updateCurrentPos([
              position.coords.latitude,
              position.coords.longitude,
            ]),
          undefined,
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          }
        );
      } else {
        alert("Location not supported by browser");
      }
    };
    fetchLocation();
  }, []);

  if (loading || canCreateLocationResult.loading) {
    return <Spinner />;
  }

  if (error) {
    return <p>Error :(</p>;
  }

  var canCreate =
    hasUAM(
      {
        uam: uam,
      },
      CREATE_LOCATION
    ) && canCreateLocationResult?.data?.canCreateLocation;

  const locations = data.locationsApi;

  var addLocation = null;

  if (canCreate === true) {
    addLocation = (
      <div id="create-location" className="fixed z-50 right-0 bottom-0 m-4">
        <RightModal title="Create New Location">
          <NewLocation role={permissions?.role} />
        </RightModal>
      </div>
    );
  } else {
    addLocation = (
      <div className="fixed z-50 right-0 bottom-0 m-4 opacity-50">
        <RightModal title="Create New Location">
          <div className="p-2">
            {tenancyState && tenancyState.tenancy_tier === "FLEX" && (
              <div className="text-md">
                You currently have a Lite tenancy, which only permits one
                location and 2 charging ports.
              </div>
            )}
            <div className="mt-2">
              Please contact EVUp to upgrade your tenancy.
            </div>
          </div>
        </RightModal>
      </div>
    );
  }

  return (
    <div className="z-0">
      <div className="wide-map border-4 border-black rounded mx-2">
        <LeafletMap
          zoom={11}
          locations={locations}
          center={currentPos}
          isAdmin={true}
        />
      </div>
      {addLocation}
      <h1 className="heading font-bold text-xl mt-6 ml-2">LOCATIONS</h1>
      <div className="mb-4 flex flex-wrap">
        {locations.map((loc) => (
          <PostMeta key={loc.id} loc={loc} />
        ))}
      </div>
      {locations.length === 0 && (
        <p className="ml-2">
          No Locations found. Create some locations to begin!
        </p>
      )}
    </div>
  );
};

const PostMeta = ({ loc }) => {
  const { secondaryColor } = useTheme();

  const styles = {
    backgroundColor: secondaryColor,
    color: `black`,
    fontWeight: "bold",
  };

  var className = "w-full xl:w-1/4 lg:w-1/3 md:w-1/2 z-10";
  var linkClassName = "w-full block text-black p-4 uppercase";

  if (loc.soft_blocked) {
    className += " opacity-25";
    linkClassName += " pointer-events-none";
  }

  return (
    <div className={className} key={loc.id}>
      <div
        className="mb4 shadow border-4 border-black text-center m-2 rounded-md"
        key={loc.id}
      >
        <h2 className="heading text-xl font-bold p-2 pt-4 h-16 truncate">
          {loc.name}
        </h2>
        <h2>Owner: {loc.party_id}</h2>
        <div className="mb-4 p-2 truncate">
          <br />
          <span className="truncate">{loc.address}</span>
          <br />
          <span className="truncate">
            {loc.city}, {loc.postal_code} {loc.country}
          </span>
          <br />
          <span className="text-sm text-gray-500 truncate">
            {(+loc.coordinates.lat).toFixed(5)},{" "}
            {(+loc.coordinates.lng).toFixed(5)}
          </span>
        </div>
        <Link
          className={linkClassName}
          style={styles}
          to={`/locations/${loc.id}`}
        >
          View Location
        </Link>
      </div>
    </div>
  );
};

/*
 *	Used for querying locations
 *	simple component with no state
 *	simply calls update query when
 *	the user makes a seach update
 */
const SearchBar = ({ updateQuery, query }) => {
  return (
    <div className="flex">
      <p>Show only online locations</p>
      <input
        className="ml-2 mt-1"
        type="checkbox"
        checked={query.onlyOnline}
        onChange={() => {
          updateQuery({
            onlyOnline: !query.onlyOnline,
          });
        }}
      />
    </div>
  );
};

export default PostList;
