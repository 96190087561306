import { useState, useEffect } from "react";

import { FaPlus, FaMinus, FaCreditCard } from "react-icons/fa";
import { PLAN_COSTS } from "../whitelabel/AdminSignupPlan";
import { PLAN_TYPES } from "../whitelabel/AdminSignupPayment";
import { Tooltip } from "../../components/Tooltip";
import { StripePaymentForm } from "./StripePaymentForm";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { gql, useMutation } from "@apollo/client";

import Spinner from "../misc/Spinner";
import { ErrorMessage } from "formik";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_SECRET_KEY}`);

export const FlexSubscriptionFlow = ({ subIntentResult }) => {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: subIntentResult.clientSecret,
  };
  console.log("OPTIONS", options);

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripePaymentForm
        subscriptionTerms={{
          is_trial: false,
          num_locations: 1,
          billing_period: "YEARLY",
        }}
        prev_locations={0}
      />
    </Elements>
  );
};

// export const SubscriptionTerms = ({ existingCount = 0, onNext }) => {
//   const [locations_asked, updateLocationsAsked] = useState(existingCount);
//   const [billing_period, updateBillingPeriod] = useState("YEARLY");

//   return (
//     <div className="flex flex-col">
//       <div className="flex flex-row">
//         <h1 className="p-2">Select number of locations:</h1>
//         <Tooltip className="my-auto ml-1">
//           The amount of locations you select should include how many locations
//           you already have. You currently have {existingCount} locations.
//         </Tooltip>
//       </div>
//       <div className="ml-2 w-1/3 flex flex-row">
//         <input
//           className="p-2 border rounded"
//           type="number"
//           value={locations_asked}
//           onChange={(e) =>
//             updateLocationsAsked(
//               Math.min(Math.max(Number.parseInt(e.target.value), 1), 99)
//             )
//           }
//           min="1"
//           max="99"
//         />
//         <button
//           className="border rounded px-3 bg-blue-400 text-white ml-2"
//           onClick={() =>
//             updateLocationsAsked(Math.min(locations_asked + 1, 99))
//           }
//         >
//           <FaPlus />
//         </button>
//         <button
//           className="border rounded px-3 bg-blue-400 text-white ml-2"
//           onClick={() => updateLocationsAsked(Math.max(locations_asked - 1, 1))}
//         >
//           <FaMinus />
//         </button>
//       </div>
//       <p className="p-2 mt-2">Save ~20% with annual billing.</p>
//       <div className="ml-2 w-1/3 flex flex-row">
//         <select
//           className="p-2 border rounded"
//           value={billing_period}
//           onChange={(e) => updateBillingPeriod(e.target.value)}
//         >
//           <option value="MONTHLY" label="MONTHLY" />
//           <option value="YEARLY" label="ANNUALLY" />
//         </select>
//       </div>
//       <h1 className="mt-1 ml-1">Additional cost:</h1>
//       <PricingCalculator
//         billing_period={billing_period}
//         locations_asked={locations_asked - existingCount}
//       />
//       <button
//         className="p-2 bg-green-600 text-white rounded border mt-2 flex flex-row justify-center"
//         onClick={() =>
//           onNext({
//             num_locations: locations_asked,
//             billing_period: billing_period,
//           })
//         }
//       >
//         Pay
//         <FaCreditCard size={18} className="mt-0.5 ml-2" />
//       </button>
//     </div>
//   );
// };

export const PricingCalculator = ({
  billing_period,
  locations_asked,
  is_trial,
}) => {
  var total_cost;

  if (billing_period === PLAN_TYPES.STANDARD_MONTHLY) {
    total_cost = locations_asked * PLAN_COSTS.STANDARD_MONTHLY;
  } else if (billing_period === PLAN_TYPES.STANDARD_ANNUAL) {
    total_cost = locations_asked * PLAN_COSTS.STANDARD_ANNUAL;
  }

  if (is_trial) {
    return (
      <div className="mt-1">
        <h1 className="ml-auto my-auto text-2xl mr-4">
          Free trial for 14 days. <br />$
          {numberWithCommas(total_cost.toFixed(2))} + GST - Billed{" "}
          {billing_period.toLowerCase()} thereafter.
        </h1>
      </div>
    );
  }

  console.log("billing period", billing_period);

  return (
    <div className="mt-1">
      <h1 className="ml-auto my-auto text-4xl mr-4">
        ${numberWithCommas(total_cost.toFixed(2))} + GST - Billed{" "}
        {billing_period.toLowerCase()}
      </h1>
    </div>
  );
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
