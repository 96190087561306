import { ApolloClient, InMemoryCache, split } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import { createClient } from "graphql-ws";
import { store } from "../store/store";

var graphqlURL = process.env.REACT_APP_GRAPHQL_URL || "http://localhost:8001";
var graphqWSlURL =
  process.env.REACT_APP_WS_GRAPHQL_URL || "ws://localhost:8001";

const authLink = setContext(async (_, { headers }) => {
  var token = "";

  try {
    token = store.getState().userState.token;
  } catch (err) {
    console.error(`Error getting token! `, err);
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: graphqWSlURL,
    connectionParams: async () => {
      // Retrieve the authorization token from local storage.
      var token = "";

      try {
        token = store.getState().userState.token;
      } catch (err) {
        console.error(`Error getting token! `, err);
      }

      return {
        authorization: token ? `Bearer ${token}` : "",
      };
    },
  })
);

const uploadLink = createUploadLink({ uri: graphqlURL });

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(uploadLink)
);

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      EVSEPreset: {
        keyFields: ["id"],
      },
    },
  }),
  link: splitLink,
  onError: ({ networkError, graphQLErrors }) => {
    console.log("graphQLErrors", graphQLErrors);
    console.log("networkError", networkError);
  },
});
