import { gql, useSubscription } from "@apollo/client";

import { FaCopy } from "react-icons/fa";

const SEARCH_DEVICE = gql`
  subscription Subscription($chargePointId: String!) {
    searchForDevice(chargePointId: $chargePointId)
  }
`;

/*
 *  This component is rendered when we are waiting for
 *  the device in question to connect.
 */
export const AwaitingDevice = ({ url, chargePointId, onDeviceFound }) => {
  const subscriptionResult = useSubscription(SEARCH_DEVICE, {
    variables: {
      chargePointId,
    },
    onSubscriptionData: ({ subscriptionData }) => {
      var found = subscriptionData.data?.searchForDevice;
      if (found) {
        onDeviceFound(chargePointId);
      }
    },
  });

  if (subscriptionResult.error) {
    return (
      <p>
        Error: could not sub to database devices {`${subscriptionResult.error}`}
      </p>
    );
  }

  return (
    <div>
      <li className="list-disc">
        Set your OCPP device's websocket URL to the following link
      </li>
      <div className="flex flex-row mt-1">
        <input
          className="border border-black ml-5 mt-1 w-3/4 rounded p-1"
          type="text"
          defaultValue={url}
        />
        <button
          className="ml-2 my-auto relative right-8"
          onClick={() => navigator.clipboard.writeText(url)}
        >
          <FaCopy />
        </button>
      </div>
    </div>
  );
};
