import { LeafletMap } from "../misc/Map";
import { FaLocationArrow } from "react-icons/fa";

const DestinationMeta = ({ loc }) => {
  return (
    <div id="locationData" className="w-full p-3 bg-white rounded-lg mb-2">
      <div className="w-full">
        <LeafletMap
          draggable={false}
          locations={[loc]}
          isAdmin={true}
          center={[loc.coordinates.lat, loc.coordinates.lng]}
        />
      </div>
      <div className="flex items-center mb-6 mt-4">
        <FaLocationArrow size="40" className="mr-6" />
        <div className="pl-4 border-l-2 border-gray-100 w-full text-right">
          <p className=""> {loc?.address}</p>
          <p className="text-s">{loc?.city}</p>
          <p className="text-s">{loc?.country}</p>
          <p className="text-xs text-gray-300">
            {loc?.coordinates?.latitude}, {loc?.coordinates?.longitude}
          </p>
        </div>
      </div>
      <p className="flex justify-between">
        <span className="text-gray-400 mr-2">Party ID:</span>
        <span>{loc?.party_id}</span>
      </p>
      <hr className="mt-3 mb-6" />
      <p className="flex justify-between">
        <span className="text-gray-400 mr-2">Parking:</span>
        <span>{loc?.parking_type}</span>
      </p>
      <p className="flex justify-between">
        <span className="text-gray-400 mr-2">Publicly Visible:</span>
        <span>{loc?.publish ? "Yes" : "No"}</span>
      </p>
      <p className="flex justify-between">
        <span className="text-gray-400 mr-2">Timezone:</span>
        <span>{loc?.time_zone}</span>
      </p>
    </div>
  );
};

export default DestinationMeta;
