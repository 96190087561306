import { gql, useQuery } from "@apollo/client";
import _ from "lodash";
import Spinner from "../misc/Spinner";

const LOCATIONS_NAMES_QUERY = gql`
  query GetLocations {
    locations(publish: true) {
      id
      metadata {
        displayName
      }
    }
  }
`;

export const LocationSelector = ({ defaultValue, locationSelected }) => {
  const { data, loading, error } = useQuery(LOCATIONS_NAMES_QUERY);

  if (loading) return <Spinner />;

  const locationNames = [];
  let i = 0;
  data?.locations.forEach((x) => {
    locationNames[i] = { name: x?.metadata?.displayName, id: x?.id };
    i++;
  });
  const sortedLocations = _.orderBy(locationNames, ["name"], ["asc"]);

  if (!defaultValue) {
    defaultValue = "none";
  }

  return (
    <select
      className="ml-auto border rounded w-2/3 mb-2 p-2 bg-primary brandPrimaryColor"
      onChange={(e) => {
        locationSelected(e.target.value);
      }}
      value={defaultValue}
    >
      <option value="none" label="Select a location" />
      {sortedLocations?.map((x) => (
        <option value={x.id} label={x.name} key={sortedLocations.indexOf(x)} />
      ))}
    </select>
  );
};
