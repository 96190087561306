import Spinner from "../misc/Spinner";

import { RightModal } from "../layout/rightModal";
import { TariffForm } from "./TariffForm";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useTheme } from "../../hooks/useTheme";
import { useAuth } from "../../hooks/useAuth";

const TARIFFS = gql`
  query Ocpi_tariffs {
    ocpi_tariffs {
      id
      currency
      party_id
      type
      tariff_alt_text {
        text
        language
      }
      elements {
        price_components {
          type
          price
          vat
          step_size
        }
      }
      last_updated
    }
  }
`;

const DELETE_TARIFF = gql`
  mutation Ocpi_delete_tariff($ocpiDeleteTariffId: String!) {
    ocpi_delete_tariff(id: $ocpiDeleteTariffId)
  }
`;

export const TariffManager = ({}) => {
  const { tenancyState } = useAuth();
  const { loading, error, data, refetch } = useQuery(TARIFFS, {
    fetchPolicy: "no-cache",
  });
  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <div className="m-auto text-red-500">{error}</div>;
  }

  return (
    <div>
      <div className="flex flex-row ml-1 mr-1 mb-2">
        <h1 className="text-3xl font-bold mt-2">Tariff Manager</h1>
        <div
          className={`ml-auto ${
            tenancyState && !tenancyState.active
              ? " opacity-50 pointer-events-none"
              : ""
          }`}
        >
          <RightModal title="Create Tariff" className="mr-0">
            <TariffForm onRefetch={refetch} />
          </RightModal>
        </div>
      </div>
      <hr className="border-gray-400 border my-2" />
      <TariffList tariffs={data.ocpi_tariffs} onDelete={refetch} />
    </div>
  );
};

const TariffList = ({ tariffs, onDelete }) => {
  return (
    <div>
      {tariffs.map((x) => (
        <TariffTile tariff={x} key={x.id} onDelete={onDelete} />
      ))}
    </div>
  );
};

export function roundNumber(num, scale) {
  if (!("" + num).includes("e")) {
    return +(Math.round(num + "e+" + scale) + "e-" + scale);
  } else {
    var arr = ("" + num).split("e");
    var sig = "";
    if (+arr[1] + scale > 0) {
      sig = "+";
    }
    return +(
      Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) +
      "e-" +
      scale
    );
  }
}

export const TariffTile = ({ tariff, onDelete }) => {
  const { primaryColor } = useTheme();

  var styles = {
    backgroundColor: primaryColor,
    color: `white`,
  };

  var tariffTitle;
  var last_updated = tariff.last_updated || new Date().toISOString();
  var party_id = tariff.party_id;
  let tariffType = tariff.elements[0].price_components[0].type;
  let tariffPrice = tariff.elements[0].price_components[0].price;
  let step = tariff.elements[0].price_components[0].step_size;

  var description = tariff.tariff_alt_text?.map((x) => x.text)?.join("\n");

  if (!description) {
    description = "None.";
  }

  if (tariffType === "ENERGY") {
    tariffTitle = `$ ${roundNumber(tariffPrice / step, 2)} / kWh`;
  } else if (tariffType === "TIME") {
    tariffTitle = `$ ${roundNumber(tariffPrice, 2)} / ${step / 60} min`;
  } else if (tariffType === "FLAT") {
    tariffTitle = `$ ${roundNumber(tariffPrice, 2)} FLAT`;
  } else {
    tariffTitle = "TypeError";
  }

  return (
    <div
      key={tariff.id}
      className="bg-primary border-black border-2 w-full rounded-md p-2 mt-2 inline-block hoverShadow"
    >
      <div className="flex flex-row ml-2">
        <div
          className="flex flex-row p-1 border-solid rounded w-2/3"
          style={styles}
        >
          <div className="flex flex-col w-4/5">
            <h1 className="text-lg mr-1 brandPrimaryColor">{tariffTitle}</h1>
            <h1 className="text-sm text-gray-400 truncate w-2/3">
              ID: {tariff.id}
            </h1>
          </div>
          <div className="flex flex-col ml-auto">
            <h1 className="text-lg mr-1 brandPrimaryColor">
              Owner - {party_id}
            </h1>
            <h1 className="text-sm truncate">
              Created - {new Date(last_updated).toDateString()}
            </h1>
          </div>
        </div>
        <DeleteTariff tariffId={tariff.id} onDelete={onDelete} />
      </div>
      <hr className="mt-1 border-small-secondary" />
      <div className="flex flex-row mt-1 text-gray-200">
        {tariff.elements.map((x, i) => (
          <TariffElement key={i} element={x} />
        ))}
        <div className="ml-8">
          Description:
          <br />
          <span className="text-sm">{description}</span>
        </div>
      </div>
    </div>
  );
};

const DeleteTariff = ({ tariffId, onDelete }) => {
  const { tenancyState } = useAuth();
  const [ocpi_delete_tariff, { loading, error, data }] =
    useMutation(DELETE_TARIFF);
  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <div className="mx-2 text-red-500">{`${error}`}</div>;
  }

  return (
    <button
      className={`ml-auto mr-2 bg-red-600 rounded-lg text-white font-bold mt-2 px-4 h-10${
        tenancyState && !tenancyState.active
          ? " opacity-50 pointer-events-none"
          : ""
      }`}
      onClick={async () => {
        const result = await ocpi_delete_tariff({
          variables: {
            ocpiDeleteTariffId: tariffId.toString(),
          },
        });

        alert("Tariff successfully deleted");
        onDelete(); // Refetch query
      }}
    >
      Delete
    </button>
  );
};

const TariffElement = ({ element }) => {
  if (element.price_components.length === 0) {
    return <h1>Error</h1>;
  }

  var priceComponent = element.price_components[0];
  if (element.price_components.length === 1) {
    var parkingFee = "FREE";
  } else {
    var parkingFee = `${element.price_components[1].price} / hour
            `;
  }

  return (
    <div className="ml-2 mt-1 w-48">
      <div className="ml-2">
        <h1>
          Type: <span className="text-sm">{priceComponent.type}</span>
        </h1>
        <h1>
          Price:{" "}
          <span className="text-sm">
            $
            {`${priceComponent.price}  ${
              priceComponent.type === "FLAT"
                ? " "
                : priceComponent.type === "TIME"
                ? "/ min"
                : "/ kWh"
            }`}
          </span>
        </h1>
        <h1>
          Idle Fee:{" $"}
          <span className="text-sm truncate">{parkingFee}</span>
        </h1>
      </div>
    </div>
  );
};
