import React, { useState } from "react";
import DataGrid from "react-data-grid";
import Spinner from "../misc/Spinner";

import { ButtonlessModal, RightModal } from "../layout/rightModal";
import { NewCard } from "./CreateCard";
import { EditToken } from "./EditToken";
import { gql, useQuery } from "@apollo/client";

const TOKENS = gql`
  query GetTokens {
    ocpi_tokens {
      contract_id
      country_code
      group_id
      issuer
      party_id
      type
      uid
      valid
      visual_number
      whitelist
    }
  }
`;

export const Tokens = ({}) => {
  const [searchTerm, updateSearchTerm] = useState("");
  const { loading, error, data } = useQuery(TOKENS);

  console.debug(error);

  if (loading) return <Spinner />;
  if (error) return <p>Error Loading tokens</p>;

  return (
    <div>
      <CardsHeader />
      <hr className="my-2 border border-gray-400" />
      <CardDataGrid tokens={data.ocpi_tokens} searchTerm={searchTerm} />
      <div className="fixed right-0 top-0 m-4">
        <RightModal title="Add RFID Card">
          <NewCard />
        </RightModal>
      </div>
    </div>
  );
};

const CardsSeachBar = ({ updateSearchTerm }) => {
  return (
    <div className="pt-2 relative mx-auto text-gray-600 p-4">
      <input
        className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
        type="search"
        name="search"
        placeholder="Search"
        onChange={(event) => {
          updateSearchTerm(event.target.value);
        }}
      />
    </div>
  );
};

/*
Will re add later.

<div className="ml-auto">
    <button
        className="bg-green-600 text-white text-sm font-bold py-1 px-2 rounded"
        onClick={() => toggleImportCards(!showImportCards)}
    >
        Import Cards CSV File
    </button>
    <ButtonlessModal
        title="Import Cards"
        onClose={() => {toggleImportCards(false)}}
        show={showImportCards}
    >
        <ImportMifareCards/>
    </ButtonlessModal>
</div>
*/

const CardsHeader = () => {
  const [showImportCards, toggleImportCards] = useState(false);

  return (
    <div className="flex flex-row my-2 ml-2">
      <h1 className="text-3xl font-bold">Mifare Cards </h1>
    </div>
  );
};

/*
 *   Only show the "edit token" panel when
 *   we have selected a token. When we click
 *   on a token, we set the selected token to it
 *   when we've finished editing the selected token
 *   we save it, and clear the selectedToken field.
 */
const CardDataGrid = ({ tokens, searchTerm }) => {
  const [selectedToken, updateSelectedToken] = useState(undefined);

  const columns = [
    {
      name: "Country Code",
      headerCellClass: "brandPrimaryColor",
      key: "country_code",
    },
    { name: "Party ID", headerCellClass: "brandPrimaryColor", key: "party_id" },
    { name: "UID", headerCellClass: "brandPrimaryColor", key: "uid" },
    { name: "Type", headerCellClass: "brandPrimaryColor", key: "type" },
    {
      name: "Contract ID",
      headerCellClass: "brandPrimaryColor",
      key: "contract_id",
    },
    {
      name: "Visual Number",
      headerCellClass: "brandPrimaryColor",
      key: "visual_number",
    },
    { name: "Issuer", headerCellClass: "brandPrimaryColor", key: "issuer" },
    { name: "Group ID", headerCellClass: "brandPrimaryColor", key: "group_id" },
    { name: "Valid", headerCellClass: "brandPrimaryColor", key: "valid" },
    {
      name: "Whitelist",
      headerCellClass: "brandPrimaryColor",
      key: "whitelist",
    },
  ];

  if (searchTerm.length > 0) {
    tokens = tokens.filter((x) => filterSearchTerm(x, searchTerm));
  }

  return (
    <div>
      <ButtonlessModal
        title="Edit Token"
        onClose={() => updateSelectedToken(undefined)}
        show={selectedToken}
      >
        <EditToken initialToken={selectedToken} />
      </ButtonlessModal>
      <style>
        {".rdg { height: 100vh !important; max-height: calc( 100vh - 180px ) }"}
      </style>
      <DataGrid
        columns={columns}
        className="rdg-dark"
        rows={tokens.map((x) => {
          return {
            ...x,
            valid: x.valid === true ? "Yes" : "No",
          };
        })}
        onRowClick={(row) => {
          var selectedToken = tokens.find((x) => x.id === row.id);

          if (!selectedToken) {
            alert("Malformed Token!");
            return;
          }

          updateSelectedToken(selectedToken);
        }}
      />
    </div>
  );
};

/*
 *   Have we typed a subset of a displayed field?
 */
function filterSearchTerm(row, searchTerm) {
  searchTerm = searchTerm.toLowerCase();

  return (
    row["country_code"]?.toLowerCase()?.indexOf(searchTerm) !== -1 ||
    row["party_id"]?.toLowerCase()?.indexOf(searchTerm) !== -1 ||
    row["uid"]?.toLowerCase()?.indexOf(searchTerm) !== -1 ||
    row["type"]?.toLowerCase()?.indexOf(searchTerm) !== -1 ||
    row["contract_id"]?.toLowerCase()?.indexOf(searchTerm) !== -1 ||
    row["visual_number"]?.toLowerCase()?.indexOf(searchTerm) !== -1 ||
    row["issuer"]?.toLowerCase()?.indexOf(searchTerm) !== -1 ||
    row["group_id"]?.toLowerCase()?.indexOf(searchTerm) !== -1 ||
    row["whitelist"]?.toLowerCase()?.indexOf(searchTerm) !== -1
  );
}
