import { gql, useMutation } from "@apollo/client";
import Spinner from "../misc/Spinner";

import type1logo from "../../assets/type1_charger.png";
import type2logo from "../../assets/type2_charger.png";
import ccstype1 from "../../assets/type1_ccs.png";
import ccstype2 from "../../assets/ccs_type2_charger.png";
import chademo from "../../assets/chademo_charger.png";

const DELETE_PRESET = gql`
  mutation DeleteDevicePreset($deleteDevicePresetId: String!) {
    deleteDevicePreset(id: $deleteDevicePresetId)
  }
`;

export const TYPES = {
  IEC_62196_T1: {
    logo: type1logo,
    title: "Type 1",
  },
  IEC_62196_T2: {
    logo: type2logo,
    title: "Type 2",
  },
  IEC_62196_T1_COMBO: {
    logo: ccstype1,
    title: "Type 1 DC",
  },
  IEC_62196_T2_COMBO: {
    logo: ccstype2,
    title: "Type 2 DC",
  },
  CHADEMO: {
    logo: chademo,
    title: "Chademo",
  },
  UNKNOWN: {
    logo: chademo,
    title: "Unknown",
  },
};

export const DevicePresetCard = ({ preset, onDelete }) => {
  const [deleteDevicePreset, { loading, error }] = useMutation(DELETE_PRESET);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <h1>{error?.message}</h1>;
  }

  var capabilities = preset.capabilities.map((x) => (
    <h1 key={x}>{x.replaceAll("_", "  ")}</h1>
  ));

  if (capabilities.length === 0) {
    capabilities = <p>None.</p>;
  }

  var connectors = preset.connectors.map((x) => (
    <DeviceConnector connector={x} key={x.id} />
  ));

  if (connectors.length === 0) {
    connectors = <p>None.</p>;
  }


  return (
    <div
      key={preset.id}
      className="bg-primary text-white flex-col w-full h-auto rounded p-2 text-sm mb-2 flex justify-between"
    >
      <div className="flex flex-row mt-2">
        <h1
          key="name"
          className="bg-secondary brandSecondaryColor rounded font-bold text-lg px-2 py-1.5 ml-2"
        >
          {preset.preset_name}
        </h1>
        <h1
          key="vendor-name"
          className="bg-secondary brandSecondaryColor rounded font-bold text-lg px-2 py-1.5 ml-2"
        >
          {preset.vendor} {preset.model_name}
        </h1>
        <button
          id={preset.preset_name}
          className="bg-red-500 ml-auto mr-2 text-white rounded text-lg px-2 py-1.5"
          onClick={async () => {
            await deleteDevicePreset({
              variables: {
                deleteDevicePresetId: preset.id,
              },
            });
            onDelete();
          }}
        >
          Delete Preset
        </button>
      </div>
      <div key="connector-wrapper" className="flex flex-row ml-2 mb-2 mt-4">
        <div className="flex flex-row">{connectors}</div>
        <div className="flex flex-col ml-auto py-4 px-6 h-48 overflow-y-scroll items-center text-white border-secondary rounded bg-primary mr-2">
          <h1 className="font-bold">Capabilities:</h1>
          <div>{capabilities}</div>
        </div>
      </div>
    </div>
  );
};

export const DeviceConnector = ({ connector }) => {
  const standard = connector.standard;
  const power_type = connector.power_type;
  var max_power = (connector.max_voltage * connector.max_amperage) / 1000;

  if (power_type === "AC_3_PHASE") {
    max_power *= 3;
  }

  const format = connector.format;
  const connector_meta = TYPES[standard];

  return (
    <div
      key={connector.id}
      className="p-4 h-48 w-48 items-center text-white border-secondary flex flex-col bg-primary rounded-lg mr-4"
    >
      <h1 className="font-bold">{connector_meta.title}</h1>
      <img className="h-24" src={connector_meta.logo} />
      <h1>
        {max_power}kW {format}
      </h1>
    </div>
  );
};
