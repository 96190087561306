/*
 *  This file is for when the user does
 *  not have an internet connection.
 *
 *  This component will be displayed.
 *  It will say "You're offline, please
 *  reconnect."
 */
export const OfflineIndicator = ({}) => {
  return (
    <div className="flex flex-row items-center">
      <div className="content-center ml-16">
        <h1>You're Offline</h1>
        <p>
          This software requires internet connectivity to function, please
          restore connectivity :)
        </p>
      </div>
    </div>
  );
};
