export const UPDATE_ENABLED = "UPDATE_ENABLED";

const INITIAL_STATE = {
  enabled: true,
};

export function adminSignupAuthReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_ENABLED: {
      return {
        enabled: action.enabled,
      };
    }
  }
  return state;
}
