import Firebase from "firebase/app";
import { Functions } from "../index";

export async function uploadFileContents(rows, functions) {
  if (!rows.length) {
    return false;
  }

  try {
    var uploadMifareList = Functions.httpsCallable("uploadMifareList");

    var result = await uploadMifareList({
      cards: rows,
      company: "NONE",
    });
  } catch (err) {
    console.log(err);
    return false;
  }
  return true;
}

export async function updateCard(
  active,
  cardNumber,
  company,
  customerCode,
  serialNumber,
  id
) {
  var cards = Firebase.firestore().collection("cards");

  var changeDate = new Date().toISOString();

  try {
    await cards.doc(id).update({
      active: active,
      cardNumber: cardNumber,
      company: company,
      customerCode: customerCode,
      serialNumber: serialNumber,
      cardModified: changeDate,
    });
  } catch (err) {
    return false;
  }

  return true;
}

/*
 *   CreateToken function may
 *   be used for updating a token,
 *   as it's a firestore set.
 */
export async function createToken(token) {
  if (!token.id) {
    return false;
  }

  var tokens = Firebase.firestore().collection("ocpi_tokens");
  try {
    var id = token.id;
    delete token.id;

    var result = await tokens.doc(id).set({
      ...token,
      uid: id,
      last_updated: Firebase.firestore.Timestamp.now(),
    });

    return true;
  } catch (err) {
    console.log(`Error creating card ${err}`);
    return false;
  }
}
