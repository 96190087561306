import firebase from "firebase";

import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";

import { useEffect, useState } from "react";
import { LOGIN_ERROR, LOGIN_SUCCESS } from "../../../reducers/userState";
import { RestrictedSpinner } from "../../misc/Spinner";

const selectedIndex = 0;

export const MFA = ({ resolver, handleLoginError, handleLoginSuccess }) => {
  const [verificationId, updateVerificationId] = useState(undefined);
  const [loading, updateLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const recaptcha = new firebase.auth.RecaptchaVerifier("recaptchaVerifier", {
      size: "invisible",
    });
    recaptcha.render();

    var phoneInfoOptions = {
      multiFactorHint: resolver.hints[selectedIndex],
      session: resolver.session,
    };

    var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
    updateLoading(true);
    phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, recaptcha)
      .then((verificationId) => {
        updateVerificationId(verificationId);
        updateLoading(false);
      });

    updateVerificationId(verificationId);
  }, []);

  return (
    <>
      <div id="recaptchaVerifier" />
      {loading && (
        <div className="relative bg-white shadow-md rounded px-8 mb-4 text-gray-500 h-32">
          <RestrictedSpinner />
        </div>
      )}
      {!loading && (
        <>
          <Formik
            initialValues={{
              mfa: "",
            }}
            onSubmit={async ({ mfa }) => {
              if (
                resolver.hints[selectedIndex].factorId !==
                firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID
              ) {
                handleLoginError(
                  "User has enrolled an unsupported 2nd factor!"
                );
                return;
              }

              try {
                var cred = firebase.auth.PhoneAuthProvider.credential(
                  verificationId,
                  mfa
                );

                var multiFactorAssertion =
                  firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
                // Complete sign-in.
                await resolver.resolveSignIn(multiFactorAssertion);
                handleLoginSuccess("Login success!");
              } catch (err) {
                console.error(`A signin error occurred: `, err);
                handleLoginError("Please ensure the 2FA code is correct!");
              }
            }}
          >
            <Form className="relative bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 text-gray-500">
              <p className="mb-4">
                Please enter the 2FA code sent to your phone.
              </p>
              <div className="mb-4">
                <label>Code: </label>
                <Field
                  type="text"
                  name="mfa"
                  className="p-1"
                  placeholder="code"
                />
              </div>
              <div className="mb-4 flex justify-between">
                <button className="btn-green block m-auto" type="submit">
                  Submit
                </button>
              </div>
            </Form>
          </Formik>
          <button className="text-xs block m-auto"> Sign In</button>
        </>
      )}
    </>
  );
};

const handleLoginError = (reason) => ({
  type: LOGIN_ERROR,
  message: reason,
});

const handleLoginSuccess = (reason) => ({
  type: LOGIN_SUCCESS,
  message: reason,
});

const mapStateToMFAFormProps = ({ userState }) => ({
  resolver: userState.resolver,
});

export const MFAContainer = connect(mapStateToMFAFormProps, {
  handleLoginError,
  handleLoginSuccess,
})(MFA);
