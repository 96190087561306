import { gql, useMutation, useQuery } from "@apollo/client";
import { connect } from "react-redux";
import { ChargerPresetSelector } from "./PresetSelector";
import { LocationSelector } from "./LocationSelector";
import Spinner from "../misc/Spinner";
import { useAuth } from "../../hooks/useAuth";

import {
  LOCATION_ID,
  PRESET_ID,
  DISPLAY_NAME,
  PARTY_ID,
  PREFILL_VALUES,
} from "../../reducers/updateEVSEMetadata";

const QUERY_METADATA = gql`
  query Evse_metadata($evseId: String!) {
    evse_metadata(evse_id: $evseId) {
      display_name
      preset_id
      location_id
      party_id
    }
  }
`;

const DELETE_DEVICE = gql`
  mutation DeleteDevice($deleteDeviceId: String!) {
    deleteDevice(id: $deleteDeviceId)
  }
`;

const UPDATE_METADATA = gql`
  mutation UpdateDeviceMetaData(
    $evseId: String!
    $displayName: String
    $presetId: String
    $locationId: String
    $partyId: String
  ) {
    updateDeviceMetaData(
      evse_id: $evseId
      display_name: $displayName
      preset_id: $presetId
      location_id: $locationId
      party_id: $partyId
    )
  }
`;

/******************************* FORM SECTION ******************************/

export const EditMetadataForm = ({
  chargePointId,
  handlePrefillValues,
  updateMetadata,
  onClose,
}) => {
  var queryMetadata = useQuery(QUERY_METADATA, {
    variables: {
      evseId: chargePointId,
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      var evse_metadata = data.evse_metadata;
      var { display_name, location_id, preset_id, party_id } = evse_metadata;
      if (display_name === "Unknown") display_name = undefined;
      handlePrefillValues(display_name, preset_id, location_id, party_id);
    },
  });

  const [updateDeviceMetadata, updateDeviceResult] =
    useMutation(UPDATE_METADATA);
  const [deleteDevice, deleteDeviceResult] = useMutation(DELETE_DEVICE);

  if (
    queryMetadata.loading ||
    updateDeviceResult.loading ||
    deleteDeviceResult.loading
  ) {
    return <Spinner />;
  }

  if (
    updateDeviceResult?.error ||
    queryMetadata?.error ||
    deleteDeviceResult?.error
  ) {
    return (
      <h1>
        {updateDeviceResult?.error?.message ||
          queryMetadata?.error?.message ||
          deleteDeviceResult?.error?.message}
      </h1>
    );
  }

  return (
    <div>
      <div>
        <div className="mb-2 w-full">
          Charger ID: <span className="ml-auto">{chargePointId}</span>
        </div>
        <div className="flex flex-row">
          <h1 className="py-2">Location: </h1>
          <UpdateLocationContainer />
        </div>
        <div className="flex flex-row">
          <h1 className="py-2">Device Preset: </h1>
          <UpdatePresetContainer />
        </div>
      </div>
      <UpdateMetadataInfoContainer />
      <button
        className="bg-green-600 text-white text-sm font-bold py-2 px-4 rounded mt-2"
        type="button"
        onClick={async () => {
          await updateDeviceMetadata({
            variables: {
              evseId: chargePointId,
              displayName: updateMetadata.display_name,
              presetId: updateMetadata.preset_id,
              locationId: updateMetadata.location_id,
              partyId: updateMetadata.party_id,
            },
          });
        }}
      >
        Submit
      </button>
      {/* CHECK USER CAN DELETE FOR FOLLOWING BUTTON */}
      {
        <button
          className="btn-red text-white text-sm font-bold ml-2 py-2 px-4 rounded mt-2"
          type="button"
          onClick={async () => {
            if (
              !window.confirm(
                "Are you sure you want to delete this device and all of its associated data from the system?"
              )
            ) {
              return;
            }

            console.debug(chargePointId);

            var result = await deleteDevice({
              variables: {
                deleteDeviceId: chargePointId,
              },
            });

            console.debug("Deleted device: ", result);

            // window.location.reload(false);
            onClose();
            alert("Device deleted successfully!");
            deleteDeviceResult?.refetch();
          }}
        >
          Delete Device
        </button>
      }
    </div>
  );
};

const handlePrefillValues = (
  display_name,
  preset_id,
  location_id,
  party_id
) => ({
  type: PREFILL_VALUES,
  display_name,
  preset_id,
  location_id,
  party_id,
});

const mapStateToMetadataProps = (state) => {
  const updateMetadata = state.updateMetadata;
  return {
    updateMetadata: updateMetadata,
  };
};

export const UpdateMetadataFormContainer = connect(mapStateToMetadataProps, {
  handlePrefillValues,
})(EditMetadataForm);

/******************************* INFO SECTION ******************************/

const UpdateMetadataInfoSection = ({
  handleUpdateDisplayName,
  display_name,
  handleUpdatePartyId,
  party_id,
}) => {
  const { uam, permissions } = useAuth();
  return (
    <>
      <div className="flex flex-row mt-2">
        <h1 className="mr-2 py-2">Charger Display Name:</h1>
        <input
          className="ml-auto border rounded p-2 bg-primary brandPrimaryColor placeholder-gray-400"
          value={display_name || ""}
          placeholder="Unknown"
          onChange={(e) => handleUpdateDisplayName(e.target.value)}
        />
      </div>
      {permissions?.role === "sysadmin" && (
        <div className="flex flex-row mt-2">
          <h1 className="mr-2 py-2">Party ID:</h1>
          <input
            className="ml-auto border rounded p-2 bg-primary brandPrimaryColor uppercase"
            value={party_id || ""}
            onChange={(e) => handleUpdatePartyId(e.target.value)}
          />
        </div>
      )}
    </>
  );
};

const handleUpdateDisplayName = (display_name) => ({
  type: DISPLAY_NAME,
  display_name,
});

const handleUpdatePartyId = (party_id) => ({
  type: PARTY_ID,
  party_id,
});

const mapStateToInfoProps = (state) => {
  var updateMetadata = state.updateMetadata;
  return {
    display_name: updateMetadata.display_name,
    party_id: updateMetadata.party_id,
  };
};

export const UpdateMetadataInfoContainer = connect(mapStateToInfoProps, {
  handleUpdateDisplayName,
  handleUpdatePartyId,
})(UpdateMetadataInfoSection);

/******************************* LOCATION SELECTOR SECTION ******************************/

const LocationDropdownSelector = ({ handleUpdateLocation, defaultValue }) => {
  return (
    <LocationSelector
      locationSelected={handleUpdateLocation}
      defaultValue={defaultValue}
    />
  );
};

const handleUpdateLocation = (location_id) => ({
  type: LOCATION_ID,
  location_id,
});

const mapStateToLocationProps = (state) => {
  return {
    defaultValue: state.updateMetadata.location_id,
  };
};

export const UpdateLocationContainer = connect(mapStateToLocationProps, {
  handleUpdateLocation,
})(LocationDropdownSelector);

/******************************* PRESET SELECTOR SECTION ******************************/

const PresetSelector = ({ preset_name, preset_id, handleUpdatePreset }) => {
  return (
    <ChargerPresetSelector
      onPresetSelected={handleUpdatePreset}
      preset_name={preset_name}
      preset_id={preset_id}
    />
  );
};

const handleUpdatePreset = (preset_id) => ({
  type: PRESET_ID,
  preset_id,
});

const mapStateToPresetProps = (state) => {
  var updateMetadata = state.updateMetadata;
  return {
    preset_name: updateMetadata.preset_name,
    preset_id: updateMetadata.preset_id,
  };
};

export const UpdatePresetContainer = connect(mapStateToPresetProps, {
  handleUpdatePreset,
})(PresetSelector);
