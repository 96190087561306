import { FaTrash } from "react-icons/fa";
import { TYPES } from "../../device_presets/DevicePreset";

export const PresetPreview = ({ preset, selected, updateSelected }) => {
  var capabilities = preset.capabilities.map((x) => (
    <h1 key={x}>{x.replaceAll("_", "  ")}</h1>
  ));

  if (capabilities.length === 0) {
    capabilities = <p>None.</p>;
  }

  var connectors = preset.connectors.map((x) => (
    <PreviewConnector connector={x} key={x.id} />
  ));

  if (connectors.length === 0) {
    connectors = <p>None.</p>;
  }

  return (
    <div
      className={`w-auto bg-primary flex-col rounded text-sm flex justify-between ${
        selected === preset
          ? `strongBlueDropshadow border-2 border-black`
          : `border border-black`
      }`}
      onClick={() => updateSelected(preset)}
    >
      <div className="flex flex-row">
        <h1 className="bg-secondary brandSecondaryColor rounded p-2 m-2">
          {preset.preset_name}
        </h1>
        <h1
          key="name"
          className="bg-secondary brandSecondaryColor rounded p-2 mt-2 mb-2"
        >
          {preset.vendor + " "}
          {preset.model_name}
        </h1>
      </div>
      <div key="connector-wrapper" className="flex flex-row">
        <div className="flex flex-row m-2">{connectors}</div>
        <div className="flex flex-col w-48 ml-auto items-center text-black border rounded bg-white m-2 p-2">
          <h1 className="font-bold">Standard:</h1>
          <div className="overflow-y-scroll">
            {preset?.connectors[0]?.standard}
          </div>
        </div>
      </div>
    </div>
  );
};

const PreviewConnector = ({ connector }) => {
  const standard = connector.standard;
  const power_type = connector.power_type;
  var max_power = (connector.max_voltage * connector.max_amperage) / 1000;

  if (power_type === "AC_3_PHASE") {
    max_power *= 3;
  }

  const format = connector.format;
  const connector_meta = TYPES[standard];

  return (
    <div
      key={connector.id}
      className="p-1 items-center text-black flex flex-col border rounded bg-white mr-4"
    >
      <h1 className="font-bold">{connector_meta.title}</h1>
      <img className="" src={connector_meta.logo} />
      <h1 className="mx-auto">
        {max_power}kW {format}
      </h1>
    </div>
  );
};
