import React, { useState } from "react";
import ReactDOM from "react-dom";
import MediaQuery from "react-responsive";

import { FaDirections } from "react-icons/fa";
import { Link } from "react-router-dom";

import {
  typesAtLocation,
  TYPES,
  STANDARD_TO_TITLE,
  typePortCounter,
  kWperDevice,
  getDeviceBrandsForType,
} from "./LocationMetadata";

import { AiOutlineCloseCircle, AiFillCloseCircle } from "react-icons/ai";

import { connect } from "react-redux";

/* Modal including locational metadata */
export const EcommerceModal = ({
  onClose,
  show,
  locationData,
  displayType,
}) => {
  if (!show) {
    return null;
  }
  let modalPosition;
  if (displayType === "sidemodal") {
    modalPosition = "sideModal z-50";
  } else {
    modalPosition = "slideUpDrawer z-50 border-t-4 border-color-primary";
  }

  const portalContainer = document.getElementById("rightModalTarget");
  return ReactDOM.createPortal(
    <>
      <div
        id="ecommerce-modal"
        className="absolute z-30 inset-0 h-full w-full overflow-auto bg-gray-500 bg-opacity-50"
      >
        <div className={modalPosition}>
          <div className="bg-white w-full shadow z-100 min-h-full overflow-y-scroll flex flex-col">
            <EcommerceModalHeader
              onClose={onClose}
              locationId={locationData.id}
              locImages={locationData.images}
            />
            <ModalTitleContainer locationData={locationData} />
            <ModalPlugsSection locationData={locationData} />
          </div>
        </div>
      </div>
    </>,
    portalContainer
  );
};

const EcommerceModalHeader = ({ onClose, locationId, locImages }) => {
  /* Pass setShowModal as onClose */
  const [closeHover, setCloseHover] = useState(false);
  var image =
    locImages.length > 0
      ? locImages[0].url
      : "https://evup-4634.kxcdn.com/img/containers/main/carousel/evup-news.png/9333120809c9af6bd394a691d090784c.png";

  return (
    <div className="h-56 overflow-clip">
      <img src={image} alt="Charging Station" className="w-min-full z-10" />
      <button
        id="close-button"
        className="relative closeButton top-4 right-4 mb-4 ml-auto"
        onClick={() => {
          onClose();
        }}
        onMouseOver={() => {
          setCloseHover(true);
        }}
        onMouseOut={() => {
          setCloseHover(false);
        }}
      >
        <CloseButton hovering={closeHover} />
      </button>{" "}
    </div>
  );
};

const ModalTitle = ({ locationData, geolocation }) => {
  let supported = typesAtLocation(locationData).map(
    (elem) => TYPES[STANDARD_TO_TITLE[elem]].title
  );

  if (supported.length === 0) {
    supported = null;
  } else {
    supported = `Supported: ${supported.join(", ")}`;
  }

  const directionsAddress = geolocation
    ? `https://www.google.com/maps/dir/${geolocation?.lat},${geolocation?.lng}/${locationData.coordinates?.lat},${locationData.coordinates?.lng}`
    : `https://www.google.com/maps/dir//${locationData.coordinates?.lat},${locationData.coordinates?.lng}`;

  return (
    <div className="flex flex-row title w-full z-100 bg-primary">
      <div className="w-2/3 ml-2 mt-2 h-3/4 right-1 supportedTypes">
        <span className="text-lg">
          {locationData.name !== locationData.address
            ? locationData.name
            : null}
        </span>
        <br />
        {locationData.address} <br />
        <h1 className="truncate">{supported}</h1>
      </div>
      <div className="items-end ml-auto mr-4 mt-4 text-center">
        <a
          id="directions-button"
          href={directionsAddress}
          target="_blank"
          rel="noreferrer"
          className="z-50"
        >
          <FaDirections size={40} className="ml-5" />
          Directions
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  geolocation: state.maps.geolocation,
});

export const ModalTitleContainer = connect(mapStateToProps)(ModalTitle);

const ModalPlugsSection = ({ locationData }) => {
  const types = typesAtLocation(locationData).map(
    (elem) => STANDARD_TO_TITLE[elem]
  );

  let offline = types.length === 0;
  if (offline) {
    return (
      <div
        id="deviceless-msg"
        className="bg-primary brandPrimaryColor rounded mx-2 mt-2 p-3"
      >
        No login required for this location <br />
        Plugin to start charging!
      </div>
    );
  } else {
    return (
      <div className="w-full z-100 bg-white">
        <div className="w-1/6 ml-4 mt-2 border-t relative border-black plugtitle rounded text-center">
          Plugs
        </div>
        <div className="w-full plugtitlebehind relative h-4 bottom-4 border-t border-black"></div>
        <ModalSinglePlugDiv
          locationData={locationData}
          type="type1"
          show={types.includes("type1")}
        />
        <ModalSinglePlugDiv
          locationData={locationData}
          type="type2"
          show={types.includes("type2")}
        />
        <ModalSinglePlugDiv
          locationData={locationData}
          type="chademo"
          show={types.includes("chademo")}
        />
        <ModalSinglePlugDiv
          locationData={locationData}
          type="ccstype1"
          show={types.includes("ccstype1")}
        />
        <ModalSinglePlugDiv
          locationData={locationData}
          type="ccstype2"
          show={types.includes("ccstype2")}
        />
      </div>
    );
  }
};

// Copied from mobile-rn
export function GetChargingRate(tariff) {
  if (!tariff?.elements) return "$FREE";
  for (var price_component of tariff.elements[0].price_components) {
    if (price_component.type === "FLAT") {
      return `$${price_component.price} Flat`;
    } else if (price_component.type === "TIME") {
      return `$${(
        (price_component.price * 60) /
        price_component.step_size
      ).toFixed(2)} / min`;
    } else if (price_component.type === "ENERGY") {
      return `$${(price_component.price / price_component.step_size).toFixed(
        2
      )} / kWh`;
    }
  }
  // No valid charging tariff
  return "$FREE";
}

const ModalSinglePlugDiv = ({ locationData, type, show }) => {
  if (!show) {
    return null;
  }

  const connector = TYPES[type];
  const logo = connector.logo;
  const title = connector.title;

  let cheapestTariff = GetChargingRate(locationData.tariff);

  return (
    <div className="blueBackdrop typeSectionBorder mt-0 h-32 w-full flex flex-row justify-start relative bottom-4">
      <div className="flex flex-col ml-2 mt-4">
        <div className="flex flex-row typetitle justify-center ml-1 rounded">
          <span className="my-auto">{title}</span>
        </div>
        <img src={logo} alt={title} className="plugDivImage" />
      </div>
      <div className="flex flex-col ml-3 mt-4 w-2/5 h-full text-sm">
        <span className="portAvailability">
          <span className="underline">
            {typePortCounter(locationData.evses, type)}
          </span>{" "}
          available
        </span>

        <div className="infoText mt-1 mb-1">
          <p>Up to {kWperDevice(locationData.evses, type)}kW</p>
          <MediaQuery minWidth={350}>
            <p>
              Vendors:{" "}
              {getDeviceBrandsForType(locationData.evses, type).join(", ")}
            </p>
          </MediaQuery>
        </div>
      </div>
      <div className="flex flex-col mt-3 ml-auto mr-8">
        <div className="flex flex-row w-full h-6">
          <span className="ecommerceModalPrice ml-1">
            From: {cheapestTariff}
          </span>
        </div>
        <div className="useNow">
          <Link to={`/ecommerce/${locationData.id}`}>Use Now</Link>
        </div>
      </div>
    </div>
  );
};

export const CloseButton = ({ hovering }) => {
  if (!hovering) {
    return (
      <AiOutlineCloseCircle
        className="inline mt-0.5 text-lg align-middle"
        size={23}
      />
    );
  } else {
    return (
      <AiFillCloseCircle
        className="inline mt-0.5 text-lg align-middle"
        size={23}
      />
    );
  }
};
