import React, { useLayoutEffect, useRef } from "react";
import { Route, Link } from "react-router-dom";
import {
  FaLocationArrow,
  FaUserCircle,
  FaPencilAlt,
  FaCogs,
  FaBolt,
  FaIdCard,
  FaBuilding,
  FaCode,
  FaStopwatch,
  FaCar,
  FaDollarSign,
  FaPowerOff,
  FaMoneyBillWaveAlt,
  FaWarehouse,
  FaBalanceScaleRight,
} from "react-icons/fa";

import Menu from "./Menu";
import { useAuth } from "../../hooks/useAuth";
import Spinner from "../misc/Spinner";

const MENU_ITEM_STYLE = "inline-block mr-3";

const MENU_ITEMS = [
  {
    name: "VIEW MAP",
    link: "/ecommerce",
    icon: <FaCar className={MENU_ITEM_STYLE} />,
  },
  {
    name: "LOCATIONS",
    link: "/locations",
    icon: <FaLocationArrow className={MENU_ITEM_STYLE} />,
  },
  {
    name: "DEVICES",
    link: "/machines",
    icon: <FaCogs className={MENU_ITEM_STYLE} />,
  },
  {
    name: "ANALYTICS",
    link: "/analytics",
    icon: <FaBolt className={MENU_ITEM_STYLE} />,
  },
  {
    name: "SESSIONS",
    link: "/sessions",
    icon: <FaStopwatch className={MENU_ITEM_STYLE} />,
  },
  {
    name: "RFID",
    link: "/tokens",
    icon: <FaIdCard className={MENU_ITEM_STYLE} />,
  },
  {
    name: "DLB",
    link: "/dlb",
    icon: <FaBalanceScaleRight className={MENU_ITEM_STYLE} />,
  },
  {
    name: "TARIFFS",
    link: "/tariffs",
    icon: <FaMoneyBillWaveAlt className={MENU_ITEM_STYLE} />,
  },
  {
    name: "TENANCY",
    link: "/tenancy",
    icon: <FaWarehouse className={MENU_ITEM_STYLE} />,
  },
  {
    name: "PRESETS",
    link: "/device-presets",
    icon: <FaCode className={MENU_ITEM_STYLE} />,
  },
  {
    name: "SUBSCRIBE",
    link: "/subscribe",
    icon: <FaDollarSign className={MENU_ITEM_STYLE} />,
  },
  {
    name: "ADMIN",
    link: "/accounts",
    icon: <FaBuilding className={MENU_ITEM_STYLE} />,
  },
  {
    name: "ME",
    link: "/account",
    icon: <FaUserCircle className={MENU_ITEM_STYLE} />,
  },
];

const SYS_ADMIN_MENU = [
  "/ecommerce",
  "/locations",
  "/machines",
  "/analytics",
  "/sessions",
  "/tariffs",
  "/device-presets",
  "/dlb",
  "/accounts",
  "/tenancy",
  "/account",
];

const TENANCY_ADMIN_MENU = [
  "/ecommerce",
  "/locations",
  "/machines",
  "/analytics",
  "/sessions",
  "/tariffs",
  "/dlb",
  "/tenancy",
  "/account",
];

const LOCATION_ADMIN_MENU = [
  "/ecommerce",
  "/locations",
  "/machines",
  "/analytics",
  "/sessions",
  "/dlb",
  "/tariffs",
  "/account",
];

const PrivateRoute = (props) => {
  const user = useAuth();
  const loading = user.loading;
  const lite = user.lite;
  const permissions = user.permissions;

  var menu = [];

  const containerRef = useRef(null);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const subscriptionBannerNode = document.getElementsByClassName(
        "subscription-banner"
      )[0];
      containerRef.current.style.height = `calc(100vh - ${
        subscriptionBannerNode ? subscriptionBannerNode.offsetHeight : 0
      }px)`;
    }
  }, [containerRef, user.tenancyState]);

  // Dont return a spinner or we get a weird flickering effect
  if (loading) {
    return;
  }

  switch (permissions?.role) {
    case "sysadmin": {
      console.log("is sysadmin!");
      menu = SYS_ADMIN_MENU;
      break;
    }
    case "tenancyadmin": {
      console.log("is tenancy admin!");
      menu = TENANCY_ADMIN_MENU;
      break;
    }
    case "locationadmin": {
      menu = LOCATION_ADMIN_MENU;
      break;
    }
    default: {
      return (
        <div className="bg-primary flex flex-row items-center justify-center text-white w-full">
          <div className="flex flex-col items-center">
            <p>Please login to access this page.</p>
            <Link to="/login" className="mt-2 px-4 py-2 bg-green-600 rounded">
              <button id="admin-login">Admin Login</button>
            </Link>
          </div>
        </div>
      );
    }
  }

  // Temporarily removing the subscribe page as per Liz's request.
  //if (lite && !menu.includes("/subscribe")) menu.splice(8, 0, "/subscribe");

  var menuBuilt = [];

  // Map our links to menu items.
  for (var link of menu) {
    var index = MENU_ITEMS.findIndex((x) => x.link === link);
    if (index !== -1) {
      menuBuilt.push(MENU_ITEMS[index]);
    }
  }

  return (
    //Don't change these triangle brackets to div, bad things will happen.
    <>
      <Menu menuProfile={menuBuilt} />
      <div className="flex-1">
        <div ref={containerRef} className="container mx-auto p-4 overflow-auto">
          <Route {...props} />
        </div>
      </div>
    </>
  );
};

export default PrivateRoute;
