import { useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { EditUserForm } from "./UserEditorForm";
import Spinner from "../misc/Spinner";
import { useAuth } from "../../hooks/useAuth";

const USER_QUERY = gql`
  query GetUser($uid: String!) {
    user(uid: $uid) {
      uid
      email
      name
      phone_number
      permissions {
        role
      }
      party_id
      company
    }
  }
`;

const CREATE_USER = gql`
  mutation CreateAdminUser(
    $email: String!
    $name: String!
    $permissions: PermissionsInput!
    $company: String!
    $password: String!
    $phoneNumber: String!
  ) {
    createAdminUser(
      email: $email
      name: $name
      permissions: $permissions
      company: $company
      password: $password
      phone_number: $phoneNumber
    )
  }
`;

const EDIT_USER = gql`
  mutation (
    $uid: String!
    $email: String
    $name: String
    $role: String
    $company: String
    $phone_number: String
    $party_id: String
  ) {
    editUser(
      uid: $uid
      email: $email
      company: $company
      name: $name
      role: $role
      party_id: $party_id
      phone_number: $phone_number
    )
  }
`;

const DELETE_USER = gql`
  mutation ($uid: String!) {
    deleteUser(uid: $uid)
  }
`;

export const RoleHierarchy = {
  sysadmin: 3,
  tenancyadmin: 2,
  locationadmin: 1,
  user: 0,
};

export const CreateUser = ({ role }) => {
  const [userInfo, setUserInfo] = useState({});
  const [createAdminUser, { loading, error, data }] = useMutation(CREATE_USER);
  var user = useAuth();
  console.log("Creating user with party id", user.party_id);


  if (loading) return <Spinner />;
  if (error) return <p>Error: '{`${error}`}'</p>;

  console.log("User info is", userInfo)

  return (
    <div>
      <EditUserForm
        defaultValue={{
          permissions: {
            role: role,
          },
          company: user.company,
        }}
        type={"CREATE"}
        canEdit={true}
        role={role}
        onChange={(user) => setUserInfo(user)}
      />
      <button
        key="create-button"
        onClick={async () => {
          //Validate the request
          var valid = validateUserCreationRequest(userInfo, role);

          if (valid !== true) {
            alert(
              `Please ensure all fields are filled out! \nField ${valid} is invalid.`
            );
            return;
          }

          var result = await createAdminUser({
            variables: {
              company: userInfo.company || "",
              email: userInfo.email,
              password: userInfo.password,
              name: userInfo.name,
              permissions: userInfo.permissions,
              phoneNumber: userInfo.phone_number || "",
            },
          });

          if (result) {
            alert("User successfully created!");
            window.location.assign(`/tenancy`);
          }
        }}
        className="mt-2 btn-green border border-black"
      >
        Create
      </button>
    </div>
  );
};

export const EditUser = ({ role, id }) => {
  const [userInfo, setUserInfo] = useState({});
  const [editUser, editUserStatus] = useMutation(EDIT_USER);
  const [deleteUser, deleteUserStatus] = useMutation(DELETE_USER);

  //Get the user first.
  const userQueryStatus = useQuery(USER_QUERY, {
    variables: {
      uid: id,
    },
  });

  if (
    editUserStatus.loading ||
    deleteUserStatus.loading ||
    userQueryStatus.loading
  )
    return <Spinner />;
  if (editUserStatus.error || deleteUserStatus.error || userQueryStatus.error) {
    console.debug("EDIT USER STATUS ERROR:", editUserStatus.error);
    console.debug("DELETE USER ERROR:", deleteUserStatus.error);
    console.debug("QUERY USER ERROR:", userQueryStatus.error);
    return <p>Error editing user :(</p>;
  }

  //User may only be edited if they are lower in the heirarchy than the editor
  var canEdit =
    findRoleDistance(role, userQueryStatus?.data?.user?.permissions?.role) > 0;

  return (
    <div>
      <EditUserForm
        onChange={(user) => setUserInfo(user)}
        role={role}
        defaultValue={userQueryStatus?.data.user}
        canEdit={canEdit}
        type={"EDIT"}
      />
      {canEdit && (
        <div className="mt-2 flex flex-row">
          <button
            className="btn-blue mr-2"
            onClick={async () => {
              var validationResult = validateUserEditRequest(
                userInfo,
                id,
                role
              );

              if (validationResult !== true) {
                alert(
                  `The following error occurred when trying to update the user.\n ${validationResult}`
                );
                return;
              }

              var result = await editUser({
                variables: {
                  uid: id,
                  email: userInfo.email,
                  name: userInfo.name,
                  role: userInfo.permissions.role,
                  company: userInfo.company || "",
                  party_id: userInfo.party_id,
                  phone_number: userInfo.phone_number,
                },
              });

              if (result) {
                window.location.assign(`/tenancy`);
              }
            }}
          >
            Update
          </button>
          <button
            className="btn-red"
            onClick={async () => {
              if (
                !window.confirm("Are you sure you want to delete this user?")
              ) {
                return;
              }

              var result = await deleteUser({
                variables: {
                  uid: id,
                },
              });

              if (result?.data?.deleteUser === true) {
                window.location.assign(`/tenancy`);
              }
            }}
          >
            Delete
          </button>
        </div>
      )}
    </div>
  );
};

function validateUserCreationRequest(user, requestorRole) {
  if (user.name === undefined) {
    return "name";
  }

  if (user.email === undefined) {
    return "email";
  }

  if (user?.permissions?.role === undefined) {
    return "role";
  }

  if (
    (!user.company ||
      user.company?.length === 0 ||
      user.company === "SELECT_COMPANY") &&
    requestorRole === "sysadmin"
  ) {
    return "invalid company!";
  }

  if (user.password === undefined) {
    return "password";
  }

  return true;
}

export function findRoleDistance(me, them) {
  //sysadmin can do anything
  if (me === "sysadmin") {
    return 1;
  }
  return RoleHierarchy[me] - RoleHierarchy[them];
}

function validateUserEditRequest(userDetails, id, requestorRole) {
  if (!id || id.length === 0) {
    return "ID error occurred, please contact support.";
  }

  if (!userDetails.email || userDetails.email?.length === 0) {
    return "invalid email!";
  }

  if (!userDetails.name || userDetails.name?.length === 0) {
    return "invalid name!";
  }

  if (
    (!userDetails.company || userDetails.company?.length === 0) &&
    requestorRole === "sysadmin"
  ) {
    return "invalid company!";
  }

  if (!userDetails.party_id || userDetails.party_id?.length !== 3) {
    return "invalid party ID!";
  }

  if (
    !userDetails.permissions?.role ||
    userDetails.permissions?.role?.length === 0
  ) {
    return "invalid role selected!";
  }

  if (!userDetails.phone_number || userDetails.phone_number?.length === 0) {
    return "invalid phone number!";
  }

  return true;
}
