import { max } from "mathjs";
import React, { useState } from "react";
import { validateTariff } from "../../actions/tariffs";
import { Tooltip } from "../../components/Tooltip";

//What energy selections should users be given to charge?
//in kWh
const ENERGY_VALUES = [1, 5, 10, 20, 25];

//What time selections should users have to charge?
//in minutes
const TIME_VALUES = [5, 15, 30, 60, 120];

export const PricingCalculator = ({ tariff, evse, onNext }) => {
  if (!evse.connected) {
    return (
      <div className="text-center p-2 bg-white border rounded">
        This machine is offline. Please contact support.
      </div>
    );
  }

  if (!tariff) {
    return (
      <div className="text-center p-2 bg-white border rounded">
        This machine is incorrectly configured to accept payment. Please contact
        support.
      </div>
    );
  }

  //Go through payment flow, at the end, enter details and pay
  var priceComponents = tariff.elements.map((x) => x.price_components).flat();

  var energy = priceComponents.filter((x) => x.type === "ENERGY");

  //Decide which buttons to show the user.
  const TYPE = energy.length > 0 ? "ENERGY" : "TIME";
  const VALUES = TYPE === "ENERGY" ? ENERGY_VALUES : TIME_VALUES;

  var description = null;

  //get the english description text
  var descriptionText = tariff.tariff_alt_text.filter(
    (x) => x.language === "en"
  )?.[0]?.text;

  if (descriptionText) {
    description = <h1 className="text-center">{descriptionText}</h1>;
  }

  return (
    <div className="p-8 bg-white rounded border">
      <h1 className="font-bold text-2xl mb-2 text-center">Select session:</h1>
      {description}
      <PaymentButtons
        tariff={tariff}
        type={TYPE}
        values={VALUES}
        onSelect={({ total_price, units, type }) => {
          if (type === "TIME") {
            //covert time to seconds!
            units = units * 60;
          }

          validateContractProposition(
            {
              //one of ENERGY, TIME
              type: type,
              //how many units does the user wish to use
              units: units,
              //the tariff
              tariff: tariff,
              total_price: total_price,
            },
            onNext
          );
        }}
      />
    </div>
  );
};

const PaymentButtons = ({ type, values, onSelect, tariff }) => {
  if (!values) {
    return <h1>Error!</h1>;
  }

  return (
    <div>
      {values.map((x) => (
        <PaymentButton
          key={x}
          type={type}
          value={x}
          onSelect={onSelect}
          tariff={tariff}
        />
      ))}
    </div>
  );
};

const PaymentButton = ({ tariff, type, value, onSelect }) => {
  //reduce the tariff to some total price
  const [totalPrice, updateTotalPrice] = useState(
    // eslint-disable-next-line
    reduceTariff(tariff, type, value)
  );

  var unit = type === "ENERGY" ? "kWh" : "mins";
  // Square has a minimum transaction price of $1.00
  var decidedPrice = max(1, totalPrice);

  return (
    <button
      className="w-full rounded flex flex-row justify-center bg-primary brandPrimaryColor p-4 uppercase text-center mt-2"
      onClick={() =>
        onSelect({
          //in kWh
          units: value,
          type: type,
          total_price: decidedPrice,
        })
      }
    >
      <p className="my-auto">
        {value} {unit} : ${decidedPrice}
      </p>
      {totalPrice < 1 && (
        <Tooltip id="minprice" className="my-auto ml-2 normal-case">
          The minimum price for prepaid charging is $1.00
        </Tooltip>
      )}
    </button>
  );
};

export function reduceTariff(tariff, type, units) {
  if (!tariff || !type || !units) {
    return "Pricing Unavailable";
  }

  if (type === "TIME") {
    units = units * 60;
  }

  //Reduce to just price components
  var priceComponents = tariff.elements.map((x) => x.price_components).flat();

  var flatRate = priceComponents.filter((x) => x.type === "FLAT")?.[0];
  //dynamic component,
  //one of ENERGY, TIME
  var dynamic = priceComponents.filter((x) => x.type === type)?.[0];

  var sum = 0;

  if (flatRate) {
    sum += reducePriceComponent(flatRate, 1);
  }

  if (dynamic) {
    sum += reducePriceComponent(dynamic, units);
  }

  return Number.parseFloat(sum.toFixed(2));
}

function validatePrepaymentContract(contractProposition) {
  var tariff = contractProposition.tariff;
  var component_types = tariff.elements
    .map((x) => x.price_components.map((x) => x.type))
    .flat();

  //Pre payed tariffs may only have one of energy and time.
  if (
    component_types.indexOf("ENERGY") !== -1 &&
    component_types.indexOf("TIME") !== -1
  ) {
    return false;
  } else {
    return true;
  }
}

//This function must decide if we are ready to go to the next step
//or is the form only partially filled out?
function validateContractProposition(contractProposition, onNext) {
  //The contract proposition must be validated, only then may be proceed.
  //For each element in our tariff, is our contract proposition valid against
  //these elements?
  var tariff = contractProposition.tariff;

  if (
    !validateTariff(tariff) ||
    !validatePrepaymentContract(contractProposition)
  ) {
    alert("This device's tariff is misconfigured! Please contact support!");
    return;
  }

  //Case the proposition is valid
  //We dont return and instead proceed to the next step
  onNext(contractProposition);
}

//Take a price component and return a price
function reducePriceComponent(priceComponent, units) {
  //Billed per how many units i.e 2$ per 2 KwH
  var step_size = priceComponent.step_size;
  //Price per step
  var price = priceComponent.price;
  //GST (optional)
  var vat = priceComponent.vat;

  if (priceComponent.type === "FLAT") {
    step_size = 1;
    units = 1;
  }

  var priceBeforeVAT = (units / step_size) * price;
  if (vat) {
    return priceBeforeVAT + priceBeforeVAT * (vat / 100);
  } else {
    return priceBeforeVAT;
  }
}
