import React from "react";
import MediaQuery from "react-responsive";

import type1logo from "../../assets/type1_charger.png";
import type2logo from "../../assets/type2_charger.png";
import ccstype1 from "../../assets/type1_ccs.png";
import ccstype2 from "../../assets/ccs_type2_charger.png";
import chademo from "../../assets/chademo_charger.png";

import { EcommerceModal } from "./EcommerceModal";

export const ALLOWED_TYPES = [
  "CHADEMO",
  "IEC_62196_T1",
  "IEC_62196_T1_COMBO",
  "IEC_62196_T2",
  "IEC_62196_T2_COMBO",
];

export const TYPES = {
  type1: {
    logo: type1logo,
    title: "Type 1",
    connectorStandard: "IEC_62196_T1",
  },
  type2: {
    logo: type2logo,
    title: "Type 2",
    connectorStandard: "IEC_62196_T2",
  },
  ccstype1: {
    logo: ccstype1,
    title: "Type 1 DC",
    connectorStandard: "IEC_62196_T1_COMBO",
  },
  ccstype2: {
    logo: ccstype2,
    title: "Type 2 DC",
    connectorStandard: "IEC_62196_T2_COMBO",
  },
  chademo: {
    logo: chademo,
    title: "Chademo",
    connectorStandard: "CHADEMO",
  },
};

export const STANDARD_TO_TITLE = {
  IEC_62196_T1: "type1",
  IEC_62196_T2: "type2",
  IEC_62196_T1_COMBO: "ccstype1",
  IEC_62196_T2_COMBO: "ccstype2",
  CHADEMO: "chademo",
};

export const MetaDisplay = ({ locationData, onDismiss, show }) => {
  return (
    <div onClick={onDismiss}>
      <MediaQuery minWidth={550}>
        <EcommerceModal
          show={show}
          locationData={locationData}
          onClose={onDismiss}
          displayType="sidemodal"
        />
      </MediaQuery>
      <MediaQuery maxWidth={550}>
        <EcommerceModal
          show={show}
          locationData={locationData}
          onClose={onDismiss}
          displayType="slideup"
        />
      </MediaQuery>
    </div>
  );
};

/*
 * Function that outputs argument object for ConnectorMetadata.
 * Can handle any amount of supported types
 */
function ConnectorMetadataArgument(locationData, types) {
  let connectorInfo = [];
  for (var i = 0; i < types.length; i++) {
    connectorInfo.push({});
    connectorInfo[i][`Title`] = TYPES[types[i]].title;
    connectorInfo[i][`Logo`] = TYPES[types[i]].logo;
    connectorInfo[i][`Ports`] = typePortCounter(locationData.evses, types[i]);
    connectorInfo[i][`kW`] = kWperDevice(locationData.evses, types[i]);
  }
  return connectorInfo;
}

/*
 * The device info display in location metadata.
 * Could be abstracted in future to display n devices.
 */
const ConnectorMetadata = ({ show, locationData, types }) => {
  if (!show) {
    return null;
  } else {
    let connectorInfo = ConnectorMetadataArgument(locationData, types);

    return (
      <div className="flex flex-row gap-x-2 mx-auto justify-center w-fit grow mb-1">
        {connectorInfo.slice(0, Math.min(connectorInfo.length, 2)).map((x) => {
          return (
            <LocationMetadataTypeInfo
              key={connectorInfo.indexOf(x)}
              title={x["Title"]}
              logo={x["Logo"]}
              ports={x["Ports"]}
              kw={x["kW"]}
              seperator={connectorInfo.indexOf(x) != 0}
            />
          );
        })}
      </div>
    );
  }
};

const LocationMetadataTypeInfo = ({ title, logo, ports, kw, seperator }) => {
  if (seperator) {
    seperator = "metaTypeSeperator";
  } else {
    seperator = null;
  }
  return (
    <div className={`flex flex-col whitespace-nowrap w-14`}>
      <img
        src={logo}
        alt={`${title} Charger`}
        className="chargerImg mx-auto inline-block"
      />
      <strong>{title}</strong>
      Ports: {ports}
      <br />
      kW: {kw}
    </div>
  );
};

/* ---------------- METADATA FUNCTIONS ---------------- */

/*
 *  Function to count all the ports at a location
 *  Will be useful somewhere, not yet in use
 */
export function totalPortCounter(evses) {
  let counter = 0;
  for (let device of evses) {
    counter += device.connectors.length;
  }
  return counter;
}

/*
 *  A function to count the amount of ports for a given charger type
 *  To feed through data to meta display
 */
export function typePortCounter(evses, type) {
  let counter = 0;
  for (let device of evses) {
    for (let connector of device.connectors) {
      if (connector.standard === TYPES[type].connectorStandard) {
        counter++;
      }
    }
  }
  return counter;
}

/*
 *  Return an array with the connector types at given location
 *  Used to interpret what connector types to display in metadata & modal
 */
export function typesAtLocation(locationData) {
  let connectors = [];
  for (let device of locationData.evses) {
    for (var connector of device.connectors) {
      if (connectors.includes(connector.standard)) {
        continue;
      } else {
        connectors.push(connector.standard);
      }
    }
  }
  return connectors.filter((x) => ALLOWED_TYPES.includes(x));
}

/*
 * Return the maximum kW at a location for a specified device type
 */
export function kWperDevice(evses, type) {
  let possibleKW = [];
  for (let device of evses) {
    for (let connector of device.connectors) {
      if (connector.standard === TYPES[type].connectorStandard) {
        var max_power = (connector.voltage * connector.max_amperage) / 1000;
        if (connector.power_type === "AC_3_PHASE") {
          max_power *= 3;
        }
        possibleKW.push(max_power);
      }
    }
  }
  return Math.max(...possibleKW);
}

export function getDeviceBrandsForType(evses, type) {
  let allBrands = [];
  for (let device of evses) {
    for (let connector of device.connectors) {
      if (allBrands.includes(device.vendor)) {
        continue;
      } else if (
        connector.standard === TYPES[type].connectorStandard &&
        device.vendor
      ) {
        allBrands.push(device.vendor === "Unknown" ? "EVUp" : device.vendor);
      }
    }
  }
  return allBrands;
}
