import logo from "../assets/logo.png";

export const PRIMARY_COLOR = "PRIMARY_COLOR";
export const SECONDARY_COLOR = "SECONDARY_COLOR";
export const TENANT_LOGO = "TENANT_LOGO";

const INITIAL_STATE = {
  primaryColor: "#333",
  secondaryColor: "#2fdce1",
  logo: logo,
};

export function tenantThemeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PRIMARY_COLOR: {
      return {
        ...state,
        primaryColor: action.primaryColor,
      };
      break;
    }
    case SECONDARY_COLOR: {
      return {
        ...state,
        secondaryColor: action.secondaryColor,
      };
      break;
    }
    case TENANT_LOGO: {
      return {
        ...state,
        logo: action.logo,
      };
    }
  }
  return state;
}
