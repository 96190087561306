import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { FirestoreDocument } from "react-firestore";
import { startCommand } from "../../actions/machines";
import Spinner from "../misc/Spinner";

function CommandButton(props) {
  let { evse_id, messageType, payload, title } = props;
  // TODO: make this compnent more useful by mapping common messages to standard payloads
  const [deBouncer, toggleDeBouncer] = useState(false);
  const [isClicked, toggleClicked] = useState(false);
  const [latestCommandID, setCommandID] = useState("");
  payload = payload ? payload : {};
  return (
    <button
      className={`border p-1 m-1 relative bg-white ${
        deBouncer ? "cursor-not-allowed pointer-events-none" : ""
      }`}
      onClick={() => {
        toggleClicked(true);
        toggleDeBouncer(true);
        // various timeouts
        setTimeout(() => toggleDeBouncer(false), 10000);
        setTimeout(() => toggleClicked(false), 10000);
        setTimeout(() => setCommandID(""), 30000);
        startCommand(evse_id, messageType, payload).then((id) => {
          setCommandID(id);
          toggleClicked(false);
        });
      }}
    >
      <span>{title ? title : messageType}</span>
      {isClicked && <Spinner size={10} />}
      {latestCommandID && (
        <FirestoreDocument path={`ocpp_commands/${latestCommandID}`}>
          {({ error, isLoading, data }) => {
            if (isLoading) {
              return <Spinner size={10} />;
            }
            console.debug("Command data: ", data);
            return (
              <>
                {data.sent && <p>...</p>}
                {data.response && (
                  <FaCheck className="inline text-green-600 ml-auto" />
                )}
              </>
            );
          }}
        </FirestoreDocument>
      )}
    </button>
  );
}

export default CommandButton;
