import logo from "../../../assets/logo.png";
import { useState } from "react";
import { EcommerceHeader } from "../../../components/EcommerceHeader";
import { AiOutlinePlus } from "react-icons/ai";
import { gql, useQuery, useMutation } from "@apollo/client";
import Spinner from "../../misc/Spinner";
import { FaTrash } from "react-icons/fa";

const GET_USER_RFIDS = gql`
  query Enduser_rfid {
    enduser_rfid {
      tokens {
        party_id
        country_code
        uid
        type
        contract_id
        issuer
        visual_number
        group_id
        valid
        language
        whitelist
        default_profile_type
        energy_contract {
          supplier_name
          contract_id
        }
        last_updated
      }
    }
  }
`;

const ADD_USER_RFID = gql`
  mutation Add_driver_RFID($rfid: createEndUserRFID!) {
    add_driver_RFID(rfid: $rfid) {
      errors
    }
  }
`;

const DELETE_USER_RFID = gql`
  mutation Delete_driver_RFID($rfid: String!) {
    delete_driver_RFID(rfid: $rfid)
  }
`;

export const EndUserRFID = () => {
  return (
    <div className="w-full sm:flex overflow-y-auto">
      <div className="bg-white w-full h-full overflow-auto">
        <EcommerceHeader>MY RFID CARDS</EcommerceHeader>
        <RFIDList />
      </div>
    </div>
  );
};

const RFIDList = () => {
  const { error, loading, data } = useQuery(GET_USER_RFIDS);
  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <h1>Error</h1>;
  }

  var user_tokens = data.enduser_rfid.tokens;

  var card_section;
  if (user_tokens.length == 0) {
    card_section = <div>You have no RFID cards added.</div>;
  } else {
    card_section = user_tokens.map((x) => {
      return <RFIDCard ocpi_token={x} key={x.uid} />;
    });
  }

  return (
    <div className=" px-2 mt-20 pt-5">
      <div className="flex flex-row flex-wrap justify-center">
        {card_section}
      </div>
      <div className="flex flex-row flex-wrap justify-center">
        <AddRFID hasRFIDs={user_tokens.length == 0} />
      </div>
    </div>
  );
};

const AddRFID = ({ hasRFIDs }) => {
  const [addingRFID, updateAddingRFID] = useState(false);
  const [addedRFIDName, updateAddedRFIDName] = useState();
  const [addedRFIDUID, updateAddedRFIDUID] = useState();

  const [add_driver_RFID, { error, loading, data }] =
    useMutation(ADD_USER_RFID);
  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <h1>Error</h1>;
  }

  /* Update whether we display a button or input card depending on state value */
  if (addingRFID) {
    return (
      <div className="inline-block h-36 rounded-md mb-2 w-96 mt-4 mx-4 max-w-sm bg-primary ">
        <div className="flex flex-row mt-5">
          <div className="flex flex-col justify-center">
            <img className="h-auto w-auto p-4" src={logo} />
          </div>
          <div className="flex flex-col py-1 justify-center">
            <div className="flex flex-row  mb-3">
              <label className="brandPrimaryColor">Name:</label>
              <input
                type="textarea"
                className="border rounded w-2/3 ml-auto mr-2 pl-1"
                onChange={(e) => {
                  updateAddedRFIDName(e.target.value);
                }}
              ></input>
            </div>
            <div className="flex flex-row mb-3">
              <label className="text-white">RFID:</label>
              <input
                type="textarea"
                className="border rounded w-2/3 ml-auto mr-2 pl-1"
                onChange={(e) => {
                  updateAddedRFIDUID(e.target.value);
                }}
              ></input>
            </div>
            <button
              className="text-sm bg-green-600 text-white w-1/2 rounded ml-auto mr-12"
              onClick={async () => {
                const result = await add_driver_RFID({
                  variables: {
                    rfid: {
                      name: addedRFIDName,
                      uid: addedRFIDUID.toUpperCase(),
                    },
                  },
                });
                console.debug(result);
                if (result.data?.add_driver_RFID?.errors?.length >= 1) {
                  alert(result.data.add_driver_RFID.errors.join("\n"));
                } else {
                  alert("RFID successfully added.");
                  window.location.reload(false);
                }
              }}
            >
              + Add
            </button>
          </div>
        </div>
      </div>
    );
    /* Display button initially, change state if pressed */
  } else {
    return (
      <div className="mt-4 w-full flex flex-row justify-center">
        <button
          className="mx-auto rounded inline-block items-center text-center border border-solid p-1 border-black w-auto"
          onClick={() => {
            updateAddingRFID(true);
          }}
        >
          <AiOutlinePlus className="mr-0.5 mb-1 inline" /> Add RFID Card
        </button>
      </div>
    );
  }
};

const RFIDCard = ({ ocpi_token }) => {
  const [delete_driver_RFID, { error, loading, data }] =
    useMutation(DELETE_USER_RFID);
  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <h1>Error</h1>;
  }
  return (
    <div className="inline-block h-36 rounded-md mb-2 w-96 mx-4 max-w-sm bg-primary ">
      <div className="flex flex-row mt-5">
        <div className="flex flex-col justify-center">
          <img className="h-auto w-auto p-4" src={logo} />
        </div>
        <div className="flex flex-col py-1 justify-center ml-2">
          <div className="flex flex-row text-white text-xs mb-1">
            Card Name:
          </div>
          <div className="flex flex-row brandPrimaryColor font-semibold text-xl mb-5">
            {ocpi_token.visual_number}
          </div>
          <div className="text-sm text-white">RFID: {ocpi_token.uid}</div>
        </div>
        <button className="ml-auto mb-auto mr-4 w-1/8">
          <FaTrash
            className="text-white"
            size={25}
            onClick={async () => {
              const result = await delete_driver_RFID({
                variables: {
                  rfid: ocpi_token.uid,
                },
              });

              if (result.data?.delete_driver_RFID?.errors?.length >= 1) {
                alert(result.data.delete_driver_RFID.join("\n"));
              } else {
                alert("EVSE successfully deleted.");
                window.location.reload(false);
              }
            }}
          />
        </button>
      </div>
    </div>
  );
};
