import React from "react";

import Firebase from "firebase/app";

import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import { BrowserRouter } from "react-router-dom";
import { ProvideAuth } from "./hooks/useAuth";

import { ApolloProvider } from "@apollo/client";
import { client } from "./apollo/client";
import { PersistGate } from "redux-persist/integration/react";
import { createRoot } from "react-dom/client";

import { TOKEN_UPDATE } from "./reducers/userState";

// stylesheets
import "./index.css";
import "./styles/custom.css";

import App from "./views/App";

// connect our app to firebase
const dbConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
};
var firebaseApp = Firebase.initializeApp(dbConfig);
export const auth = Firebase.auth();
// If using Cypress, make sure there is no auth persistence as to avoid autologin
window.Cypress &&
  Firebase.auth().setPersistence(Firebase.auth.Auth.Persistence.NONE);

export var Functions = firebaseApp.functions();
Firebase.auth().onIdTokenChanged(async (user) => {
  try {
    var token = user ? await user.getIdToken() : null;
    store.dispatch({
      type: TOKEN_UPDATE,
      token,
    });
  } catch (err) {
    console.log("ERROR UPDATING TOKEN:", err);
    store.dispatch({
      type: TOKEN_UPDATE,
      token: undefined,
    });
  }
});

const root = createRoot(document.getElementById("root"));
// render the App component to our document root with React
root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <BrowserRouter>
        <ProvideAuth>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </ProvideAuth>
      </BrowserRouter>
    </Provider>
  </ApolloProvider>
);
