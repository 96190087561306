import { gql, useQuery } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import { RestrictedSpinner } from "../../misc/Spinner";
import { AwaitingDevice } from "./device-search/DeviceEnrollment";
import { useAuth } from "../../../hooks/useAuth";

export const ConnectDevice = ({ onNext }) => {
  const [chargePointId, updateChargePointId] = useState("");
  const [chargePointSubmitted, updateChargePointSubmitted] = useState(false);
  const userResult = useAuth();

  const url =
    process.env.REACT_APP_ENV === "live"
      ? `wss://ocpp.evup.com.au/${userResult?.party_id}/${chargePointId}`
      : `wss://testocpp.evup.com.au/${userResult?.party_id}/${chargePointId}`;

  if (userResult.error) return <p>Error: could not get user</p>;

  return (
    <div className="mt-2">
      <div className="p-2 w-full rounded bg-primary text-sm text-white items-center m-auto mb-2">
        <p>Now, we'll get your device connected to EVUp</p>
      </div>
      <li className="list-disc mt-3">
        Enter the chargePointId of your OCPP 1.6-J device
      </li>
      <div className="flex flex-row mb-2 mt-1">
        <input
          type="text"
          className="border border-black ml-5 mt-1 w-3/4 rounded p-1"
          defaultValue={chargePointId}
          onChange={(e) => updateChargePointId(e.target.value)}
        />
        <button
          className="ml-2 my-auto mt-0.5 rounded bg-green-600 p-2"
          onClick={() => updateChargePointSubmitted(true)}
        >
          Confirm
        </button>
      </div>
      {chargePointSubmitted && (
        <>
          <AwaitingDevice
            chargePointId={chargePointId}
            url={url}
            onDeviceFound={onNext}
          />
          <div className="flex flex-row w-auto justify-center">
            <RestrictedSpinner size={30} className="mt-2 mb-2" />
            <span className="mt-7 ml-3">
              Awaiting connection for {chargePointId}...
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export function generateChargePointId() {
  var chargePointId = uuidv4();
  chargePointId = chargePointId.replaceAll("-", "");
  if (chargePointId.length > 25) {
    chargePointId = chargePointId.slice(0, 24);
  }
  return chargePointId;
}
