import { Route } from "react-router-dom";
import { FirestoreProvider } from "react-firestore";
import Firebase from "firebase/app";

import React from "react";
import ReactGA from "react-ga";

import ErrorBoundary from "./misc/ErrorBoundary";
import Routes from "./Routes";
import Layout from "./layout/Layout";
import SnackbarProvider from "react-simple-snackbar";

const App = () => (
  <SnackbarProvider>
    <FirestoreProvider firebase={Firebase}>
      <ErrorBoundary>
        <Layout>
          <Route path="/" component={ScrollToTop} />
          <Route path="/" component={Analytics} />
          <Routes />
        </Layout>
      </ErrorBoundary>
    </FirestoreProvider>
  </SnackbarProvider>
);

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }
  render() {
    return null;
  }
}

// Track Google Analytics page view for every route
// https://github.com/react-ga/react-ga/issues/122#issuecomment-319546248
const Analytics = ({ location }) => {
  const page = location.pathname + location.search;
  ReactGA.set({ page });
  ReactGA.pageview(page);
  return null;
};

export default App;
