import React, { useState, useEffect, useLayoutEffect } from "react";
import Moment from "react-moment";
import moment from "moment";

import DataGrid from "react-data-grid";
import "react-data-grid/lib/styles.css";
import { gql, useQuery } from "@apollo/client";
import Spinner from "../misc/Spinner";
import { FaFileExport } from "react-icons/fa";
import FileSaver from "file-saver";
import { useAuth } from "../../hooks/useAuth";
import { MdOutlineRefresh } from "react-icons/md";
const { parse } = require("json2csv");

const SESSION_STATUS = {
  ERROR: "Errored",
  FINISHED: "Finished",
  INPROGRESS: "In Progress",
  TERMINATED: "Terminated",
  UNPAID: "Unpaid",
};

const SESSIONS = gql`
  query Sessions($limit: Int, $evse_id: String, $status: String) {
    sessions(limit: $limit, evse_id: $evse_id, status: $status) {
      id
      status
      type
      party_id
      connector_number
      evse_id
      location_id
      start_time
      last_modified
      fulfilled
      kwh
      phases {
        start_time
        last_modified
        type
        kwh
        cost
      }
      user_id
      total_cost
      end_time
      error
      payment_metric
      agreed_duration
      energy_limit
      contract_abandoned
      terminated_time
      terminated_user_id
    }
  }
`;

export const ExportButton = ({ data, fields, title, className, btnStyle }) => {
  // The className should just be used to position the button
  const opts = { fields };

  const csv = parse(data, opts);

  const handleExport = () => {
    const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    FileSaver.saveAs(csvBlob, `${title}-data.csv`);
  };

  return (
    <div className={`mx-1 ${className}`}>
      <button
        style={btnStyle}
        className="flex flex-row border border-black bg-primary py-2 px-4 rounded brandPrimaryColor"
        onClick={handleExport}
      >
        Export to CSV
        <FaFileExport className="inline brandPrimaryColor ml-2 mt-0.5" />
      </button>
    </div>
  );
};

export const LegacyCheckbox = ({ evse_id, checked }) => {
  var reassignmentURL;
  if (!checked) {
    reassignmentURL = evse_id
      ? `/legacySessions/evse/${evse_id}`
      : "/legacySessions";
  } else {
    reassignmentURL = evse_id ? `/sessions/evse/${evse_id}` : "/sessions";
  }
  return (
    <div className="mx-2">
      <button
        className="flex-row flex bg-primary py-2 px-4 rounded brandPrimaryColor"
        onClick={() => {
          window.location.assign(reassignmentURL);
        }}
      >
        <h1>See Legacy Sessions</h1>
        <input
          type="checkbox"
          className="border-black border my-auto ml-2"
          defaultChecked={checked}
        />
      </button>
    </div>
  );
};

export const SessionsPage = ({ match }) => {
  // In the future, some sort of cell copying functionality should be added by a frontend developer.
  const [filters] = useState({});
  const [limit, updateLimit] = useState(1000);
  const [status, setStatus] = useState(undefined);
  const { loading, error, refetch, data } = useQuery(SESSIONS, {
    variables: {
      limit: 1000,
      evse_id: match?.params?.evse_id,
      status: undefined,
    },
    pollInterval: 30000, // poll every 30 seconds
    fetchPolicy: "no-cache",
  });
  const [subscriptionBannerHeight, setSubscriptionBannerHeight] = useState(100);
  const [reloading, setReloading] = useState(false);
  const user = useAuth();

  useLayoutEffect(() => {
    const subscriptionBannerNode = document.getElementsByClassName(
      "subscription-banner"
    )[0];
    if (subscriptionBannerNode) {
      setSubscriptionBannerHeight(
        subscriptionBannerNode.offsetHeight + subscriptionBannerHeight
      );
    }
  }, [user.tenancyState]);

  useEffect(() => {
    // Reset the state on page navigation
    if (limit !== 1000 || status) {
      updateLimit(1000);
      setStatus(undefined);
    }
  }, [match?.params?.evse_id]);

  if (loading) return <Spinner />;
  if (error) return <h1>Error getting sessions! {`${error}`}</h1>;

  var sessions = (data?.sessions || [])
    ?.filter((x) => filterSession(x, filters))
    ?.map((x) => mapSessionToRow(x));

  var title = match.params.evse_id ? (
    <p className=" text-gray-500 ml-3 mt-3">
      # EVSE ID: {match.params.evse_id}
    </p>
  ) : (
    <p className=" text-gray-500 ml-3 mt-3"># Tenancy ID: {user.party_id}</p>
  );

  const refetchData = async (limit, status) => {
    setReloading(true);
    await refetch({ limit, status });
    setReloading(false);
  };

  const onStatusChange = (status) => {
    const newStatus = status || undefined;
    setStatus(newStatus);
    refetchData(limit, newStatus);
  };

  // Return
  return (
    <div className="w-full pr-4">
      <div className="flex flex-row">
        <div className="flex flex-row">
          <h1 className="mt-1 ml-1 text-3xl font-bold">Sessions</h1>
          {title}
        </div>
        <div className="ml-auto flex flex-row">
          {/*<SearchBar
            permissions={user.permissions}
            filtersUpdated={updateFilters}
  />*/}
          <div className="flex flex-row bg-primary brandPrimaryColor p-2 rounded">
            <label className="mr-2 my-auto">Table Size:</label>
            <input
              type="number"
              value={limit}
              onChange={(e) => updateLimit(parseInt(e.target.value))}
              className="w-16 rounded text-black px-1"
            />
            <button className="ml-1" onClick={() => refetchData(limit, status)}>
              <MdOutlineRefresh size={25} />
            </button>
          </div>
          <div className="ml-1 flex flex-row bg-primary brandPrimaryColor p-2 rounded">
            <label className="mr-2 my-auto">Status:</label>
            <select
              className="border rounded"
              name="session-status"
              value={status}
              onChange={(e) => onStatusChange(e.target.value)}
            >
              <option label="All" />
              {Object.keys(SESSION_STATUS).map((key) => (
                <option value={key} label={SESSION_STATUS[key]} key={key} />
              ))}
            </select>
          </div>
          <ExportButton
            data={sessions}
            fields={[
              "id",
              "status",
              ...(status === "TERMINATED"
                ? ["terminated_user_id", "terminated_time"]
                : []),
              "type",
              "party_id",
              "connector_number",
              "evse_id",
              "location_id",
              "start_time",
              "end_time",
              "duration",
              "last_modified",
              "fulfilled",
              "kwh",
              "phases",
              "user_id",
              "total_cost",
              "error",
              "payment_metric",
              "agreed_duration",
              "energy_limit",
              "contract_abandoned",
            ]}
            title={"session"}
          />
        </div>
      </div>
      <hr className="my-3 border border-gray-400" />
      <div className="relative">
        {reloading && (
          <div
            className="absolute w-full h-full"
            style={{ zIndex: 1, paddingTop: 80 }}
          >
            <Spinner />
          </div>
        )}
        <style>
          {`.rdg { height: 100vh !important; max-height: calc(100vh - ${subscriptionBannerHeight}px) }`}
        </style>
        <div style={{ opacity: reloading && 0.9 }} className="w-full">
          <DataGrid
            columns={[
              {
                key: "start_time",
                name: "Start",
                headerCellClass: "brandPrimaryColor",
                formatter: ({ row }) => (
                  <Moment format="DD/MM hh:mm a">{row.start_time}</Moment>
                ),
                resizable: true,
              },
              {
                key: "end_time",
                name: "End",
                headerCellClass: "brandPrimaryColor",
                formatter: ({ row }) => {
                  if (row.end_time) {
                    return (
                      <Moment format="DD/MM hh:mm a">{row.end_time}</Moment>
                    );
                  } else {
                    return <h1>{SESSION_STATUS["INPROGRESS"]}</h1>;
                  }
                },
                resizable: true,
              },
              {
                key: "duration",
                name: "Duration",
                headerCellClass: "brandPrimaryColor",
                resizable: true,
              },
              {
                key: "status",
                headerCellClass: "brandPrimaryColor",
                name: "Status",
                formatter: ({ row }) => SESSION_STATUS[row.status],
              },
              ...(status === "TERMINATED"
                ? [
                    {
                      key: "terminated_user_id",
                      headerCellClass: "brandPrimaryColor",
                      name: "Terminated User ID",
                      formatter: ({ row }) => (
                        <p className="w-32 truncate">
                          {row.terminated_user_id}
                        </p>
                      ),
                    },
                    {
                      key: "terminated_time",
                      name: "Terminated At",
                      headerCellClass: "brandPrimaryColor",
                      formatter: ({ row }) =>
                        row.terminated_time && (
                          <Moment format="DD/MM hh:mm a">
                            {row.terminated_time}
                          </Moment>
                        ),
                      resizable: true,
                    },
                  ]
                : []),
              {
                key: "user_id",
                headerCellClass: "brandPrimaryColor",
                name: "User ID",
                formatter: ({ row }) => (
                  <p className="w-32 truncate">{row.user_id}</p>
                ),
              },
              {
                key: "type",
                headerCellClass: "brandPrimaryColor",
                name: "Type",
              },
              {
                key: "payment_metric",
                headerCellClass: "brandPrimaryColor",
                name: "Cost Metric",
              },
              {
                key: "party_id",
                headerCellClass: "brandPrimaryColor",
                name: "Party ID",
              },
              { key: "kwh", headerCellClass: "brandPrimaryColor", name: "kWh" },
              {
                key: "evse_id",
                headerCellClass: "brandPrimaryColor",
                name: "EVSE ID",
                resizable: true,
              },
              {
                key: "connector_number",
                headerCellClass: "brandPrimaryColor",
                name: "Port #",
              },
              {
                key: "total_cost",
                headerCellClass: "brandPrimaryColor",
                name: "Cost",
              },
              {
                key: "error",
                headerCellClass: "brandPrimaryColor",
                name: "Errors",
                formatter: ({ row }) => (
                  <p className="w-52 truncate">{row.error}</p>
                ),
              },
            ]}
            rows={sessions}
            className="text-xs rdg-dark"
            enableCellSelect={true}
          />
        </div>
      </div>
    </div>
  );
};

function filterSession(session, filters) {
  var start_date_time = session.start_time;
  var end_date_time = session.end_time;

  if (!end_date_time) {
    end_date_time = new Date();
  } else {
    end_date_time = new Date(end_date_time);
  }

  if (!start_date_time) {
    start_date_time = new Date();
  } else {
    start_date_time = new Date(start_date_time);
  }

  var duration = (end_date_time - start_date_time) / (1000 * 60 * 60);

  if (
    filters?.minDuration &&
    duration < Number.parseFloat(filters?.minDuration)
  ) {
    //check session duration falls within min and max
    //if the duration is below the min, invalidate
    return false;
  }

  //if the duration is above the max, invalidate
  if (
    filters?.maxDuration &&
    duration > Number.parseFloat(filters?.maxDuration)
  ) {
    return false;
  }

  //if the session statuses don't match, invalidate
  if (filters?.sessionStatus && session?.status !== filters?.sessionStatus) {
    return false;
  }

  //if the session KWH is below the min, invalidate
  if (filters.minKWH && session?.kwh < Number.parseFloat(filters?.minKWH)) {
    return false;
  }

  //if the session KWH is above the max, invalidate
  if (filters.maxKWH && session?.kwh > Number.parseFloat(filters?.maxKWH)) {
    return false;
  }

  //Type coercion here to ensure the date comparison isnt make on strings or something wierd.
  //if the session starttime is before the filter starting date, invalidate
  if (
    filters?.startingDate &&
    +start_date_time < +new Date(filters?.startingDate)
  ) {
    return false;
  }

  //if the session endtime is after the filter finishing, invalidate
  if (
    filters?.finishingDate &&
    +end_date_time > +new Date(filters?.finishingDate)
  ) {
    return false;
  }

  return true;
}

const mapSessionToRow = (session) => {
  var start_time = session.start_time;
  var end_time = session.end_time;
  const duration = moment
    .duration(moment(end_time || undefined).diff(moment(start_time)))
    .format("D[d] H[h] m[m]");
  var kwh = session.kwh;

  if (kwh > 500) {
    kwh = kwh / 1000;
  }

  return {
    id: session.id,
    start_time,
    end_time,
    duration: `${duration}${!end_time ? "+" : ""}`,
    status: session.status,
    type: session.type,
    payment_metric: session.payment_metric || "FREE",
    party_id: session.party_id,
    kwh: kwh.toFixed(2),
    user_id: session.user_id,
    evse_id: session.evse_id,
    connector_number: session.connector_number,
    total_cost: "$" + (session.total_cost / 100).toFixed(2),
    error: session.error || "None",
    terminated_time: session.terminated_time,
    terminated_user_id: session.terminated_user_id,
  };
};
